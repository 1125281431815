import {Button} from '@startlibs/components';
import {TextInput} from '@startlibs/form';
import _ from 'lodash/fp'
import React from 'react'
import {CardWithIcon, PageContainer, RequestStatusIcon,} from '../../components/PageLayout';
import {CaseRequestCard} from '../CaseRequestCard';
import {ConfirmDialog, useConfirmDialog} from '../../hooks/useConfirmDialog';
import {ContactCard} from '../../components/ContactCard';
import {ContactSelectBoxes} from './RecordsStep';
import {ContactSelectInput} from '../../components/ContactSelectInput';
import {Header} from '../../components/Header';
import {PurviewFooter} from '../../components/PurviewFooter';
import {getJwt} from '../../hooks/useJwt';
import {jwtPostFetcher} from '../../utils/authFetch';
import {will} from '@startlibs/utils'
import {CASE_DRAFT} from '../../enums/CaseState'
import {REQUESTED} from '../../enums/ContactRelationKind'
import {isPendingAskingMore} from '../../patient/utils/patientUtils'


export const WaitingPatientUpdates = ({caseRequest,setCaseRequest}) => {
  // const paymentIsPending = isOfflinePaymentPending(caseRequest)


  const isAccepted = _.get('acceptanceInfo.accepted',caseRequest) && _.get('medicalRecordsReleaseLicense',caseRequest)

  const alreadyPaid = useConfirmDialog(<ConfirmDialog
    title="It is longer possible to revoke acceptance"
    closeButton={<Button onClick={() => window.location.reload()}>Close</Button>}
  >
    <p>This case has already been accepted. Click below to refresh the page and see the case.</p>

  </ConfirmDialog>)

  const revokeConfirm = useConfirmDialog(
    <ConfirmDialog
      title="Revoke request"
      confirm={<Button alert>Revoke</Button>}
      action={(v) => jwtPostFetcher(getJwt())("/api/admin/revokeRequestMedicalRecords?requestId="+caseRequest.requestId,v)}
      onSuccess={will(setCaseRequest,_.flow(isPendingAskingMore(caseRequest) ? _.identity : _.set("state", CASE_DRAFT),_.set('caseFlags.requestFlags',{})))}
    >
      <p>The contacted party will no longer have editing permissions.</p>
      <CaseRequestCard caseRequest={caseRequest} viewOnly />
      <p>Do you wish to proceed?</p>
    </ConfirmDialog>
  )

  const reminderConfirm = useConfirmDialog(
    <ConfirmDialog
      title="Send reminder"
      confirm={<Button highlight icon="email">Send</Button>}
      values={caseRequest.caseFlags}
      action={(v) => jwtPostFetcher(getJwt())("/api/admin/sendRequestMedicalRecordsReminder?requestId="+caseRequest.requestId,v)}
      notify="Reminder sent successfully."
    >
      <p>Remind the listed party to complete requested actions on the following case:</p>
      <CaseRequestCard caseRequest={caseRequest} viewOnly />
      <p>The reminder will be sent to:</p>
      <ContactSelectBoxes path="contactRelationType">
        <ContactSelectInput
          raw
          value={true}
          fieldValue={true}
          caseRequest={caseRequest}
          relationKind={REQUESTED}
          viewOnly
        >
        </ContactSelectInput>
      </ContactSelectBoxes>
      <TextInput
        textarea
        autoResize
        label="Message"
        path="message"
        descText="This will be included in the reminder email."
        placeholder="Insert custom message here."
      />
    </ConfirmDialog>
  )

  return <><PageContainer>
      <Header title="Contact patient">
      </Header>
      <CardWithIcon>
        <RequestStatusIcon icon="clock"/>
        <h3>Waiting for updates from the case contact</h3>
        <ContactCard
          caseRequest={caseRequest}
          relationKind={REQUESTED}
        />
        <p>The case contact is reviewing your updates request. You will be notified as soon as updates are provided.</p>
        <p>If the contact takes too long to provide the updates, you can send them a reminder or revoke this updates request, allowing you to edit the related steps again.</p>
        <div  css="margin-top:2rem">
          <Button highlight icon="email" onClick={reminderConfirm}>Send a reminder</Button>
          <Button onClick={revokeConfirm} hover="alert">Revoke request</Button>
        </div>
      </CardWithIcon>
  </PageContainer>
  <PurviewFooter/>
</>
}
