import {BellowFieldDescription, DatePicker, Field, SimpleCheckbox, useFormValue} from '@startlibs/form';
import {SplitColumnsContainer} from '@startlibs/components';
import {getColor, smoothScroll} from '@startlibs/utils'
import React, {useEffect, useRef} from 'react'
import SignaturePad from 'signature_pad'
import styled from 'styled-components'
import {Card, PageFooter, SectionHeading} from '../../components/PageLayout';
import {ReleaseTerms} from './ReleaseTerms'
import {required} from '../../utils/validation'
import {AcceptTermsCheckbox} from '../components/AcceptTermsCheckbox'
import {locationIsEmpty, locationIsNotEmpty} from '../forms/MedicalRecordLocationForm'
import { UPLOAD } from '../../enums/UploaderManagement';
import { getContact, getRelationLabel } from '../../enums/ContactRelationKind';
import { useIntl } from 'react-intl';

const SignaturePadContainer = styled("canvas")`
  background: white;
  border: 1px solid ${getColor('gray210')};
  border-radius: 5px;
  display: block;
  margin-bottom: .5rem;
  width: 100%;
`
const DocumentDateInputsContainer = styled.div`
  display: flex;
  margin-bottom: 2rem;
  > * {
    flex-grow: 1;
    :first-child {
      flex-basis: 60%;
      margin-right: 3rem;
    }
    :last-child {
    }
  }
 ${BellowFieldDescription} {
   margin-top: -0.5rem;
   font-size: 11px;
 }
`

export const ReleaseSignoff = ({caseRequest, signaturePad, focusLocations, mode = '', htmlTerm, setHtmlTerm}) => {

  const intl = useIntl()
  const signatureRef = useRef()
  const dateFrom = useRef()
  const dateTo = useRef()
  const dateExpiration = useRef()
  const header = useRef()

  const contact = getContact(intl, caseRequest)
  const contactRelationType = getRelationLabel(intl,caseRequest.contactDetails.contactRelationType,'representative')

  const [records] = useFormValue('records')

  useEffect(() => {
    const canvas = signatureRef.current
    canvas.width = canvas.offsetWidth;
    signaturePad.openWith(new SignaturePad(canvas, {backgroundColor: "rgb(255,255,255)"}));
    const resizeEvent = () => {
      const imageData = canvas.getContext('2d').getImageData(0, 0, canvas.offsetWidth, canvas.offsetHeight);
      canvas.width = canvas.offsetWidth;
      canvas.getContext('2d').putImageData(imageData, 0, 0)
    }
    window.addEventListener("resize", resizeEvent);
    return () => window.removeEventListener("resize", resizeEvent);
  }, [])

  const focus = (field) => () => {
    if (field === "locations") {
      focusLocations()
    } else {
      smoothScroll({target:header.current,offset:-80})
      if (field === 'dateFrom') {
        dateFrom.current?.focus()
      } else if (field === 'dateTo') {
        dateTo.current?.focus()
      } else if (field === 'dateExpiration') {
        dateExpiration.current?.focus()
      }
    }
  }

  return <>
    <SectionHeading ref={header}>
      <h4>Medical records release</h4>
    </SectionHeading>
    <Card css={mode === UPLOAD ? "padding: 0; border: 0px;" : "" }>
      <DocumentDateInputsContainer>
        <Field
          label="Authorize access to medical records registered between"
          bellowDescText="Only records registered between these dates will be authorized"
          mandatory
        >
          <SplitColumnsContainer>
            <DatePicker
              ref={dateFrom}
              format="MM/dd/yyyy"
              path="dateFrom"
              placeholder="MM/DD/YYYY"
            />
            <DatePicker
              format="MM/dd/yyyy"
              path="dateTo"
              ref={dateTo}
              placeholder="MM/DD/YYYY"
            />
          </SplitColumnsContainer>
        </Field>
        <Field label="Expiration date" bellowDescText="No records will be authorized after this date" mandatory>
          <DatePicker
            format="MM/dd/yyyy"
            path="dateExpiration"
            ref={dateExpiration}
            placeholder="MM/DD/YYYY"
          />
        </Field>
      </DocumentDateInputsContainer>
      <h4>Authorization for Use or Disclosure of Protected Health Information</h4>
      {/* <p>For purposes of this Agreement, “We” or “Us” refers to Medical Institution from which you accessed this system and “You” refers to the Patient identified.</p> */}
      <ReleaseTerms focus={focus} caseRequest={caseRequest} htmlTerm={htmlTerm} setHtmlTerm={setHtmlTerm} isNew={true}/>
      <p css="margin-bottom:2rem">
        <b>By signing below you confirm that you have have read and agree to the above medical records release authorization.</b>
      </p>
      <Field label="Digital signature" descText="Please sign below to agree to the terms." mandatory>
        <SignaturePadContainer height={120} ref={signatureRef} css="height: 120px; max-height: 120px;"/>
      </Field>
      <div>
        <p><b>Signed by: </b><b className='fs-exclude'>{contact.name}</b></p>
        <p><b>Relationship to Patient: {contactRelationType}</b></p>
      </div>
    </Card>
    
    {/*<AcceptTermsCheckbox caseRequest={caseRequest}/>*/}
  </>
}
