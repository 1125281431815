import React from 'react'
import {Errors, WithForm} from '@startlibs/form'
import {useEnsureRef, useToggle} from '@startlibs/core'
import {useNotification} from '../components/Notifications'
import {
  booleanRequired,
  buildValidation,
  confirmEmails,
  emailValidation,
  onlyIfEqual,
  onlyIfNotEqual,
  optional,
  required
} from '../utils/validation'
import {PatientDetails} from './forms/PatientDetails'
import _ from 'lodash/fp'
import {callIfFunction, formatDate, parseDate} from '@startlibs/utils'
import {OTHER, PATIENT, REF_PHYSICIAN} from '../enums/ContactRelationType'
import {ContactDetails} from './forms/ContactDetails'
import {ReferringPhysician} from './forms/ReferringPhysician'
import {InsuranceDetails} from './forms/InsuranceDetails'
import {PrimaryConcern} from './forms/PrimaryConcern'
import {DIGITAL} from '../enums/PaymentMethod'
import {checkInsurance, INSURANCE_REQUIRED_KEYS, removeExtraInsuranceFields} from './forms/utils'
import {PRIVATE, MEDICARE} from '../enums/InsuranceType'
import {fixMedicareInsideInsurances} from './utils'
import {useSetConfirmExitPage} from '../hooks/useConfirmExitPage'
import {AcceptTermsCheckbox, requiredTerms} from './components/AcceptTermsCheckbox'
import {IfLocale, useIsLocale} from '../hocs/IfLocale'
import {SectionHeading} from '../components/PageLayout'
import { CaseReconciliation } from './forms/CaseReconciliation'

const onFailure = (e) => {
  console.log(e)
}

const dobTransform = _.update('dob', (d) => formatDate(d, "yyyy-MM-dd"))
const dobParse = _.update('dob', (d) => d && parseDate(d, null, "yyyy-MM-dd"))

const alreadyHasEmail = (path, caseRequest, props) => caseRequest && _.get(path, caseRequest) === _.get(path, props)


export const PatientAndContactDetails = ({
                                           scrollToContact, scrollToPrimaryConcern, hideErrors, onChange,
                                           action, isPatient, isNurse, isRefPhysician, isPatientCreating, readOnly, onSuccess, onFailure, children, formRef, caseRequest, isAdmin, values = {
    contactDetails: {contactRelationType: isRefPhysician ? REF_PHYSICIAN : PATIENT},
    patientInfo: {insurances:[]},
    referringPhysician: isPatientCreating ? {acceptContact: false} : {},
    caseInfo: {hasNewDiagnosisChangedTreatmentOrRecommendation: null},
    // caseInfo: {},
    categories:[]
  }
                                         }) => {


  const ensuredFormRef = useEnsureRef(formRef)

  // useSetConfirmExitPage(() => !readOnly && ensuredFormRef.current.confirm())

  const [, setNotification] = useNotification()

  const saveAndExitLoading = useToggle()


  const transform = _.flow(
    (values) => values.contactDetails.contactRelationType === REF_PHYSICIAN ? _.set('referringPhysician', {}, values) : values,
    _.update('patientInfo.dob', (date) => formatDate(new Date(date), "MM-dd-yyyy")),
    _.update('patientInfo.insurances', (insurances) => insurances?.filter((insurance,i) => !checkInsurance(insurance,i,true).length)),
    _.update('patientInfo.insurances', (insurances) => insurances.map(removeExtraInsuranceFields)),
    (values) => values.caseInfo.hasBeenTreatedOrSurgeryBefore === false 
      ?  _.update('caseInfo.treatedOrSurgeryBeforeDescription', () => '',values)
      : values
  )

  const isLegal = useIsLocale({contains:'LEGAL'})

  const preValidation = buildValidation({
    'categories': (categories) => categories && categories.find(_.isString) && "Please select a valid category from the list." ,
    'patientInfo.firstName': [required],
    'patientInfo.lastName': [required],
    'patientInfo.dob': [required, (d) => d === -1 && "Invalid date"],
    'patientInfo.gender': [required],
    'contactDetails.contactRelationType': [required],
    'contactDetails.fullName.first': [onlyIfNotEqual('contactDetails.contactRelationType', PATIENT, required)],
    'contactDetails.fullName.last': [onlyIfNotEqual('contactDetails.contactRelationType', PATIENT, required)],
    'contactDetails.otherRelationType': [onlyIfEqual('contactDetails.contactRelationType', OTHER, required)],
    'contactDetails.email': [required, emailValidation],
    'contactDetails.confirmEmail': [(confirm, k, props) => !alreadyHasEmail('contactDetails.email', caseRequest, props) && confirmEmails('contactDetails.email')(confirm, k, props)],
    'contactDetails.phoneNumber': [required],
    'contactDetails.prefCommunicationMode': [required],
    // 'contactDetails.prefContactTime': [required],
    // 'referringPhysician.name': [(v,k,props) => props.contactDetails.relationType !== REF_PHYSICIAN && required(v)],
    'referringPhysician.email': [onlyIfNotEqual('contactDetails.contactRelationType', REF_PHYSICIAN, optional(emailValidation))],
    'referringPhysician.confirmEmail': [onlyIfNotEqual('contactDetails.contactRelationType', REF_PHYSICIAN, (v, k, props) => !alreadyHasEmail('referringPhysician.email', caseRequest, props) && confirmEmails('referringPhysician.email')(v, k, props))],
    'caseInfo.clinicalSummary': [required],
    'caseInfo.intakeDiagnosis': isLegal ? [] : values?.caseInfo?.intakeDiagnosis !== null ? [required] : [],
    'caseInfo.hasBeenTreatedOrSurgeryBefore': isLegal ? [] : [booleanRequired],
    'acceptedTerms': isPatient && requiredTerms(caseRequest)
  }, ({patientInfo: {insurances}}) => {
    if (insurances && insurances.length) {
      const errorsListList = insurances.map((insurance,i) => checkInsurance(insurance,i))
      const hasOneSuccess = errorsListList.find((list, i) => i === 0 && !list.length)
      return {
        ..._.fromPairs(_.flatten(errorsListList.filter(list => hasOneSuccess ? list.length < INSURANCE_REQUIRED_KEYS.length : true)))
      }
    }
  })

  return <WithForm
    alwaysSave
    onChange={onChange}
    ref={ensuredFormRef}
    transform={transform}
    action={action}
    onSuccess={onSuccess}
    onFailure={onFailure}
    preValidation={preValidation}
    values={fixMedicareInsideInsurances(values)}
  >{form => <>
    <PatientDetails isAdmin={isAdmin} readOnly={readOnly}/>
    <ContactDetails scrollTo={scrollToContact} readOnly={readOnly}/>
    <IfLocale not contains='LEGAL'>
      <ReferringPhysician readOnly={readOnly} isAdmin={isAdmin}/>
      <InsuranceDetails readOnly={readOnly} form={form}/>
    </IfLocale>
    <PrimaryConcern readOnly={readOnly} scrollTo={scrollToPrimaryConcern}/>
    {/* <IfLocale not contains='LEGAL'>
      {isAdmin && caseRequest === undefined && <CaseReconciliation/>}
    </IfLocale> */}
    {isPatient && !readOnly && <AcceptTermsCheckbox caseRequest={caseRequest}/>}

    {!hideErrors && <Errors/>}
    {callIfFunction(children, form)}
  </>}</WithForm>
}
