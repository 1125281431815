import dicomParser from 'dicom-parser'
import {getFileNameAndExtension, getUUID} from './common'

const DicomTextValue = (attr, dataSet, required) => {
  const element = dataSet.elements[attr]
  let text = ''

  if (element !== undefined) {
    const str = dataSet.string(attr)
    if (str !== undefined) {
      text = str
    }
  } else if (required) {
    throw new Error(`Element ${attr} not found`)
  }

  /* patientID */
  /* studyUID */
  /* seriesUID */
  /* instanceUID */
  if (
    attr === 'x00100020' ||
    attr === 'x0020000d' ||
    attr === 'x0020000e' ||
    attr === 'x00080018'
  ) {
    text = text.trim()
  }

  return text
}

const isDicomDir = dataSet => {
  const DICOM_DIR_TAG = 'x00020002'
  const element = dataSet.elements[DICOM_DIR_TAG]

  if (element !== undefined) {
    const str = dataSet.string(DICOM_DIR_TAG)
    if (str === '1.2.840.10008.1.3.10') {
      return true
    }
  }

  return false
}

export const instanceFromServerAttributes = (serverInstance, prevInstance) => ({
  isCompliant: true,
  isDicomDir: false,
  instanceNumber: serverInstance.InstanceNumber || prevInstance.instanceNumber,
  instanceUID: serverInstance.SOPInstanceUID || prevInstance.instanceUID,
  patientDoB: serverInstance.PatientBirthDate || prevInstance.patientDoB,
  patientID: serverInstance.PatientID || prevInstance.patientID,
  patientName: serverInstance.PatientName || prevInstance.patientName,
  patientSex: serverInstance.PatientSex || prevInstance.patientSex,
  referringPhysician: serverInstance.ReferringPhysicianName || prevInstance.referringPhysician,
  seriesDate: serverInstance.SeriesDate || prevInstance.seriesDate,
  seriesInstitutionName: serverInstance.InstitutionName || prevInstance.seriesInstitutionName,
  seriesModality: serverInstance.Modality || prevInstance.seriesModality,
  seriesTime: serverInstance.SeriesTime || prevInstance.seriesTime,
  seriesUID: serverInstance.SeriesInstanceUID || prevInstance.seriesUID,
  studyDate: serverInstance.StudyDate || prevInstance.studyDate,
  studyDescription: serverInstance.StudyDescription || prevInstance.studyDescription,
  studyUID: serverInstance.StudyUID || prevInstance.studyUID,
  fileName:
    (prevInstance &&
      (prevInstance.fileName ||
        (prevInstance.sourceFile && getFileNameAndExtension(prevInstance.sourceFile)[0]))) ||
    'Missing filename',
  key: (prevInstance && prevInstance.key) || getUUID(),
})

export const getDicomInstanceJson = (byteArray, requireAll) => {
  const dataSet = dicomParser.parseDicom(byteArray, { untilTag: 'x00200013' })

  try {
    const dicomProperties = {
      patientID: DicomTextValue('x00100020', dataSet),

      patientName: DicomTextValue('x00100010', dataSet),
      patientDoB: DicomTextValue('x00100030', dataSet),

      patientSex: DicomTextValue('x00100040', dataSet),

      studyUID: DicomTextValue('x0020000d', dataSet, true),
      studyDate: DicomTextValue('x00080020', dataSet),
      studyDescription: DicomTextValue('x00081030', dataSet),

      seriesUID: DicomTextValue('x0020000e', dataSet),
      seriesUIDs: [DicomTextValue('x0020000e', dataSet)].filter(Boolean),
      seriesDate: DicomTextValue('x00080021', dataSet),
      seriesTime: DicomTextValue('x00080031', dataSet),
      seriesModality: DicomTextValue('x00080060', dataSet),
      modalities: [DicomTextValue('x00080060', dataSet)].filter(Boolean),
      seriesInstitutionName: DicomTextValue('x00080080', dataSet),

      instanceUID: DicomTextValue('x00080018', dataSet, true),
      referringPhysician: DicomTextValue('x00080090', dataSet),
      instanceNumber: DicomTextValue('x00200013', dataSet),

      isDicomDir: isDicomDir(dataSet),
    }
    const isCompliant = !!dicomProperties.studyUID && !!dicomProperties.instanceUID
      // !!dicomProperties.studyUID && !!dicomProperties.seriesUID && !!dicomProperties.instanceUID

    return { ...dicomProperties, isCompliant }
  } catch (e) {
    // console.log(e)
    return { nonCompliant: true }
  }
}
