import {useToggle} from '@startlibs/core';
import {useLocation} from 'react-router';
import React, { useEffect } from 'react'
import {ALL, ARCHIVE, FINISHED} from '../enums/CaseFilter';
import {AdminKanban} from './dashboard/AdminKanban'
import {AdminResults} from './dashboard/AdminResults'
import {BoxPageContainer, BoxPageLayout, BoxPageWrapper} from '../components/BoxPageLayout';
import {DashboardHeader} from '../components/asyncQueryList/DashboardHeader'
import {DashboardLoader} from '../components/asyncQueryList/DashboardLoader'
import {PurviewFooter} from '../components/PurviewFooter'
import {useQueryParams} from '../hooks/useQueryParams'
import {lazyUserCategories} from './experts/hooks/useEditCategories'
import { lazyProviderInfo, lazyUserInfo } from '../components/WithProvider';

/*  {
    "patient": {
      "lastName": "ads",
      "firstName": "dsadss",
      "dob": {
        "year": 2020,
        "month": "JANUARY",
        "era": "CE",
        "dayOfYear": 2,
        "dayOfWeek": "THURSDAY",
        "leapYear": true,
        "dayOfMonth": 2,
        "monthValue": 1,
        "chronology": {"calendarType": "iso8601", "id": "ISO"}
      },
      "gender": "M",
      "address": null
    },
    "paymentDetails": {
      "paymentType": "MEDICARE",
      "paymentMethod": "DIGITAL",
      "caseCost": 0,
      "additionalFee": null,
      "externallyPaid": false,
      "chargeValue": null,
      "chargeDate": null,
      "stripeToken": null
    },
    "caseInfo": {
      "patientDiagnosis": null,
      "clinicalSummary": "dsaddsds",
      "hasBeenTreatedOrSurgeryBefore": true,
      "questionsConsultant": null,
      "careProviders": null,
      "declineReason": null
    },
    "contactDetails": {
      "email": "cdsaasd@das.com",
      "phoneNumber": "212112",
      "prefCommunicationMode": "EMAIL",
      "prefContactTime": "AFTERNOON",
      "name": null,
      "otherRelationType": null
    },
    "referringPhysician": {"name": "dsadadssad", "email": null, "phoneNumber": null},
    "medicalRecordLocationForm": null,
    "report": null,
    "state": "CASE_DRAFT",
    "deleted": false,
    "whenCreatedEpochMilli": 1577984457975,
    "whenModifiedEpochMilli": 1577984457975,
    "whenReturnedEpochMilli": null,
    "whenSubmittedEpochMilli": null,
    "requestCode": "7RDEQ"
  }
*/
const checkIsKanban = (params) => !params.state
const checkIsFiltering = (params) => params.search || params.to || params.from || params.dob
const ADMIN_FILTERS = [
  {label: "Active", value: ''},
  {label: "Finished", value: FINISHED},
  {label: "Archived",value: ARCHIVE},
  {label: "All",value: ALL}
]

export const AdminDashboard = () => {
  const location = useLocation()

  const userInfo = lazyUserInfo.read()
  const providerInfo = lazyProviderInfo.read()

  const categories = lazyUserCategories.read()
  const [params, setParams, updateParams] = useQueryParams(location, ['from', 'to', 'search', 'state'])
  const isKanban = checkIsKanban(params)
  const isFiltering = checkIsFiltering(params)
  const loading = useToggle()

  useEffect(() => {
    window.FS.setUserVars({
      displayName : userInfo.firstName + ' ' + userInfo.lastName,
      email : userInfo.login,
      id: userInfo.id,
      role: userInfo.role,
      type: "ADMIN",
      customer: providerInfo.name
    })
  }, [])

  return <BoxPageLayout responsiveWorkaround>
    <BoxPageContainer>
      <BoxPageWrapper
        fullWidth
        css="padding-top: 2rem;
        min-height: calc(100vh - 11.5rem);"
      >
        <DashboardHeader
          categories={categories}
          skipPageSize={isKanban}
          params={params}
          setParams={setParams}
          defaultMaxResults={isKanban ? 100 : 20}
          location={location}
          loading={loading}
          stateFilters={ADMIN_FILTERS}
        />
        <DashboardLoader
          skipPagination={isKanban}
          defaultMaxResults={isKanban ? 100 : 20}
          allResults={isKanban}
          url='/api/admin/searchRequests'
          params={params}
          setParams={setParams}
          location={location}
          loading={loading}
          updateParams={updateParams}
        >{({key, refreshList, queryCount, list, setList, params: lodadedParams}) =>
          isKanban
          ? <AdminKanban
            isFiltering={isFiltering}
            list={list}
            refreshList={refreshList}
            setList={setList}
            isLoading={!checkIsKanban(lodadedParams) ||loading.isOpen}
          />
          : <AdminResults
            isFiltering={isFiltering}
            key={key}
            params={params}
            setList={setList}
            refreshList={refreshList}
            isLoading={checkIsKanban(lodadedParams) || loading.isOpen}
            setParams={setParams}
            queryCount={queryCount}
            list={list}
          />
        }</DashboardLoader>
      </BoxPageWrapper>
    </BoxPageContainer>
    <PurviewFooter />
  </BoxPageLayout>
}
