import {Combobox, DatePicker, TextInput} from '@startlibs/form'
import {SplitColumnsContainer} from '@startlibs/components'
import React, { useState } from 'react'
import {MedicalRecordLocationForm} from './MedicalRecordLocationForm';
import { PROVIDERS_LABELS, PROVIDERS_OPTIONS } from '../medicalRelease/ReleaseLocationForms';
import { BIOPSY_FACILITIES,
  HOSPITALS,
  PHYSICIAN_OR_PROVIDER,
  SCAN_FACILITIES,
  OTHER_FACILITIES } from '../../enums/MedicalRecordLocationType';

export const OtherFacilities = ({i,form,path,disabled,toggle}) => {

  const [type, setType] = useState(form.getValue([...path,i,"locationType"]))

  return <MedicalRecordLocationForm disabled={disabled} form={form} path={path} i={i} toggle={toggle}>
    <SplitColumnsContainer>
      <Combobox 
        // path="locationType"
        path={[...path, i, "locationType"]}
        options={PROVIDERS_OPTIONS}
        getLabel={v => PROVIDERS_LABELS[v]}
        label="Institution type"
        onChange={(v) => { setType(v)}}
      />
    </SplitColumnsContainer>
    <SplitColumnsContainer>
      <TextInput
        disabled={disabled}
        path={[...path, i, "name"]}
        label={
          type == OTHER_FACILITIES 
          || type == SCAN_FACILITIES 
          || type == BIOPSY_FACILITIES 
          ? 
            "Facility" 
          : type == HOSPITALS 
            ? "Hospital" : "Physician or provider" 
        }
      />
        
      {type == PHYSICIAN_OR_PROVIDER && <TextInput
          disabled={disabled}
          path={[...path, i, "type"]}
          label="Speciality"
        />
      }
      {type == SCAN_FACILITIES && <TextInput
          disabled={disabled}
          path={[...path, i, "type"]}
          label="Type of scan"
        />
      }
      <TextInput
        disabled={disabled}
        path={[...path, i, "city"]}
        label="City/State"
      />
    </SplitColumnsContainer>
    <SplitColumnsContainer>
      <TextInput
        disabled={disabled}
        path={[...path, i, "contactName"]}
        label="Contact name"
        helpText="(Optional)"
      />
      <TextInput
        disabled={disabled}
        path={[...path, i, "email"]}
        label="Email"
        helpText="(Optional)"
      />
      <TextInput
        disabled={disabled}
        path={[...path, i, "phoneNumber"]}
        label="Phone/Fax"
        helpText="(Optional)"
      />
    </SplitColumnsContainer>
  </MedicalRecordLocationForm>
}