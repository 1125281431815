import {Button} from '@startlibs/components'
import {TextInput} from '@startlibs/form'
import React from 'react'
import {CaseRequestCard} from '../CaseRequestCard'
import {ConfirmDialog, useConfirmDialog} from '../../hooks/useConfirmDialog'
import {PENDING_REQUEST} from '../../enums/CaseState'
import {buildValidation, required} from '../../utils/validation'
import {getJwt} from '../../hooks/useJwt'
import {jwtFormFetcher} from '../../utils/authFetch'

const preValidation = buildValidation({
  "message": required
})

export const useArchiveDialog = (caseRequest,onSuccess) => useConfirmDialog(
  <ConfirmDialog
    title={
      caseRequest.state === PENDING_REQUEST ? "Decline and archive request"
        : "Abort and archive case"
    }
    action={jwtFormFetcher(getJwt())("/api/admin/archiveCase?requestId="+(caseRequest.requestId || caseRequest.id))}
    onSuccess={onSuccess}
    preValidation={preValidation}
    notify={
      caseRequest.state === PENDING_REQUEST ? "Declined and archived successfully."
        : "Case aborted and archived successfully."
    }
    confirm={<Button alert>{caseRequest.state === PENDING_REQUEST ? 'Decline' : 'Abort'} and archive</Button>}
  >
    <p>{caseRequest.state === PENDING_REQUEST ? "The following case request " : "This case "} will no longer be visible under "Active cases":</p>
    <CaseRequestCard viewOnly caseRequest={caseRequest} />
    <p>You will still be able to access this case under "Archived cases" and "All cases".</p>
    <TextInput
      textarea
      mandatory
      autoResize
      path="message"
      label="Reason:"
      descText="This message will be visible to the case contact."
      placeholder={"Provide your reason for " + (caseRequest.state === PENDING_REQUEST ? "declining" : "aborting") + " this case here."}
    />
    <p>Are you sure you want to proceed?</p>
  </ConfirmDialog>
)
