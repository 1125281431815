import {Icon} from '@startlibs/components';
import { darken } from 'polished';
import React from 'react'
import styled from 'styled-components';

export const Star = styled(({ marked, starId, size, className}) => {
    return <Icon 
        icon={marked ? 'star' : 'start-line-ultra-light'}
        data-star-id={starId}
        className={className}
        size={size}
        marked={marked}
        role="button"
      />
  })`
  font-size: ${props => props.size}px;
  color: ${props => props.marked ? '#FFCB2D' : '#c8c8c8'};
  cursor: pointer;
  padding: 0 0.25rem;
  :active {
    color: ${props => darken(0.1, '#FFCB2D')};
    transition: none;
  }
`
  
export const StarRating = ({ value, size = 36, quantity = 5, rating, setRating, selection, setSelection }) => {
    
    const hoverOver = event => {
        let val = 0;
        if (event && event.target && event.target.getAttribute('data-star-id'))
            val = event.target.getAttribute('data-star-id');
        setSelection(val);
    };
    
    return (
      <div
        onMouseOut={() => hoverOver(null)}
        onClick={e => {
            setRating(e.target.getAttribute('data-star-id') || rating)
        }}
        onMouseOver={hoverOver}
      >
        {[... Array(quantity)].map((star, i) => {
            return  <Star
                        starId={i + 1}
                        key={`star_${i + 1}`}
                        size={size}
                        marked={selection ? selection >= i + 1 : rating >= i + 1}
                    />
        })}
      </div>
    );
  };