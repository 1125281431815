import {Icon} from '@startlibs/components';
import {formatDate, getColor, media} from '@startlibs/utils';
import React from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components'
import {DATE_FORMAT} from '../../config/DateFormat'
import {getDateValue} from '../../utils/utils'
import {lazyUserInfo} from '../WithProvider'
import {lazyUserCategories} from '../../admin/experts/hooks/useEditCategories'


const ActiveFilters = styled.div `
  margin-bottom: -1.25rem;
  ${media.max(800) `
    margin-bottom: -0.75rem;
  `}
  margin-top: 0.5rem;
  > span {
    margin-top: 0.25rem;
    background: rgba(0,0,0,0.075);
    border-radius: 50px;
    padding: 0 0.5rem;
    margin-left: 0.25rem;
    line-height: 2rem;
    display: inline-block;
  }
  > span:hover {
    background: rgba(0,0,0,0.1);
    cursor: pointer;
  }
  ${props => props.popupIsOpen && `
    opacity: 0.4;
    pointer-events: none;
  `}
  ${Icon} {
    background: rgba(0,0,0,0.075);
    border-radius: 50%;
    width: 18px;
    height: 18px;
    line-height: 18px;
    margin: 0 -0.25rem 0 0.25rem;
    text-align: center;
    display: inline-block;
    vertical-align: -1px;
    :hover {
      background: #dec0c1;
      color: ${getColor('alert')};
    }
  }
  a {
    color: inherit;
    text-decoration: none;
  }
`

export const ActiveAdvancedFilters = ({advancedPopup,params,setParams,lazyCategories = lazyUserCategories}) => {
  const categories = lazyCategories ? lazyCategories.read() : []
  const userInfo = lazyUserInfo.read()

  const dob = getDateValue('dob',params)
  const from = getDateValue('from',params)
  const to = getDateValue('to',params)
  const categoriesInUse = (params.categories||"").split("|").map(key =>categories.find(({id})=>id.toString()===key)).filter(Boolean)
  const uploadDateSameDay = to && from && to.getTime() === from.getTime()

  const hasFilters = dob || from || to || userInfo.categories?.length > 0 || categoriesInUse.length > 0

  const removeKeys = (...keys) => (e) => {
    keys.forEach(key => {
      setParams(_.unset(key))
    })
    e.stopPropagation()
  }


  if (!hasFilters) return null



  return <ActiveFilters popupIsOpen={advancedPopup.isOpen}>
    <b>Filtering:</b>
    {
      !!categoriesInUse.length && <span onClick={advancedPopup.willOpenWith("categories")}>Cases from <span>{categoriesInUse.map(({name})=> name).join(", ")}</span> division{categoriesInUse?.length > 1 && "s"}<a onClick={removeKeys("categories")}><Icon icon="x"/></a></span>
    }
    {
      userInfo.categories?.length >= 1 && !categoriesInUse.length && <span>Cases from <span>{userInfo.categories.map(({name})=> name).join(", ")}</span> division{userInfo.categories?.length > 1 && "s"}</span>}
    {
      !!dob && <span onClick={advancedPopup.willOpenWith("dob")}>DOB: {formatDate(dob,DATE_FORMAT())}<a onClick={removeKeys("dob")}><Icon icon="x"/></a></span>
    }
    {
      !uploadDateSameDay && !!to && !!from && <span onClick={advancedPopup.willOpenWith("from")}>Created between: {formatDate(from,DATE_FORMAT())} and {formatDate(to,DATE_FORMAT())}<a onClick={removeKeys('to','from')}><Icon icon="x"/></a></span>
    }
    {
      !!uploadDateSameDay && <span onClick={advancedPopup.willOpenWith("from")}>Created on: {formatDate(from,DATE_FORMAT())} <a onClick={removeKeys('from', 'to')}><Icon icon="x"/></a></span>
    }
    {
      !to && !!from && <span onClick={advancedPopup.willOpenWith("from")}>Created after: {formatDate(from,DATE_FORMAT())} <a onClick={removeKeys('from')}><Icon icon="x"/></a></span>
    }
    {
      !!to && !from &&
      <span onClick={advancedPopup.willOpenWith("to")}>Created before: {formatDate(to,DATE_FORMAT())}<a onClick={removeKeys('to')}><Icon icon="x"/></a></span>
    }
  </ActiveFilters>
}
