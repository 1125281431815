import { Button, Icon, Loading, ContextMenu, Li } from '@startlibs/components';
import { callIfFunction, formatDate } from '@startlibs/utils';
import {usePopupToggle} from '@startlibs/core'
import React, {useContext, useEffect, useRef, useState} from 'react'
import _ from 'lodash/fp'

import { AttachmentActions, AttachmentBox, AttachmentCheckbox, AttachmentDescription, AttachmentInfoContainer, DropdownIcon, TextButton } from '../AttachmentBoxStyles';
import { Clinical, Pathology } from '../../enums/RecordClass';
import { useViewRecord } from '../../service/utils/downloadFile';
import styled from 'styled-components';
import { dicomDateToDate } from '../../utils';
import {UploaderConfigContext} from "../../service/UploaderConfigContext";
import {getShortTokenUrl} from "../../service/utils/endpointUtils";
import {useUIDataSelector} from "../../service/hooks/useUIDataSelector";
import {GroupAction} from "../../service/GroupAction";
import {useDoAction} from "../../service/hooks/useDoAction";

const HiddenFilesAttachmentBox = styled(AttachmentBox)`
  padding-right: 0.75rem;
  padding-left: 1rem;
  overflow: hidden;
  min-height:0
`

const FileCheckbox = styled(AttachmentCheckbox)`
  margin: 0 0 0 2rem;
  position: relative;
  :after {
    content: '';
    position: absolute;
    left: -1rem;
    top: -0.75rem;
    bottom: -0.75rem;
    border-left: 1px solid rgba(41,122,163,0.25);
  }
`

const FileThumbnail = styled.div `
  width: 60px;
  height: 60px;
  margin: -0.25rem 0.75rem -0.25rem -0.25rem;
  border-radius: 6px;
  background-color: rgba(41,122,163,0.15);
  background-image: url("${props => props.image}");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex-shrink: 0;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  :after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    pointer-events: none;
  }
  :hover:after {
    background: rgba(0,0,0,0.2);
  }
  ${props => props.isLoading && `
    :after {
      background: rgba(0,0,0,0.5) !important;
    }
  `}
`

const LazyBackgroundImage = ({ viewFileUrl, action, isLoadingViewer, children }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.addEventListener('load', () => setIsLoaded(true));
    image.src = viewFileUrl;
  }, [viewFileUrl]);

  if (!isLoaded) {
    // return null;
    return <FileThumbnail
        image={viewFileUrl}
        onClick={() => {action()}}
        isLoading={isLoadingViewer}
    >
      <Loading white absolute/>
    </FileThumbnail>
  }

  return (
    <FileThumbnail
      image={viewFileUrl}
      onClick={() => {action()}}
      isLoading={isLoadingViewer}
  >
    {isLoadingViewer && <Loading white absolute/>}
  </FileThumbnail>
  );
};

export const Item = ({
                       isLoading,
                       selectedFiles,
                       setSelectedFiles,
                       filelist,
                       file,
                       setFilelist,
                       setCount,
                       setParams,
                       imgToken
                     }) => {
  const config = useContext(UploaderConfigContext)
  const groups = useUIDataSelector('groups')
  const doAction = useDoAction()
  const shortTokenUrl = getShortTokenUrl(config)
  const {requestId,canAddHiddenFileToCase,apiEndpoints:{downloadFiles, viewFileSrc}} = config
  const moveTo = (groupId) => doAction(GroupAction.SimpleMoveByRecordUID, file.recordUID, 'notclass', groupId)

  const tableRef = useRef()
  const contextMenu = usePopupToggle()
  const groupItemsList = groups.find(g => g.id === 'notclass')
  const [openViewerLink,viewerIsLoading] = useViewRecord(config, file)
  const canView = (attachment) => {
    return ["pdf","jpg","jpeg","png","gif","bmp","webp","pdf"].indexOf((attachment.fileExtension || "").toLowerCase())>=0  ||
      attachment.recordClass === Clinical || (attachment.recordClass === Pathology)
  }
  const hasThumbnail = (attachment) => {
    return ["jpg","jpeg","png","gif","bmp","webp"].indexOf((attachment.fileExtension || "").toLowerCase())>=0
  }
  const viewFileUrl = imgToken && viewFileSrc(imgToken, file.recordUID)
  
  function handleClick(id){
    // If contains the id. Must remove it
    if(selectedFiles.findIndex(listItem => listItem === id) >= 0){
      // Found
      const filtered = selectedFiles.filter(listItem => listItem !== id)
      setSelectedFiles(filtered)
    }else{
      // Do not contains. Must add it into the array
      const added = selectedFiles.concat(id)
      setSelectedFiles(added)
    }
  }
  const studyDate =  file.format === 'DicomStudy' ? dicomDateToDate(file.info.studyDate) : null
  var fileLabel = file.info 
    ? file.info.fileExtension && file.info.description.indexOf('.'+file.info.fileExtension) < 0 
      ? file?.info?.description + '.'+file?.info?.fileExtension 
      : file?.info?.description
    : file.fileExtension && file.description.indexOf('.'+file.fileExtension) < 0 
      ? file?.description + '.'+file?.fileExtension 
      : file?.description
  
  return <HiddenFilesAttachmentBox key={file.recordUID} isLoading={isLoading}>
          {/* {hasThumbnail(file) &&
            <FileThumbnail
              image={viewFileUrl}
              onClick={() => {openViewerLink()}}
              isLoading={viewerIsLoading}
            >
              {viewerIsLoading && <Loading white absolute/>}
            </FileThumbnail>
          } */}
          {hasThumbnail(file) && 
            <LazyBackgroundImage 
              viewFileUrl={viewFileUrl} action={openViewerLink} isLoadingViewer={viewerIsLoading}
            ></LazyBackgroundImage>
          }
          <AttachmentInfoContainer>
            {/* <AttachmentDescription>{file.info.fileExtension && file.info.description.indexOf('.'+file.info.fileExtension) < 0 ? file?.info?.description + '.'+file?.info?.fileExtension : file?.info?.description }</AttachmentDescription> */}
            {file.format === 'DicomStudy' 
              ? <AttachmentDescription className="fs-exclude">{file.info.studyDescription ? file.info.studyDescription : "DICOM Study"} {!isNaN(studyDate) && `(${formatDate(studyDate,'MM-dd-yyyy')})`}</AttachmentDescription>
              : <AttachmentDescription className="fs-exclude">{file.originalFileName} {file.originalFileName !== fileLabel && <span css="color:rgba(0,0,0,0.4);"> ({fileLabel})</span>}</AttachmentDescription>
            }
          </AttachmentInfoContainer>
          <AttachmentActions>
            <div className="buttons-wrapper">
              {canView(file) && <TextButton
                  onClick={() => {
                    openViewerLink()
                  }}
                  isLoading={viewerIsLoading}
                >
                  {viewerIsLoading ? <Loading size={15} borderWidth={3}/> : <Icon icon="view"/>}
                  View
                </TextButton>
              }
              {
              <TextButton onClick={() => {
                  shortTokenUrl(requestId)
                    .then((response) => {
                      callIfFunction(downloadFiles(response.jwt, [file.recordUID]))
                    })
                  }}>
                  <Icon icon="download" />
                  Download
                </TextButton>
              }
              {canAddHiddenFileToCase && <Button highlight small onClick={() => contextMenu.openWith(file.recordUID)} withDropdown noFocus css="margin-left:0.75rem;">Add to case
                {
                  contextMenu.isOpen == file.recordUID && 
                  <ContextMenu className="noIcons">
                    {groups.map(group =>
                      (group.name && group.id !== 'notclass')
                        ? <Li 
                            key={group.id+''+file.recordUID}
                            label={group.name} 
                            onClick={() => {
                              moveTo( group.id)
                              var ItemObj = [{'recordUID': file.recordUID}]
                              var newFileList = _.differenceBy(_.property('recordUID'), filelist,  ItemObj )
                              setFilelist(newFileList)
                              setCount(newFileList.length)
                              setSelectedFiles([])
                              setParams(_.set('page', 1),true)
                            }}
                          />
                        : null
                    )}
                    {/* <ListSeparator/> */}
                  </ContextMenu>
                }</Button>
              }
            <FileCheckbox raw 
              setValue={() => {handleClick(file.recordUID)}} 
              value={selectedFiles.findIndex(listItem => listItem === file.recordUID) >= 0}
            />
            </div>
          </AttachmentActions>
  </HiddenFilesAttachmentBox>
}
