import _ from 'lodash/fp'
import React from 'react'
import {isOtherKnowExtension} from "../dicom/FileParser";
import {EXPERT, PROVIDER} from "../enums/UserRoles";
import {Clinical, Pathology, Radiology} from "../enums/RecordClass";
import {getItemSessionUID, getRowsForRecords} from "./utils/recordUtils";
import {DEVICE, DISK} from "../enums/UploaderStepsManagement";
import {NonCompliantDicom} from "../enums/RecordFormat";

const EnhancedRecordClassList = [Pathology, Radiology, Clinical]
export const DEFAULT_GROUPS = EnhancedRecordClassList.map(key =>
  ({id:key,name:key,items:[]})
)

export const GroupAction = {
  SetupNewRecordsGroups: ({setData, getData, config, doAction}, newRecords) => {
    setData(_.update('groups', groups => {
        const inGroups = _.flatten(groups.map(_.get('items')))
        const notInGroups = newRecords.filter(attachment => inGroups.indexOf(getItemSessionUID(attachment)) < 0)
        if (notInGroups.length) {
          // Check if (CLINICAL || RADIOLOGY || PATHOLOGY){
          // Check if desired group exists
          // move to group
          // else
          // create and move to group
          // else
          // put it on ungroupped or other
          notInGroups.map(
            (item) => {
              if (EnhancedRecordClassList.findIndex((type) => type === item.recordClass) >= 0) {
                if (groups.findIndex((g) => g.name === item.recordClass) >= 0) {
                  // group exists
                  const newItem = [getItemSessionUID(item).toString()]
                  groups = _.update(groups.findIndex((g) => g.name === item.recordClass) + '.items', _.union(_, newItem), groups)
                } else {
                  // group do not exist
                  groups = groups.concat({name: item.recordClass, id: Date.now().toString(36), items: []})
                  const newItem = [getItemSessionUID(item).toString()]
                  groups = _.update(groups.findIndex((g) => g.name === item.recordClass) + '.items', _.union(_, newItem), groups)
                }
              } else {
                if (isOtherKnowExtension(item) || config.role === PROVIDER || item.doNotHide) {
                  // put it into other
                  // if (groups.findIndex((g) => g.name === Other) < 0) {
                  //   groups = groups.concat({ name: Other, id: Other, items: [] })
                  // }
                  // const newItem = [getItemSessionUID(item).toString()]
                  // groups = _.update(groups.findIndex((g) => g.id === Other) + '.items', _.union(_, newItem), groups)
                  if (groups.findIndex((g) => g.id === 'ungrouped') < 0) {
                    groups = groups.concat({name: '', id: 'ungrouped', items: []})
                  }
                  const newItem = [getItemSessionUID(item).toString()]
                  groups = _.update(groups.findIndex((g) => g.id === 'ungrouped') + '.items', _.union(_, newItem), groups)

                } else {
                  // put it into unidentified
                  if (groups.findIndex((g) => g.id === 'notclass') < 0) {
                    groups = groups.concat({name: 'Hidden files', id: 'notclass', items: []})
                  }
                  const newItem = [getItemSessionUID(item).toString()]
                  groups = _.update(groups.findIndex((g) => g.id === 'notclass') + '.items', _.union(_, newItem), groups)
                }

              }
            }
          )
          let ungroup = groups.filter(g => g.id === 'ungrouped')
          let unidentified = groups.filter(g => g.id === 'notclass')
          groups = groups.filter(g => g.id !== 'ungrouped' && g.id !== 'notclass')
          if (ungroup.length > 0) {
            if (unidentified.length > 0) {
              groups = [...unidentified, ...ungroup, ...groups]
            } else {
              groups = [...ungroup, ...groups]
              // groups = groups.concat(ungroup, groups)
            }
          } else {
            if (unidentified.length > 0) {
              groups = [...unidentified, ...ungroup, ...groups]
            } else {
              groups = [...ungroup, ...groups]
            }
          }

          return groups
        } else {
          return groups
        }
      }
    ))
    // if (config.role !== PROVIDER) {
      doAction(GroupAction.RefreshGroups)
    // }
  },
  UpdateGroups: ({setData,doAction}, updater) => {
    setData(_.update('groups', updater))
    doAction(GroupAction.RefreshGroups)
  },
  FixUngroupedRecords: ({setData, config, recordManager, doAction}) => {
    setData(_.update('groups', (prevGroups => {
      const rows = getRowsForRecords(recordManager.getRecords())
      const ungrouped = _.orderBy([({uploadDate}) => uploadDate || 0], ['desc'], rows)
        .map(_.get('key'))
      const inGroup = _.flatten(prevGroups.map(_.get('items')))

      const newUngroupeds = _.difference(ungrouped, inGroup)
      if (!newUngroupeds.length) {
        return prevGroups
      } else {
        const notInGroups = newUngroupeds.map((key) => rows.find(item => item.key === key))
        // Classifying new ungrouped records
        // Different rules for JPEGS. If they were uploaded on by one. Or less than max limit, should be shown
        // Otherwise, should be hidden files
        const jpegCount = notInGroups.filter(({fileExtension}) => ['jpg','jpeg'].indexOf(fileExtension?.toLowerCase()) >= 0).length
        const JPEG_SHOW_LIMIT =  window.JPEG_SHOW_LIMIT || 10;
        const hideJpegFiles = jpegCount > JPEG_SHOW_LIMIT
        var patDevJpgs = JSON.parse(window.localStorage.getItem("patDevJpgs")) || []
        var patDiscJpgs = JSON.parse(window.localStorage.getItem("patDiscJpgs")) || []
        window.localStorage.removeItem('patDevJpgs')
        window.localStorage.removeItem('patDiscJpgs')

        let groups = prevGroups
        notInGroups.map(
          (item) => {
            if (EnhancedRecordClassList.findIndex((type) => type === item.recordClass) >= 0) {
              if (groups.findIndex((g) => g.name === item.recordClass) >= 0) {
                // group exists
                const newItem = [item.key+""]
                groups = _.update(groups.findIndex((g) => g.name === item.recordClass) + '.items', _.union(_, newItem), groups)
              } else {
                // group do not exist
                groups = groups.concat({name: item.recordClass, id: Date.now().toString(36), items: []})
                const newItem = [item.key+""]
                groups = _.update(groups.findIndex((g) => g.name === item.recordClass) + '.items', _.union(_, newItem), groups)
              }
            } else {
              if (isOtherKnowExtension(item) || 
                config.role === PROVIDER || 
                (!hideJpegFiles && ['jpg','jpeg'].indexOf(item.fileExtension.toLowerCase()) >= 0) ||
                (patDevJpgs.length > 0 && ['jpg','jpeg'].indexOf(item.fileExtension.toLowerCase()) >= 0 && (patDevJpgs.includes(item.key))  || patDevJpgs.includes(item?.recordUID)) ||
                (patDiscJpgs.length > 0 && ['jpg','jpeg'].indexOf(item.fileExtension.toLowerCase()) >= 0 && (patDiscJpgs.includes(item.key)) || patDiscJpgs.includes(item?.recordUID))
              ) {
                // put it into other
                // if (groups.findIndex((g) => g.name === Other) < 0) {
                //   groups = groups.concat({ name: Other, id: Other, items: [] })
                // }
                // const newItem = [getItemSessionUID(item).toString()]
                // groups = _.update(groups.findIndex((g) => g.id === Other) + '.items', _.union(_, newItem), groups)
                if (groups.findIndex((g) => g.id === 'ungrouped') < 0) {
                  groups = groups.concat({name: '', id: 'ungrouped', items: []})
                }
                const newItem = [item.key+""]
                groups = _.update(groups.findIndex((g) => g.id === 'ungrouped') + '.items', _.union(_, newItem), groups)

              } else {
                // put it into unidentified
                if (groups.findIndex((g) => g.id === 'notclass') < 0) {
                  groups = groups.concat({name: 'Hidden files', id: 'notclass', items: []})
                }
                const newItem = [item.key+""]
                groups = _.update(groups.findIndex((g) => g.id === 'notclass') + '.items', _.union(_, newItem), groups)
              }

            }
          }
        )
        let ungroup = groups.filter(g => g.id === 'ungrouped')
        let unidentified = groups.filter(g => g.id === 'notclass')
        groups = groups.filter(g => g.id !== 'ungrouped' && g.id !== 'notclass')
        if (ungroup.length > 0) {
          if (unidentified.length > 0) {
            groups = [...unidentified, ...ungroup, ...groups]
          } else {
            groups = [...ungroup, ...groups]
            // groups = groups.concat(ungroup, groups)
          }
        } else {
          if (unidentified.length > 0) {
            groups = [...unidentified, ...ungroup, ...groups]
          } else {
            groups = [...ungroup, ...groups]
          }
        }
        return groups
      }
    })))
    doAction(GroupAction.RefreshGroups)
  },
  AddGroup: ({setData,doAction}) => {
    const groupId = Date.now().toString(36)
    setData(_.update('groups', (groups) => {
      const newGroupQtd = groups.filter((g) => g.name === 'New group' || g.name.includes('New group (')).length
      if (newGroupQtd > 0) {
        return _.concat(groups, {name: 'New group (' + newGroupQtd + ')', id: groupId, items: []})
      } else {
        return _.concat(groups, {name: 'New group', id: groupId, items: []})
      }
    }))
    doAction(GroupAction.RefreshGroups)
    return groupId
  },
  Ungroup: ({getData,setData,doAction}, group) => {
    const hasItems = group.items.length > 0
    const UngroupedGroupIndex = getData('groups').findIndex((g) => g.id === 'ungrouped')
    if(!hasItems) {
      setData(_.update('groups', _.flow(
        _.differenceBy(_.get('id'), _, [group]),
        _.update([UngroupedGroupIndex, 'items'], _.concat(group.items))
      )))
    }else{
      if(UngroupedGroupIndex < 0) {
        doAction(GroupAction.UpdateGroups, (groups) =>
          [{name: '', id: 'ungrouped', items: group.items}].concat(groups.filter(g => g.id !== 'ungrouped'))
        )
        setData(_.update('groups', _.flow(
          _.differenceBy(_.get('id'), _, [group])
        )))
      }else{
        setData(_.update('groups', _.flow(
          _.differenceBy(_.get('id'), _, [group]),
          _.update([UngroupedGroupIndex, 'items'], _.concat(group.items))
        )))
      }
    }
    doAction(GroupAction.RefreshGroups)
  },
  MoveRecord: ({setData, getData, config, recordManager, doAction}, source, destination) => {
    const isCollapsed = getData('collapsed').includes(source.droppableId)
    const add = (index, item) => list => isCollapsed ? list.concat(item) : [...list.slice(0, index), item, ...list.slice(index)]
    const remove = (index) => (list) => [...list.slice(0, index), ...list.slice(index + 1)]
    const shouldRemoveNulls = (config.mode !== DEVICE && config.mode !== DISK)
    const flatKeys = recordManager.getRecords().map(_.get('key')).concat(NonCompliantDicom)
    const clearNulls = shouldRemoveNulls
      ? _.filter(item => flatKeys.includes(item))
      : _.filter(item => true)

    setData(_.update('groups',groups => {
      const item = clearNulls(groups.find(({id}) => id === source.droppableId).items)[source.index]
      return groups.map( group =>
          _.flow(
            _.update('items', clearNulls),
            group.id === source.droppableId ? _.update('items', remove(source.index)) : _.identity,
            group.id === destination.droppableId ? _.update('items', add(destination.index, item)) : _.identity
          )(group)
        )
      }
    ))
    doAction(GroupAction.RefreshGroups)
  },
  SimpleMoveByRecordUID: ({doAction,recordManager},recordUID,from,to) => {
    const record = recordManager.getRecords().find((record) => record.recordUID === recordUID)
    if(record){
      doAction(GroupAction.SimpleMoveRecord,record,from,to)
    }
  },
  SimpleMoveRecord: ({doAction,getData},record,fromGroupId,toGroupId) => {
    const currentGroup = _.find(['id',fromGroupId],getData().groups)
    const nextGroup = _.find(['id',toGroupId],getData().groups)
    const recordIndex = currentGroup?.items?.indexOf(record.key)
    if (recordIndex >= 0) {
      doAction(
        GroupAction.MoveRecord,
        {droppableId: fromGroupId, index:recordIndex},
        {droppableId: toGroupId, index: nextGroup?.items?.length || 0}
      )
    }
  },

  RemoveInvalidItems: ({getData,setData, doAction, recordManager}) => {
    const validKeys = recordManager.getRecords().map(_.get('key'))
    const hasNonCompliant = recordManager.getRecords().find(_.matchesProperty('recordFormat',NonCompliantDicom))
    setData(_.update('groups',_.map(_.update('items',_.filter(v => validKeys.includes(v) || (hasNonCompliant && v === NonCompliantDicom))))))
    doAction(GroupAction.RefreshGroups)
  },
  RefreshGroups: ({getData,config, recordManager}) => {
    const clearTemps = _.map(_.update('items',_.flow(_.map(recordManager.getPersistedKey),_.filter(Boolean))))
    const removeSize = _.map(_.unset('size'))

    const retry = () => config.persistGroups(clearTemps(removeSize(getData('groups'))))
      .catch(() =>
        config.setNotification({type:"alert", timeout: 0,msg:(close) => <span>Could not refresh groups <a onClick={() => { close(); retry()}}>Retry</a></span>})
      )
      if (config.role !== PROVIDER && config.role !== EXPERT) {
        retry()
      }
  },
  UnionGroups: ({setData, recordManager},newGroups) => {
    setData(_.update('groups',_.map(group => {
      const newItems = newGroups.find(_.matchesProperty('id', group.id))?.items || []
      return _.update(
        'items',
        _.unionWith((a,b) => recordManager.getPersistedKey(a) === recordManager.getPersistedKey(b), newItems),
        group
      )
    })))
  }

}
