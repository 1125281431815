import { Button, Icon, Loading, Tooltip, TextButton} from '@startlibs/components';
import React, {useEffect, useState} from 'react'
import _ from 'lodash/fp'
import styled, {css} from 'styled-components'
import {Header} from '../../components/Header'
import {Card, PageContainer} from '../../components/PageLayout'
import {PurviewFooter} from '../../components/PurviewFooter'
import { getColor } from '@startlibs/utils';
import { PENDING_REQUEST, WAITING_ACCEPTANCE, UNDER_REVIEW, CASE_REVIEWED, CASE_CLOSED, WAITING_MEDICAL_RECORDS, WAITING_MORE_INFORMATION, WAITING_APPROVAL, PENDING_ASSIGNMENT } from '../../enums/CaseState';
import { formatDateNoUTC } from '../../utils/utils';
import { willUseSuspense } from '../../hooks/useSuspense';
import { jwtGetFetcher } from '../../utils/authFetch';
import { getJwt } from '../../hooks/useJwt';
import { isNotRevoked } from './expertReview/utils';
import { isStateBefore } from '../../request/utils';
import { lazyTerms } from '../../patient/utils/caseRequestUtils';
import { useToggle } from '@startlibs/core';
import { CaseDashboardCard } from '../../components/CaseDashboardCard';
import { useNavigate } from 'react-router';
import { darken, desaturate, lighten } from 'polished';
import { WaitingMedicalRecordsBox } from './info/WaitingMedicalRecordsBox';
import { isPendingAskingMore } from '../../patient/utils/patientUtils';

const ActivityCard = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  font-size: 14px;
  ${Icon} {
    font-size: 24px;
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    text-align: center;
    margin-right: 1.5rem;
    color: black;
    background: rgba(0,0,0,0.075);
    border-radius: 50%;
    position: relative;
  }
  ${TextButton} {
    margin-left: auto;
    margin-right: 0.5rem;
    font-weight: bold;
  }
  .activityDetail {
    font-size: 12px;
    color: rgba(0,0,0,0.5);
    margin-top: 2px;
  }
  & + & {
    margin-top: 3rem;
    ${Icon} {
      :after {
        content: '';
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%,-100%);
        height: 3.25rem;
        width: 2px;
        background: ${getColor('gray240')};
      }
    }
  }
  ${props => props.box && css`
    border-radius: 8px;
    padding: 1rem;
    background: ${getColor('gray240')};
    ${Icon} {
      margin-right: 0.75rem;
    }
  `}
  ${props => props.box && props.pendingActivity && css`
    background: ${getColor('lightYellow')};
  `}
`

const PageTitle = styled.div`
  font-size: 20px;
  color: ${getColor('main')};
  font-weight: 600;
`
const CaseId = styled.div`
  font-size: 14px;
  color: ${getColor('gray90')};
  margin-top: 2px;
` 
const getIconByEventTypeName = (eventTypeName) => {
  switch (eventTypeName) {
    case "Case Accessed":
      return "view"
    case "Case Approval Requested":
      return "email-line"
    case "Case Approved":
      return "check"
    case "Sent reminder to Case Contact":
      return "clock"
    case "Read Only Case Share Revoked from Expert":
      return "physician-line"
    case "Case Declined":
      return "x-circle"
    case "Case Division Added":
      return "plus-circle"
    case "Case Division Removed":
      return "x-circle"
    case "Records Requested":
      return "dicom-no-text"
    case "Case Rejectd To Draft":
      return "x-circle"
    case "Case Reviewed":
      return "checked-report"
    case "Case Review Accepted":
      return "check"
    case "Case Declined by Expert":
      return "physician-line"
    case "Case Unassigned from Expert":
      return "physician-line"
    case "Case Review Completed":
      return "checked-report"
    case "Case Review Update Request":
      return "edit"
    case "Read Only Case Shared to Expert":
      return "physician-line"
    case "Case Assigned to Expert":
      return "physician-line"
    case "Case Updated":
      return "edit"
    case "Case Update Requested":
      return "email-line"
    case "Sent reminder to Expert":
      return "physician-line"
    case "External Report Uploaded":
      return "checked-report"
    case "Fee Added":
      return "payment"
    case "Payment Made":
      return "payment"
    case "Payment Requested":
      return "payment"
    case "Release Forms Requested":
      return "releases"
    case "Request Accepted":
      return "check"
    case "Case Created":
      return "plus-circle"
    case "Patient Information Requested":
      return "email-line";
    case "Video Consult Joined":
      return "video"
    case "Video Consult Link Sent":
      return "video"
    case "Patient Login":
      return "arrow-right-line"
    case "Patient Logout":
      return "arrow-left-line"
    default:
      return "check-files"
  }
}

const useCurrentExpertsSuspense = willUseSuspense((requestId) =>
  jwtGetFetcher(getJwt())(`/api/experts/bycase/${requestId}`)
)

const useLastAuditEventSuspense = willUseSuspense((requestId) =>
  jwtGetFetcher(getJwt())(`/api/case/lastAuditEvent?requestId=${requestId}`)
)

const CheckPoint = styled.div`
  background: ${getColor('gray240')};
  width: 1.5rem;
  height: 1.5rem;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
  position: relative;
  ${props => props.checked && css`
    background: ${getColor('main')};
    color: white;
    box-shadow: 0 0 0 4px ${props => desaturate(0.5, lighten(0.5, getColor("main")(props)))};
  `}
`

const Timeline = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  border-radius: 100px;
  position: relative;
  background:
  ${props => props.isCompleted ? css`
    ${props => desaturate(0.5, lighten(0.5, getColor("main")(props)))}
  ` : props.wasAssigned ? css`
    linear-gradient(to right, ${props => desaturate(0.5, lighten(0.5, getColor("main")(props)))} 67%, white 67% 100%)
  ` : props.wasAccepted ? css`
    linear-gradient(to right, ${props => desaturate(0.5, lighten(0.5, getColor("main")(props)))} 34%, white 34% 100%)  
  ` : `white`};
  padding: 4px;
  margin-bottom: 6rem;
  :after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 100px;
    box-shadow: inset 0 0 0 1px rgba(0,0,0,0.08);
    pointer-events: none;
  }
`
const CheckpointLabel = styled.div`
  text-align: center;
  white-space: nowrap;
  position: absolute;
  top: 2.5rem;
  color: ${getColor('gray60')};
  font-size: 14px;
  ${props => !props.left && !props.right && `
    left: 50%;
    transform: translateX(-50%);
  `}
  ${props => props.left && `
    left: 0;
    text-align: left;
  `}
  ${props => props.right && `
    right: 0;
    text-align: right;
  `}
`

const CaseDashboardCardContainer = styled.div `
  display: flex;
  margin-bottom: 2rem;
`

const ReturnButton = styled(Button) `
  box-shadow: none;
  color: ${getColor('gray60')};
  font-weight: bold;
  ${Icon} {
    font-size: 20px;
  }
`

export const StatusStep = ({caseRequest, setCaseRequest}) => {

  const paidRequests = caseRequest.payments.filter(req => req.paid == true).length
  const paymentRequests = caseRequest.payments.filter(req => req.revoked == false || req.revoked == undefined || req.revoked == null).length
  
  const navigate = useNavigate()
  const viewHistory = useToggle()
  
  const terms = lazyTerms.read(getJwt())
  const termsWithAgreement = terms
    .map(term => caseRequest.acceptanceInfo?.agreements.find(agreement => agreement.serviceTermId === term.id)).filter(Boolean)   
  
  const [rawSharedExperts, setSharedExperts] = useState(useCurrentExpertsSuspense(caseRequest.requestId))
  const sharedExperts = rawSharedExperts.filter(isNotRevoked(caseRequest))
  const reviewedByExpert = sharedExperts && sharedExperts.length > 0 && sharedExperts[0].reviewDateEpochMilli ? true : false;
  const expertName = sharedExperts && sharedExperts.length > 0 && sharedExperts[0]?.expert?.firstName ? sharedExperts[0].expert.firstName + ' '+ sharedExperts[0].expert.lastName : '';

  const [auditEvents, setAuditEvents] = useState([])
  const [loadingEvents, setLoadingEvents] = useState(false)
  const [lastAuditEvent, setLastAuditEvent] = useState(useLastAuditEventSuspense(caseRequest.requestId))
  const [experts, setExperts] = useState([])
  const [admins, setAdmins] = useState([])

  useEffect(() => {
    if(experts.length === 0){
      jwtGetFetcher(getJwt())(`/api/experts?nocache=${new Date().getTime()}`)
      .then((response) => {
        setExperts(response)
      })
    }
    if(admins.length === 0){
      jwtGetFetcher(getJwt())(`/api/admin/searchUsers?nocache=${new Date().getTime()}`)
      .then((response) => {
        setAdmins(response.list)
      })
    }
  },[])
  
  useEffect(() => {
    // scroll to top
    window.scrollTo(0, 0);
    if(viewHistory.isOpen){
      if(auditEvents.length === 0){
        setLoadingEvents(true)
        jwtGetFetcher(getJwt())(`/api/case/auditEvents?requestId=${caseRequest.requestId}`)
        .then((response) => {
          setAuditEvents(response)
          // setAuditEvents(_.uniqBy('event',response)) TODO - change to this to check itens by event
          setLoadingEvents(false)
        })
      }
    }
  },[viewHistory.isOpen])

  const wasCreated = true;
  const wasAccepted = caseRequest.state !== PENDING_REQUEST;
  const wasAssigned = caseRequest.state === WAITING_ACCEPTANCE 
  || caseRequest.state === UNDER_REVIEW 
  || caseRequest.state === CASE_REVIEWED 
  || caseRequest.state === CASE_CLOSED;
  // || caseRequest.state === CASE_ARCHIVED
  // || caseRequest.state === REQUEST_REJECTED;
  const isCompleted = caseRequest.state === CASE_CLOSED;

  // Sometimes, the dateWhenAccepted is null and the case is already in case draft, so we will use the date when created
  const dateWhenAccepted = caseRequest.whenAcceptedEpochMilli !== null 
    ? caseRequest.whenAcceptedEpochMilli : caseRequest.whenCreatedEpochMilli;

  const readOnly = [WAITING_MEDICAL_RECORDS, UNDER_REVIEW, WAITING_MORE_INFORMATION, WAITING_APPROVAL, WAITING_ACCEPTANCE, CASE_CLOSED].indexOf(caseRequest.state) >= 0
  const wasSentToExpert = !isStateBefore(caseRequest.state)(WAITING_ACCEPTANCE)

  const findUserByEventAndMail = (event, email) => {
    return event.indexOf("Patient") >= 0 
      ? email 
      : admins.find(adm => adm.email === email) 
        ? admins.find(adm => adm.email === email).firstName + ' ' + admins.find(adm => adm.email === email)?.lastName + ' ('+email+')'
        : experts.find(exp => exp.expertEmail === email) 
          ? experts.find(exp => exp.expertEmail === email).firstName + ' ' + experts.find(exp => exp.expertEmail === email)?.lastName + ' ('+email+')'
          : email === 'net.purview.security.ReadOnlyAccessPrincipal' 
            ? 'Read Only Access'
            : email
  }

  return <>
    <PageContainer>
      <Header title="Case status and activity"></Header>
      {!viewHistory.isOpen && <Card>

        {/* Header */}
        <PageTitle className="fs-exclude">{(caseRequest.firstName || caseRequest.patientInfo?.firstName) 
        + ' ' + ((caseRequest.middleName ? caseRequest.middleName + ' ' : '') || (caseRequest.patientInfo?.middleName ? caseRequest.patientInfo?.middleName + ' ' : '')) 
        + (caseRequest.lastName || caseRequest.patientInfo?.lastName)}</PageTitle>
        {caseRequest.requestCode && <CaseId><b>Case Id:</b> {caseRequest.requestCode}</CaseId>}


        {/* Timeline with dates and actions */}
        <Timeline wasAccepted={wasAccepted} wasAssigned={wasAssigned} isCompleted={isCompleted}>
          <Tooltip content={formatDateNoUTC(new Date(caseRequest.whenCreatedEpochMilli), "MM/dd/yyyy at hh:mm")}>
            <CheckPoint checked={wasCreated}>
              {wasCreated && <Icon icon="check"/>}
              <CheckpointLabel left>
                  <b>Created</b>
                  <p>{formatDateNoUTC(new Date(caseRequest.whenCreatedEpochMilli), "MM/dd/yyyy")}</p>
              </CheckpointLabel>
            </CheckPoint>
          </Tooltip>
          <Tooltip content={wasAccepted ? formatDateNoUTC(new Date(dateWhenAccepted), "MM/dd/yyyy at hh:mm") : null}>
            <CheckPoint checked={wasAccepted}>{wasAccepted && <Icon icon="check"/>}
              <CheckpointLabel style={{opacity: wasAccepted ? 1 : '0.3' }}>
                  <b>Accepted</b>
                  {wasAccepted && <p>{formatDateNoUTC(new Date(dateWhenAccepted), "MM/dd/yyyy")}</p>}
              </CheckpointLabel>
            </CheckPoint>
          </Tooltip>
          <Tooltip content={wasAssigned ? formatDateNoUTC(new Date(caseRequest.whenSubmittedEpochMilli), "MM/dd/yyyy at hh:mm") : null}>
            <CheckPoint checked={wasAssigned}>{wasAssigned && <Icon icon="check"/>}
              <CheckpointLabel style={{opacity: wasAssigned ? 1 : '0.3' }}>
                  <b>Assigned</b>
                  {wasAssigned && <p>{formatDateNoUTC(new Date(caseRequest.whenSubmittedEpochMilli), "MM/dd/yyyy")}</p>}
              </CheckpointLabel>
            </CheckPoint>
          </Tooltip>
          <Tooltip content={isCompleted ? formatDateNoUTC(new Date(caseRequest.whenReturnedEpochMilli), "MM/dd/yyyy at hh:mm") : null}>
            <CheckPoint checked={isCompleted}>{isCompleted && <Icon icon="check"/>}
              <CheckpointLabel style={{opacity: isCompleted ? 1 : '0.3'}} right>
                  <b>Completed</b>
                  {isCompleted && <p>{formatDateNoUTC(new Date(caseRequest.whenReturnedEpochMilli), "MM/dd/yyyy")}</p>}
              </CheckpointLabel>
            </CheckPoint>
          </Tooltip>
        </Timeline>
        
        {/* Cards with numbers */}
        <CaseDashboardCardContainer>
          <CaseDashboardCard 
            mainNumber={termsWithAgreement.length+" of "+terms.length} 
            footerText={"Agreements signed"} 
            success={termsWithAgreement.length - terms.length === 0} 
            title="Check agreements"
            path={"agreements"}
          />
          {/* <CaseDashboardCard 
            disabled={medicalRecordsSize === null} 
            isLoading={medicalRecordsSize === null} 
            mainNumber={medicalRecordsSize} 
            footerText={"Medical records"} 
            success={medicalRecordsSize>0}
            title="Check medical records" 
            path={"records"}
          /> */}
          <CaseDashboardCard 
            disabled={false} 
            isLoading={false} 
            mainNumber={paymentRequests > 0 ? paidRequests+" of "+paymentRequests : null}
            headerText={paymentRequests == 0 ? "Payment" : null} 
            mainText={paymentRequests == 0 ? "No fee added" : null} 
            footerText={paymentRequests > 0 ? "Fees paid" : null} 
            success={paymentRequests === paidRequests && paymentRequests > 0}
            title="Check payments" 
            path={"payment"}
          />
          <CaseDashboardCard 
            headerText={"Assigned to:"} 
            mainText={wasAssigned ? expertName : caseRequest.state === PENDING_ASSIGNMENT ? "Assign now": "Not provided"} 
            success={wasAssigned} 
            disabled={!wasAssigned && caseRequest.state !== PENDING_ASSIGNMENT}
            title="View experts" 
            path={"expert"}
          />
          <CaseDashboardCard 
            headerText={"Report"} 
            mainText={reviewedByExpert ? isCompleted ? "Completed" : "Writing" : "Not provided"} 
            success={isCompleted} 
            disabled={!reviewedByExpert}
            title="View report" 
            path={"report"}
          />
        </CaseDashboardCardContainer>


        {/* Activity */}
        <h4>Last update:</h4>
        <ActivityCard box>
          <Icon icon={getIconByEventTypeName(lastAuditEvent.event)} />
          <div css="margin-right:1rem;">  
            <div><b>{lastAuditEvent.event}</b></div>
            <div className='activityDetail'>{formatDateNoUTC(new Date(lastAuditEvent.createdEpochMili), "MM/dd/yyyy at hh:mm")} by {findUserByEventAndMail(lastAuditEvent.event,lastAuditEvent.who)}</div>
          </div>
          <TextButton onClick={() => viewHistory.toggle()}>View history</TextButton>
        </ActivityCard>


        {/* Pending */}
        {readOnly && wasSentToExpert && <>
          <h4 css="margin-top:2rem;">Pending:</h4>
          <ActivityCard box pendingActivity>
            <Icon icon={caseRequest.state === CASE_CLOSED ? 'check' : 'clock'}/>
            <div css="margin-right:1rem;">  
              <div>{caseRequest.state === CASE_CLOSED ? 'This case is already finished.' : 'Expert review'}</div>
              <div className='activityDetail'>{caseRequest.state === CASE_CLOSED ? 'The report is already concluded and available for the case contact.' : expertName + ' is currently reviewing this case.'}</div>
            </div>
            <TextButton onClick={() => {navigate("../expert")}}>View experts</TextButton>
          </ActivityCard>
        </>}
        {!(caseRequest.state !== WAITING_MEDICAL_RECORDS && !isPendingAskingMore(caseRequest)) && <>
          <h4 css="margin-top:2rem;">Pending:</h4>
          <WaitingMedicalRecordsBox caseRequest={caseRequest} setCaseRequest={setCaseRequest} small />
        </>}
      </Card>}

      {viewHistory.isOpen && <Card>
        <ReturnButton noShadow rounded color="gray240" onClick={()=>viewHistory.toggle()} icon="return">Return to dashboard</ReturnButton>
        <PageTitle css="margin:2rem 0 1.5rem;">Case history</PageTitle>

        {loadingEvents && <Loading />}

        {!loadingEvents && auditEvents.length === 0 && <p>No history</p>}

        {!loadingEvents && auditEvents.map((auditEvent, index) => 
          <ActivityCard timeline={true}>
            <Icon icon={getIconByEventTypeName(auditEvent.event)} />
            <div>
              <div><b>{auditEvent.event}</b></div>
              <div className='activityDetail'>{formatDateNoUTC(new Date(auditEvent.createdEpochMili), "MM/dd/yyyy at hh:mm")} by {findUserByEventAndMail(auditEvent.event, auditEvent.who)}</div>
            </div>
          </ActivityCard>
        )}
        
      </Card>}
      
    </PageContainer>
    
    <PurviewFooter/>
  </>
}
