import {createNewRecord, EmptyFileError, BlackListedError, RecordClass} from './utils'
import _ from 'lodash/fp'
import {processPathologyFiles} from './processPathologyFiles'
import {createSimpleRecord, isNonDicom, processNonDicomFiles} from './processNonDicomFiles'
import {processDicomFile} from './processDicomFile'
import {createRecord} from "../components/processing/MultiFilePathologyParser";
import {NonCompliantDicom} from "../enums/RecordFormat";

const discardMacOSApps = files => {
  const invalidPaths = _.uniq(
    files
      .map(({ path }) => path.match(/(.*\.[aA][pP][pP]\/)Contents\/MacOS\//))
      .filter(Boolean)
      .map(([wholePath, initialPath]) => initialPath)
  )
  return _.partition(
    ({ path }) => !invalidPaths.find(invalidPath => path.indexOf(invalidPath) === 0),
    files
  )
}
const createNonCompliantDicom = (instance) => ({
  // description:possibleDicomFile.filename,
  description:instance.filename,
  recordFormat: NonCompliantDicom,
  recordClass: RecordClass.Radiology,
  // fileExtension: possibleDicomFile.extension,
  fileExtension: instance.extension,
  files: [instance]
})

export function* processAllFiles(dirtyFiles,adminUserEmail = '') {
  const [instances,discardedMacOSFiles] = discardMacOSApps(dirtyFiles)

  yield [[],[],discardedMacOSFiles]
  let remainingNonPathologyFiles = []
  let processedFiles = []

  /* eslint-disable no-restricted-syntax */
  for (const asyncResult of processPathologyFiles(instances)) {
    yield Promise.resolve(asyncResult).then(([pathologyRecords, remainingFilesToProcess, processedFiles]) => {
      remainingNonPathologyFiles = remainingNonPathologyFiles.concat(remainingFilesToProcess)
      return [pathologyRecords, [], processedFiles]
    })
  }

  let remainingPossibleDicomFiles = []
  /* eslint-disable no-restricted-syntax */
  for (const asyncResult of processNonDicomFiles(remainingNonPathologyFiles,adminUserEmail)) {
    yield Promise.resolve(asyncResult).then(([nonDicomRecords, remainingFilesToProcess, processedFiles]) => {
      remainingPossibleDicomFiles = remainingPossibleDicomFiles.concat(remainingFilesToProcess)
      return [nonDicomRecords, [], processedFiles]
    })
  }

  const [invalidNonDicomExtensions, possibleDicomFiles] = _.partition(
    isNonDicom,
    remainingPossibleDicomFiles
  )
  
  // yield [[],[],invalidNonDicomExtensions]
  yield [[],invalidNonDicomExtensions,invalidNonDicomExtensions]

  let invalidProcessedDicom = []
  /* eslint-disable no-await-in-loop */
  for (const possibleDicomFile of possibleDicomFiles) {
    yield processDicomFile(possibleDicomFile,adminUserEmail)
      .then(record => [[record], [], [possibleDicomFile]])
      .catch(e => {
        if (e instanceof EmptyFileError) {
          invalidProcessedDicom = invalidProcessedDicom.concat(possibleDicomFile)
          return [[],[possibleDicomFile],[possibleDicomFile]]
        } else  if (e instanceof BlackListedError) {
          invalidProcessedDicom = invalidProcessedDicom.concat(possibleDicomFile)
          return [[],[possibleDicomFile],[possibleDicomFile]]
        } else if (e.nonCompliant) {
          return [[createNonCompliantDicom(possibleDicomFile)],[],[possibleDicomFile]]
        } else {
          return [[createSimpleRecord(possibleDicomFile)],[],[possibleDicomFile]]
        }
      })
  }
}
