import {Route, Routes, useLocation, useNavigate, useParams} from 'react-router'
import React, {useEffect, useRef, useState} from 'react'
import {PaymentStep} from './steps/PaymentStep'
import {AdminRequestOverview} from './steps/AdminRequestOverview'
import {CASE_ARCHIVED, getDefaultPath} from '../enums/CaseState';
import {ExpertAssignmentStep} from './steps/ExpertAssignmentStep'
import {ExpertReviewStep} from './steps/ExpertReviewStep'
import {FinalReportStep, PrintPdfPreview} from './steps/FinalReportStep'
import {PageContent, PageWrapper, RequestSidebar} from './steps/RequestSidebar'
import {PageLoadingSuspense} from '../components/PageLoading'
import {PatientStep} from './steps/PatientStep'
import {RecordsStep} from './steps/RecordsStep'
import {RejectedCase} from './steps/RejectedCase'
import {getJwt} from '../hooks/useJwt'
import {jwtGetFetcher, jwtPostFetcher} from '../utils/authFetch'
import {willUseSuspense, wrapPromise} from '../hooks/useSuspense'
import {isAlreadyAccepted, isAlreadyPaid} from '../request/utils'
import {ReleaseStep} from './steps/ReleaseStep'
import {ClinicalInformationStep} from './steps/ClinicalInformationStep'
import {ContactPatientStep} from './steps/ContactPatientStep'
import {RemoteConsultationStep} from './steps/RemoteConsultationStep'
import {AgreementsStep} from './steps/AgreementsStep'
import {getTimezoneOffset} from '../utils/utils'
import {CaseNotesStep} from './steps/CaseNotesStep'
import {useToggle} from '@startlibs/core'
import {CaseNotesPanel} from '../components/panel/CaseNotesPanel'
import {Redirect} from "../components/Redirect";
import _ from 'lodash/fp'
import { lazyProviderInfo, lazyUserInfo } from '../components/WithProvider'
import { StatusStep } from './steps/StatusStep';
import { ReconciliationStep } from './steps/ReconciliationStep';


const useAuthSuspense = willUseSuspense((requestCode) => jwtGetFetcher(getJwt())(`/api/viewCaseRequest?requestCode=${requestCode}&timezoneOffset=${getTimezoneOffset()}`))
// const useAuthSuspense = willUseSuspenseNew((requestCode) => jwtGetFetcher(getJwt())(`/api/viewCaseRequest?requestCode=${requestCode}&timezoneOffset=${getTimezoneOffset()}`))
// const caseData = wrapPromise((requestCode) => jwtGetFetcher(getJwt())(`/api/viewCaseRequest?requestCode=${requestCode}&timezoneOffset=${getTimezoneOffset()}`))

const Identity = () => null

export const AdminRequest = () => {
  const {requestCode} = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const userInfo = lazyUserInfo.read()
  const providerInfo = lazyProviderInfo.read()

  const panelToggle = useToggle()
  const [caseRequest, setCaseRequest] = useState(useAuthSuspense(requestCode))
  // const [caseRequest, setCaseRequest] = useState(caseData.read(requestCode))
  const [isLoadingNotes, setLoadingNotes] = useState(false)
  const [caseNotes, setCaseNotes] = useState([])
  const panelFormRef = useRef()

  useEffect(() => {
    var now = new Date()
    var eventProperties = {
      adminName_str: userInfo.firstName + ' ' + userInfo.lastName,
      adminMail_str: userInfo.login,
      adminId_int: userInfo.id,
      customer_str: providerInfo.name,
      dateTime_date: now,
      requestId_int: caseRequest.requestId,
      caseId_str: caseRequest.requestCode,
      caseState_str: caseRequest.state
    }
    window.FS.event('Admin Case Accessed',eventProperties)
  }, [])
    
  // This will be triggered on close tab/window when panel is open and note active / not saved 
  useEffect(() => {
    const onUnload = function (e) {
      if(!panelToggle.isOpen){
        return;
      }
      if (!panelFormRef.current || !panelFormRef.current.hasChanged) {
        return;
      }
      // Cancel the event
      e.preventDefault();
      // Chrome requires returnValue to be set
      e.returnValue = '';
    }
    window.addEventListener('beforeunload', onUnload);
    return () => window.removeEventListener('beforeunload', onUnload)
  },[location, panelToggle.isOpen, panelFormRef.current])

    // This will be triggered on browser back button when panel is open and note active / not saved 
    useEffect(() => {
      const warningText =
      'You have unsaved changes - are you sure you wish to leave this page?';
      const onPopState = function (e) {
      
        if (!panelFormRef.current || !panelFormRef.current.hasChanged) {
          return;
        }
        
        if(!panelToggle.isOpen){
          return; 
        }

        if(e.target.location.pathname.includes('admin/request') && (
            !e.target.location.pathname.includes('/releases') 
            && !e.target.location.pathname.includes('/records') 
            && !e.target.location.pathname.includes('/remote-consultation')
          )){
          return;
        }
        
        if (window.confirm(warningText)){ return;}
        navigate(location.pathname);
      }
      window.addEventListener('popstate', onPopState);
      return () => window.removeEventListener('popstate', onPopState)
    },[location, panelToggle.isOpen, panelFormRef.current ])

  useEffect(() => {
    if (caseRequest.caseFlags?.adminUpdateReceived) {
      jwtPostFetcher(getJwt())("/api/admin/unsetAdminUpdateReceived?requestId=" + caseRequest.requestId)
    }
  }, [])

  useEffect(() => {
    if (caseRequest.requestId) {
      setLoadingNotes(true);
      jwtGetFetcher(getJwt())("/api/case/notes?requestId=" + caseRequest.requestId)
        .then(response => {setCaseNotes(response); setLoadingNotes(false)})
    }
  }, [])

  if (location.pathname.indexOf("printPdfPreview")>0) {
    return <PrintPdfPreview caseRequest={caseRequest} path="printPdfPreview" />

  }

  return <PageWrapper responsiveWorkaround noSideBar={caseRequest.state === CASE_ARCHIVED}>
      {
        caseRequest.state !== CASE_ARCHIVED &&
        <React.Suspense fallback={null}>
          <RequestSidebar caseRequest={caseRequest} setCaseRequest={setCaseRequest} panelToggle={panelToggle} default />
        </React.Suspense>
      }
    <PageContent>
      <PageLoadingSuspense>
        <Routes>
          <Route path="case-status" element={<StatusStep caseRequest={caseRequest} setCaseRequest={setCaseRequest}/>}/>
          {/* <Route path="case-reconciliation" element={<ReconciliationStep caseRequest={caseRequest} setCaseRequest={setCaseRequest}/>}/> */}
          <Route path="patient" element={<PatientStep caseRequest={caseRequest} setCaseRequest={setCaseRequest}/>}/>
          <Route path="agreements" element={<AgreementsStep caseRequest={caseRequest} setCaseRequest={setCaseRequest}/>}/>
          <Route path="records" element={<SuspenseStep Component={RecordsStep} caseRequest={caseRequest} setCaseRequest={setCaseRequest}/>}/>
          <Route path="releases" element={<SuspenseStep Component={ReleaseStep} caseRequest={caseRequest} setCaseRequest={setCaseRequest}/>}/>
          <Route path="payment" element={<PaymentStep caseRequest={caseRequest} setCaseRequest={setCaseRequest}/>}/>
          <Route path="contact" element={<ContactPatientStep caseRequest={caseRequest} setCaseRequest={setCaseRequest}/>}/>
          <Route path="clinical" element={<ClinicalInformationStep caseRequest={caseRequest} setCaseRequest={setCaseRequest}/>}/>
          {/* <CaseNotesStep path="notes" caseRequest={caseRequest} setCaseRequest={setCaseRequest} caseNotes={caseNotes} setCaseNotes={setCaseNotes} openPanel={panelToggle} /> */}
          <Route path="notes/*" 
            element={<SuspenseStep
            Component={CaseNotesStep}
            caseRequest={caseRequest}
            setCaseRequest={setCaseRequest}
            caseNotes={caseNotes} 
            setCaseNotes={setCaseNotes} 
            openPanel={panelToggle}
            isLoadingNotes={isLoadingNotes}
            location={location}
          />}/>
          <Route path="expert" element={<SuspenseStep
            Component={ExpertAssignmentStep}
            caseRequest={caseRequest}
            setCaseRequest={setCaseRequest}
          />}/>
          <Route path="rejected" element={<SuspenseStep
            Component={RejectedCase}
            caseRequest={caseRequest}
            setCaseRequest={setCaseRequest}
          />}/>
          <Route path="remote-consultation" element={<SuspenseStep Component={RemoteConsultationStep} setCaseRequest={setCaseRequest} caseRequest={caseRequest}/>}/>
          <Route path="review" element={<SuspenseStep Component={ExpertReviewStep} caseRequest={caseRequest} setCaseRequest={setCaseRequest}/>}/>
          <Route path="report" element={<SuspenseStep Component={FinalReportStep} caseRequest={caseRequest} setCaseRequest={setCaseRequest}/>}/>
          <Route path="upload-report" element={<SuspenseStep Component={FinalReportStep} uploadPDF caseRequest={caseRequest} setCaseRequest={setCaseRequest}/>}/>
          <Route path="overview" element={<AdminRequestOverview caseRequest={caseRequest} setCaseRequest={setCaseRequest}/>}/>
          <Route path="*" element={<Redirect
            to={`/admin/request/${requestCode}/${getDefaultPath(caseRequest, isAlreadyAccepted(caseRequest) || isAlreadyPaid(caseRequest))}`}
            default
            replace
            noThrow
          />}/>
        </Routes>
      </PageLoadingSuspense>
      <CaseNotesPanel formRef={panelFormRef} caseRequest={caseRequest} open={panelToggle.isOpen} caseNotes={caseNotes} setCaseNotes={setCaseNotes} close={panelToggle.close} isLoadingNotes={isLoadingNotes}/>
    </PageContent>
  </PageWrapper>
}

const SuspenseStep = ({Component, ...props}) => <PageLoadingSuspense><Component {...props} /></PageLoadingSuspense>
