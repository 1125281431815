import {Button, Link} from '@startlibs/components'
import { TextInput } from '@startlibs/form';
import React from 'react'
import {FormattedMessage} from 'react-intl'
import _ from 'lodash/fp'
import {CaseRequestCard} from '../../CaseRequestCard'
import {
  ConfirmDialog,
  useConfirmDialogWithProps
} from '../../../hooks/useConfirmDialog';
import {ContactSelectBoxes, SelectorTitle} from '../RecordsStep'
import {ContactSelectInput} from '../../../components/ContactSelectInput'
import {PRIMARY} from '../../../enums/ContactRelationKind'
import {REF_PHYSICIAN} from '../../../enums/ContactRelationType'
import {PENDING_REQUEST, WAITING_MEDICAL_RECORDS} from '../../../enums/CaseState'
import {buildValidation} from '../../../utils/validation'
import {getJwt} from '../../../hooks/useJwt'
import {jwtPostFetcher} from '../../../utils/authFetch'
import {isPendingAskingMore} from '../../../patient/utils/patientUtils'

const preValidation = buildValidation({
  contactRelationType: (v) => !v && 'Please choose a contact before proceeding.',
})

const onlyPayment = (requestFlags) => requestFlags.askForPayments && Object.values(requestFlags).filter(_.identity).length === 1

const isSendToWait = (values,caseRequest) => (values.contactRelationType !== caseRequest.contactDetails.contactRelationType || values.requestFlags.askedRecords || values.requestFlags.askedPatientDetails)

export const useConfirmAskPatientUpdate = (caseRequest, setCaseRequest) => useConfirmDialogWithProps((requestFlags) =>
  <ConfirmDialog
    title="Request updates"
    confirmChanges={false}
    confirm={<Button highlight icon="email">Request</Button>}
    values={{
      contactRelationType: caseRequest.referringPhysician.email ? undefined : caseRequest.contactDetails.contactRelationType,
      requestFlags: _.isObject(requestFlags) ? requestFlags : {}
    }}
    action={(values) => onlyPayment(requestFlags) ? jwtPostFetcher(getJwt())('/api/admin/requestPatientPayment?requestId=' + caseRequest.requestId, values) : jwtPostFetcher(getJwt())('/api/admin/contactPatient?requestId=' + caseRequest.requestId, values)}
    preValidation={preValidation}
    onSuccess={(values) => setCaseRequest(_.flow(
        (onlyPayment(requestFlags) || caseRequest.state === PENDING_REQUEST) ? _.identity : _.set('state', WAITING_MEDICAL_RECORDS),
      _.set('caseFlags.requestFlags', values.requestFlags),
      _.set('contactDetails.requestMedicalRecordsContactRelationType', values.contactRelationType)
      ))
    }
    notify={onlyPayment(requestFlags) ? "Payment requested successfully." : "Updates requested successfully."}
  >
    <p><FormattedMessage defaultMessage="You are about to request that the indicated contact complete the previously selected actions on this case:" description="useConfirmAskPatientUpdate subtitle"/></p>
    <CaseRequestCard
      viewOnly
      caseRequest={caseRequest}
    />
    {
      caseRequest.referringPhysician.email
      ? <>
        <SelectorTitle><span>Select a contact:</span> <Link
          className="link"
          to={`/admin/request/${caseRequest.requestCode}/patient`}
          state={{scrollToContact:true}}
        >Edit contacts</Link></SelectorTitle>
        <ContactSelectBoxes path="contactRelationType">
          <ContactSelectInput
            fieldValue={caseRequest.contactDetails.contactRelationType}
            caseRequest={caseRequest}
            relationKind={PRIMARY}
          />
          <ContactSelectInput
            fieldValue={REF_PHYSICIAN}
            caseRequest={caseRequest}
            relationKind={REF_PHYSICIAN}
          />
        </ContactSelectBoxes> </>
      : <>
        <SelectorTitle><span>An e-mail will be delivered to the case primary contact with your request:</span> <Link
          className="link"
          state={{scrollToContact:true}}
          to={`/admin/request/${caseRequest.requestCode}/patient`}
        >Edit contacts</Link></SelectorTitle>
        <ContactSelectBoxes path="contactRelationType">
          <ContactSelectInput
            raw
            value={true}
            fieldValue={true}
            caseRequest={caseRequest}
            relationKind={PRIMARY}
            viewOnly
          >
          </ContactSelectInput>
        </ContactSelectBoxes>
      </>
    }
    <TextInput
      textarea
      autoResize
      label="Message"
      path="message"
      descText={<FormattedMessage defaultMessage="This will be shown to the contact along with the request." description="useConfirmAskPatientUpdate message input description" />}
      placeholder="Insert custom message here."
    />
  {!onlyPayment(requestFlags) && <p><FormattedMessage defaultMessage='To avoid data loss, you will not be able to update the "Patient & contact details" of this case until the contact is finished or the request is revoked.' description='useConfirmAskPatientUpdate request observation'/></p>}
  </ConfirmDialog>
)
