import {Icon} from '@startlibs/components';
import {media} from '@startlibs/utils';
import React from 'react'
import {FormattedMessage} from 'react-intl'
import styled from 'styled-components';
import {SUPER} from '../../enums/UserRole'
import {ScrollToTop} from '../../components/ScrollToTop'
import {
  Sidebar,
  SidebarCard,
  SidebarContainer,
  SidebarTitle,
  StepLink
} from '../steps/RequestSidebar';
import {WithLazyResource} from '../../hooks/useSuspense'
import {lazyUserInfo} from '../../components/WithProvider'
import {Match} from "../../components/Match";

const ControlPanelSidebarContainer = styled(SidebarContainer)`
  padding: 8rem 0 3rem 2rem;
  width: 19rem;
  ${media.max(520)`
    padding-top: 6.25rem;
    padding-left: 1rem;
  `}
`

export const ControlPanelSidebar = () => {


  return <Match path="/admin/control/:page">{({match}) => {
    const page = match?.params && match?.params.page
    return <ControlPanelSidebarContainer>
      <ScrollToTop when={page}/>
      <Sidebar>
        <SidebarCard>
          <div className="sidebar-heading">
            <SidebarTitle>Control Panel</SidebarTitle>
          </div>
          <StepLink
            to="staff"
            selected={page === "staff"}
          ><Icon icon="users-line"/><FormattedMessage description="Staff management label" defaultMessage="Staff management"/></StepLink>
          <StepLink
            to="divisions"
            selected={page === "divisions"}
          ><Icon icon="divisions"/> Divisions settings</StepLink>
          <WithLazyResource value={lazyUserInfo}>{userInfo => <>
            {
              userInfo.role === SUPER && <>
              <StepLink
                to="notifications"
                selected={page === "notifications"}
              ><Icon icon="email-line"/> Notification settings</StepLink>
              <StepLink
                to="audit"
                selected={page === "audit"}
              ><Icon icon="audit"/> Audit log</StepLink>
            </>}
          </>}</WithLazyResource>
        </SidebarCard>
      </Sidebar>
    </ControlPanelSidebarContainer>
  }
  }</Match>
}
