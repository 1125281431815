import React, {useContext} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components';

import {Button, Dialog, Loading} from '@startlibs/components';
import {callIfFunction, getColor} from '@startlibs/utils'
import {useToggle} from '@startlibs/core'

import {ViewAllButton} from './ViewAllButton'
import {useActivityLog} from './hooks/useActivityLog';
import {DicomStudy, NonCompliantDicom} from '../enums/RecordFormat';
import {Pathology} from '../enums/RecordClass';
import {isMultiFile} from './FileinputBox';
import {jwtGetFetcher} from '../utils/authFetch';
import {UploaderConfigContext} from "../service/UploaderConfigContext";
import {UIAction} from "../service/UIAction";
import {DEVICE, DISK} from "../enums/UploaderStepsManagement";
import {useUIDataSelector} from "../service/hooks/useUIDataSelector";
import {useDoAction} from "../service/hooks/useDoAction";
import {useRecordsSelector} from "../service/hooks/useRecords";
import {useIsUploading} from "../service/hooks/useUploadQueueSelector";
import {UploaderAction} from "../service/UploaderAction";
import {getRowsForRecords, getRowsForRecordsStatic} from "../service/utils/recordUtils";

export const UploaderHeaderComponent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.25rem;
  padding: 1.25rem 0;
  align-items: center;
  border-bottom: 1px solid ${getColor('gray210')};
  margin-top: -2rem;
  position: sticky;
  min-height: 55px;
  ${Button} {
    margin-left: 0.75rem;
  }
  .left-container, .right-container {
    display: flex;
    align-items: center;
  }
`
const HeaderTitle = styled.span`
  font-size: 15px;
  font-weight: bold;
  margin-right: 0.75rem;
`
const SelectedRecordsCounter = styled.span`
  padding: 0.25rem 0.75rem;
  background-color: rgba(0,0,0,0.075);
  border-radius: 5px;
  font-weight: 600;
  min-height: 24px;
`
const UploadingRecords = styled.span`
  padding: 0.25rem 0.75rem 0.25rem 0.5rem;
  background-color: ${getColor('lightBlue')};
  border-radius: 5px;
  font-weight: 600;
  display: flex;
  align-items: center;
  min-height: 24px;
  .link {
    margin-left: 0.5rem;
    font-size: 11px;
  }
  ${Loading} {
    margin-right: 0.5rem;
  }
`

export const UploaderHeader = () => {
  const isUploading = useIsUploading()
  const unclassifiedRecords = useUIDataSelector(_.flow(_.get('groups'),_.find(_.matchesProperty('id','notclass')),_.get('items'))) || []
  const records = useRecordsSelector(getRowsForRecordsStatic).filter(({key}) => !unclassifiedRecords.includes(key))
  const quarantinedRecordsUIDs = records.filter(({quarantined,partiallyQuarantined}) => quarantined || partiallyQuarantined).map(({recordUID}) => recordUID+"")
  const data = useUIDataSelector()
  const doAction = useDoAction()

  const {
    mode,

    worklistViewerJwt: expertViewJwt,
    appJwt: jwt,
    requestId,

    withViewAllButton,
    withoutDelete,
    allowDownload,
    allowDownloadMedicalImages,
    
    listMode,
    disabled,

    linkedStudies,
    apiEndpoints,

    setNotification,
    setLinkedStudies
  } = useContext(UploaderConfigContext)

  const disableDelete = withoutDelete || listMode || disabled
  const {dicomViewer: viewerLoadUrl,downloadFiles,shortTokenUrl: maybeShortTokenUrl} = apiEndpoints

  const shortTokenUrl = maybeShortTokenUrl || (() => requestId
    ? jwtGetFetcher(callIfFunction(expertViewJwt))(`/api/shortDownloaderToken`,{requestId})
    : jwtGetFetcher(callIfFunction(expertViewJwt))(`/api/shortDownloaderToken`))

  const openActivityLog = () => doAction(UIAction.ToggleActivityLogDialog)
  const setSelectMode = (value) => doAction(UIAction.SetSelectMode,value)
  const setSelectedRecords = (value) => doAction(UIAction.SetSelectedRecords,value)

  const isSelectMode = data.selectMode
  const selectedRecords = data.selectedRecords

  const [activityLog] =  useActivityLog()
  const deleteDialog = useToggle()
  const loading = useToggle()
  const downloadLoading = useToggle()
  let nonCompliantFiles = 0
  
  // NonCompliant Dicom Files are grouped - so it must count as only one record
  _.map((item) =>{
    let record = records.find((record) => record.recordUID === item)
    if(record?.format === "NonCompliantDicom"){
      nonCompliantFiles = nonCompliantFiles+1
    }
  },selectedRecords)

  const selectedLength = nonCompliantFiles === 0 
    ? selectedRecords.length
    : selectedRecords.length - nonCompliantFiles + 1

  const keysToRecordUID = (keys) => keys.map((key) => records.find((record) => record.key === key)?.recordUID).filter(v => v)
  const keysToRecordUIDLodash = _.flow(
    _.map(
      (key) => records.find((record) => record.key === key)?.recordUID 
    ),
    _.filter(v => v)
  )
  
  const removeSelectedFiles = async () => {
    try {
      await doAction(UploaderAction.DeleteMany,records.filter(({key}) => selectedRecords.includes(key)))
    } catch (e) {

    } finally {
      setSelectMode(false)
      setSelectedRecords([])
      loading.close()
      return deleteDialog.close()
    }
      
  }
  if (mode === DEVICE || mode === DISK) {
    return null
  }
  return records.length >= 1 ? <>
    <UploaderHeaderComponent>
        {isSelectMode 
          ? 
            <>
              <div className="left-container">
                <HeaderTitle>
                  Select records
                </HeaderTitle>
                {selectedLength > 0 && <SelectedRecordsCounter>{selectedLength} {selectedLength > 1 ? 'records' : 'record'} selected</SelectedRecordsCounter>}
              </div>
              <div className="right-container">
                <a className="light-link gray" 
                  onClick={() => {
                    const allRecords = []
                    if (allowDownloadMedicalImages) {
                      records.map(item => {
                        allRecords.push(item.key)
                      })
                    } else {
                      let hasFilesNotSelectable = false
                      records.map(item => {
                        if (
                          (item.format === DicomStudy) ||
                          ((item.recordClass === Pathology) && (isMultiFile(item.format)))
                        ) {
                          hasFilesNotSelectable = true
                        } else {
                          allRecords.push(item.key)
                        }
                      })
                      if(hasFilesNotSelectable){
                        setNotification({type:"alert", timeout: 4000,msg:(close) => <span>Some medical records were not able to be selected.</span>})
                      }
                    }
                    setSelectedRecords(allRecords)
                  }
                }>Select all</a>
              <a className="light-link gray" css="margin-left:0.75rem;" onClick={() => setSelectedRecords([])}>
                Clear
              </a>
              {/* {!withoutDelete &&  */}
              {!disableDelete &&
                <Button small hover="alert" icon="delete" 
                  onClick={() => {selectedLength === 0 
                    ? setNotification({type:"alert", timeout: 4000,msg:(close) => <span>Select at least one record and try again</span>})
                    : deleteDialog.open()}}>Delete</Button> }
              {allowDownload && 
                <Button
                  small isLoading={downloadLoading.isOpen} icon="download"
                  // Commenting this out, to keep the same functionality as before
                  // Users are able to download files even if they are quarantined, in order to check.
                  // disabled={selectedRecords.find(id => quarantinedRecordsUIDs.includes(id))}
                  onClick={() => {selectedLength === 0 
                    ? setNotification({type:"alert", timeout: 4000,msg:(close) => <span>Select at least one record and try again</span>}) 
                    : downloadLoading.wait(shortTokenUrl(requestId)
                      .then((response) => callIfFunction(downloadFiles(response.jwt, doAction(UploaderAction.getRecordsUID,records.filter(({key}) => selectedRecords.includes(key))))))
                    )}}>Download</Button>}
                <Button small outline onClick={() => {setSelectedRecords([]); setSelectMode(false)}}>Cancel</Button>
              </div>
            </>
          :
            <>
              <div className="left-container">
                <HeaderTitle>
                  {records.length > 1 && `${records.length} records`}
                  {records.length === 1 && '1 record'}
                </HeaderTitle>
                
              {isUploading ? <UploadingRecords>
                <Loading size={16} borderWidth={3}/>Uploading <a className="link activity-log-link" onClick={openActivityLog}>Upload log</a>
              </UploadingRecords>
              :
              activityLog.length > 0 && <a className="link activity-log-link" onClick={openActivityLog}>Upload log</a>}
            </div>
            <div className="right-container">
            {(allowDownload || (!withoutDelete === undefined)) && <a className="link" onClick={() => setSelectMode(true)}>Select records</a>}
              {withViewAllButton && 
                <ViewAllButton 
                  viewerLoadUrl={viewerLoadUrl}
                  jwt={jwt}
                />
              }
            </div>
          </>
        }
    </UploaderHeaderComponent>
    
    {deleteDialog.isOpen &&
      <Dialog
        closeDialog={deleteDialog.close}
        title={<>Delete medical {selectedLength > 1 ? 'records' : 'record'}</>}
        footer={<>
          <Button onClick={deleteDialog.close}>Cancel</Button>
          <Button alert isLoading={loading.isOpen} onClick={loading.willWait(removeSelectedFiles)}>Delete</Button>
        </>}
      >
        <p css="font-size:15px!important"><b>This will permanently delete {selectedLength} {selectedLength > 1 ? 'records' : 'record'}</b>.</p>
        <p>Would you like to proceed?</p>
      </Dialog>
      }
    </>
    
    : <></>
}