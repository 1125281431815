import {Icon, Button, ContextMenu, Li, Dialog, TextButton} from '@startlibs/components'
import {Errors, FormValue, SimpleCheckbox, TextInput, WithForm} from '@startlibs/form'
import { callIfFunction, getColor, will } from '@startlibs/utils';
import {usePopupToggle, useToggle} from '@startlibs/core'
import React, { useRef, useState } from 'react'
import _ from 'lodash/fp'
import styled, { css } from 'styled-components';
import {CASE_CLOSED, CASE_REVIEWED, PENDING_ASSIGNMENT, WAITING_MORE_INFORMATION} from '../../../enums/CaseState'
import {
  Card,
  PageContainer,
  SectionHeading
} from '../../../components/PageLayout';
import {CaseRequestCard} from '../../CaseRequestCard'
import {ConfirmDialog, useConfirmDialog} from '../../../hooks/useConfirmDialog'
import {ExpertProfileCard} from '../../experts/components/ExpertProfileCard'
import {Header} from '../../../components/Header'
import { StatusLabel } from '../../../request/payment/PaymentCard';
import { WaitingBox } from '../info/WaitingMedicalRecordsBox';
import {filterExpert, formatDateNoUTC} from '../../../utils/utils'
import { getAvatarBackgroundImage } from '../../experts/utils/avatar';
import {getJwt} from '../../../hooks/useJwt'
import {jwtPostFetcher} from '../../../utils/authFetch'
import {useAskExpertMoreInformation} from '../hooks/useAskExpertMoreInformation'
import {useToggleImageDialog} from '../../hooks/useToggleImageDialog'
import {useUnshareDialog} from '../../hooks/useUnshareDialog'
import { FormattedMessage } from 'react-intl';

import { IfLocale } from '../../../hocs/IfLocale';
import { EmptyListPlaceholder, SearchInput } from '../../../components/StyledTable';
import { useEffect } from 'react';
import { useUnshareCoReviewDialog } from '../../hooks/useUnshareCoReviewDialog';
import { NewExpertShareDialog } from '../../NewExpertShareDialog';
import { buildValidation } from '../../../utils/validation';

export const AssignmentStatusBox = styled(WaitingBox)`
  .waitingBoxContent {
    ${Icon} {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    h4 {
      margin-bottom: 2px;
    }
  }
  ${props => props.reviewed && css`
    background: ${getColor('lightBlue')};
    color: ${getColor('main')};
    justify-content: space-between;
    .waitingBoxContent {
      ${Icon} {
        font-size: 2.5rem;
        width: 4rem;
        height: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        :before {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          content: '';
          border-radius: 2rem;
          border: 4px solid ${getColor('main')};
        }
      }
    `}
    }
`

const ExpertList = styled.div`
  border: 1px solid ${getColor('gray210')};
  border-radius: 0 0 5px 5px;
  max-height: 170px; 
  overflow-x: hidden;
  overflow-y: auto;
  border-top: none;
`

const ExpertReviewItem = styled.div`
  box-shadow: 0 0 0 1px ${getColor('gray210')};
  padding: 1rem 1.25rem;
  background: white;
  ${props => props.active && css`
    background: ${getColor('lightYellow')};
  `}
  border-radius: 6px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  :last-child {
    margin-bottom: 0;
  }
  .info-wrapper {
    display: flex;
    align-items: center;
    .avatar-wrapper {
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      min-width: 3.75rem;
      min-height: 3.75rem;
      width: 3.75rem;
      height: 3.75rem;
      border-radius: 2rem;
      margin-right: 1rem;
      box-shadow: inset 0 0 0 1px rgba(0,0,0,0.15);
      ${props => props.noPic && css`
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${getColor('gray240')};
        ${Icon} {
          font-size: 2rem;
          margin-top: -.25rem;
          color: ${getColor('gray180')};
        }
      `}
    }
    .expert {
      font-size: 14px;
      font-weight: 600;
      max-width: 305px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .details {
      margin-top: 0.25rem;
      white-space: nowrap;
      display: flex;
      flex-wrap: wrap;
      span {
        margin-right: 1rem;
      }
    }
  }
  .buttons-wrapper {
    flex-shrink: 0;
    ${Button} ~ ${Button} {
      margin-left: .5rem;
    }
  }
`

const ReviewedLabel = styled(StatusLabel)`
  color: ${getColor('success')};
  background: ${getColor('lightGreen')};
  margin-right: .5rem;
`

const DeclinedLabel = styled(StatusLabel)`
  color: ${getColor('alert')};
  background: ${getColor('lightRed')};
  margin-right: .5rem;
`

const SecurityDataWarning = styled.div`
  color: rgba(0,0,0,0.5);
  margin-top: -0.25rem;
  h4 {
    font-size: 12px;
    margin-bottom: 0.25rem;
  }
  p {
    font-size: 11px;
    max-width: 36rem;
  }
`

const StyledExpertRow = styled.div`
  cursor: pointer;
  &:nth-child(odd) {
    background-color: #EFF4F7;
  }
  &:hover {
    background: rgba(0,139,210,0.15);
  }
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${getColor('gray210')};
  padding: 1rem;
  &:last-child {
    border-bottom: 0px;
  }
  .speciality {
    font-size: 12px;
    opacity: 0.8;
    margin-top: 0.25rem;
  }
  label {
    margin-bottom: 0;
  }
  ${props => props.selected && css`
    &:nth-child(odd), &:nth-child(even) {
      background-color: ${getColor('main')};
      color: white;
      &:hover {
        background: ${getColor('main')};
      }
    }
    ${Icon} {
      position: absolute;
      left: 50%;
      right: 50%;
      transform: translate(-50%,-50%);
      color: ${getColor('main')};
      width: 12px;
      font-weight: 600;
    }
  `}
`



export const ExpertReviewList = ({allExperts, experts, setExperts, setCaseRequest, caseRequest, goToAssign, providerInfo, activeCoReviewExperts, setActiveCoReviewExperts, sharedExperts}) => {

  const formRef = useRef()
  const expertUpdater = (expert) => (updater) => setExperts(_.map(item => item === expert ? callIfFunction(updater, item) : item))
  
  // Adding Co-Review Experts with read only access (EVD49)
  const addAdditionalExperts = useToggle()
  const [selectedCoReviewExpertsId, rawSetSelectedCoReviewExpertsId] = useState([])
  const [count, setCount] = useState(0)
  const [availableExperts, setAvailableExperts] = useState([])

  const atLeastOneError = (v) => !Object.values(v).filter(_.identity).length && 'Please select at least one expert.'
  const preValidation = buildValidation({
    'selectedCoReviewExperts': (v) => atLeastOneError(v)
  })

  const action = ({allowDownload, message, selectedCoReviewExperts, sendEmail}) => {
    
    var addCoReviewExpertPromises = null
    addCoReviewExpertPromises = selectedCoReviewExperts.map(selectedExpert => {
      return jwtPostFetcher(getJwt())(`/api/expert/${selectedExpert}/coreview/${caseRequest.requestId}/download/${!!allowDownload}`, {message,sendEmail})
    })

    return Promise.all(addCoReviewExpertPromises)
      .then((responseAll) => {
        const localCoReviewExperts = activeCoReviewExperts
        responseAll.map((addedExpert) => {
          localCoReviewExperts.push(addedExpert)
        })
        setActiveCoReviewExperts(localCoReviewExperts)
      })
  }

  const setSelectedCoReviewExpertsId = (list) => {
    rawSetSelectedCoReviewExpertsId(list)    
  }

  // Can not allow to add lead expert as a co-review.
  // Also, should not allow to make duplicated co-review relations.
  useEffect(() => {
    let availableExperts = allExperts.filter((expert) => activeCoReviewExperts.findIndex((item) => item.expert.id == expert.id ) < 0)
    availableExperts = availableExperts.filter((expert) => sharedExperts.findIndex((item) => item.expert.id == expert.id ) < 0)
    setAvailableExperts(availableExperts)
  }, [allExperts, activeCoReviewExperts, experts])

  return <PageContainer>
    <Header title="Expert assignment"/>
    {caseRequest.activeCaseExpertId &&
      <AssignmentStatusBox>
        <div className="waitingBoxContent">
          <Icon icon="clock"/>
          <h4>
            Pending review
          </h4>
          <p>The expert highlighted below is currently reviewing the case.</p>
        </div>
      </AssignmentStatusBox>
    }
    {
      !caseRequest.activeCaseExpertId && (caseRequest.state === CASE_REVIEWED || caseRequest.state === CASE_CLOSED) &&
        <AssignmentStatusBox reviewed>
          <div className="waitingBoxContent">
            <Icon icon="check"/>
            <h4>
              This case has already been reviewed
            </h4>
            <p>
              <FormattedMessage
                description='ExpertReviewLists experts have reviewed warning'
                defaultMessage='The expert physician(s) listed below have reviewed this case.'
              />
            </p>
          </div>
          <Button.Link highlight to={`/admin/request/${caseRequest.requestCode}/report`}>View report</Button.Link>
        </AssignmentStatusBox>
    }
    <SectionHeading>
      <h4>Assigned Expert(s)</h4>
    </SectionHeading>
    <Card>
      {experts.length > 0 ? 
        <> {
          experts.map(expertShare =>
            caseRequest.activeCaseExpertId === expertShare.id
              ? <ActiveExpertItem
                key={expertShare.id}
                experts={experts}
                caseRequest={caseRequest}
                setCaseRequest={setCaseRequest}
                expertShare={expertShare}
                setExperts={setExperts}
                setExpertShare={expertUpdater(expertShare)}
                activeCoReviewExperts={activeCoReviewExperts}
              />
              : <ExpertItem
                key={expertShare.id}
                caseRequest={caseRequest}
                setCaseRequest={setCaseRequest}
                expertShare={expertShare}
                setExperts={setExperts}
                setExpertShare={expertUpdater(expertShare)}
              />
          )
        }
        {!caseRequest.activeCaseExpertId && <Button onClick={goToAssign} small highlight css="min-width:10rem;">Reassign</Button>}
        </> : 
        <div css="text-align: center;">
          <p css="max-width:27rem;margin:0 auto 1rem;color:rgba(0,0,0,0.5);">
            There is no assigned expert to this case.
          </p>
          <Button onClick={goToAssign} small highlight css="min-width:10rem;">Assign expert</Button>
        </div>
      }
      
      {/* highlight css="display: block; margin-left: auto;">Reassign</Button>} */}

    </Card>

    <SectionHeading>
      <h4>Additional Expert(s)</h4>
    </SectionHeading>
    <Card>
      {activeCoReviewExperts.length === 0 ? 
        <div css="text-align: center;">
          <p css="max-width:20rem;margin:0 auto 1rem;color:rgba(0,0,0,0.5);">
            Additional Experts that are added are given read-only access to this case.
          </p>
          <Button small highlight css="min-width:10rem;"
            onClick={() => addAdditionalExperts.open()}
            disabled={caseRequest.state === CASE_CLOSED}
          >
            Add expert
          </Button>
        </div>
      :
        <>
          <p>Additional Experts that are added are given read-only access to this case.</p>
          
          {activeCoReviewExperts.map(expertCoReviewAssignment => 
            <CoReviewerExpert
              key={expertCoReviewAssignment.id}
              expertCoReviewAssignment={expertCoReviewAssignment}
              selectedCoReviewExpertsId={selectedCoReviewExpertsId}
              setSelectedCoReviewExpertsId={setSelectedCoReviewExpertsId}
              count={count}
              setCount={setCount}
              caseRequest={caseRequest}
              activeCoReviewExperts={activeCoReviewExperts}
              setActiveCoReviewExperts={setActiveCoReviewExperts}
            />
          )}
          <Button small highlight css="padding: 0px 2.5rem;" 
            onClick={() => addAdditionalExperts.open()}
            disabled={caseRequest.state === CASE_CLOSED}
          >
            Add expert
          </Button>
        </>
      }
    </Card>

    {/* {!caseRequest.activeCaseExpertId && <Button onClick={goToAssign} highlight css="display: block; margin-left: auto;">Reassign</Button>} */}
    
    {addAdditionalExperts.isOpen && 
      <WithForm
        // onFailure={responseFailure((n,{status}) => status === 565 && "This case is shared with another expert")}
        ref={formRef}
        action={action}
        preValidation={preValidation}
        values={{allowDownload: false, sendEmail: true, selectedCoReviewExperts: []}}
        onSuccess={() => {
          addAdditionalExperts.close()
        }}
        
      >{form => 
        <AddCoReviewExperts 
          caseRequest={caseRequest} 
          experts={availableExperts} 
          form={form} 
          closeDialog={addAdditionalExperts.close} 
          providerInfo={providerInfo} 
          selectedCoReviewExpertsId={selectedCoReviewExpertsId}
          setSelectedCoReviewExpertsId={setSelectedCoReviewExpertsId}
          count={count}
          setCount={setCount}
          activeCoReviewExperts={activeCoReviewExperts}
          setActiveCoReviewExperts={setActiveCoReviewExperts}
          setExperts={setAvailableExperts}
          availableExperts={availableExperts}
        />
      }</WithForm>
    }
  </PageContainer>
}

const ActiveExpertItem = ({expertShare, caseRequest, setCaseRequest, setExpertShare, setExperts, experts, active, activeCoReviewExperts}) => {
  const {expert} = expertShare
  const hasOneReview = !!experts.find(expert => expert.reviewDateEpochMilli)

  const openRevokeDialog = useUnshareDialog(expertShare, caseRequest, _.flow(
    will(setCaseRequest, _.flow(
      hasOneReview
        ? _.set('state', CASE_REVIEWED)
        : _.set('state', PENDING_ASSIGNMENT),
      _.set('activeCaseExpertId', null)
    ))
  ))

  const reminderConfirm = useConfirmDialog(
    <ConfirmDialog
      title="Remind expert"
      confirm={<Button highlight icon="email">Send</Button>}
      action={(v) => caseRequest.state === WAITING_MORE_INFORMATION
        ? jwtPostFetcher(getJwt())("/api/admin/remindRequestMoreInformation/" + expertShare.id, v)
        : jwtPostFetcher(getJwt())("/api/admin/sendRequestAcceptanceReminder?requestId=" + caseRequest.requestId, v)
      }
    >
      <p>Please review the case details below:</p>
      <CaseRequestCard caseRequest={caseRequest}/>
      <p>Sending assignment reminder to:</p>
      {expert && <ExpertProfileCard expert={expert}/>}
      <TextInput
        textarea
        autoResize
        label="Message"
        path="message"
        placeholder="Insert custom message here"
      />
    </ConfirmDialog>
  )


  return <React.Suspense fallback={null}><ExpertItem caseRequest={caseRequest} expertShare={expertShare} setExperts={setExperts} setExpertShare={setExpertShare} active>
    <Button onClick={reminderConfirm} highlight small icon="email">Send reminder</Button>
    <Button onClick={openRevokeDialog} small>Unassign</Button>
    {/* <Button onClick={openRevokeDialog} small disabled={activeCoReviewExperts.length}>Unassign</Button> */}
  </ExpertItem></React.Suspense>
}

const ExpertItem = ({setExpertShare, expertShare, caseRequest, setCaseRequest, setExperts, children, active}) => {
  const {expert} = expertShare
  const expertName = expert.firstName + " " + expert.lastName

  const openImageDialog = useToggleImageDialog(expertShare, caseRequest, () => setExpertShare(_.update("allowStudyDownload", v => !v)))

  const contextMenu = usePopupToggle()
  const requestMoreinfo = useAskExpertMoreInformation(caseRequest,setCaseRequest, [expertShare], setExperts)

  return <ExpertReviewItem active={active} noPic={!expert.picture}>
    <div className="info-wrapper">
      <div className="avatar-wrapper" css={expert.picture ? getAvatarBackgroundImage(expert) : ""}>{!expert.picture && <Icon icon="physician"/>}</div>
      <div>
        <div className="expert">
          {expertName.length > 2 ?
            expertName : expert.email
          }
        </div>
        <div className="details">
          <span><strong>Email:</strong> {expert.email}</span>
          {expert.info.phone && <span><strong>Phone:</strong> {expert.info.phone}</span>}
        </div>
      </div>
    </div>
    <div className="buttons-wrapper">
      {!active ?
        <ReviewedLabel>
          <Icon icon="check" />Reviewed on {formatDateNoUTC(new Date(expertShare.reviewDateEpochMilli),'MM/dd/yyyy hh:mm')}
        </ReviewedLabel>
        : children
      }
      <Button onClick={contextMenu.open} small onlyDropdown>
        {
          contextMenu.isOpen &&
          <ContextMenu>
            <Li
              label={(expertShare.allowStudyDownload ? "Disable" : "Enable") + " image download"}
              onClick={openImageDialog}
            />
            {
              !caseRequest.activeCaseExpertId &&
              <Li
                label="Ask for more information"
                onClick={requestMoreinfo}
              />
            }
          </ContextMenu>
        }
      </Button>
    </div>

  </ExpertReviewItem>
}

const CoReviewerExpert = ({ expertCoReviewAssignment, selectedCoReviewExpertsId, setSelectedCoReviewExpertsId, count, setCount, caseRequest, setActiveCoReviewExperts, activeCoReviewExperts}) => {

  const {expert} = expertCoReviewAssignment
  const expertName = expert.firstName + " " + expert.lastName
  const openUnshareDialog = useUnshareCoReviewDialog(expertCoReviewAssignment,caseRequest, () => {
    var indexCoReview = activeCoReviewExperts.findIndex((item) => item.id === expertCoReviewAssignment.id)
    setActiveCoReviewExperts(_.set(`[${indexCoReview}].revokeDateEpochMilli`,true,activeCoReviewExperts))
  })
  const contextMenu = usePopupToggle()
  
  return <ExpertReviewItem noPic={!expert.picture}>
    <div className="info-wrapper">
      <div className="avatar-wrapper" css={expert.picture ? getAvatarBackgroundImage(expert) : ""}>{!expert.picture && <Icon icon="physician"/>}</div>
      <div>
        <div className="expert">
          {expertName.length > 2 ?
            expertName : expert.email
          }
        </div>
        <div className="details">
          <span><strong>Email:</strong> {expert.email}</span>
          {expert.info?.phone && <span><strong>Phone:</strong> {expert.info?.phone}</span>}
        </div>
      </div>
    </div>
    <div className="buttons-wrapper">
      {expertCoReviewAssignment.reviewDateEpochMilli ?
      <>
        <ReviewedLabel>
          <Icon icon="check" />Reviewed on {formatDateNoUTC(new Date(expertCoReviewAssignment.reviewDateEpochMilli),'MM/dd/yyyy hh:mm')}
        </ReviewedLabel>
        <Button onClick={contextMenu.open} small onlyDropdown>
          {
            contextMenu.isOpen &&
            <ContextMenu>
              <Li
                label={"Remove access"}
                onClick={openUnshareDialog}
              />
            </ContextMenu>
          }
        </Button>
      </>
      :
        // expert.info.declineReason.length > 0 ?
        //   <DeclinedLabel>
        //     <Tooltip content={expert.info.declineReason}><Icon icon="close" /> Declined</Tooltip>
        //   </DeclinedLabel>
        // :
        <Button onClick={openUnshareDialog} small>
          Remove access
        </Button>  
      }
      
    </div>

  </ExpertReviewItem>
}

const TableFieldLabel = styled.div`
  font-size: 13px;
  margin-bottom:0.5rem;
  display:flex;
  align-items:center;
  justify-content: space-between;
`
const TableSearchInput = styled(SearchInput)`
  width: 100%;
  input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`
const SelectedAmount = styled.div `
  font-weight: 600;
  margin-left: 1rem;
  a {
    font-weight: 400;
    margin-left: 0.25rem;
  }
`

const AddCoReviewExperts = ({closeDialog, form, caseRequest, setExperts, experts, selectedCoReviewExpertsId, setSelectedCoReviewExpertsId, count, setCount, availableExperts, setAvailableExperts}) => {

  useEffect(() => {
    return () => {setCount(0); setSelectedCoReviewExpertsId([]);}
  },[])

  const handleClick = (selected,expertId) => {
    var index = selectedCoReviewExpertsId.findIndex((item) => item === expertId)
    if(index >= 0 && selected){ return }
    var localList = selectedCoReviewExpertsId
    if(selected){
      localList.push(expertId)
      setCount(count+1)
    }else if(index >= 0){
      localList.splice(index, 1);
      setCount(count-1)
    }
    setSelectedCoReviewExpertsId(localList)
    form.setValue('selectedCoReviewExperts',selectedCoReviewExpertsId)
  }

  const query = useToggle('')
  const newExpertDialog = useToggle()
  const filteredExperts =  query.isOpen
    ? experts.filter(filterExpert(query.isOpen))
    : experts
  
  return <Dialog
    title="Add expert"
    closeDialog={closeDialog}
    isLoading={false}
    footer={<>
      <Button onClick={closeDialog}>Cancel</Button>
      <Button
        autoFocus
        highlight
        isLoading={form.isLoading}
        type="submit"
        onClick={form.willSubmitForm}
      >Add Expert</Button>
    </>}
  >
    <p>This will give the selected Expert(s) read-only access to this case.</p>
    <CaseRequestCard caseRequest={caseRequest}/>
    
    <TableFieldLabel>
      <span>Select a registered Expert or add by email:</span>
      {selectedCoReviewExpertsId.length > 0 &&
        <SelectedAmount>
          {/* 2 selected <a className='light-link'>Clear</a> */}
          {selectedCoReviewExpertsId.length} selected <a className='light-link' onClick={() => {
            setSelectedCoReviewExpertsId([])
            form.setValue('selectedCoReviewExperts',[])
          }}>Clear</a>
        </SelectedAmount>
      }
    </TableFieldLabel>
    <TableSearchInput>
      <Icon icon="search"/>
      <TextInput placeholder="Search experts" raw value={query.isOpen} setValue={query.openWith}/>
    </TableSearchInput>
    {filteredExperts.length > 0 && experts.length > 0 ?
      <ExpertList>
        {
          filteredExperts.map(expert =>
            <ExpertRow
              selected={selectedCoReviewExpertsId.findIndex(item => item === expert.id) >= 0} //setSelectedExpert={setSelectedExpert}
              expert={expert}
              handleClick={handleClick}
            />
          )
        }
      </ExpertList>
    : 
      (
        <EmptyListPlaceholder onDialog>
          {experts.length > 0 && filteredExperts.length === 0 &&
          <span>There are no experts matching your search criteria.</span>}
          {experts.length === 0 && <span>There are no experts registered in this system yet.</span>}
        </EmptyListPlaceholder>
      )
    }
    <TextButton onClick={newExpertDialog.open} css="margin:0.75rem 0 1rem"><b>Add new expert by email</b></TextButton> 

    {newExpertDialog.isOpen &&
      <NewExpertShareDialog
        setExperts={setExperts}
        closeDialog={newExpertDialog.close}
        selectExpert={(expert) => {
          var localList = selectedCoReviewExpertsId
          localList.push(expert.id)
          setCount(count+1)
          setSelectedCoReviewExpertsId(localList)
          form.setValue('selectedCoReviewExperts',selectedCoReviewExpertsId)
        }}
      />
    } 
    
    <FormValue path="sendEmail">{sendEmail => sendEmail && <>
      <TextInput
        path="message"
        label="Message"
        descText="This will be included in the email sent to the expert(s)."
        textarea
        autoResize
      />
      <SecurityDataWarning>
        <h4>This email may contain confidential and protected health care information.</h4>
        <p>
          Please be sure that the email of the recipient has been entered
          correctly and that you are using appropriately confidential mechanisms
          for this communication.
        </p>
      </SecurityDataWarning>
    </>}</FormValue>

    <Errors/>
  </Dialog>
}

const ExpertRow = ({selected, expert, handleClick}) => {
  
  const expertName = expert.firstName + " " + expert.lastName
  
  return <StyledExpertRow selected={selected} onClick={() => handleClick(!selected,expert.id)}>
    <div>
      <div>
        {expertName.length > 2 ?
          <><b>{expertName}</b> ({expert.expertEmail})</>
          :
          expert.expertEmail
        }</div>
      <IfLocale not contains="LEGAL">
        {expert.specialization?.length > 0 &&
          <div className="speciality">Speciality: {(expert.specialization || []).join(", ")}</div>
        }</IfLocale>
    </div>
    <SimpleCheckbox
      raw
      value={selected}
      setValue={() => handleClick(!selected,expert.id)}
    />
  </StyledExpertRow>
}
