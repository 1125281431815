import {
  FormValue,
  Field,
  RadioboxGroup,
  SimpleCheckbox,
  SimpleRadiobox,
  ToggleCheckbox,
  TextInput
} from '@startlibs/form'
import {SplitColumnsContainer} from '@startlibs/components'
import React from 'react'
import {Card, SectionHeading} from '../../components/PageLayout'
import {DIGITAL, OFFLINE} from '../../enums/PaymentMethod'
import {PriceInput} from '../../components/PriceInput'
import {getCoveredAndCharge} from './utils'


export const PaymentDetailsForm = () => {
  return <>
      <SplitColumnsContainer>
        <TextInput
          path="description"
          label="Fee description"
          placeholder="Ex: Additional consultation fee"
          mandatory
        />
        <PriceInput
          label="Out of pocket fee"
          path="chargeValue"
          placeholder="Ex: 300.00"
          mandatory
        />
      </SplitColumnsContainer>
      <RadioboxGroup
        path="paymentMethod"
        label="Payment method"
        framedBoxes
        wide
        mandatory
        horizontal
      >
        <SimpleRadiobox label="Digital payment" fieldValue={DIGITAL}/>
        <SimpleRadiobox label="Off-line payment" fieldValue={OFFLINE}/>
      </RadioboxGroup>
      <FormValue
        path="paymentMethod"
      >{paymentMethod =>
        paymentMethod === DIGITAL
          ? <ToggleCheckbox
              path="sendEmail"
              label={<b>Send an email requesting payment when adding this fee</b>}
              css="margin-top:2rem"
              descText="You can also send the payment request by email later"
            />
          : paymentMethod === OFFLINE
          ? <Field label="Manual confirmation" css="margin-top: 1.5rem;">
            <SimpleCheckbox
              path="paid"
              label="Mark as paid"
              helpText="(in case paid externally already)"
              framedBoxes
              highlightSelected
            />
          </Field>
          : null
      }</FormValue>
  </>

}