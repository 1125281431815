import React from 'react'
import {ConfirmDialog, useConfirmDialog} from '../../../hooks/useConfirmDialog'
import {Button} from '@startlibs/components'
import {jwtPostFetcher} from '../../../utils/authFetch'
import {getJwt} from '../../../hooks/useJwt'
import {CaseRequestCard} from '../../CaseRequestCard'
import {PRIMARY} from '../../../enums/ContactRelationKind'
import {TextInput} from '@startlibs/form'
import {ContactCard} from '../../../components/ContactCard'

export const useRequestPatientSignoff = (caseRequest, action, onSuccess) =>  useConfirmDialog(
  <ConfirmDialog
    title="Requesting new authorizations"
    confirm={<Button highlight>Request authorizations</Button>}
    values={{message: ""}}
    action={(values) => action().then(() => jwtPostFetcher(getJwt())("/api/admin/requestMedicalRecordReleaseSignature?requestId=" + caseRequest.requestId,values))}
    onSuccess={onSuccess}
    notify="Authorizations requested successfully."
  >
    <p>You are about to request medical records release authorizations for this case:</p>
    <CaseRequestCard
      viewOnly
      caseRequest={caseRequest}
    />
    <p>An e-mail will be delivered to the case primary contact with your request:</p>
    <ContactCard
      checked
      focused
      caseRequest={caseRequest}
      relationKind={PRIMARY}
    />
    <TextInput
      textarea
      autoResize
      label="Message"
      path="message"
      descText="This will be included in the email sent requesting authorizations to the case contact."
      placeholder="Insert custom message here."
    />
    <p>You will still be able to edit this case while the contact provides authorizations.</p>
  </ConfirmDialog>
)
