import {
  createNewRecord,
  EmptyFileError,
  EXECUTABLES_EXTENSIONS,
  getDicomInstanceJson,
  BlackListedError,
  readFile,
  RecordClass,
  RecordFormat,
  SETTINGS_EXTENSIONS,
  SYSTEM_EXTENSIONS,
} from './utils'
import {processQueue} from './queues'

const PARTIAL_DICOM_BUFFER_SIZE = 5e5

const isPossibleDicom = file => ['dcm'].indexOf(file.name.toLowerCase().split('.').pop()) >= 0
const filterIsBlacklisted = file =>
  [...EXECUTABLES_EXTENSIONS, ...SETTINGS_EXTENSIONS, ...SYSTEM_EXTENSIONS].indexOf(
    file.name.toUpperCase().split('.').pop()
  ) >= 0

const CONFIG = { partialBufferSize: PARTIAL_DICOM_BUFFER_SIZE }

const arrayFromBuffers = (a, b) => {
  const merged = new Uint8Array(a.length + b.length)
  merged.set(a)
  merged.set(b, a.length)
  return merged
}

const checkDicomFile = async (file, config = CONFIG, completeFile) => {
  const partialBufferArray = await readFile(
    completeFile ? file : file.slice(0, Math.min(config.partialBufferSize, file.size))
  )
  const partialByteArray = new Uint8Array(partialBufferArray)
  try {
    return { file, dicomInstance: getDicomInstanceJson(partialByteArray, !completeFile) }
  } catch (e) {
    if (file.size > config.partialBufferSize && !completeFile) {
      // try full
      const remainingBufferArray = await readFile(file.slice(config.partialBufferSize, file.size))
      const byteArray = arrayFromBuffers(partialByteArray, new Uint8Array(remainingBufferArray))
      return {
        file,
        dicomInstance: getDicomInstanceJson(byteArray),
        byteArray: completeFile ? byteArray : undefined,
      }
    }
    return Promise.reject(e)
  }
}

export const processDicomFile = async (augumentedFile,adminUserEmail='') => {
  const {file} = augumentedFile
  if (file.size === 0) {
    throw new EmptyFileError()
  }

  const {
    value: [unlock],
  } = await processQueue.next()
  try {
    const { dicomInstance } = await checkDicomFile(file)
    return createNewRecord(
      {
        description:dicomInstance.studyDescription || file.name,
        recordClass:RecordClass.Radiology,
        recordFormat:RecordFormat.DicomStudy,
        files: [
          {
            ...augumentedFile,
            params: {
              instanceUID: dicomInstance.instanceUID,
            },
          },
        ],
        fileExtension: 'dicom'
      },
      {
        info: dicomInstance,
        studyUID: dicomInstance.studyUID,
        params: {
          studyUID: dicomInstance.studyUID,
          class: RecordClass.Radiology,
          format: RecordFormat.DicomStudy,
        },
        stateContext: {
          Submitted: {
            by: adminUserEmail && adminUserEmail.length > 0 ? adminUserEmail : ''
          }
        }
      }
    )
  } catch (e) {
    if (filterIsBlacklisted(file)) {
      throw new BlackListedError()
    }
    if (isPossibleDicom(file)) {
      return createNewRecord({
        description:file.name,
        recordClass: RecordClass.Radiology,
        recordFormat: RecordFormat.NonCompliantDicom,
        files:  [
          augumentedFile,
        ],
        fileExtension: 'dicom'
      })
    }
    throw e
  } finally {
    unlock()
  }
}
