import { Button, Icon } from '@startlibs/components';
import {FormValue} from '@startlibs/form'
import {useToggle} from '@startlibs/core'
import React, { useRef, useState } from 'react';
import {AddButton} from '../../components/AddButton'
import { AssignmentStatusBox } from './expertReview/ExpertReviewList';
import {
  BIOPSY_FACILITIES,
  HOSPITALS,
  PHYSICIAN_OR_PROVIDER,
  SCAN_FACILITIES
} from '../../enums/MedicalRecordLocationType'
import {
  CASE_ARCHIVED,
  CASE_CLOSED,
  CASE_REJECTED,
  CASE_REVIEWED,
  REQUEST_REJECTED,
  WAITING_ACCEPTANCE,
  WAITING_MEDICAL_RECORDS
} from '../../enums/CaseState';
import {Card, PageContainer, PageFooter, SectionHeading} from '../../components/PageLayout'
import {Header} from '../../components/Header'
import {MEDICAL_RELEASE_PATH} from '../../request/medicalRelease/ReleaseLocationForms'
import {MedicalReleaseForm} from '../../request/MedicalReleaseForm'
import {PageLoadingSuspense} from '../../components/PageLoading'
import {PurviewFooter} from '../../components/PurviewFooter'
import {ReleaseList} from '../../patient/ReleaseList'
import {WaitingMedicalRecordsBox} from './info/WaitingMedicalRecordsBox'
import {emailRegex} from '../../utils/validation'
import {getAdminCaseRequestUrl, isStateBefore} from '../../request/utils'
import {getJwt} from '../../hooks/useJwt'
import {jwtFormFetcher, jwtGetFetcher} from '../../utils/authFetch'
import {locationIsNotEmpty} from '../../request/forms/MedicalRecordLocationForm'
import {useConfirmAskPatientUpdate} from './hooks/useConfirmAskPatientUpdate'
import {useRequestPatientSignoff} from './hooks/useRequestPatientSignoff'
import {useRequestRecords} from './hooks/useRequestRecords'
import {willUseSuspense} from '../../hooks/useSuspense'

const EditItemButton = ({release, setRelease, setReleases, caseRequest, getReleases}) => {

  const [request] = useRequestRecords(getReleases(), caseRequest, release)

  return <Button small alpha onClick={request} icon="email">Request records</Button>
}

const SHOW_ALL_EMPTY = {
  [MEDICAL_RELEASE_PATH]: [HOSPITALS, SCAN_FACILITIES, BIOPSY_FACILITIES, PHYSICIAN_OR_PROVIDER].map((locationType, i) => ({
    id: Date.now() + '-' + i,
    locationType
  }))
}

const useAuthSuspense = willUseSuspense((requestId) =>
  jwtGetFetcher(getJwt())(`/api/medicalRecordLocationForms`, {requestId})
)
export const ReleaseStep = (props) => <PageLoadingSuspense>
  <LoadedReleaseStep {...props}/>
</PageLoadingSuspense>

export const LoadedReleaseStep = ({caseRequest, setCaseRequest}) => {

  const [releases, setReleases] = useState(useAuthSuspense(caseRequest.requestId))

  const refreshReleases = () =>
    jwtGetFetcher(getJwt())(`/api/medicalRecordLocationForms`, {requestId:caseRequest.requestId})
      .then(setReleases)

  const formRef = useRef()
  const uploaderRef = useRef()
  const releasesActive = isStateBefore(caseRequest.state, WAITING_ACCEPTANCE) || caseRequest.state === CASE_REVIEWED

  const addAuthorizations = useToggle(!releases.length && releasesActive)


  const saveLoading = useToggle()
  const action = (values = formRef.current.getTransformedValues()) =>
    saveLoading.wait(jwtFormFetcher(getJwt())('/api/medicalRecordLocationForm?requestId=' + caseRequest.requestId, {method: 'PUT'})(values))
      .then(refreshReleases)


  const onSuccess = () => addAuthorizations.close()

  const askSignoff = useRequestPatientSignoff(
    caseRequest,
    () => formRef.current ? action() : Promise.resolve(),
    onSuccess
  )

  const confirmAskSignOff = (e) => {
    if (formRef.current && !formRef.current.validate()) {
      return;
    } else {
      return askSignoff()
    }
  }


  const confirmAskUpdate = useConfirmAskPatientUpdate(caseRequest, setCaseRequest)

  const [confirmAskRecords, hasProviderRequestableReleases] = useRequestRecords(releases, caseRequest)

  const saveAndConfirmAskUpdates = () =>
    formRef.current
      ? formRef.current.submitForm().then(confirmAskUpdate.open)
      : confirmAskUpdate.open()

  const isClosed = (caseRequest.state === CASE_CLOSED)
  const noReason = (caseRequest.state === CASE_REJECTED || caseRequest.state === REQUEST_REJECTED || caseRequest.state === CASE_ARCHIVED)

  return <>
    <PageContainer>
      <Header title="Medical record locations and release">
        {
          !addAuthorizations.isOpen && <>
            {
              hasProviderRequestableReleases && releasesActive &&
              <Button highlight onClick={confirmAskRecords} icon="email">Request records</Button>
            }
          </>
        }
      </Header>
      {
        !releasesActive &&
        <AssignmentStatusBox reviewed={isClosed}>
          <div className="waitingBoxContent">
            <Icon icon={isClosed ? 'check' : 'clock'}/>
            <h4>
              {isClosed ? "This case is already finished." : "This case can't currently receive new medical records or releases"}
            </h4>
            <p>
              {isClosed ?
                "The report is already concluded and available for the case contact. "
                : (noReason ?
                "" : "There is an expert reviewing the case. ")
              }
              {!isClosed && "Requesting providers for records and editing releases are locked."}</p>
          </div>
        </AssignmentStatusBox>
      }
      {
        !addAuthorizations.isOpen && (releasesActive || releases.length>0) &&
        <>
          <WaitingMedicalRecordsBox caseRequest={caseRequest} setCaseRequest={setCaseRequest}/>
          <SectionHeading>
            {/*<p>Identify location of prior records, and complete release below.</p>*/}
          </SectionHeading>
          <Card>
            <ReleaseList
              releases={releases}
              setReleases={setReleases}
              // onEmpty={() => releasesActive && !caseRequest.caseFlags.adminMedicalReleaseUpdated && addAuthorizations.openWith(SHOW_ALL_EMPTY)}
              onEmpty={() => releasesActive && !caseRequest.caseFlags.adminMedicalReleaseUpdated}
              caseRequest={caseRequest}

              editItemButton={(release, setRelease) => emailRegex.test(release.medicalRecordLocationItem.email)
                ? <EditItemButton
                  release={release}
                  setRelease={setRelease}
                  caseRequest={caseRequest}
                  getReleases={() => releases}
                  setReleases={setReleases}
                />
                : null
              }
            />
            {
              releasesActive &&
              <AddButton onClick={addAuthorizations.open}>Add new authorization</AddButton>
            }
          </Card>
        </>
      }
      {
        addAuthorizations.isOpen
          ? <>
            <WaitingMedicalRecordsBox caseRequest={caseRequest} setCaseRequest={setCaseRequest}/>
            <MedicalReleaseForm
            setCaseRequest={setCaseRequest}
            alwaysSave
            optional
            values={addAuthorizations.isOpen !== true ? addAuthorizations.isOpen : undefined}
            uploaderRef={uploaderRef}
            formRef={formRef}
            caseRequest={caseRequest}
            onSuccess={onSuccess}
            action={action}
            toggle={() =>{addAuthorizations.close()}}
          >{(form) => <>
            <PageFooter>
              <FormValue path="records">{records =>
                !!records.find(locationIsNotEmpty)
                  ? <>
                    <Button type="submit" highlight isLoading={form.isLoading}>Save</Button>
                  </>
                  : <>
                    {
                      releases.length > 0
                        ? <Button onClick={addAuthorizations.close}>Cancel</Button>
                        : <Button.Link to={getAdminCaseRequestUrl(caseRequest,'records')}>Skip</Button.Link>
                    }
                  </>
              }</FormValue>
            </PageFooter>
          </>}
          </MedicalReleaseForm>
          </>
          : <PageFooter>
            {
              releasesActive
              ? (caseRequest.state !== WAITING_MEDICAL_RECORDS && <Button.Link highlight to={getAdminCaseRequestUrl(caseRequest,'records')}>Save and advance</Button.Link>)
              : <Button.Link highlight to={getAdminCaseRequestUrl(caseRequest,'records')}>Skip</Button.Link>
            }
          </PageFooter>
      }
    </PageContainer>
    <PurviewFooter/>
  </>
}
