import { Button, Icon, Loading, Tooltip, TextButton, SplitColumnsContainer} from '@startlibs/components';
import React, {useEffect, useState} from 'react'
import _ from 'lodash/fp'
import styled, {css} from 'styled-components'
import {Header} from '../../components/Header'
import {Card, PageContainer} from '../../components/PageLayout'
import {PurviewFooter} from '../../components/PurviewFooter'
import { useNavigate } from 'react-router';
import { Errors, TextInput, WithForm } from '@startlibs/form';
import { buildValidation, required } from '../../utils/validation';
import { CASE_CLOSED } from '../../enums/CaseState';
import { jwtPostFetcher } from '../../utils/authFetch';
import { getJwt } from '../../hooks/useJwt';

const preValidation = buildValidation({
  'caseInfo.ehrInfo.mrn': (v) => !v.length && required(),
})


export const ReconciliationStep = ({caseRequest, setCaseRequest}) => {

  const action = ({caseInfo}) =>  jwtPostFetcher(getJwt())("/api/caseInfo?requestId=" + caseRequest.requestId, caseInfo, {method: "PUT"})

  return <>
    <PageContainer>
      <Header title="Case to EHR"></Header>
      <Card>
        <h4>Insert the information below to reconciliate this case to the Epic system</h4>
        <br></br>
        <WithForm
          alwaysSave
          values={caseRequest}
          preValidation={preValidation}
          action={action}
          onSuccess={(values) => {
            setCaseRequest(values)
          }}
        >{form => 
          <>
            <SplitColumnsContainer>
              <TextInput
                label="MRN (Patient ID)"
                path="caseInfo.ehrInfo.mrn"
                mandatory
              />
              <TextInput
                label="Order Number"
                path="caseInfo.ehrInfo.orderNumber"
              />
            </SplitColumnsContainer>
            <p>If the order number is empty, the case report will be seen as unsolicited message on the Epic system.</p>  
            <Errors/>
            <div css="text-align: right; margin-top: .5rem;">
              {/* <Button onClick={() => form.confirm('discard-remote-log').then(cancel)}>Cancel</Button> */}
              <Button type='submit' highlight isLoading={form.isLoading} onClick={() => form.clearErrors()}>Save</Button>
            </div>
          </>
        }</WithForm>
      </Card>

      {caseRequest.state === CASE_CLOSED 
        ? <Card style={{display: "flex", justifyContent: "space-between", flexWrap: "nowrap", flexDirection: "row", alignItems: "center"}}>
          {/* TODO: Change Icon */}
          <h3> <Icon icon="arrow-down"></Icon> Case report is available</h3>
          <Button highlight onClick={() => console.log('send')}>Send to EHR</Button>
        </Card>
        : null
      }
    </PageContainer>
    <PurviewFooter/>
  </>
}
