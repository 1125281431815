import { Button, Icon, Li } from '@startlibs/components';
import { getColor } from '@startlibs/utils';
import { useToggle } from '@startlibs/core';
import {Route, Routes, useLocation, useNavigate, useParams} from 'react-router';
import React, {Suspense, useEffect, useState} from 'react'
import styled from 'styled-components';
import {EditExpert} from '../admin/experts/EditExpert'
import {ExpertAnswers} from './ExpertAnswers'
import {ExpertCaseList} from './ExpertCaseList'
import {ExpertOverview} from './ExpertOverview'
import {ExpertRemoteConsultation} from './ExpertRemoteConsultation'
import {ExpertReport} from './ExpertReport'
import {ExpertRequestSidebar} from './ExpertRequestSidebar'
import {NavbarTabs, UserNavbar} from '../components/Navbar'
import {PageContent, PageWrapper} from '../admin/steps/RequestSidebar'
import {ProviderTitle} from '../components/ProviderTitle'
import {getExpertDefaultPath} from '../enums/CaseState'
import {getTimezoneOffset} from '../utils/utils'
import { useIntl } from 'react-intl'
import {jwtGetFetcher, jwtPostFetcher} from '../utils/authFetch'
import { setJwt } from '../hooks/useJwt';
import {willUseSuspense} from '../hooks/useSuspense'
import { lazyProviderInfo, lazyUserInfo } from '../components/WithProvider';
import { ExpertSSOLogin } from '../admin/ExpertSSOLogin';
import { ExpertLogin } from '../admin/ExpertLogin';
import {Redirect} from "../components/Redirect";
// import { ExpertReadOnlyQuestions } from './ExpertReadOnlyQuestions';
import { ExpertReadOnlyOverview } from './ExpertReadOnlyOverview';

const DEV_JWT = process.env.NODE_ENV === 'development' && 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY2Nlc3NUeXBlIjoiRVhQRVJUIiwiZXhwZXJ0SWQiOiIxMCIsImxvZ2luIjoiZ2FicmllbC5tb250ZWlybzAwQGdtYWlsLmNvbSIsInByb3ZpZGVyTmFtZSI6ImRldiJ9.sD8YS_V8LcAzWB2at-GqmsgNQoKHLYSk9CZhtgsgfiM'
const getJwt = () => window.JWT_URL_PARAM || window.localStorage.getItem("expert-jwt") || DEV_JWT

export const FeedbackButton = styled(Button)`
  color: ${getColor('main')};
  font-size: 13px;
  box-shadow: none;
  margin-left: auto;
  margin-right: 1rem;
  padding-right: 1rem;
  align-self: center;
  ${Icon} {
    color: ${getColor('main')};
  }
`

export const ExpertRoutes = () => {
  let location = useLocation();
  let navigate = useNavigate();

  const providerInfo = lazyProviderInfo.read()

  if (getJwt()) {
    setJwt(getJwt(),"expert")
  }
  const jwt = getJwt()
  const intl = useIntl()
  const feedbackPopup = useToggle()
  const [showButton, setShowButton] = useState(true);

  // if (location.pathname === "/login") {
  //   return <ExpertLogin />
  // }


  if (location.pathname === "/expert/login") {
    return <ExpertLogin />
  }

  if ((location.pathname === "/expert/sso/login") && (providerInfo?.ssoEnabled === true)){
    // return <ExpertLogin />
    return <ExpertSSOLogin/>
  }

  if (!getJwt()) {
    return providerInfo?.ssoEnabled === true
      ?
      <Redirect to="/expert/sso/login" />
      :
      <Redirect to="/expert/login" />
  }

  const userInfo = lazyUserInfo.read()


  const addOrUpdateExpert = (expert) => console.log(expert) ||
    jwtPostFetcher(getJwt())('/api/expert', expert, {method: 'PUT'})

  const incompleteFooter =  (form, expert, providerInfo) => <>
    {((!providerInfo?.ssoEnabled) || expert.email.indexOf('@purview') >= 0) && <div className="left-block">
      <Button.a target="_top" href={"/api/expert/passettings?t="+jwt}>Account settings</Button.a>
    </div>}
    <div className="right-block">
      <Button.Link to="/expert">Maybe later</Button.Link>
      <Button isLoading={form.isLoading} highlight type="submit">Finish profile</Button>
    </div>
  </>

  const expertFooter =  (form, expert, providerInfo) => <>
    
    {((!providerInfo?.ssoEnabled) || expert.email.indexOf('@purview') >= 0) && <div className="left-block">
      <Button.a target="_top" href={"/api/expert/passettings?t="+jwt}>Account settings</Button.a>
    </div>}
    <div className="right-block">
      <Button.Link to="/expert">Cancel</Button.Link>
      <Button isLoading={form.isLoading} highlight type="submit">Update details</Button>
    </div>
  </>

  return <>
    <UserNavbar
      indexUrl="/expert"
      resetPasswordUrl="/api/expert/resetPassword"
      // subtitle="Expert physician"
      subtitle={intl.formatMessage({defaultMessage:"Expert physician", description:"Expert header bar subtitle expert physicians"})}
      exitUrl='/api/expertAuthUrl'
      routes={<>
        <NavbarTabs
          selected={location.pathname === '/expert'}
          to="/expert"
        >Cases</NavbarTabs>
      {showButton && <FeedbackButton icon="feedback" textOnly onClick={()=>{feedbackPopup.open()}}>Give feedback</FeedbackButton>}
      </>}
      extraContextMenuItem={<Li label="Edit profile" linkTo="/expert/profile" />}
    />
    <ProviderTitle/>
    <Routes>
      <Route path="case/:requestId/*" element={<ExpertCase feedbackPopup={feedbackPopup} setShowButton={setShowButton}/>} />
      <Route path="profile/finish" element={<EditExpert onSuccess={() => navigate("/expert")} isIncomplete footer={incompleteFooter} isExpert action={addOrUpdateExpert} emptyId=""/>}/>
      <Route path="profile" element={<EditExpert onSuccess={() => navigate("/expert")} isExpert footer={expertFooter} action={addOrUpdateExpert} emptyId=""/>}/>
      <Route path="*" element={<ExpertCaseList jwt={jwt} feedbackPopup={feedbackPopup} />}/>
    </Routes>
  </>
}


const useAuthSuspense = willUseSuspense((requestId) => jwtGetFetcher(getJwt())(`/api/viewCaseRequest?requestId=${requestId}&timezoneOffset=${getTimezoneOffset()}`))
const SuspenseStep = ({Component, ...props}) => <Suspense><Component {...props} /></Suspense>

const ExpertCase = ({feedbackPopup, setShowButton}) => {
  const { requestId } = useParams()
  const [caseRequest, setCaseRequest] = useState(useAuthSuspense(requestId))
  const userInfo = lazyUserInfo.read()
  const providerInfo = lazyProviderInfo.read()
  useEffect(() => {
    var now = new Date()
    var eventProperties = {
      expertName_str: userInfo.firstName + ' ' + userInfo.lastName,
      expertMail_str: userInfo.login,
      expertId_int: userInfo.id,
      customer_str: providerInfo.name,
      dateTime_date: now,
      requestId_int: requestId,
      caseId_str: caseRequest.requestCode,
      caseState_str: caseRequest.state
    }
    window.FS.event('Expert Case Accessed',eventProperties)
  }, [])

  // console.log("caseRequest", caseRequest)
  return caseRequest && <PageWrapper responsiveWorkaround>
    <ExpertRequestSidebar caseRequest={caseRequest}/>
    <PageContent>
      <Routes>
        <Route path="remote-consultation" element={<SuspenseStep Component={ExpertRemoteConsultation} setCaseRequest={setCaseRequest} caseRequest={caseRequest} feedbackPopup={feedbackPopup}/>}/>
        <Route path="overview" element={
          caseRequest.isCoReview 
            ? <ExpertReadOnlyOverview setCaseRequest={setCaseRequest} caseRequest={caseRequest} feedbackPopup={feedbackPopup} path="overview"/>
            : <ExpertOverview setCaseRequest={setCaseRequest} caseRequest={caseRequest} feedbackPopup={feedbackPopup}/>
        }/>
        <Route path="questions" element={<ExpertAnswers setCaseRequest={setCaseRequest} caseRequest={caseRequest} feedbackPopup={feedbackPopup}/>}/>
        <Route path="report" element={<ExpertReport setCaseRequest={setCaseRequest} caseRequest={caseRequest} feedbackPopup={feedbackPopup} setShowButton={setShowButton}/>}/>
        <Route path="upload-report" element={<ExpertReport setCaseRequest={setCaseRequest} caseRequest={caseRequest} feedbackPopup={feedbackPopup} setShowButton={setShowButton} uploadPDF/>}/>
        <Route path='*' element={<Redirect to={`/expert/case/${requestId}/${getExpertDefaultPath(caseRequest?.state)}`} replace/>}/>
      </Routes>
    </PageContent>
  </PageWrapper>
}