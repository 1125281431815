import { Button, Icon, Link } from '@startlibs/components';
import {TextInput} from '@startlibs/form'
import {getColor} from '@startlibs/utils';
import {useToggle} from '@startlibs/core'
import React from 'react';
import _ from 'lodash/fp'
import styled from 'styled-components';
import {CaseRequestCard} from '../admin/CaseRequestCard'
import {ConfirmDialog, useConfirmDialog} from '../hooks/useConfirmDialog'
import {ContactSelectBoxes, SelectorTitle} from '../admin/steps/RecordsStep'
import {ContactSelectInput} from '../components/ContactSelectInput'
import {DIGITAL, OFFLINE} from '../enums/PaymentMethod';
import {PRIMARY} from '../enums/ContactRelationKind'
import {PaymentCard} from './payment/PaymentCard'
import {PaymentDetailsForm} from './forms/PaymentDetailsForm'
import {buildValidation, required} from '../utils/validation';
import {getJwt} from '../hooks/useJwt'
import {jwtPostFetcher} from '../utils/authFetch'

export const SignatureDate = styled.div`
  background: ${getColor('lightGreen')};
  color: ${getColor('success')};
  padding: 0.25rem 0.5rem;
  border-radius: 6px;
  display: inline-block;
  font-style: italic;
  min-height: 2rem;
  ${Icon} {
    font-size: 15px;
    margin-right: 0.25rem;
    line-height: 0;
    vertical-align: -3px;
  }
`
export const SignagureImageContainer = styled.div`
border: 1px solid ${getColor('gray210')};
border-radius: 6px;
margin-bottom: 1rem;
overflow: hidden;
min-height: 3rem;
img {
  max-height: 120px;
  max-width: 100%;
  margin: auto;
}
${SignatureDate} {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
}
`

const onFailure = (e) => {
  console.log(e)
}

const isSendEmail = ({sendEmail,paymentMethod}) => !!sendEmail && paymentMethod === DIGITAL
const isPaid = ({paid,paymentMethod}) => !!paid && paymentMethod === OFFLINE

const EMPTY_VALUES = {paymentMethod: DIGITAL}
export const useAddPayment = (caseRequest,setCaseRequest) => {

  const action = (values) => console.log(values) || jwtPostFetcher(getJwt())("/api/admin/payment?requestId="+caseRequest.requestId,values)


  const onSuccess = (values) =>{
    paymentValues.openWith(EMPTY_VALUES)
    setCaseRequest(_.update("payments",(v=[]) =>v.concat({...values,requestDate:new Date(),chargeDate: values.paid ? new Date(): undefined})))
  }

  const transform = (values) => {
    return {
      sendEmail: isSendEmail(values),
      paid: isPaid(values),
      ...values
    }
  }

  const preValidation = buildValidation({
    'description': [required],
    'paymentMethod': [required],
    'chargeValue': [required],
  })

  const onFailure = (e) => {
    console.log(e)
  }

  const isSendingEmail = useToggle(false)
  const isMarkedAsPaid = useToggle(false)
  const onChange = (prev,values) => {
    isSendingEmail.openWith(isSendEmail(values))
    isMarkedAsPaid.openWith(isPaid(values))
  }

  const paymentValues = useToggle(EMPTY_VALUES)


  const sendEmail = useConfirmDialog(<ConfirmDialog
    transform={transform}
    action={action}
    onSuccess={onSuccess}
    onFailure={onFailure}
    preValidation={preValidation}
    confirmChanges={false}
    values={paymentValues.get()}
    confirm={<Button highlight icon="email">Add fee and request payment</Button>}
    alternativeButton={(closeDialog) => <Button onClick={() => { closeDialog(); setTimeout(request, 150) }}>Return</Button>}
    title="Adding a new fee due now"
    notify="Payment requested successfully."
  >
    <p>You are about to request a payment regarding the following case:</p>
    <CaseRequestCard caseRequest={caseRequest}/>
    <PaymentCard payment={paymentValues.isOpen} hideDateAdded/>

    <SelectorTitle><span>An e-mail will be delivered to the case primary contact with your request:</span> <Link
      className="link"
      to={`/admin/request/${caseRequest.requestCode}/patient`}
      state={{scrollToContact:true}}
    >Edit contacts</Link></SelectorTitle>
    <ContactSelectBoxes path="contactRelationType">
      <ContactSelectInput
        raw
        value={true}
        fieldValue={true}
        caseRequest={caseRequest}
        relationKind={PRIMARY}
        viewOnly
      >
      </ContactSelectInput>
    </ContactSelectBoxes>

    <TextInput
      textarea
      autoResize
      label="Message"
      path="message"
      descText="This will be included in the email sent to the case contact upon requesting payment."
      placeholder="Insert custom message here."
    />
  </ConfirmDialog>,[paymentValues.isOpen])

  const openSendEmail = (values) => {
    paymentValues.openWith(values)
    setTimeout(sendEmail,200)
  }

  const request = useConfirmDialog(<ConfirmDialog
    onChange={onChange}
    transform={transform}
    action={isSendingEmail.isOpen ? undefined : action}
    onSuccess={isSendingEmail.isOpen ? openSendEmail : onSuccess}
    onFailure={onFailure}
    confirmChanges={false}
    values={paymentValues.get()}
    preValidation={preValidation}
    title="Adding a new fee due now"
    notify={isSendingEmail.isOpen ? undefined : "Fee added successfully."}
    confirm={<Button highlight>{
      isSendingEmail.isOpen ? "Proceed" :
      isMarkedAsPaid.isOpen ? "Add and mark as paid" : "Add this fee"}</Button>}
  >
    <p>You are about to add a new fee due now to the following case:</p>
    <CaseRequestCard caseRequest={caseRequest} css="margin-bottom:2rem"/>
    <PaymentDetailsForm caseRequest={caseRequest}/>
  </ConfirmDialog>,[paymentValues.isOpen,isSendingEmail.isOpen,isMarkedAsPaid.isOpen])


  return request

}
