import _ from 'lodash/fp'
import {createNewRecord, RecordClass, RecordFormat,} from './utils'


const createRecord = (group, format) => {
  const rootPath = _.minBy('path', group).path
  return createNewRecord({
      description:group[0].filename,
      recordClass:RecordClass.Pathology,
      recordFormat:format,
      files:group.map((augumentedFile) => ({
        ...augumentedFile,
        params: {multiFilePath: augumentedFile.path.replace(rootPath, '')}
      })),
      fileExtension: group[0].extension
    },
    {
      multi: true,
      params: {
        // uploadedFiles: group.map(
        //   ({ path, filename, extension }) => `${path}/${filename}.${extension}`
        // ),
        infoDescription: group[0].filename,
      },
    }
  )
}

const getPathologyRecordFormat = extension => {
  const formats = {
    tif: RecordFormat.TIF,
    tiff: RecordFormat.TIFF,
    isyntax: RecordFormat.PhilipsiSyntax,
    ndpi: RecordFormat.HamamatsuNDPI,
    svs: RecordFormat.AperioSVS,
    svslide: RecordFormat.AperioSVS,
    bif: RecordFormat.VentanaBIF,
    scn: RecordFormat.Leica,
    vms: RecordFormat.HamamatsuVMS,
    vmu: RecordFormat.HamamatsuVMU,
  }
  return formats[extension] || RecordFormat.TIF
}

const createSingleFilePathologyRecord = ({file,extension,filename}) => {
  return createNewRecord({
    description: filename,
    recordClass: RecordClass.Pathology,
    recordFormat: getPathologyRecordFormat(extension),
    files: [{ file }],
    fileExtension: extension
  })
}

const multiPathologyParser = config => allFiles => {
  const compatibleFiles = allFiles.filter(
    ({ extension }) => extension && config.WHITE_LIST.indexOf(extension.toLowerCase()) >= 0
  )

  const [validGroups, discardGroups] = _.flow(
    _.filter(config.PRIMARY_EVIDENCE),
    _.map(primaryFile =>
      [primaryFile].concat(
        compatibleFiles.filter(
          other => other.file !== primaryFile.file && config.FILTER_GROUP(primaryFile, other)
        )
      )
    ),
    _.partition(config.VALIDATE)
  )(compatibleFiles)
  return [validGroups, _.flatten(discardGroups)]
}

const HamamatsuVMSParserConfig = (() => {
  const PRIMARY_EVIDENCE = ({ extension }) => extension.toLowerCase() === 'vms'

  const WHITE_LIST = ['vms', 'jpeg', 'jpg', 'opt']

  const FILTER_GROUP = (primaryFile, compatible) => {
    const miraxRoot = primaryFile.path
    return compatible.path === miraxRoot
  }

  const VALIDATE = group => {
    const images = group.filter(
      ({ extension }) => ['jpeg', 'jpg'].indexOf(extension.toLowerCase()) >= 0
    )
    return images.length > 0
  }
  return { PRIMARY_EVIDENCE, WHITE_LIST, FILTER_GROUP, VALIDATE }
})()

const HamamatsuVMUParserConfig = (() => {
  const PRIMARY_EVIDENCE = ({ extension }) => extension.toLowerCase() === 'vmu'

  const WHITE_LIST = ['vmu', 'jpeg', 'jpg', 'opt']

  const FILTER_GROUP = (primaryFile, compatible) => {
    const miraxRoot = primaryFile.path
    return compatible.path === miraxRoot
  }

  const VALIDATE = group => {
    const images = group.filter(
      ({ extension }) => ['jpeg', 'jpg'].indexOf(extension.toLowerCase()) >= 0
    )
    return images.length > 0
  }

  return { PRIMARY_EVIDENCE, WHITE_LIST, FILTER_GROUP, VALIDATE }
})()

const MiraxParserConfig = (() => {
  const PRIMARY_EVIDENCE = ({ extension }) => extension.toLowerCase() === 'mrxs'

  const WHITE_LIST = ['mrxs', 'dat', 'ini']

  const FILTER_GROUP = (primaryFile, compatible) => {
    const miraxRoot = `${primaryFile.path}/${primaryFile.filename}`
    return compatible.path === miraxRoot
  }

  const VALIDATE = group => {
    const slideDat = group.find(({ file }) => file.name.toLowerCase() === 'slidedat.ini')
    const dat = group.filter(({ extension }) => extension.toLowerCase() === 'dat')
    return slideDat && dat.length > 0
  }
  return { PRIMARY_EVIDENCE, WHITE_LIST, FILTER_GROUP, VALIDATE }
})()

const PATHOLOGY_EXTENSIONS = ['tiff', 'tif', 'bif', 'scn', 'svslide', 'svs', 'ndpi', 'isyntax']

const isPathologyFile = ({extension}) => PATHOLOGY_EXTENSIONS.indexOf(extension?.toLowerCase()) >= 0

export function* processPathologyFiles(augmentedFiles) {

  const [miraxGroups, miraxDiscardedFiles] = multiPathologyParser(MiraxParserConfig)(augmentedFiles)
  const miraxUsedFiles = _.flatten(miraxGroups).map(_.get('file'))
  yield [miraxGroups.map(group => createRecord(group, 'Mirax')), [], [...miraxUsedFiles,...miraxDiscardedFiles]]

  const [vmsGroups, vmsDiscardedFiles] = multiPathologyParser(HamamatsuVMSParserConfig)(
    augmentedFiles
  )
  const vmsUsedFiles = _.flatten(vmsGroups).map(_.get('file'))
  yield [vmsGroups.map(group => createRecord(group, 'HamamatsuVMS')), [], [...vmsUsedFiles,...vmsDiscardedFiles]]

  const [vmuGroups, vmuDiscardedFiles] = multiPathologyParser(HamamatsuVMUParserConfig)(
    augmentedFiles
  )
  const vmuUsedFiles = _.flatten(vmsGroups).map(_.get('file'))
  yield [vmuGroups.map(group => createRecord(group, 'HamamatsuVMU')), [], [...vmuUsedFiles,...vmuDiscardedFiles]]

  const usedFiles = [...vmsUsedFiles,...vmuUsedFiles,...miraxUsedFiles]

  const discardFiles = [
    ...vmsDiscardedFiles,
    ...vmuDiscardedFiles,
    ...miraxDiscardedFiles,
    ...usedFiles,
  ].map(file => ({file}))

  const remainingFiles = _.differenceBy('file', augmentedFiles,discardFiles)

  const [pathologySingleFiles, finalRemainingFiles] = _.partition(isPathologyFile, remainingFiles)

  yield [pathologySingleFiles.map(createSingleFilePathologyRecord), finalRemainingFiles, pathologySingleFiles]
}
