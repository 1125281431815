import React, {useMemo} from 'react'


export const UploaderConfigContext = React.createContext({})
export const UploaderConfigProvider = (
  {
    children,

    jwt,
    appJwt,
    providerJwt,
    worklistViewerJwt,
    requestId,
    caseId,

    mode,
    isApp,
    role,
    listMode,
    disabled,
    canOpenWorklist,
    withViewAllButton,
    withoutDelete,
    allowDownload,
    allowDownloadMedicalImages,
    canAddHiddenFileToCase,

    canMoveGroup,
    allowReorder,
    autoGrouping,
    canAddGroup,
    persistGroups,

    apiEndpoints,
    refreshUrl,
    patientName,
    linkedStudies,

    setNotification,
    setLinkedStudies,

    removePersistedRecord,
    removeSelectedRecords,
    persistRecordInfo,

    setToBeUploaded,
    setIsUploading,
    setTotal,
    locations,
    adminUserEmail
  }) => {
  const value = useMemo(() => ({
    jwt,
    appJwt,
    providerJwt,
    worklistViewerJwt,
    requestId,
    caseId,

    mode,
    isApp,
    role,
    listMode,
    disabled,
    canOpenWorklist,
    withViewAllButton,
    withoutDelete,
    allowDownload,
    allowDownloadMedicalImages,
    canAddHiddenFileToCase,

    canMoveGroup,
    allowReorder,
    autoGrouping,
    canAddGroup,
    persistGroups,

    apiEndpoints,
    refreshUrl,
    patientName,
    linkedStudies,

    setNotification,
    setLinkedStudies,

    removePersistedRecord,
    removeSelectedRecords,
    persistRecordInfo,

    setToBeUploaded,
    setIsUploading,
    setTotal,
    locations,
    adminUserEmail
  }), [
    jwt, appJwt, providerJwt, worklistViewerJwt, requestId, caseId,
    mode, isApp, role, listMode, disabled, canOpenWorklist, withViewAllButton, withoutDelete, allowDownload, allowDownloadMedicalImages,canAddHiddenFileToCase,
    canMoveGroup, allowReorder, autoGrouping, canAddGroup, persistGroups,
    apiEndpoints, refreshUrl, patientName, linkedStudies,
    setNotification, setLinkedStudies,
    removePersistedRecord, removeSelectedRecords, persistRecordInfo,
    setToBeUploaded, setIsUploading, setTotal,locations,adminUserEmail
  ])

  return <UploaderConfigContext.Provider value={value}>{children}</UploaderConfigContext.Provider>

}