import React, {useContext} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components/macro'
import {DEVICE, DISK} from "../enums/UploaderStepsManagement";
// import {DragDropContext, Droppable} from "react-beautiful-dnd";
import {DragDropContext, Droppable} from "@hello-pangea/dnd";
import {isOtherKnowExtension} from "../dicom/FileParser";
import {Pathology, Radiology} from "../enums/RecordClass";
import {_s} from "@startlibs/utils";
import {isMinified} from "./FileinputBox";
import {AddButton} from "./styled/UploaderStyled";
import {UploaderConfigContext} from "../service/UploaderConfigContext";
import {RecordGroup} from "./RecordGroup";
import {useDoAction} from "../service/hooks/useDoAction";
import {GroupAction} from "../service/GroupAction";
import {useUIDataSelector} from "../service/hooks/useUIDataSelector";
import {useRecordsSelector} from "../service/hooks/useRecords";
import {getItemPersistedUID, getRowsForRecords, getRowsForRecordsStatic} from "../service/utils/recordUtils";

styled
const isOfRecordClass = (recordClass) => (attachment) =>
  attachment?.recordClass === recordClass
let css = "background: #eff4f7; margin:-0.5rem; margin-top: 4px;margin: 0.5rem 0rem; border-radius: 8px;";
let cssBorder = css + "border: 1px solid lightgray;";

export const Records = () => {
  const {
    mode,
    setNotification,
    allowReorder,
    canAddGroup,
    disabled,
  } = useContext(UploaderConfigContext)
  const doAction = useDoAction()
  const recordGroups = useUIDataSelector('groups')
  const recordKeys = useRecordsSelector(_.map(getItemPersistedUID))
  const recordRows = useRecordsSelector(getRowsForRecordsStatic)
  const identifiedFiles = recordRows.filter(item => item.mode === mode && !isOtherKnowExtension(item) && !isOfRecordClass(Pathology)(item) && !isOfRecordClass(Radiology)(item))
  const addGroup = () => doAction(GroupAction.AddGroup)

  const onDragEnd = ({source, destination, ...rest}) => {
    if (!destination) {
      return
    }
    if ((destination.droppableId === "notclass") && (rest?.draggableId === "NonCompliantDicom")) {
      setNotification({
        type: "alert",
        timeout: 0,
        msg: (close) => <span>It is not possible to move NonCompliantDicoms to Hidden Files</span>
      })
      return
    }
    if (source.droppableId === "board") {
      doAction(GroupAction.UpdateGroups, _s.moveElement(source.index, destination.index))
    } else {
      doAction(GroupAction.MoveRecord, source, destination)
    }
  }


  return <>
    <DragDropContext onDragEnd={onDragEnd}>
      {(mode == DISK || mode == DEVICE)
        ? // DEVICE OR DISK
        <>
          {isMinified(mode) && identifiedFiles.length > 0 &&
            <h4 css="font-size: 14px;margin-top: 1rem;">We found the following medical records: </h4>}
          <Droppable
            droppableId="board"
            type="GROUP"
          >
            {(provided) => (
              <>
                <div ref={provided.innerRef} {...provided.droppableProps}
                     css={isMinified(mode)
                       ? recordRows.filter(
                         (item) => item.mode === mode
                           && isOtherKnowExtension(item)
                           && !isOfRecordClass(Pathology)(item)
                           && !isOfRecordClass(Radiology)(item)
                       ).length > 0
                         ? cssBorder
                         : css
                       : "margin:-0.5rem"}>
                  {recordGroups.filter(g => g.id !== 'notclass').map((group, index) => (
                    <RecordGroup key={group.id} index={index} group={group}/>
                  ))}
                  {provided.placeholder}
                </div>
                {/*This div below is the separated notclass item*/}
                <div>
                  {recordGroups.filter(g => g.id === 'notclass').map((group, index) => (
                    <RecordGroup key={group.id} index={index} group={group}/>
                  ))}
                </div>
              </>
            )}
          </Droppable>
        </>
        : // NORMAL
        <Droppable
          droppableId="board"
          type="GROUP"
        >
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}
                 css={isMinified(mode) ? recordRows.filter((item) => item.mode === mode).length > 0 ? cssBorder : css : "margin:-0.5rem"}>
              {recordGroups.map((group, index) => (
                <RecordGroup key={group.id} index={index} group={group}/>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      }

    </DragDropContext>

    {
      allowReorder && canAddGroup && !disabled && (recordKeys.length > 0 || recordGroups.length > 1) &&
      <AddButton css="margin-top:2rem;" onClick={addGroup}>Add record group</AddButton>
    }
  </>
}