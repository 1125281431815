import {useRefState} from '@startlibs/core'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components';
import {enums, Uploader, Uploader2} from 'uploader-frontend'
import {authPostFetcher, jwtGetFetcher, jwtPostFetcher} from '../utils/authFetch'
import {getJwt} from '../hooks/useJwt'
import {getProviderJwt, setProviderJwt} from '../hooks/useProviderJwt'
import {useNotification} from '../components/Notifications'
import { getUploaderJwt, setUploaderJwt } from '../hooks/useUploaderJwt';
import { getStorageHost } from '../hooks/useStorageHost';
import {callIfFunction, getFetcher, postFetcher, using} from '@startlibs/utils'
import { downloadFiles, pdfIframeSrc, viewFileSrc } from './utils';
import { ADMIN, PATIENT } from '../enums/UserRoles';
import {lazyUserInfo} from '../components/WithProvider'

const apiEndpoints = {
  dicomViewer:(studies) => Promise.resolve({viewerURL:getStorageHost()+`/view/records/${studies.filter(_.get('recordUID')).map(_.get("recordUID")).join("|")}?t=${getUploaderJwt()}`}),
  nonDicomViewer: (record) => getStorageHost()+`/view/record/${record.recordUID}?t=${getUploaderJwt()}`,
  // shortTokenUrl: (requestId) => jwtGetFetcher(getJwt())(`/api/shortDownloaderToken`,{requestId: requestId}),
  shortTokenUrl: (requestId) => {
    return requestId 
      ? jwtGetFetcher(getJwt())(`/api/shortDownloaderToken`,{requestId: requestId})
      : jwtGetFetcher(getJwt())(`/api/shortDownloaderToken`)},
  downloadRecord: (record) => getStorageHost()+`/download/record/${record.recordUID}?t=${getUploaderJwt()}`,
  storageHost: () => getStorageHost(),
  loadDownloadRecord: (record,filename) => record.nonCompliantFiles
  ? jwtGetFetcher(getUploaderJwt())(getStorageHost()+`/download/async/nonDicoms/${record.nonCompliantFiles.map(_.get("recordUID")).join("-")}`).then(({fileUID})=> ({url:() => getStorageHost()+`/download/file/${fileUID}?t=${getUploaderJwt()}${filename ? '&originalFilename='+encodeURIComponent(filename) : ""}`}))
  : jwtGetFetcher(getUploaderJwt())(getStorageHost()+`/download/async/${record.recordUID}`).then(({fileUID})=> ({url:() => getStorageHost()+`/download/file/${fileUID}?t=${getUploaderJwt()}${filename ? '&originalFilename='+encodeURIComponent(filename) : ""}`}))
  ,
  pdfIframeSrc: (record) => pdfIframeSrc(record),
  downloadFiles: (shortJwt, records) => {downloadFiles(shortJwt, records)},
  viewFileSrc: (shortJwt, recordId) => viewFileSrc(shortJwt, recordId),
  
}

const fileFields = ['fileName','fileExtension','fileType','fileSize','uid','description','docType','locationFormId','uploadDate']

const fileFields2 = ['fileName','fileExtension','fileType','fileSize','uid','description','docType','locationFormId']
export const RecordsManagerNew = ({caseRequest = {}, autoGrouping, uploaderRef, listMode, caseId, allowReorder, disabled, setMedicalRecords, setCaseRequest, patientInfo, canOpenWorklist, isPatient, role, mode, refreshUrl, ...rest}) => {
  
  const [, setNotification] = useNotification()
  const userInfo = role === ADMIN ? lazyUserInfo.read() : {}
  const adminUserEmail = role === ADMIN ? userInfo?.login : ''

  const medicalRecordsStore = useRefState(() => (caseRequest.medicalRecords||[]).map((file) => ({...file,...file.info})), (prev,current) => setMedicalRecords(current))
  var studyUIDList = []
  caseRequest?.medicalRecords?.map((item) => item?.info?.studyUID ? studyUIDList.push(item?.info?.studyUID) :  null)
  const [linkedStudies, setLinkedStudiesb] = useState(studyUIDList);
  const [locations, setLocations] = useState([]);

  useEffect(() => {

    let refreshTimeout = -1

    // get medicarl record locations
    jwtGetFetcher(getJwt())(`/api/medicalRecordLocationForms`, {requestId:caseRequest.requestId})
      .then((response) => {
        setLocations(response.map(({id, medicalRecordLocationItem: {name}}) => ({id, name})))
      })

    if(canOpenWorklist && !isPatient){
      //let url = 'https://purviewimage.vivacloud.io/worklist/api/authenticate';
      jwtGetFetcher(getJwt())('/api/worklistToken').then((response) => {
        setProviderJwt(response.jwt);
        refreshTimeout = setInterval(() => {
          jwtGetFetcher(getJwt())('/api/worklistToken')
            .then((response) => {
              setProviderJwt(response.jwt)})
        },10*60*1000)

      })
    }
    return  () => {
      clearInterval(refreshTimeout)
      setCaseRequest(_.unset("medicalRecords"));
    }
    
  }, [])

  const setLinkedStudies = (lStudies,type,newStudy) =>{
    
    setLinkedStudiesb(lStudies);
    
    if(type == 'Add'){
      medicalRecordsStore.set(medicalRecordsStore.get().concat(newStudy));
      setMedicalRecords(medicalRecordsStore.get());
    }
  }

  const persistRecord = (record) => {
    medicalRecordsStore.set(_.unionBy(_.property('recordUID'), [record]))
    return Promise.resolve()
  }

 const persistGroups = useMemo(() => {
    let lastUpdateGroups = Promise.resolve()
    let isUpdatingGroups = false
    let nextUpdateGroups = null

    const syncUpdateGroup = () => {
      if (isUpdatingGroups) {
        return lastUpdateGroups
      } else {
        const next = () => {
          isUpdatingGroups = true
          const currentGroups = nextUpdateGroups
          const updateGroups = (groups) => jwtPostFetcher(getUploaderJwt())(getStorageHost()+"/groups/case/"+caseId,{info:groups.filter(({transient})=>!transient)},{method:"PUT"}).then(() => {
            // setMedicalRecords(_.set('groups.list',groups));
            if(isPatient){
              setCaseRequest(_.set('group.info', groups)); 
            }
            // setCaseRequest(_.set('group.info', groups)); 
          })
          return updateGroups(nextUpdateGroups)
            .catch(e => {
              isUpdatingGroups = false
              return Promise.reject(e)
            })
            .then(() => {
              isUpdatingGroups = false
              if (currentGroups !== nextUpdateGroups) {
                syncUpdateGroup()
              }
            })

        }
        lastUpdateGroups = next()
        return lastUpdateGroups
      }
    }
    return (groups) => {
      nextUpdateGroups = groups
      return syncUpdateGroup()
    }
  },[])

  const persistRecordInfo = (updatedRecord,currentInfo) => {

    const notesPromise = updatedRecord?.notes?.note !== currentInfo?.notes?.note
      ? jwtPostFetcher(getUploaderJwt())(getStorageHost()+`/record/${updatedRecord.recordUID}/notes`,{note:updatedRecord?.notes?.note}, {method: 'PUT'})
      : Promise.resolve()
    const infoPromise = (!currentInfo || currentInfo.docType !== updatedRecord.docType || currentInfo.description !== updatedRecord.description)
      ? jwtPostFetcher(getUploaderJwt())(getStorageHost()+"/medicalRecord/info/"+updatedRecord.recordUID,{..._.pick(fileFields2,updatedRecord),type:'NonDicomFile'},{method:"PUT"})
      : Promise.resolve()

    const metaPromise = !_.isEqual(updatedRecord?.metadata,currentInfo?.metadata)
      ? jwtPostFetcher(getUploaderJwt())(getStorageHost()+`/record/${updatedRecord.recordUID}/metadata`,updatedRecord?.metadata, {method: 'PUT'})
      : Promise.resolve()

    const statePromise = (updatedRecord?.state !== currentInfo?.state && currentInfo?.state)
      ? jwtPostFetcher(getUploaderJwt())(getStorageHost()+`/record/${updatedRecord.recordUID}/state`,updatedRecord?.state, {method: 'PUT'})
      : Promise.resolve()

    return Promise.all([notesPromise,infoPromise,metaPromise,statePromise])
      .then(() =>
        medicalRecordsStore.set(_.unionBy(_.property('recordUID'), [updatedRecord]))
      )
  }

  const removePersistedRecord = (recordUID) => {

    const MAX_DELETE_RECORDS =  window.MAX_DELETE_RECORDS || 1000;
    
    var removePromises = null
    var nonCompliantsObj = []

    if(recordUID === enums.MetaRecordType.ALL_NON_COMPLIANT_DICOM){
      using(medicalRecordsStore.get().filter(record => record.format === enums.RecordFormat.NonCompliantDicom))(nonCompliants => {
        
        _.map((item) => nonCompliantsObj.push({'recordUID': item.recordUID}),nonCompliants)
        const chunkedArray = _.chunk(MAX_DELETE_RECORDS, nonCompliants);    
        removePromises = chunkedArray.map(items => {
          if(items.length){
            return jwtPostFetcher(getUploaderJwt())(getStorageHost()+'/record/'+items.map(_.get('recordUID')).join("-"),undefined,{method:"DELETE"})
          }else{
            Promise.resolve({})
          }
        })
        
      })

      return Promise.all(removePromises).then(() => {
          medicalRecordsStore.set(_.differenceBy(_.property('recordUID'), medicalRecordsStore.get(),  nonCompliantsObj ))
      })

    }else{
      
      const removePromise = medicalRecordsStore.get().find(record => record.recordUID === recordUID)
        ? jwtPostFetcher(getUploaderJwt())(getStorageHost()+'/record/'+recordUID,undefined,{method:"DELETE"})
        : Promise.resolve(false)

      return removePromise.then(() =>
        medicalRecordsStore.set(_.differenceBy(_.property('recordUID'), _ , [{recordUID}]))
      )
    }
  }

  const removeSelectedRecords = (selectedRecords) => {

    const MAX_DELETE_RECORDS =  window.MAX_DELETE_RECORDS || 1000;
    
    const selectdRecordsObj = []
    _.map((item) => selectdRecordsObj.push({'recordUID': item}),selectedRecords)
    
    const chunkedArray = _.chunk(MAX_DELETE_RECORDS, selectedRecords);
    
    const removePromises =
      chunkedArray.map(items => {
        if(items.length){
          var chuckedSelectedRecordsObj = []
          _.map((item) => chuckedSelectedRecordsObj.push({'recordUID': item}),items)
          return jwtPostFetcher(getUploaderJwt())(getStorageHost()+'/record/'+items.map((item) => item).join("-"),undefined,{method:"DELETE"})
        }else{
          Promise.resolve(false)
        }
      }) 
    
    return Promise.all(removePromises).then(() =>
      medicalRecordsStore.set(_.differenceBy(_.property('recordUID'), medicalRecordsStore.get(),  selectdRecordsObj ))
    )

    // const removePromise =
    //   selectedRecords.length
    //     ? jwtPostFetcher(getUploaderJwt())(getStorageHost()+'/record/'+selectedRecords.map((item) => item).join("-"),undefined,{method:"DELETE"})
    //     : Promise.resolve(false)

    // return removePromise.then(() =>
    //   medicalRecordsStore.set(_.differenceBy(_.property('recordUID'), medicalRecordsStore.get(),  selectdRecordsObj ))
    // )

  }

  return <>
    <Uploader2
      listMode={listMode}
      downloadEnabled={caseRequest}
      disabled={disabled}
      jwt={getUploaderJwt}
      apiEndpoints={apiEndpoints}
      ref={uploaderRef}
      groups={caseRequest.group?.info}
      appJwt={getUploaderJwt}
      persistRecord={persistRecord}
      persistRecordInfo={persistRecordInfo}
      removePersistedRecord={removePersistedRecord}
      removeSelectedRecords={removeSelectedRecords}
      persistGroups={persistGroups}
      setNotification={setNotification}
      medicalRecords={medicalRecordsStore.get()}
      role={isPatient ? PATIENT : role ? role : ADMIN}
      autoGrouping={autoGrouping}
      allowReorder={role === ADMIN ? true : false} 
      canMoveGroup={role === ADMIN ? true : false} 
      canAddGroup={role === ADMIN ? true : false} 
      // Those are to open Radiology Worklist
      patientName={patientInfo?.firstName+' '+patientInfo?.lastName}
      linkedStudies={linkedStudies}
      setLinkedStudies={setLinkedStudies}
      canOpenWorklist={canOpenWorklist}
      worklistViewerJwt={getJwt}
      providerJwt={getProviderJwt}
      withViewAllButton
      downloadFiles={downloadFiles}
      mode={mode}
      requestId={caseRequest.requestId}
      refreshUrl={refreshUrl}
      caseId={caseId}
      locations={locations}
      adminUserEmail={role === ADMIN ? adminUserEmail : ''}
    />
    </>
}