import {Button, ContextMenu, Icon, Li} from '@startlibs/components';
import {formatDate, getColor, media} from '@startlibs/utils';
import {usePopupToggle, useToggle} from '@startlibs/core';
import React from 'react'
import {FormattedMessage} from 'react-intl'
import _ from 'lodash/fp'
import styled from 'styled-components';
import {DATE_FORMAT} from 'config/DateFormat'
import {DropdownButton, StyledRow, StyledTable, StyledTableHead, TextButton} from 'components/StyledTable';
import {Pagination} from 'components/Pagination'
import {
  CASE_CLOSED,
  CASE_REVIEWED,
  PENDING_REQUEST,
  UNDER_REVIEW,
  WAITING_ACCEPTANCE,
  WAITING_MORE_INFORMATION,
} from '../../../enums/CaseState';
import {PaginationWrapper} from '../ExpertList';
import {useToggleImageDialog} from '../../hooks/useToggleImageDialog'
import {useUnshareDialog} from '../../hooks/useUnshareDialog'
import {formatDateNoUTC} from '../../../utils/utils'

const ExpertCaseTable = styled(StyledTable)`
  position: relative;
  a.share-case {
    position: absolute;
    top: .75rem;
    right: 1.25rem;
  }
  ${StyledRow} {
    &:last-of-type {
      border-bottom: 0;
    }
    > div {
      &.case-id {
        flex-basis: 70px;
        min-width: 70px;
      }
      &.patient {
        flex-grow: 1;
        flex-basis: 290px;
        .patient-id {
          margin-right:0.75rem
        }
      }
      &.case-date {
        flex-basis: 140px;
        min-width: 90px;
      }
      &.status {
        flex-basis: 140px;
        min-width: 100px;
      }
      &.actions {
        flex-basis: 260px;
        min-width: 260px;
        .small-screen-visible {
          display: none;
        }
      }
    }
  }
  ${media.max(1024)`
    .small-screen-hidden {
      display: none;
    }
    ${StyledRow} {
      > div {
        &.actions {
          flex-basis: 180px;
          min-width: 180px;
          .small-screen-visible {
            display: flex;
          }
        }
      }
    }
  `}
  ${media.max(880)`
    border: 0px;
    overflow: visible;
    a.share-case {
      top: -3.25rem;
      right: 0;
    }
    ${StyledRow} {
      display: block;
      border: 1px solid ${getColor('gray210')};
      border-radius: 5px;
      padding: 1.5rem 1.5rem 0;
      font-size: 13px;
      position: relative;
      margin-bottom: 1rem;
      &:last-of-type {
        border: 1px solid ${getColor('gray210')};
        margin-bottom: 0;
      }
      &:nth-child(even) {
        background-color: white;
      }
      > div {
        padding: 0;
        margin-bottom: .75rem;
        &:before {
          font-weight: 600;
          display: inline-block;
          margin-right: .25rem;
        }
        &.case-id {
          position: absolute;
          display: inline-block;
          top: 1.5rem;
          right: 1.5rem;
          &:before {
            content: "Case ID: ";
            display: inline;
          }
        }
        &.patient {
          .patient-name {
            font-size: 1.75rem;
            margin-bottom: .5rem;
            &:before {
              content: "Patient: ";
              font-size: 13px;
              display: block;
            }
          }
          .patient-id:before {
            content: "Patient ";
            font-size: 13px;
            font-weight: 600;
          }
        }
        &.case-date {
          &:before {
            content: "Case date: ";
          }
        }
        &.status {
          &:before {
            content: "Status: ";
          }
        }
        &.actions {
          display: flex;
          padding: 0.5rem;
          margin: 0 -1.5rem;
          background: rgba(41, 122, 163, 0.07);
          clear: both;
          .small-screen-visible {
            display: none;
          }
          > * {
            flex-grow: 1;
            text-align: center;
            flex-shrink: 0;
          }
        }
      }
    }
    ${StyledTableHead} {
      display: none;
    }
    .small-screen-hidden {
      display: inline;
    }
  `}
  ${media.max(440)`
    ${StyledRow} {
      > div {
        &.case-id, &.patient-id {
          position: static;
          font-size: 12px;
        }
        &.patient-id {
          text-align: right;
          float: right;
        }
      }
    }
  `}
`

export const ExpertCaseList = ({cases,expert, openShareCase, setCases}) => {

  const pageToggle = useToggle(1)

  return <>
    <ExpertCaseTable>
      <StyledTableHead>
        <div className="case-id">Case ID</div>
        <div className="patient"><FormattedMessage description="patient label" defaultMessage="Patient"/></div>
        <div className="case-date">Date created</div>
        <div className="status">Status</div>
        <div className="actions" ></div>
      </StyledTableHead>
      {
        cases.slice((pageToggle.isOpen-1)*10,pageToggle.isOpen*10).map(sharedCase => <CaseRow setCases={setCases} expert={expert} sharedCase={sharedCase} key={sharedCase.id} />)
      }
      <Button.a onClick={openShareCase} target="_top" className="share-case" small icon="plus-circle">Assign case</Button.a>
    </ExpertCaseTable>
    <PaginationWrapper>
      <Pagination setPageParam={(page) => () => pageToggle.openWith(page)}
                  currentPage={pageToggle.isOpen}
                  totalPages={Math.ceil((Number(cases.length)) / 10)}/>
    </PaginationWrapper>
  </>
}

const CaseRow = ({expert,sharedCase,setCases}) => {

  const getStatusLabel = (state) => {
    switch (state) {
      case PENDING_REQUEST: return "Pending request"
      case WAITING_ACCEPTANCE: return "Waiting acceptance"
      case UNDER_REVIEW:
      case WAITING_MORE_INFORMATION: return "Expert reviewing"
      case CASE_REVIEWED: return "Expert reviewed"
      case CASE_CLOSED: return "Finished"
    }
  }

  const {caseCard} = sharedCase

  const caseCardWithModified = {...caseCard,whenModifiedEpochMilli:caseCard.whenModified * 1000}

  const openToggleImageDialog = useToggleImageDialog(sharedCase,caseCardWithModified, () => setCases(cases => cases.map(c => c === sharedCase ? _.update('allowStudyDownload',v => !v,c) : c)))
  const openUnshareDialog = useUnshareDialog(sharedCase,caseCardWithModified, () => setCases(cases => cases.filter(c => c !== sharedCase)))

  const contextMenu = usePopupToggle()
  const caseDate = formatDateNoUTC(new Date(caseCard.whenCreated),DATE_FORMAT() + " at hh:mm")
  return <StyledRow>
    <div className="case-id">{caseCard.requestCode}</div>
    <div className="patient fs-exclude">
      <div className="patient-name"><strong>{caseCard.firstName} {caseCard.lastName}</strong></div>
      <div>
        <span className="nowrap"><strong>DOB:</strong> {caseCard.dob}</span>
      </div>
    </div>
    <div className="case-date">{caseDate}</div>
    <div className="status">{getStatusLabel(caseCard.status)}</div>
    <div className="actions">
      <TextButton onClick={openToggleImageDialog}>{sharedCase.allowStudyDownload ? <span>Disable<span className="small-screen-hidden"> image</span> download</span> : <span>Enable image download</span>}</TextButton>
      <TextButton className="small-screen-hidden" onClick={openUnshareDialog}>Unassign</TextButton>
      <DropdownButton className="small-screen-visible" onClick={contextMenu.open}>
        <Icon icon="arrow-down"/>
        {
          contextMenu.isOpen && <ContextMenu>
            <Li label="Unassign case" onClick={openUnshareDialog}/>
          </ContextMenu>
        }
      </DropdownButton>
    </div>
  </StyledRow>
}
