import React, {useContext} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components/macro'
import {NonCompliantDicom} from "../../enums/RecordFormat";
import {getItemPersistedUID, isMultiFile} from "../../service/utils/recordUtils";
import {useRecordsSelector} from "../../service/hooks/useRecords";
import {SingleFileRecordRow} from "./SingleFileRecordRow";
import {PROVIDER} from "../../enums/UserRoles";
import {UploaderConfigContext} from "../../service/UploaderConfigContext";
import {Radiology} from "../../enums/RecordClass";
import {DicomRecordRow} from "./DicomRecordRow";
import {PathologyRecordRow} from "./PathologyRecordRow";
import {NonCompliantDicomRecordRow} from "./NonCompliantDicomRecordRow";

styled

export const RecordRow = ({dragProvided, dragSnapshot, index, isDragDisabled, group, itemKey}) => {
  const {role} = useContext(UploaderConfigContext)
  const record = useRecordsSelector(_.find(item => item.key === itemKey))
  const recordRow = itemKey === NonCompliantDicom ? {key: NonCompliantDicom} : record

  if (group.id === "notclass" && !record.failed && role !== PROVIDER) {
    return <div ref={dragProvided.innerRef}
                {...dragProvided.draggableProps}
                {...dragProvided.dragHandleProps}>
    </div>
  }

  if (recordRow.key === NonCompliantDicom) {
    return <NonCompliantDicomRecordRow group={group} dragProvided={dragProvided} />
  }
  if (recordRow.recordClass === Radiology) {
    return <DicomRecordRow record={recordRow} group={group} dragProvided={dragProvided}/>
  }
  if (isMultiFile(recordRow)) {
    return <PathologyRecordRow record={recordRow} group={group} dragProvided={dragProvided}/>
  }
  return <SingleFileRecordRow record={recordRow} group={group} dragProvided={dragProvided}/>
}