import {Button, setNotification, TransitionDiv} from '@startlibs/components';
import React, { useEffect, useRef, useState } from 'react'
import {Card} from '../components/PageLayout';
import {getJwt} from '../hooks/useJwt'
import styled, { css } from 'styled-components';
import { jwtGetFetcher, jwtPostFetcher } from '../utils/authFetch';
import { Errors, Field, TextInput, useProvideErrors, WithForm } from '@startlibs/form';
import { useToggle } from '@startlibs/core';
import { getColor } from '@startlibs/utils';
import { transparentize, darken } from 'polished';

export const FeedbackButtonsContainer = styled.div`
  display: flex;
  margin-top: 0.5rem;
  border-radius: 6px;
  overflow: hidden;
  min-height: 48px;
`

export const FeedbackButton = styled(Button)`
  border-radius: 0px;
  flex-basis: 15%;
  flex-grow: 1;
  font-size: 13px;
  font-weight: 600;
  box-shadow: none;
  margin-left: 0 !important;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  background: ${props => transparentize(0.85, getColor(props, true))};
  ${props => props.isSelected ? css `
    color: white;
    background: ${getColor};
    :hover {
      background: ${getColor};
    }
  `: css`
    color: ${props => darken(0.05, getColor(props, true))};
    :hover {
      background: ${props => transparentize(0.77, getColor(props, true))};
    }
  `}
`

export const PatientFeedback = ({caseRequest, isPopup = false, feedbackPopup = null, setJustSentFeedback = null}) => {

  const [rating, setRating] = useState(0);
  const loadingFeedback = useToggle(false)
  const formRef = useRef()
  const [formValues, setFormValues] = useState({review: ""})
  const [ErrorProvider, errors, {setError}] = useProvideErrors()
  
  const postFeedback =  (value) => {
    setRating(value)
    jwtPostFetcher(getJwt())('/api/feedback?requestId='+caseRequest.requestId+'&presave=true',{stars: value, message: ""},{method:"PUT"})
      .then((response) => {
        
      })
      .catch((error) => {
        
      })
  }

  return <> 
        <p css="font-size:14px!important;">Your feedback is invaluable hor helping us improve for future patients.</p>
        <Field label="Overall, how would you rate your experience with this platform?" css="margin-bottom:0;">
        <FeedbackButtonsContainer>
          <FeedbackButton color="#EB5757" isSelected={rating === 1} onClick={() => postFeedback(1)} noFocus css="min-width:104px;">Not so good</FeedbackButton>
          <FeedbackButton color="#F2994A" isSelected={rating === 2} onClick={() => postFeedback(2)} noFocus>Could be improved</FeedbackButton>
          <FeedbackButton color="#E4BE49" isSelected={rating === 3} onClick={() => postFeedback(3)} noFocus>Just OK</FeedbackButton>
          <FeedbackButton color="#69CB50" isSelected={rating === 4} onClick={() => postFeedback(4)} noFocus>Good</FeedbackButton>
          <FeedbackButton color="#00B76A" isSelected={rating === 5} onClick={() => postFeedback(5)} noFocus>Very good</FeedbackButton>
        </FeedbackButtonsContainer>
        </Field>
        <WithForm
          values={formValues}
          ref={formRef}
          action={(values)=> {}}
          onSuccess={(e) => {
            if(!rating){
              return setError("Error", 'Please rate your experience above.')
            }
            loadingFeedback.open();
            jwtPostFetcher(getJwt())('/api/feedback?requestId='+caseRequest.requestId+'&presave=false',{stars: rating, message: e.review},{method:"PUT"})
              .then((response) => {
                setNotification({type:"success", timeout: 4000, msg: 'Feedback shared!'})
                loadingFeedback.close();
                if(setJustSentFeedback){
                  setJustSentFeedback(true)
                }
                if(isPopup){
                  feedbackPopup.close();
                }
              })
              .catch((error) => {
                setError("Error", 'It was not possible to send your feedback. Please try again.')
                loadingFeedback.close();
                if(isPopup){
                  feedbackPopup.close();
                }
              })
            
          }}
        >
          {form => <TransitionDiv>{rating !== 0 && <>
            <TextInput
              label="Feel free to share more details about your experience"
              path="review"
              values={null}
              textarea
              autoResize
              placeholder="Please include as much information as you would like"
              css="margin-top:1.5rem;"
            />
            <ErrorProvider value={errors}>
              <Errors/>
            </ErrorProvider>
            <div css="position: relative; text-align: right; margin-top:2rem;">
              {isPopup && <Button onClick={feedbackPopup.close}>Dismiss</Button>}
              {rating !== 0 && <Button highlight isLoading={loadingFeedback.isOpen} type="submit">Share</Button>}
            </div>
            </>
        }</TransitionDiv>}
        </WithForm>
      {/* </Card> */}
    </>
}
