import {Button, Dialog, Loading} from '@startlibs/components';
import {useToggle} from '@startlibs/core'
import React, {useContext, useEffect} from 'react'
import styled from 'styled-components';

import {AttachmentBox} from './AttachmentBoxStyles';
import {useUIDataSelector} from "../service/hooks/useUIDataSelector";
import {useDoAction} from "../service/hooks/useDoAction";
import {UploaderAction} from "../service/UploaderAction";
import {UIAction} from "../service/UIAction";

const ProcessingBoxComponent = styled(AttachmentBox)`
  padding: 0.75rem 1.5rem 0.75rem 1.5rem;
  justify-content: center;
  font-weight: 600;
  min-height: 3rem;
  div {
    margin-right: 0.75rem;
  }
`

export const ProcessingBox = () => {
  const {unprocessedFiles, isProcessing, dialogs: {cancelProcessing}} = useUIDataSelector()
  const doAction = useDoAction()
  const processingTimeout = useToggle()

  useEffect(() => {
    if (isProcessing > 0) {
      processingTimeout.open()
      processingTimeout.closeAfter(800)
    }
  }, [isProcessing > 0])

  const toggleCancelProcessDialog = () => doAction(UIAction.ToggleCancelProcessingDialog, (resolve) => resolve(false))

  const cancelQueue = () => doAction(UIAction.ToggleCancelProcessingDialog, (resolve) => resolve(true))

  if ((!unprocessedFiles || unprocessedFiles < 0) && !processingTimeout.isOpen) {
    return null
  }

  return <ProcessingBoxComponent>
    <Loading size={18} borderWidth={3}/>
    <div>Processing files...</div>
    {unprocessedFiles > 1 && <a className="link" onClick={toggleCancelProcessDialog}>Cancel</a>}
    {
      cancelProcessing &&
      <Dialog
        closeDialog={toggleCancelProcessDialog}
        title="Are you sure you wish to cancel the upload?"
        css="max-width:52rem;"
        footer={<>
          <Button onClick={toggleCancelProcessDialog}>Continue file processing</Button>
          <Button alert onClick={cancelQueue}>Cancel queued file processing</Button>
        </>}
      >
        <p>You are about to cancel the upload.</p>
        <p><b>If you do so, incomplete DICOM studies may result.</b></p>
        <p>We suggest that you wait for file processing to finish or that you delete your DICOM studies and upload them
          again afterwards to make sure they are complete.</p>
        <p>Files that have already finished uploading will remain in your medical records list unless you delete them
          manually.</p>
      </Dialog>
    }
  </ProcessingBoxComponent>
}
