import React, {useContext} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components/macro'
import {useUIDataSelector} from "../../service/hooks/useUIDataSelector";
import {
  AttachmentActions,
  AttachmentBoxFlexContainer,
  AttachmentCheckbox,
  DropdownButton,
  DropdownIcon,
  TextButton, UploadedDate
} from "../AttachmentBoxStyles";
import {UIAction} from "../../service/UIAction";
import {useDoAction} from "../../service/hooks/useDoAction";
import {ContextMenu, Icon, Li, ListSeparator, Loading} from "@startlibs/components";
import {formatDateNoUTC} from "../../utils";
import {Accepted, Rejected} from "../../enums/RecordState";
import {UploaderConfigContext} from "../../service/UploaderConfigContext";
import {Uploaded} from "../../service/enums/RecordStatus";
import {usePopupToggle} from "@startlibs/core";
import {UploaderAction} from "../../service/UploaderAction";
import {GroupAction} from "../../service/GroupAction";
import {useNewDownload, useViewRecord} from "../../service/utils/downloadFile";
import {DicomStudy, NonCompliantDicom} from "../../enums/RecordFormat";
import {canPlay, canView} from "../../service/utils/recordUtils";
import {ADMIN, PATIENT} from "../../enums/UserRoles";
import { useEffect } from 'react';
import { DEVICE, DISK } from '../../enums/UploaderStepsManagement';

styled

export const RecordRowActions = ({record, isMinified, group, isDragDisabled, deleteRecord}) => {
  const config = useContext(UploaderConfigContext)
  const {
    role,
    mode,
    allowDownloadMedicalImages,
    isApp,
    withoutDelete,
    disabled,
    locations
  } = config
  const isSelectMode = useUIDataSelector('selectMode')
  const selectedRecords = useUIDataSelector('selectedRecords')
  const doAction = useDoAction()
  
  useEffect(() => {
    
    if(record?.recordUID && role == PATIENT && (record?.fileExtension?.toLowerCase() == 'jpg' || record?.fileExtension?.toLowerCase() == 'jpeg')){

      // Files uploaded by patient will be reorganized later. 
      // We can make sure that the files will be hidden if there are more than 10 uploaded in a device or disk.
      // We need this to make sure that the combination of 6 in a device and 8 in a disk wont be hidden.
      if(mode == DEVICE){
        var patDevJpgs = JSON.parse(window.localStorage.getItem("patDevJpgs")) || []
        if(!patDevJpgs.includes(record.recordUID)){
          patDevJpgs = patDevJpgs.concat(record.recordUID)
          window.localStorage.setItem("patDevJpgs",JSON.stringify(patDevJpgs))
        }
      }
      if(mode==DISK){
        var patDiscJpgs = JSON.parse(window.localStorage.getItem("patDiscJpgs")) || []
        if(!patDiscJpgs.includes(record.recordUID)){
          patDiscJpgs = patDiscJpgs.concat(record.recordUID)
          window.localStorage.setItem("patDiscJpgs",JSON.stringify(patDiscJpgs))
        }
      }
    }
  }, [record.recordUID])
  const submitedBy = (record) => {
    // return ''
    if(role == ADMIN && record.stateContext && record.stateContext.Submitted && record.stateContext.Submitted.by) {
      return (record.stateContext.Submitted.by.includes("@") ? (" by " +record.stateContext.Submitted.by) : record.stateContext.Submitted.by.includes("Patient") ? ' by patient' : locations.find(({id}) => id == record.stateContext.Submitted.by)?.name !== undefined ? " by " +locations.find(({id}) => id == record.stateContext.Submitted.by)?.name : '')
    }else{
      return ''
    }
  }

  if (isSelectMode) {
    return <div>
      <AttachmentCheckbox
        raw
        value={selectedRecords.includes(record.key)}
        setValue={() => doAction(UIAction.ToggleSelectedRecord, record.key)}
      />
    </div>
  }

  if (record.status !== Uploaded && record.status !== Accepted) {
    return null
  }

  if (isMinified) {
    return <AttachmentActions>
      <div className="buttons-wrapper">
        {
          !disabled && !withoutDelete &&
          <a className="link" onClick={() => doAction(UIAction.ToggleDeleteRecordDialog, record)}>Delete</a>
        }
      </div>
    </AttachmentActions>
  }
  
  const isViewable = canView(record) && record.key !== NonCompliantDicom
  const isPlayable = canPlay(record)

  return <AttachmentActions>
    <div className="buttons-wrapper">
      {
        (record.status === Uploaded || record.status === Accepted)  && !isApp &&
        <>
          {
            allowDownloadMedicalImages && <DownloadButton config={config} record={record}/>
          }
          {
            isViewable && <ViewButton config={config} record={record}/>
          }
          {
            isPlayable && <ViewButton config={config} record={record} icon="video" label="Play"/>
          }
        </>
      }
      {
        !disabled && !withoutDelete &&
        <DropDownButton role={role} record={record} group={group} isDragDisabled={isDragDisabled} doAction={doAction} deleteRecord={deleteRecord}/>
      }
    </div>
    <AttachmentBoxFlexContainer>
      {
        record.uploadDate &&
        <UploadedDate>
          Uploaded: {
            formatDateNoUTC(new Date(record.uploadDate), "MM/dd/yyyy - hh:mm").indexOf('NaN') >= 0
            ? record.info && record.info.uploadDate 
              ? formatDateNoUTC(new Date(record.info.uploadDate), "MM/dd/yyyy - hh:mm") + submitedBy(record)
              : null
            : formatDateNoUTC(new Date(record.uploadDate), "MM/dd/yyyy - hh:mm") + submitedBy(record)
          }
        </UploadedDate>
      }
    </AttachmentBoxFlexContainer>
  </AttachmentActions>
}

const DownloadButton = ({config, record}) => {
  const {
    requestId,
    apiEndpoints: {downloadFiles: downloadUrl},
    worklistViewerJwt
  } = config

  const [tryDownload, downloadIsLoading, downloadIsStating, downloadFailed] = useNewDownload(worklistViewerJwt, record.recordUIDs || [record.recordUID], downloadUrl, record.fileName + (record.fileExtension ? "." : "") + record.fileExtension, requestId)
  return <TextButton disabled={record.quarantined} isLoading={downloadIsLoading} onClick={tryDownload}>
    {
      downloadIsLoading ?
        <><Loading size={15} borderWidth={3}/>Preparing...</>
        :
        downloadIsStating ?
          <><Icon icon="check"/>Started</>
          :
          <><Icon icon="download"/>Download</>
    }
  </TextButton>
}

const ViewButton = ({config,record, icon = "view", label = "View"}) => {
  const [openViewerLink,viewerIsLoading] = useViewRecord(config,record)
  return <TextButton disabled={record.quarantined} isLoading={viewerIsLoading} onClick={openViewerLink}><Icon icon={icon}/>{label}</TextButton>
}

const DropDownButton = ({role, group, isDragDisabled, doAction, record, deleteRecord}) => {
  const groups = useUIDataSelector('groups')
  const contextMenu = usePopupToggle()

  const addNewGroup = () => {
    return doAction(GroupAction.AddGroup)
  }
  const moveTo = (groupId) => doAction(GroupAction.SimpleMoveRecord, record, group.id, groupId)

  const moveToUngroup = () => {
    let ungroup = groups.filter(g => g.id === 'ungrouped')
    if (!ungroup.length) {
      doAction(GroupAction.UpdateGroups, (groups) =>
        [{name: '', id: 'ungrouped', items: []}].concat(groups.filter(g => g.id !== 'ungrouped'))
      )
    }
    moveTo("ungrouped")
  }

  return <DropdownButton onClick={contextMenu.open}>
    <DropdownIcon icon="arrow-down"/>
    {
      contextMenu.isOpen &&
      <ContextMenu>
        {
          (record.fileExtension?.toLowerCase() === "pdf" && !record.quarantined && role === ADMIN) &&
          <Li
            icon='split-doc'
            label={'Split document'}
            onClick={() => doAction(UIAction.ToggleSplitPDFDialog,record)}
          />
        }
        {
          !isDragDisabled &&
          <Li label="Move to..." icon="move-group">
            <ContextMenu my="top left" at="top right">
              {
                groups.map(groupItem =>
                  groupItem.name
                    ? <Li
                      disabled={group.id === groupItem.id}
                      icon={group.id === groupItem.id && 'check'}
                      label={groupItem.name}
                      onClick={() => moveTo(groupItem.id)}
                    />
                    : null
                )}
              <ListSeparator/>
              <Li
                label="Move to a new group"
                icon="plus-circle"
                onClick={() => moveTo(addNewGroup())}
              />
              {group.id !== 'ungrouped' &&
                <ListSeparator/>
              }
              {group.id !== 'ungrouped' &&
                <Li
                  disabled={group.id === 'ungrouped'}
                  icon="remove-from-group"
                  label={"Remove from this group"}
                  onClick={moveToUngroup}
                />
              }
            </ContextMenu>
          </Li>}
        <Li
          label={record.key === NonCompliantDicom ? "Delete non compliant files" : "Delete record"}
          icon="delete"
          onClick={deleteRecord || (() => doAction(UIAction.ToggleDeleteRecordDialog, record))}
        />
      </ContextMenu>
    }
  </DropdownButton>
}