import React from 'react'
import {getFileNameAndExtension} from './AttachmentsUtils'
import {readFile, readFileAsText} from './fileUtils'

export const isPossibleCCDA = (file) => {
  const [name, extension] = getFileNameAndExtension(file)
  return ['xml', 'cda', 'ccda'].indexOf(extension.toLowerCase()) >= 0
}

function browserSupportsXSLT() {
  var support = false;
  //if (document.recalc) { // IE 5+
  if (isIE) { // IE 5+
    support = true;
  } else if (window.XMLHttpRequest !== undefined && window.XSLTProcessor !== undefined) { // Mozilla 0.9.4+, Opera 9+
    var processor = new XSLTProcessor();
    if (typeof processor.transformDocument == 'function') {
      support = window.XMLSerializer !== undefined;
    } else {
      support = true;
    }
  }
  return support;
}

export const checkIsCCDA = (file) => readFileAsText(file).then(text => checkXSLT(text))


var isIE = ("ActiveXObject" in window)

const checkXSLT = (xmlStr) => {
  if (isIE) {
    const source = new window.ActiveXObject("Msxml2.DOMDocument.3.0");
    source.async = false;
    source.loadXML(xmlStr);
    const stylesheet = new window.ActiveXObject("Msxml2.DOMDocument.3.0");
    stylesheet.async = false
    stylesheet.load("cda.xsl");
    const result = source.transformNode(stylesheet)
    const transformedNode = new DOMParser().parseFromString(result,"text/xml")
    return !!transformedNode.childNodes.length
  } else {
    const processor = new XSLTProcessor();
    const xmlDoc = new DOMParser().parseFromString(xmlStr,"text/xml")
    return fetch(process.env.NODE_ENV === 'development' ? '/public/cda.xsl' : "/public/cda.xsl")
      .then(e =>  e.text())
      .then(text =>  new DOMParser().parseFromString(text,'text/xml'))
      .then(xsl => {
        if (typeof processor.transformDocument == 'function') {
          // obsolete Mozilla interface
          const resultDoc = document.implementation.createDocument("", "", null);
          const transformedNode = processor.transformDocument( xmlDoc, xsl, resultDoc, null);
          return !!transformedNode.childNodes.length
        }
        else {
          processor.importStylesheet(xsl);
          const transformedNode = processor.transformToFragment(xmlDoc, document);
          return !!transformedNode.childNodes.length
        }
      })
  }

}