import React from 'react'
import _ from 'lodash/fp'
import {lazyProviderInfo} from '../../components/WithProvider'
import {Load} from '../../hooks/useSuspense'
import {lazyExperts} from '../../admin/steps/finalReport/reportPages/lazyExperts'
import {Preview} from './Preview'
import {isStrEmpty} from '../../utils'
import {parseDate} from '@startlibs/utils'


const fixExperts = (loadedExperts,isExpert) => (currentIds) => {
  if (!isExpert) {
    return currentIds
  }
  return _.unionBy(_.get('expertId'),loadedExperts.map(({id})=> ({expertId:id,showEdit:true})),currentIds)
}

export const PreviewForCaseRequest = ({
                                        caseRequest,
                                        isExpert,
                                        ...props
                                      }) => {

  return <Load value={lazyProviderInfo}>{providerInfo =>
    <Load value={lazyExperts.use(_.union(caseRequest.reportExpertReviews,isExpert ? [{expertId:''}] : []))}>{experts => {

      const studyCase = caseRequest.caseInfo
      const reportLayout = caseRequest.reportData.reportLayout

      const expertReviews = reportLayout.expertProfileDetails
        ? _.flow(
          fixExperts(experts,isExpert),
          _.map((expertReview)=> _.assign(expertReview,(experts||[]).find(({id}) => expertReview.expertId === id))),
          _.filter((expertReview)=> expertReview.id && !expertReview.hide)
        )(caseRequest.reportExpertReviews)
        : []

      const report = {
        additionalFields:studyCase.additionalFields,
        expertReviews,
        patientInfo: caseRequest.patientInfo,
        requestCode: caseRequest.requestCode,
        id: caseRequest.requestId,
        institutionDetails: caseRequest?.report?.institutionDetails || caseRequest.institutionDetails,
        questionsConsultant:studyCase.questionsConsultant,
        clinicalSummary:studyCase.clinicalSummary,
        caseSummary:studyCase.caseSummary,
        reportData: caseRequest.reportData,
        signatures: studyCase.signatures,
        whenReturnedEpochMilli: caseRequest?.whenReturnedEpochMilli
      }

      return <Preview
        {...props}
        report={report}
        organization={{...providerInfo, ...providerInfo.address}}

      />
    }}</Load>
  }</Load>
}