import {Button, Dialog, Icon} from '@startlibs/components'
import {Errors, SimpleCheckbox, WithForm} from '@startlibs/form'
import {formatDate, getColor} from '@startlibs/utils';
import {lighten} from 'polished';
import {useToggle} from '@startlibs/core'
import React, {useState, useRef, useEffect, useContext} from 'react'
import _ from 'lodash/fp'
import qq from 'fine-uploader'
import styled, {css} from 'styled-components'

import {dicomDateToDate} from '../utils'
import {useViewRecords, useViewStudy} from '../service/utils/downloadFile'
import {UploaderConfigContext} from "../service/UploaderConfigContext";
import {RecordFormat} from "../service/utils";
import {DicomStudy} from "../enums/RecordFormat";
import {useRecordsSelector} from "../service/hooks/useRecords";

const isSafari = qq.safari();

const LaunchViewerButton = styled(Button)`
  margin-left: auto;
  flex-shrink: 0;
  ${props => props.isGroupButton && css`
    margin-top: 0;
    margin-bottom: .25rem;
    border: 0;
    :hover, :focus, :active {
      border: 0;
      box-shadow: none;
      :after {
        display: none;
      }
    }
  `}
`

const StudyItemStyled = styled.div`
  position: relative;
  background: ${getColor('gray240')};
  border-bottom: 1px solid ${getColor('gray210')};
  padding: 0.75rem 4rem 0.75rem 1.25rem;
  cursor: pointer;
  :last-child {
    border-bottom: none;
  }
  :hover {
    background: rgba(0,139,210,0.15);
  }
  ${props => props.isChecked && css`
    background: ${getColor('main')};
    color: white;
    .authorizationAlert {
      color: #ffb9b9 !important;
    }
    :hover {
      background: ${getColor('main')};
    }
  `}
  ${SimpleCheckbox} {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
  }
`
const StudiesListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  p {
    margin-bottom: 0;
  }
  span {
    margin-left: 1rem;
    color: ${getColor('gray180')};
  }
`
const StudiesList = styled.div`
  border: 1px solid ${getColor('gray210')};
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 1.5rem;
  > div {
    max-height: 16rem;
    overflow: auto;
  }
`

const StudyTitle = styled.div`
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 0.25rem;
`

const StudyDetails = styled.div`
  margin-top: 2px;
  font-size: 11px;
  span + span {
    margin-left: 1rem;
  }
`

export const InfoBox = styled.div`
  background: ${getColor('gray240')};
  border: 1px solid ${getColor('gray210')};
  border-radius: 6px;
  padding: 1rem;
  margin-bottom: 0.75rem;
  width: 100%;
  color: ${getColor('gray120')};
  ${props => props.lightBlue && css`
    background: #f0f6f8;
    border: 1px solid rgba(41, 122, 163, 0.25);
    color: ${getColor('main')};
  `}
  ${props => props.error && css`
    background: ${props => lighten(0.43, props.theme.colors.alert)};
    border-color: ${props => lighten(0.32, props.theme.colors.alert)};
    color: ${getColor('alert')};
  `}
  ${props => props.withIcon && css`
    display: flex;
    align-items: center;
    ${Icon} {
      font-size: 16px;
      margin-right: .5rem;
      font-weight: 400;
    }
  `}
`

export const ViewAllButton = ({isGroupButton}) => {
  const dicomRecords = useRecordsSelector(_.filter(_.matchesProperty('recordFormat',DicomStudy)))

  const [selectedAttachments, setSelectedAttachments] = useState([])
  const config = useContext(UploaderConfigContext)
  const [openViewerLink, viewerIsLoading] = useViewRecords(config, RecordFormat.DicomStudy, (dicomRecords.length > 4 ? selectedAttachments : dicomRecords))

  const dialog = useToggle()
  const infoBox = useToggle()

  useEffect(() => {
    if (selectedAttachments.length < 4 && infoBox.isOpen) {
      infoBox.close()
    }
  },[selectedAttachments])

  useEffect(() => {
    setSelectedAttachments(_.filter(attachment => dicomRecords.indexOf(attachment)>=0))
  },[dicomRecords])

  if (isSafari) {
    return null
  }

  return dicomRecords?.length >= 1
    ? <>
      <LaunchViewerButton
        onClick={dicomRecords?.length <= 4 ? openViewerLink : dialog.open}
        isLoading={viewerIsLoading}
        small
        outline
        icon="view"
        isGroupButton={isGroupButton}
      >View {dicomRecords?.length <= 4 && "all "} DICOM studies</LaunchViewerButton>
      {
        dialog.isOpen &&
        <WithForm
          alwaysSave
          action={() => {
            openViewerLink()
          }}
          onSuccess={() => {
            setSelectedAttachments([]);
            dialog.close()
          }}
          preValidation={() => selectedAttachments.length < 1 && {"":"You must select at least one DICOM study to open in the web viewer."}}
        >{form =>
          <Dialog
            title="Select studies to open on web viewer"
            closeDialog={dialog.close}
            footer={<>
              <Button onClick={dialog.close}>Cancel</Button>
              <Button onClick={form.submitForm} highlight>Launch web viewer</Button>
            </>}
          >
            <p>You are about to launch the DICOM studies web viewer.
              Please select the studies you would like to open
              (there is a 4 studies per session technical limitation).
            </p>
            <StudiesListHeader>
              <p><strong>Select DICOM studies to open on web viewer:</strong></p>
              <span>{selectedAttachments.length}/4 selected</span>
            </StudiesListHeader>
            <StudiesList>
              <div>
                {dicomRecords.map(attachment => <AttachmentItem
                attachment={attachment}
                isChecked={selectedAttachments?.indexOf(attachment) >= 0}
                toggle={() => setSelectedAttachments(attachments =>
                  attachments.indexOf(attachment) >= 0
                  ? attachments.filter(a => a !== attachment)
                  : attachments.length >= 4
                    ? (infoBox.open() && false) || attachments
                    : attachments.concat(attachment)
                )}
                />)}
              </div>
            </StudiesList>
            {
              infoBox.isOpen &&
              <InfoBox lightBlue>
                Due to a technical limitation the viewer only supports opening
                4 studies per session. If you would like to view more studies
                consider opening them separately in other browser windows or tabs.
              </InfoBox>
            }
            <Errors/>
          </Dialog>
        }</WithForm>
      }
    </>
    : null
}


const AttachmentItem = ({attachment, isChecked, toggle}) => {
  // const isChecked = values.indexOf(release.id) >= 0

  const ref = useRef()
  useEffect(() => {
    if (isChecked && ref.current) {
      ref.current.scrollIntoView({block: "center"})
    }
  }, [])

  const studyDate = dicomDateToDate(attachment.studyDate)

  return <StudyItemStyled ref={ref} onClick={toggle} isChecked={isChecked}>

    <StudyTitle>{attachment.studyDescription} {!isNaN(studyDate) && `(${formatDate(studyDate, 'MM-dd-yyyy')})`}</StudyTitle>
    <StudyDetails>
      <span>Modalities: {attachment.modalities.join(", ")}</span>
      <span>Patient name: {attachment.patientName}</span>
    </StudyDetails>
    <div onClick={e => e.stopPropagation()}><SimpleCheckbox
      raw
      setValue={toggle}
      value={isChecked}
    /></div>
  </StudyItemStyled>
}
