import {SplitColumnsContainer} from '@startlibs/components'
import {DatePicker, RadioboxGroup, SimpleRadiobox, TextInput} from '@startlibs/form'
import {getColor} from '@startlibs/utils';
import React from 'react'
import styled from 'styled-components';
import {Card, SectionHeading} from '../../components/PageLayout';
import {DATE_FORMAT} from '../../config/DateFormat'
import {FormattedMessage} from 'react-intl'
// import {useRecentlySaved} from '../associates/hooks/RecentlySaved'

export const AddressHeading = styled.div`
  font-size: 13px;
  color: ${getColor('gray150')};
  padding-bottom: .25rem;
  border-bottom: 1px solid ${getColor('gray210')};
  margin: 1.5rem 0;
`

const VIEWPORT_MIN_WIDTH = 500

export const PatientDetails = ({readOnly, isAdmin}) => {

  return <>
    <SectionHeading><h3>
      {
        <FormattedMessage
        defaultMessage='{isAdmin, select, true {Patient} false {Patient details} other {}}'
        description='PatientDetails title'
        values={{isAdmin}}
      />
    }</h3></SectionHeading>
    <Card css="padding-bottom:1.5rem;">
      <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
        <TextInput
          disabled={readOnly}
          type="text"
          path="patientInfo.firstName"
          label="First name"
          placeholder="Ex: John"
          maxLength={255}
          mandatory
        />
        <TextInput
          disabled={readOnly}
          type="text"
          path="patientInfo.middleName"
          label="Middle name"
          placeholder="Ex: James"
          maxLength={255}
        />
        <TextInput
          disabled={readOnly}
          type="text"
          path="patientInfo.lastName"
          label="Last name"
          placeholder="Ex: Doe"
          maxLength={255}
          mandatory
        />
      </SplitColumnsContainer>
      <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
        <DatePicker
          disabled={readOnly}
          weekStartsMonday
          label="Date of birth"
          placeholder={`Ex: ${DATE_FORMAT().toUpperCase()}`}
          format={DATE_FORMAT()}
          path="patientInfo.dob"
          mandatory
        />
        <RadioboxGroup disabled={readOnly} mandatory label="Gender" horizontal path="patientInfo.gender" framedBoxes wide>
          <SimpleRadiobox fieldValue="M" label="Male" className='fs-exclude'/>
          <SimpleRadiobox fieldValue="F" label="Female" className='fs-exclude'/>
          <SimpleRadiobox fieldValue="O" label="Other" className='fs-exclude'/>
        </RadioboxGroup>
      </SplitColumnsContainer>
      <AddressHeading>
        <FormattedMessage
          defaultMessage='Patient mailing address'
          description='PatientDetails address heading'
          values={{isAdmin}}
        />
      </AddressHeading>
      <TextInput
        disabled={readOnly}
        type="text"
        path="patientInfo.address.address"
        label="Street address"
        placeholder="Ex: 001 Street Name"
        maxLength={255}
        mandatory
      />
      <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
        <TextInput
          disabled={readOnly}
          type="text"
          path="patientInfo.address.city"
          label="City"
          placeholder="Ex: New York"
          maxLength={255}
          mandatory
        />
        <TextInput
          disabled={readOnly}
          type="text"
          path="patientInfo.address.state"
          label="State"
          placeholder="Ex: New York"
          maxLength={255}
          mandatory
        />
      </SplitColumnsContainer>
      <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
        <TextInput
          disabled={readOnly}
          type="text"
          path="patientInfo.address.country"
          label="Country"
          placeholder="Ex: USA"
          maxLength={255}
          mandatory
        />
        <TextInput
          disabled={readOnly}
          type="text"
          path="patientInfo.address.zipCode"
          label="Postal code"
          placeholder="Ex: 12345"
          maxLength={255}
          mandatory
        />
      </SplitColumnsContainer>
    </Card>
  </>
}
