import React from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components/macro'
import {RecordRowContainer} from "./RecordRowContainer";
import {DescriptionField} from "./DescriptionField";
import {AttachmentDescription, AttachmentDetails} from "../AttachmentBoxStyles";
import {Pathology} from "../../enums/RecordClass";
import {CCDA, FormatToLabel} from "../../enums/RecordFormat";
import {formatDate} from "@startlibs/utils";
import {dicomDateToDate} from "../../utils";
import {useToggle} from "@startlibs/core";
import {RecordDetailsDialog} from "./RecordDetailsDialog";
import {Button, Dialog} from "@startlibs/components";
import {useDoAction} from "../../service/hooks/useDoAction";
import {UploaderAction} from "../../service/UploaderAction";

styled

export const DicomRecordRow = ({record, group, dragProvided}) => {
  const studyDate = dicomDateToDate(record.info?.studyDate)
  const doAction = useDoAction()
  const recordDetailsDialog = useToggle()
  const cancelDialog = useToggle()
  const cancelLoading = useToggle()
  const deleteLoading = useToggle()
  const deleteRecord = async () => {
    await deleteLoading.wait(doAction(UploaderAction.Delete,record))
    cancelDialog.close()
  }
  const cancelUpload = async () => {
    await cancelLoading.wait(doAction(UploaderAction.CancelRecord,record))
    cancelDialog.close()
  }

  return <RecordRowContainer
    group={group}
    record={record}
    dragProvided={dragProvided}
    cancelUpload={cancelDialog.open}
    openDetails={recordDetailsDialog.open}
    isMultiFile
  >{isMinified =>
    <>
      <AttachmentDescription className="fs-exclude">
        <b>{record.description || "DICOM Study"} {!isNaN(studyDate) && `(${formatDate(studyDate, 'MM-dd-yyyy')})`} </b>
        {
          !isMinified && <a className="light-link" onClick={recordDetailsDialog.open}>Details</a>
        }
      </AttachmentDescription>
      {
        !isMinified &&
        <>
          <AttachmentDetails className="fs-exclude">
            <b>Modalities: </b>{record.info?.modalities?.join(", ")} ({record.info?.seriesUIDs?.length} series)
          </AttachmentDetails>
          <AttachmentDetails className="fs-exclude">
            <b>Patient: </b>{record.info?.patientName?.replace(/\^/g, ' ') || null}
          </AttachmentDetails>
        </>
      }
      {
        recordDetailsDialog.isOpen &&
        <RecordDetailsDialog
          title="DICOM study details"
          closeDialog={recordDetailsDialog.close}
          cancelUpload={cancelDialog.open}
          record={record}
          withCancelSingleFile
          info={<InfoBox record={record}/>}
        />
      }
      {
        cancelDialog.isOpen &&
        <Dialog
          closeDialog={cancelDialog.close}
          title="Cancel DICOM study upload"
          footer={<>
            <Button alert isLoading={deleteLoading.isOpen} onClick={deleteRecord}>Delete entire medical record</Button>
            <Button alert isLoading={cancelLoading.isOpen} onClick={cancelUpload}>Stop remaining uploads only</Button>
          </>}
        >
          <p>Would you like to cancel only the remaining file uploads in queue or also delete all files already uploaded
            to this medical record?</p>
        </Dialog>
      }
    </>
  }</RecordRowContainer>
}

const InfoBox = ({record}) => {
  const studyDate = dicomDateToDate(record.info?.studyDate)
  return <>
    <AttachmentDescription className="fs-exclude" css="font-size:14px;">
      <b>{record.info?.studyDescription} {!isNaN(studyDate) && `(${formatDate(studyDate, 'MM-dd-yyyy')})`}</b>
    </AttachmentDescription>
    <AttachmentDetails className="fs-exclude">
      <b>Patient:</b> {record.info?.patientName ? record.info?.patientName.replace(/\^/g, ' ') : null}
    </AttachmentDetails>
    <AttachmentDetails className="fs-exclude">
      <b>Modalities: </b>{record.info?.modalities.join(", ")} ({record.info?.seriesUIDs.length} series)
    </AttachmentDetails>
    <AttachmentDetails className="fs-exclude">
      <b>Referring
        physician:</b> {record.info?.referringPhysician ? record.info?.referringPhysician.replace(/\^/g, ' ') : null}
    </AttachmentDetails>
    <AttachmentDetails>
      <b>Study UID:</b> {record.studyUID}
    </AttachmentDetails>
  </>
}