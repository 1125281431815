import {ContextMenu, Icon, Li, Link, Loading} from '@startlibs/components';
import {callIfFunction, getColor, media} from '@startlibs/utils'
import {useNavigate} from 'react-router'
import {transparentize} from 'polished'
import {usePopupToggle} from '@startlibs/core'
import React, {Suspense} from 'react'
import styled, {css} from 'styled-components'
import {lazyProviderInfo, lazyUserInfo} from './WithProvider'
import {confirmExitPage} from '../hooks/useConfirmExitPage'
import {getJwt} from '../hooks/useJwt'
import {clearSession} from '../hooks/useClearSession'
import {jwtPostFetcher} from '../utils/authFetch'
import {WithLazyResource} from '../hooks/useSuspense'

const NavbarComponent = styled.div`
  background: white;
  height: 5.5rem;
  left:0;
  right:0;
  top: 0;
  position: fixed;
  z-index: 120;
  min-width: 320px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 2px 0px, rgba(0, 0, 0, 0.2) 0px 0px 5px 1px;
  .main-content {
    display: flex;
    align-items: center;
    padding: 0;
    flex-grow: 1;
    flex-shrink: 0;
    .title {
      color: ${getColor('main')};
      margin: 0 3rem 0 .5rem;
      flex-shrink: 0;
      h1 {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;;
        margin: 0 0 2px;
      }
      > div {
        font-size: 13px;
        font-weight: 300;
      }
      ${media.max(720)`
        margin: 0 1rem 0 .5rem;
      `}
      ${media.max(440)`
        ${props => !props.isPatient && css`
          display: none;
        `}
      `}
    }
  }
  .user-dropdown {
    cursor: pointer;
    color: ${getColor('gray120')};
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-right: 1.5rem;
    flex-shrink: 1;
    .email {
      margin-right: .5rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      min-width: 0;
      flex-shrink: 1;
      flex-basis: 50px;
      flex-grow: 1;
    }
    .context-menu-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      .user-icon {
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid ${getColor('gray120')};
        font-size: 18px;
        margin-right: .25rem;
        flex-shrink: 0;
      }
    }
  }
  .navbar-action {
    margin-left: auto;
    padding-right: 1.5rem;
    display: flex;
    &.no-padding {
      padding-right: 0;
    }
  }
  ${media.max(520)`
    height: 5rem;
  `}
  @media print {
    display: none !important;
  }
`
const NavbarSystemLogo = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  color: ${getColor('gray120')};
  margin-right: 1rem;
  img {
    max-width: 180px;
    max-height: 3rem;
    display: inline-block;
    margin-right: 1rem;
    padding-left: 1.5rem;
    ${props => props.hasUrl && css`
      cursor: pointer;
    `}
  }
  ${media.max(720)`
    margin-right: 0;
  `}
  ${media.max(520)`
    font-size: 14px;
    img {
      max-width: 120px;
      max-height: 3rem;
    }
  `}
  ${media.max(320)`
    span {
      display: none;
    }
  `}
`
export const NavbarTabs = styled(Link)`
  text-decoration: none;
  color: ${getColor('main')};
  font-size: 15px;
  margin: 0 0.5rem;
  height: 100%;
  padding: 4px 1.5rem;
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  :hover {
    background: ${props => transparentize(0.925, getColor('main')(props))};
  }
  ${props => props.selected && css`
    border-bottom: 4px solid ${getColor('main')};
    pointer-events: none;
    padding-bottom: 0;
  `}
  ${media.max(720)`
    padding: 4px .5rem;
    ${props => props.selected && css`
      padding-bottom: 0;
    `}
  `}
`

export const LogoutButton = styled.a`
  color: ${getColor('main')};
  font-size: 13px;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
  vertical-align: 2px;
  display: flex;
  align-items: center;
  ${Loading} {
    margin-right: 1rem;
  }
`

export const Navbar = ({indexUrl, routes, menu,subtitle, isPatient}) => {
  const navigate = useNavigate()
  return <NavbarComponent isPatient={isPatient} className="JS-fix-dialog-scroll">
    <div className="main-content">
      <Suspense fallback={<img/>}>
        <WithLazyResource value={lazyProviderInfo}>{providerInfo => <>
          <NavbarSystemLogo as={indexUrl ? Link : "div"} to={indexUrl} hasUrl={indexUrl}>

            <img src={providerInfo.logoURL || '/public/purview-logo.png'} alt="Logo"
                 onClick={() => indexUrl ? confirmExitPage().then(() => navigate(indexUrl)) : undefined}/>
          </NavbarSystemLogo>
          <div className="title">
            <h1>Expert View</h1>
            <div>{subtitle}</div>
          </div>
          {routes}
        </>}</WithLazyResource>
      </Suspense>
    </div>
    {menu}
  </NavbarComponent>
}


export const UserNavbar = ({indexUrl, routes, extraContextMenuItem,exitUrl, resetPasswordUrl, subtitle}) => {
  const contextMenu = usePopupToggle()

  return <Navbar
    subtitle={subtitle}
    indexUrl={indexUrl}
    menu={
      <WithLazyResource value={lazyProviderInfo}>{providerInfo => <>
        <WithLazyResource value={lazyUserInfo}>{userInfo =>
          <div className="user-dropdown" onClick={contextMenu.open}>
            <span className="email">{userInfo.login}</span>
            <div className="context-menu-wrapper">
              <Icon icon="user" className="user-icon"/> <Icon icon="arrow-down"/>
              {
                contextMenu.isOpen &&
                <ContextMenu atRight>
                  {callIfFunction(extraContextMenuItem,userInfo)}
                  {((!providerInfo.ssoEnabled) || userInfo.login.indexOf('@purview') >= 0) ? <Li label="Change password"
                      onClick={() => confirmExitPage().then((r) => setTimeout(() => window.location = resetPasswordUrl+'?t=' + getJwt(), 100))}/>
                      : null}
                  <Li label="Sign out" icon="sign-out"
                      onClick={() => confirmExitPage().then(() => jwtPostFetcher(getJwt())("/api/logout","") ).finally(() => {clearSession(); window.location = exitUrl})}/>
                </ContextMenu>
              }
            </div>
          </div>
      }</WithLazyResource>
      </>}</WithLazyResource>
    }
    routes={routes}
  />

}
