import { darken, transparentize } from 'polished';
import React from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components'

import {Icon, Dialog, Button, ContextMenu, Li, ListSeparator, Loading} from '@startlibs/components'
import {TextInput} from '@startlibs/form'
import { media, callIfFunction, getColor } from '@startlibs/utils';
import {useToggle, usePopupToggle} from '@startlibs/core'

import { ADMIN, EXPERT, PROVIDER } from '../enums/UserRoles';
import {
  AttachmentActions,
  AttachmentBox,
  AttachmentBoxFooter,
  AttachmentCheckbox,
  AttachmentDescription,
  AttachmentDetails,
  AttachmentIcon,
  AttachmentInfoContainer,
  DraggableCornerIcon,
  DropdownButton,
  DropdownIcon,
  ProgressBar,
  ProgressDetails,
  RejectLabel,
  TextButton,
  UploadedDate,
  UploadingStatus,
  AttachmentBoxFlexContainer,
  UploadingRecords
} from './AttachmentBoxStyles';
import { CCDA, FormatToLabel } from '../enums/RecordFormat';
import { Clinical, Other, Pathology } from '../enums/RecordClass';
import { EnhancedRecordClassList } from './FileinputBox';
import {FailedLabel} from './styled/FailedLabel'
import {MetadataField} from './recordGroup/MetadataField'
import {NotesField} from './recordGroup/NotesField'
import {RecordStateFooter} from './RecordStateFooter'
import { Rejected, Submitted } from '../enums/RecordState';
import {formatDateNoUTC} from '../utils'
import {EmptyFile, FileNotFound, getFileStateLabel, InvalidFilename, Quarantined} from '../enums/FileState'
import {getFileTypeIcon} from '../utils/FileType'
import { useNewDownload, useViewRecord } from '../service/utils/downloadFile';
import {useUploader} from '../hooks/useUploader'
import {useAddRetryFilesNotFound} from './hooks/useFilesNotFound'
import { DEVICE, DISK } from '../enums/UploaderStepsManagement';
import { PdfSplitPopup } from './pdf/PdfSplitPopup';

export const SVS_DOCTYPE = 'Pathology Results'

const DOCUMENT_TYPES = [
  '',
  'Pathology Report',
  'Radiology Report',
  'Clinical Notes',
  'History and Physical',
  'Lab (other) Result',
  'Bloodwork Result',
  'Other'
]

const EditDetailButton = styled.div`
  display: inline-block;
  padding: 4px;
  border-radius: 5px;
  margin: -0.25rem 0 -0.25rem -4px;
  position: relative;
  max-width: 83%;
  ${media.max(520)`
    margin-top: -2px;
    margin-bottom: -4px;
  `}
  ${props => props.onClick && `:hover {
    background: rgba(0,0,0,0.06);
    cursor: pointer;
  }`}
  ${Icon} {
    font-size: 18px;
    margin-left: 0.25rem;
    vertical-align: -3px;
  }
  ${DropdownIcon} {
    font-size: 12px;
    vertical-align: -1px
  }
  flex-grow: 1;
`

const canView = (attachment) => {
  return ["pdf","jpg","jpeg","png","gif","bmp","webp","pdf"].indexOf((attachment.fileExtension || "").toLowerCase())>=0  ||
    attachment.recordClass === Clinical || (attachment.recordClass === Pathology)
}

const canPlay = (attachment) => {
  return ["webm","mp4","mov","ogv","m4a","ogg"].indexOf((attachment.fileExtension || "").toLowerCase())>=0
}

export const NonDicomBox = ({
                              jwt, appJwt, disabled, withoutDelete, attachment, onFinishUpload, onCancel, onFailure, removeFile, retry, setAttachment, isApp,
                              dragProvided, role, currentGroup, moveTo, isDragDisabled, attachmentGroups, setAttachmentGroups, addGroupAndMove, setNotification,
                              isSelectMode, selectedRecords, setSelectedRecords, viewerUrl, shortTokenUrl,
                              // viewerUrl = (attachment) => `/api/viewNonDicom?file=${encodeURIComponent(attachment.uid + (attachment.fileExtension ? "." : "") + attachment.fileExtension)}&t=${callIfFunction(appJwt)}`,
                              expertViewJwt, pdfIframeSrc,downloadUrl, mode, requestId, records, setRecords, refreshUrl
                              // downloadUrl = (attachment) => `/api/viewNonDicom?file=${encodeURIComponent(attachment.uid + (attachment.fileExtension ? "." : "") + attachment.fileExtension)}&t=${callIfFunction(appJwt)}`
}) => {

  const params = {class: attachment.recordClass || Other, format: attachment.format || Other, mode: mode ? mode : 'normal'}

  const inQueue = attachment.sourceFile && !attachment.fileToUpload

  const isQuarantined = attachment.quarantined || attachment.failed === Quarantined

  const descriptionToggle = useToggle()
  const docTypeToggle = usePopupToggle()
  const deleteDialog = useToggle()
  const loading = useToggle()
  const loadingState = useToggle()
  const contextMenu = usePopupToggle()
  const splitPdfDialog = useToggle()

  const emptyList = []
  const attachmentUIDsList = emptyList.concat(attachment.recordUID)

  const isMinified = () => (mode === DEVICE || mode === DISK);
  const [tryDownload,downloadIsLoading,downloadIsStating,downloadFailed] = useNewDownload(expertViewJwt,attachmentUIDsList,downloadUrl,attachment.fileName + (attachment.fileExtension ? "." : "") + attachment.fileExtension, requestId)
  const [openViewerLink,viewerIsLoading] = useViewRecord(expertViewJwt, attachment, requestId, shortTokenUrl)

  const setNotes = (value) => setAttachment(_.set('notes.note', value))
  const setMetadata = (value) => setAttachment(_.set('metadata', value))
  const setState = (value) => setAttachment(_.set('state', value))
  const setDescription = (value) => setAttachment(_.set('description', value))

  const confirmDescription = () => {
    setDescription(descriptionToggle.isOpen)
    descriptionToggle.close()
  }

  const tryConfirmDescription = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      confirmDescription()
    } else if (e.key === 'Escape') {
      e.preventDefault()
      descriptionToggle.close()
    }
  }

  function handleClick(id){
    // If contains the id. Must remove it
    if(selectedRecords.findIndex(listItem => listItem === id) >= 0){
      // Found
      const filtered = selectedRecords.filter(listItem => listItem !== id)
      setSelectedRecords(filtered)
    }else{
      // Do not contains. Must add it into the array
      const added = selectedRecords.concat(id)
      setSelectedRecords(added)
    }
  }
  
  const [progress, cancel] = useUploader(attachment.fileToUpload, {
    params,
    jwt,
    onSuccess: onFinishUpload,
    onCancel: onCancel,
    onFailure: onFailure
  })
  useAddRetryFilesNotFound(attachment.failed === FileNotFound, retry)

  // if (currentGroup === "notclass" && !attachment.failed && !isMinified()){
  if (currentGroup === "notclass" && !attachment.failed && role !== PROVIDER){
    return <div ref={dragProvided.innerRef}
      {...dragProvided.draggableProps}
      {...dragProvided.dragHandleProps}>
    </div>
  }
  return (
          (mode === DEVICE && attachment.mode === DEVICE) || 
          (mode === DISK && attachment.mode === DISK)  ||
          !isMinified()
        // )  && (currentGroup !== "notclass" || isMinified()) && <AttachmentBox
        // )  && (currentGroup !== "notclass") && <AttachmentBox
        )  && <AttachmentBox
    failedUpload={attachment.failed} isApp={isApp} hasFooter={isQuarantined || (false && (attachment.state) && (role === ADMIN))}
    unavailable={isQuarantined || false && ((attachment.state === Rejected) && (role === ADMIN))}
    // hidden={((attachment.state === Rejected) && (role === EXPERT))}
    ref={dragProvided.innerRef}
    {...dragProvided.draggableProps}
    {...dragProvided.dragHandleProps}
    isSelectMode={isSelectMode}
    onClick={isSelectMode ? (() => {handleClick(attachment.recordUID)}) : undefined}
    isSelected={selectedRecords.findIndex(listItem => listItem === attachment.recordUID) >= 0}
    mode={mode}
  >
    
    {!isDragDisabled && <DraggableCornerIcon icon="draggable-corner"/>}
    <AttachmentIcon
      icon={getFileTypeIcon(attachment.fileExtension,attachment.recordClass)}
      isPathology={attachment.recordClass === Pathology}
      mode={mode}
    />
    <AttachmentInfoContainer>
      {!isMinified() && <AttachmentDescription className="fs-exclude"><b className="nowrap">Description: </b>
        {
          _.isString(descriptionToggle.isOpen)
          ? <TextInput
            raw
            autoFocus
            onKeyUp={tryConfirmDescription}
            onKeyPress={e => e.key === "Enter" && e.preventDefault()}
            onBlur={confirmDescription}
            maxLength={255}
            value={descriptionToggle.isOpen}
            setValue={(v) => descriptionToggle.openWith(v)}
          />
          : <EditDetailButton onClick={disabled ? undefined : descriptionToggle.willOpenWith(attachment.description)}>
            {attachment.description}
            {!disabled && <Icon icon="edit-box"/>}
          </EditDetailButton>
        }</AttachmentDescription>
      }
      {isMinified() && <AttachmentDescription className="fs-exclude"><b className="nowrap">{attachment.description}</b></AttachmentDescription>}
      {(attachment.recordClass === Pathology || attachment.format === CCDA) && !isMinified() &&
        <AttachmentDetails><b>Format: </b>{FormatToLabel[attachment.format] || attachment.format}
        </AttachmentDetails>}
      {role === ADMIN && <NotesField readOnly={disabled} setNotes={setNotes} notes={attachment.notes?.note} />}
      {role === ADMIN && <MetadataField readOnly={disabled} setMetadata={setMetadata} metadata={attachment.metadata} setNotification={setNotification} />}
    </AttachmentInfoContainer>
    {
      (attachment.sourceFile && !isQuarantined)
      ? (
        attachment.failed
         ? <UploadingStatus>
           <ProgressDetails failed>
             <span>File upload failed </span>
             {
               attachment.failed === InvalidFilename || attachment.failed === Quarantined || attachment.failed === EmptyFile
               ? <a className="link" onClick={cancel}>Cancel</a>
               : <><a className="link" css="margin-right: .25rem;" onClick={retry}>Retry</a><a className="link" onClick={cancel}>Cancel</a></>
             }
             <FailedLabel>
               <span className="label-badge">
                 <Icon icon="warning" />
                 {getFileStateLabel(attachment.failed)}
               </span>
             </FailedLabel>
           </ProgressDetails>
         </UploadingStatus>
         : inQueue
           ? <UploadingStatus>
             <ProgressDetails>
               <span>Waiting...</span>
               <a className="link" onClick={cancel}>Cancel</a>
             </ProgressDetails>
             <ProgressBar progress={progress * 100} completed={progress * 100 === 100}/>
           </UploadingStatus>
           : <UploadingStatus>
             {!isMinified() && <ProgressDetails>
               <span>Uploading file...</span>
               <a className="link" onClick={cancel}>Cancel</a>
             </ProgressDetails>}
             {!isMinified() && <ProgressBar progress={progress * 100} completed={progress * 100 === 100}/>}
             {isMinified() && <div css="display: flex; align-items: center;"><UploadingRecords><Loading size={16} borderWidth={3}/>Uploading</UploadingRecords> <a className="link" onClick={cancel}>Cancel</a></div>}
           </UploadingStatus>
        )
      : isSelectMode 
        ? 
          <AttachmentCheckbox raw setValue={() => {handleClick(attachment.recordUID)}} value={selectedRecords.findIndex(listItem => listItem === attachment.recordUID) >= 0}/>
        :
          <AttachmentActions>
            <div className="buttons-wrapper">
              {
                !isApp && !isMinified() && 
                // <TextButton
                //   disabled={isQuarantined}
                //   download={attachment.fileName + (attachment.fileExtension ? "." : "") + attachment.fileExtension}
                //   // onClick={() => downloadFile(callIfFunction(downloadUrl,attachment))} //ORIGINAL
                //   onClick={() => callIfFunction(downloadUrl([attachment.recordUID]))}
                // >
                //   <Icon icon="download"/>Download
                // </TextButton>
                <TextButton disabled={isQuarantined} 
                          download={attachment.fileName + (attachment.fileExtension ? "." : "") + attachment.fileExtension} 
                          isLoading={downloadIsLoading} 
                          onClick={tryDownload}>
                  {
                    downloadIsLoading ?
                      <><Loading size={15} borderWidth={3}/>Preparing...</>
                      :
                      downloadIsStating ?
                        <><Icon icon="check"/>Started</>
                        :
                        <><Icon icon="download"/>Download</>
                  }
                </TextButton>
              }
              {
                canView(attachment) && !isApp && !isMinified() && 
                <TextButton
                  disabled={isQuarantined}
                  onClick={openViewerLink}
                  isLoading={viewerIsLoading}
                >
                  {viewerIsLoading ? <Loading size={15} borderWidth={3}/> : <Icon icon="view"/>}
                  View
                </TextButton>
              }
              {
                canPlay(attachment) && !isApp && !isMinified() && 
                <TextButton
                  disabled={isQuarantined}
                  onClick={openViewerLink}
                  isLoading={viewerIsLoading}
                >
                  {viewerIsLoading ? <Loading size={15} borderWidth={3}/> : <Icon icon="video"/>}
                  Play
                </TextButton>
              }
              {
                !disabled && !withoutDelete && !isMinified() && <DropdownButton onClick={contextMenu.open}>
                <DropdownIcon icon="arrow-down"/>
                {
                  contextMenu.isOpen && 
                  <ContextMenu>
                    {(attachment.fileExtension.toLowerCase() === "pdf" && role === ADMIN) ? 
                      <Li 
                        icon='split-doc' 
                        label={'Split document'} 
                        onClick={() => {
                          splitPdfDialog.open()
                          contextMenu.close()
                        }}/>
                      : null 
                    }
                    {!isDragDisabled && <Li label="Move to..." icon="move-group">
                      <ContextMenu my="top left" at="top right">
                        {attachmentGroups.map(group =>
                          group.name 
                            ? <Li disabled={currentGroup === group.id} icon={currentGroup === group.id && 'check'} label={group.name} onClick={() => moveTo(group.id)}/>
                            : null
                        )}
                        <ListSeparator/>
                        <Li label="Move to a new group" icon="plus-circle" onClick={() => moveTo(addGroupAndMove())}/>
                        {currentGroup !== 'ungrouped' &&
                          <ListSeparator/>
                        }
                        {currentGroup !== 'ungrouped' &&
                          <Li disabled={currentGroup === 'ungrouped'} icon="remove-from-group" label={"Remove from this group"} 
                              onClick={() => { 
                                let ungroup = attachmentGroups.filter(g => g.id === 'ungrouped')
                                if (ungroup.length > 0) {
                                  moveTo("ungrouped")
                                }else{
                                  setAttachmentGroups(() => {
                                    let groups = attachmentGroups.filter(g => g.id !== 'ungrouped')
                                    return [{ name: '', id: 'ungrouped', items: [] }].concat(groups)
                                  })
                                  moveTo("ungrouped")
                                }
                              }}
                          />
                        }
                      </ContextMenu>
                    </Li>}
                    {/* {role === ADMIN 
                      ? attachment.state === Rejected ? 
                        (loadingState.isOpen ? <Li disabled label="Undo reject" icon="x-circle-undo" onClick={() => loadingState.wait(setState(Submitted))}/> :
                          <Li label="Undo reject" icon="x-circle-undo" onClick={() => loadingState.wait(setState(Submitted))}/>
                        )
                        :
                        (loadingState.isOpen ? <Li disabled label="Reject record" icon="x-circle" onClick={() => loadingState.wait(setState(Rejected))}/> :
                          <Li label="Reject record" icon="x-circle" onClick={() => loadingState.wait(setState(Rejected))}/>
                        )
                      : null
                    } */}
                    <Li label="Delete record" icon="delete" onClick={deleteDialog.open}/>
                  </ContextMenu>
                }
              </DropdownButton>
              }
              {!disabled && !withoutDelete && isMinified() && <a className="link" onClick={deleteDialog.open}>Delete</a>}
            </div>
            <AttachmentBoxFlexContainer>
              {
                !isMinified() && attachment.uploadDate && <UploadedDate>
                  Uploaded: {formatDateNoUTC(new Date(attachment.uploadDate),"MM/dd/yyyy - hh:mm")}
                </UploadedDate>
              }
              {/* {((attachment.state === Rejected) && (role === ADMIN)) && <RejectLabel>Rejected {(loadingState.isOpen ? <Loading size={18} css="margin-left: 0.5rem;"/> :
                <Button small alpha noFocus onClick={() => loadingState.wait(setState(Submitted))}>Undo</Button>)}
              </RejectLabel>} */}
              {attachment.state !== Rejected && loadingState.isOpen && <Loading size={18} css="margin: 0.5rem 0 -0.5rem 0.5rem;"/> }
            </AttachmentBoxFlexContainer>
          </AttachmentActions>
    }
    {
      isQuarantined
      ? <AttachmentBoxFooter alert>
          <div><b>Status: </b>Quarantined</div>
          <div>This medical record file has potentially harmful data.</div>
      </AttachmentBoxFooter>
      : null //attachment.state && (role === ADMIN) && <RecordStateFooter role={role} isPathology={currentGroup === Pathology} state={attachment.state} setState={setState}/>
    }
    {splitPdfDialog.isOpen && 
      <PdfSplitPopup
        close={splitPdfDialog.close} 
        pdfIframeSrc={pdfIframeSrc}
        recordId={attachment.recordUID}
        attachmentGroups={attachmentGroups.filter(g => g.id !== 'notclass')}
        setAttachmentGroups={setAttachmentGroups}
        jwt={jwt}
        expertViewJwt={expertViewJwt}
        requestId={requestId}
        records={records} 
        setRecords={setRecords} 
        refreshUrl={refreshUrl}
      ></PdfSplitPopup>
    }
    {
      deleteDialog.isOpen &&
      <Dialog
        closeDialog={deleteDialog.close}
        title="Confirm medical record deletion"
        footer={<>
          <Button onClick={deleteDialog.close}>Cancel</Button>
          <Button alert isLoading={loading.isOpen} onClick={loading.willWait(removeFile)}>Delete record</Button>
        </>}
      >
        <p>You are about to delete the following medical record:</p>
        <AttachmentBox css="margin-bottom:1rem;">
          <AttachmentIcon icon={getFileTypeIcon(attachment.fileExtension)} className="desktop-only"/>
          <AttachmentInfoContainer>
            <AttachmentDescription className="fs-exclude"><b>{attachment.description}</b></AttachmentDescription>
            {(attachment.recordClass === Pathology || attachment.format === CCDA) &&
            <AttachmentDetails><b>Format: </b>{FormatToLabel[attachment.format] || attachment.format}
            </AttachmentDetails>}
          </AttachmentInfoContainer>
        </AttachmentBox>
        <p><b>Are you sure you want to delete it?</b> Once confirmed this cannot be undone.</p>
      </Dialog>
    }
  </AttachmentBox>
   
}
