import { Button, Icon } from '@startlibs/components';
import { getColor } from '@startlibs/utils';
import React from 'react'
import _ from 'lodash/fp'
import styled, { css } from 'styled-components';
import {CaseRequestCard} from '../../admin/CaseRequestCard'
import {ConfirmDialog, useConfirmDialog} from '../../hooks/useConfirmDialog'
import {DIGITAL, OFFLINE} from '../../enums/PaymentMethod'
import {formatDateNoUTC, formatPrice} from '../../utils/utils'
import {getJwt} from '../../hooks/useJwt'
import {jwtPostFetcher} from '../../utils/authFetch'
import {responseFailure} from '../../utils/validation'

const PAYMENT_METHOD_LABELS = {
  [DIGITAL]: "Digital (credit card)",
  [OFFLINE]: "Offline"
}

export const PaymentCard = ({payment,caseRequest = {}, setCaseRequest, canRevoke, canPay, paymentButton, hideDateAdded}) => {

  const isPaid = payment.paid
  const isRevoked = payment.revoked
  const isPending = !payment.paid && !payment.revoked

  const index = caseRequest.payments?.indexOf(payment)

  const revoke = useConfirmDialog(<ConfirmDialog
    title="Revoking payment request"
    confirm={<Button alert>Yes, revoke payment request</Button>}
    notify="Payment request revoked"
    action={() => jwtPostFetcher(getJwt())(`/api/admin/payment/markAsRevoked/${index}?requestId=${caseRequest.requestId}`)}
    onSuccess={() => setCaseRequest(_.update(`payments[${index}]`,_.assign(_,{revoked:true,revokedDate:new Date()})))}
    onFailure={responseFailure((values,{status}) => status === 561 && {'paid':["It's not possible revoke this case because it has already been paid."]})}
  >
    <p>You are about to revoke a payment request regarding the following case:</p>
    <CaseRequestCard caseRequest={caseRequest} viewOnly />
    <p>By revoking a payment request the case contact will no longer be able to make the following payment:</p>
    <PaymentCard caseRequest={caseRequest} payment={payment}/>
    <p>Are you sure you want to revoke this payment request? You will still be able to request new payments after that.</p>
  </ConfirmDialog>)

  const markAsPaid = useConfirmDialog(<ConfirmDialog
    title="Confirm mark as paid"
    confirm={<Button highlight>Confirm marking as paid</Button>}
    notify="Payment marked as paid."
    onSuccess={() => setCaseRequest(_.update(`payments[${index}]`,_.assign(_,{paid:true,chargeDate:new Date()})))}
    action={() => jwtPostFetcher(getJwt())(`/api/admin/payment/markAsPaid/${index}?requestId=${caseRequest.requestId}`)}
  >
    <p>You are about to confirm a payment regarding the following case:</p>
    <CaseRequestCard caseRequest={caseRequest} viewOnly />
    <p>The following payment will be marked as paid:</p>
    <PaymentCard caseRequest={caseRequest} payment={payment}/>
    <p>Are you sure you want to proceed?</p>
  </ConfirmDialog>)

  return <PaymentStepComponent isPaid={isPaid} isRevoked={isRevoked}>
    <div>
      <div className="description"><b>{payment.description ? payment.description : "Case cost"}:</b> {formatPrice(payment.chargeValue)}</div>
      <div className="details">
        {!hideDateAdded && <span><b>Date added: </b>{formatDateNoUTC(new Date(payment.requestDate),"MM/dd/yyyy")}</span>}
        <span><b>Payment method: </b>{PAYMENT_METHOD_LABELS[payment.paymentMethod]}</span>
      </div>
    </div>
    <div>
      {canRevoke && <Button small alpha onClick={revoke}>Revoke</Button>}
      {canPay && <Button small alpha onClick={markAsPaid}>Mark as paid</Button>}
      {isPaid && <StatusLabel><Icon icon="check"/>{payment.paymentMethod === OFFLINE ? "Marked as paid on" : "Paid on"} {formatDateNoUTC(new Date(payment.chargeDate),"MM/dd/yyyy at hh:mm")}</StatusLabel>}
      {isRevoked && <StatusLabel><Icon icon="unavailable" css="font-weight:bold;"/>Revoked on {formatDateNoUTC(new Date(payment.revokedDate),"MM/dd/yyyy at hh:mm")}</StatusLabel>}
      {paymentButton}
    </div>
  </PaymentStepComponent>
}

const PaymentStepComponent = styled.div `
  box-shadow: 0 0 0 1px ${getColor('gray210')};
  padding: 1rem 1.25rem;
  background: white;
  border-radius: 6px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  :last-child {
    margin-bottom: 0;
  }
  .description {
    font-size: 16px;
  }
  .details {
    margin-top: 0.25rem;
    span + span {
      margin-left: 1.5rem;
    }
  }
  background-color: ${getColor('lightYellow')};
  ${props => props.isPaid && css`
    background-color: white;
    ${StatusLabel} {
      color: ${getColor('success')};
      background: ${getColor('lightGreen')};
    }
  `}
  ${props => props.isRevoked && css`
    background-color: ${getColor('gray240')};
  `}
`
export const StatusLabel = styled.div `
  font-size: 12px;
  padding: 4px 1rem;
  border-radius: 6px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: rgba(0,0,0,0.6);
  font-style: italic;
  background: rgba(0,0,0,0.075);
  ${Icon} {
    font-size: 16px;
    margin-right: 5px;
    margin-left: -5px;
    line-height: 0.9;
    pointer-events: none;
  }
`
