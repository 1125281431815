import _ from 'lodash/fp'
import React from 'react'
import {formatRecord} from './utils'
import {processAllFiles} from './processAllFiles'
import {getFileInstanceList} from "../dicom/FileEventToList";
import {Canceled, Deleted, FileNotFound, Shortlisted, Waiting} from "../enums/FileState";
import {GroupAction} from "./GroupAction";
import {updateActivityLogBy, updateActivityLogFile} from "../components/hooks/useActivityLog";
import {NonCompliantDicom} from "../enums/RecordFormat";

const updateInfo = (path) => async ({recordManager, config}, record, value) => {
  const update = _.set(path, value)
  if (record.recordUID) {
    await config.persistRecordInfo(update(record), record)
    recordManager.updateRecord(record,update)
  } else {
    recordManager.updateRecord(record,update)
  }
}

export const UploaderAction = {
  Retry: ({recordManager, uploader}, record) => {
    recordManager.queueToRetry(record)
    uploader.start()
  },
  RetryFilesNotFound: ({recordManager, uploader}) => {
    recordManager.retryByFailure(FileNotFound)
    uploader.start()
  },
  RetryFile: ({recordManager, uploader},record,file) => {
    recordManager.queueToRetry(record,file)
    uploader.start()
  },
  Delete: async ({recordManager, uploader, doAction}, record) => {
    await doAction(UploaderAction.DeleteMany, [record])
  },
  DeleteMany: async ({
                       recordManager,
                       doAction,
                       uploader,
                       config: {removeSelectedRecords, setLinkedStudies}
                     }, recordRows) =>
    uploader.doWithoutCallingNext(async () => {
      const records = recordRows.find(_.matchesProperty('key',NonCompliantDicom))
        ? recordRows
          .filter(_.negate(_.matchesProperty('key',NonCompliantDicom)))
          .concat(recordManager.getRecords().filter(_.matchesProperty('recordFormat',NonCompliantDicom)))
        : recordRows
      const canceledRecordsUIDs = await Promise.all(records.map(record => uploader.cancelRecord(record).then(uid => uid || record.recordUID)))
      const recordsUIDs = canceledRecordsUIDs.filter(Boolean)
      if (recordsUIDs.length) {
        await removeSelectedRecords(recordsUIDs)
      }
      records.forEach(recordManager.removeRecord)
      records.forEach(record => updateActivityLogBy('key', record.key, Deleted))
      const studyUIDS = records.map(_.get('studyUID'))
      setLinkedStudies(_.filter(item => !studyUIDS.includes(item)))
      doAction(GroupAction.RemoveInvalidItems)
    }),
  DeleteManyByUID: async ({recordManager, doAction, uploader, config: {removeSelectedRecords}}, recordsUIDs) => {
    doAction(UploaderAction.DeleteMany,recordManager.getRecords().filter(({recordUID,recordFormat}) => recordsUIDs.includes(recordUID) || recordsUIDs.includes(recordFormat)))
  },
  CancelQueue: async ({uploader}) => {
    return uploader.cancelQueue()
  },
  CancelRecord: ({uploader,recordManager},record) =>
    uploader.doWithoutCallingNext(async () => {
      await uploader.cancelRecord(record)
      recordManager.cancelRecord(record)
      updateActivityLogBy('key', record.key, Canceled)
    }),
  CancelSingleFile: ({uploader,recordManager},record,file) =>
    uploader.doWithoutCallingNext(async () => {
      await uploader.cancelFile(file)
      recordManager.removeFile(record,file)
    }),
  Upload: async ({setData, getData, setError, recordManager, uploader, doAction, config}, event) => {
    setData(_.update('isProcessing', i => i + 1))
    let allErrors = []
    const fileInstances = await getFileInstanceList(event)
    if (event.target && event.target.value) {
      event.target.value = ""
    }
    let unprocessedFiles = fileInstances
    setData(_.update('unprocessedFiles', (i = 0) => i + fileInstances.length))
    /* eslint-disable no-restricted-syntax */
    for await (const [records, errors, processedFiles] of processAllFiles(fileInstances,config.adminUserEmail)) {
      unprocessedFiles = _.difference(unprocessedFiles, processedFiles)
      if (records.length) {
        records.map(record => {
          record.files.forEach(instance => updateActivityLogFile(instance.file,Waiting,record))
          record?.studyUID ? config.setLinkedStudies(linkedStudies => linkedStudies.concat(record.studyUID)) : null
        })
        recordManager.insertMany(records.map(_.set('mode',config.mode)))
        doAction(GroupAction.SetupNewRecordsGroups, records)
        config.setTotal(i => i + records.length)
        config.setToBeUploaded(i => i + records.length)
        uploader.start()
      }
      
      setData(_.update('unprocessedFiles', (i) => i - processedFiles.length))
      if (errors.length) {
        errors.map(item => updateActivityLogFile(item.file,Shortlisted,item))
        allErrors = allErrors.concat(errors)
      }
      const canceled = await getData("dialogs.cancelProcessing")?.promise
      if (canceled) {
        setData(_.set('isProcessing', 0))
        setData(_.set('unprocessedFiles', 0))
        return;
      }
    }
    if (allErrors.length >= 1) {
      if(allErrors.length === 1){
        //setError("fileType", unprocessedFiles[0].filename + " could not be uploaded. File type not supported.")
        setError("fileType", allErrors[0]?.filename + '.' + allErrors[0]?.extension + " could not be uploaded. File type not supported.")
      } else {
        setError("fileType", allErrors.length + " files could not be uploaded due to file types not being supported.")
      }
    } 
    // else {
    //   if(unprocessedFiles.length >= 1){
    //     setError("fileType", unprocessedFiles.length + " files could not be uploaded due to file types not being supported.")
    //   }
    // }
    setData(_.update('isProcessing', i => i - 1))
  },
  SetNotes: updateInfo('notes.note'),
  SetMetadata: updateInfo('metadata'),
  SetDescription: updateInfo('description'),
  LinkVivaStudyAsRecord: ({recordManager, doAction}, study) => {
    const record = formatRecord(study)
    recordManager.addRecord(record)
    doAction(GroupAction.SetupNewRecordsGroups, [record])
  },
  SetRecords: ({recordManager},updater) => {
    recordManager.setRecords(updater)
  },
  getRecordsUID: ({  recordManager }, recordRows) => {
    // Needed to get each record UID for the download many action, when there are nonCompliant files
    const records = recordRows.find(_.matchesProperty('key',NonCompliantDicom))
      ? recordRows
        .filter(_.negate(_.matchesProperty('key',NonCompliantDicom)))
        .concat(recordManager.getRecords().filter(_.matchesProperty('recordFormat',NonCompliantDicom)))
        // .filter(record => !record.quarantined)) // Removing quarantined records from the list of records to download
      : recordRows
    return records.map(record => record.recordUID)
  }
}
