import { Button, Icon, Link } from '@startlibs/components';
import {
  CheckboxGroup,
  Errors,
  SimpleCheckbox,
  WithForm
} from '@startlibs/form';
import {getColor, wrapLazy} from '@startlibs/utils';
import React, {useEffect, useRef} from 'react'
import {FormattedMessage} from 'react-intl'
import _ from 'lodash/fp'
import styled from 'styled-components';
import {
  CASE_CLOSED,
  CASE_REVIEWED,
  PENDING_REQUEST,
  WAITING_ACCEPTANCE,
  WAITING_MEDICAL_RECORDS
} from '../../enums/CaseState';
import {
  Card,
  PageContainer,
  PageFooter,
  SectionHeading
} from '../../components/PageLayout';
import {DIGITAL} from '../../enums/PaymentMethod';
import {Header} from '../../components/Header'
import {InfoBox} from '../../components/InfoBox';
import {SignatureDate} from '../../request/Payment';
import {WaitingPatientUpdates} from './WaitingPatientUpdates'
import {buildValidation} from '../../utils/validation'
import {getJwt} from '../../hooks/useJwt'
import {hasAcceptedAllTerms} from '../../patient/utils/caseRequestUtils'
import {isPendingAskingMore} from '../../patient/utils/patientUtils'
import {isStateBefore} from '../../request/utils';
import {jwtGetFetcher} from '../../utils/authFetch'
import {lazyProviderInfo} from '../../components/WithProvider'
import {useConfirmAskPatientUpdate} from './hooks/useConfirmAskPatientUpdate'
import {IfLocale, useIsLocale} from '../../hocs/IfLocale'

export const PaymentRequestedTag = styled.div`
  font-size: 11px;
  color: ${getColor('warning')};
  display: inline-flex;
  align-items: center;
  line-height: 12px;
  border: 1px solid ${getColor('warning')};
  border-radius: 5px;
  padding: 2px 5px 2px 3px;
  margin-left: .5rem;
  ${Icon} {
    font-size: 15px;
    margin-right: .25rem;
  }
`
const MarkAsReadButton = styled(Button)`
  position: absolute;
  right: -0.5rem;
  top: -2px;
`
const lazyTerms = wrapLazy((jwt) =>
  jwtGetFetcher(jwt)('/api/serviceTerms')
)

export const ContactPatientStep = ({caseRequest, setCaseRequest}) => {

  const providerInfo = lazyProviderInfo.read()
  
  var allTermsAccepted = ''
  if(providerInfo?.serviceTerms === true){
    allTermsAccepted = hasAcceptedAllTerms(lazyTerms.read(),caseRequest)
  }else{
    allTermsAccepted = true
  }

  const confirmDialog = useConfirmAskPatientUpdate(caseRequest, setCaseRequest)
  const formRef = useRef()
  const isNotLegal = useIsLocale({not:true,contains:"LEGAL"})

  const needsPayment = !!caseRequest.payments.find(payment => (payment?.paymentMethod === DIGITAL && payment?.chargeValue > 0) && !payment?.paid && !payment?.revoked)
  const needsAgreement = isNotLegal && !caseRequest.acceptanceInfo.acceptedTerms && (providerInfo?.requiresAgreement === true) && (providerInfo?.serviceTerms === true)
  
  const atLeastOneError = (props) => !Object.values(props).filter(_.identity).length && 'Please select at least one update to request.'
  const preValidation = buildValidation({
    'askedPatientDetails': (v, k, props) => atLeastOneError(props),
    // 'askedMedicalRelease': (v, k, props) => atLeastOneError(props),
    'askedRecords': (v, k, props) => atLeastOneError(props),
    'askForPayments': (v, k, props) => needsPayment && atLeastOneError(props),
    'askForServiceTerms': (v, k, props) => atLeastOneError(props)
  })

  useEffect(() => {
    if((providerInfo?.requiresAgreement === true) && (providerInfo?.serviceTerms === true)){
      formRef.current?.setValues(_.set("askForServiceTerms", !caseRequest.acceptanceInfo.acceptedTerms))
    }
  }, [caseRequest.acceptanceInfo.acceptedTerms])


  const wasSentToExpert = !isStateBefore(caseRequest.state)(WAITING_ACCEPTANCE)
  const canUnassign = isStateBefore(caseRequest.state)(CASE_REVIEWED)

  const requestedPayment = caseRequest.caseFlags?.requestFlags?.askForPayments


  if (caseRequest.state === WAITING_MEDICAL_RECORDS || isPendingAskingMore(caseRequest)) {
    return <WaitingPatientUpdates caseRequest={caseRequest} setCaseRequest={setCaseRequest}/>
  }

  const isPending = caseRequest.state === PENDING_REQUEST

  return <PageContainer>
    <Header title={<FormattedMessage defaultMessage='Contact patient' description='ContactPatientStep title'/>}/>
    <WithForm
      alwaysSave
      ref={formRef}
      values={isPending
        ? {askedPatientDetails: true}
        : {
          // askedMedicalRelease: wasSentToExpert ? false : true,
          askedRecords: wasSentToExpert ? false : true,
          askForPayments: isNotLegal && needsPayment,
          // askForServiceTerms: isNotLegal && !caseRequest.acceptanceInfo.acceptedTerms
          askForServiceTerms: needsAgreement
        }
      }
      preValidation={preValidation}
      action={confirmDialog.openWith}
      onSuccess={() => formRef.current.clearErrors()}
    >
      <SectionHeading>
        <p><FormattedMessage defaultMessage="Request that the case contact or referring physician review and update this case." description="ContactPatientStep subtitle"/></p>
      </SectionHeading>
      <Card>
        <IfLocale not contains="LEGAL">
        {
          wasSentToExpert  &&
          (needsPayment || !allTermsAccepted
              ?
              <InfoBox
                lightBlue
                css="margin-bottom: 2rem;"
              >Since this case has been {caseRequest.state === CASE_CLOSED ? "finished " : "assigned to an expert "}, you are not able to request updates other than payment or agreements.</InfoBox>
              :
              <div>
                <InfoBox lightBlue>This case has been {caseRequest.state === CASE_CLOSED ? "finished " : "assigned to an expert "} and no updates can be requested to the patient at the moment.</InfoBox>
                <div>
                  {caseRequest.payments.length < 1 ?
                    <span>You can still setup a payment in the <Link
                      className="link"
                      to="../payment"
                    >Payment page</Link> and send an update request to the patient afterwards.</span>
                    : (canUnassign &&
                      <span>If you need to request updates to the patient please unassign this case from the expert.</span>)
                  }
                </div>
              </div>
          )
        }
        </IfLocale>
        {
          wasSentToExpert
            ? 
            <>
              <IfLocale not contains="LEGAL">
                {(needsPayment || !allTermsAccepted) && 
                  <CheckboxGroup
                    framedBoxes highlightSelected
                  > 
                    {needsPayment &&
                      <SimpleCheckbox
                        css="margin-top: .5rem;" disabled={!needsPayment} path="askForPayments" label={
                          <>Provide payment {requestedPayment &&
                          <PaymentRequestedTag><Icon icon="clock"/> Waiting on payment</PaymentRequestedTag>}</>
                        }
                      />
                    }
                    {(!allTermsAccepted && providerInfo?.serviceTerms === true) &&
                      <SimpleCheckbox
                        css="margin-top: .5rem;"
                        path="askForServiceTerms"
                        label={
                          <span css="padding-right:10rem">
                            Sign the required <Link
                            className="link"
                            to={`/admin/request/${caseRequest.requestCode}/agreements`}
                          >agreements</Link>
                            <MarkAsReadButton
                              as={Link}
                              small
                              to={`/admin/request/${caseRequest.requestCode}/agreements`}
                            >Review agreements</MarkAsReadButton>
                          </span>
                        }
                      />
                    }
                  </CheckboxGroup>
                }
              </IfLocale>
              <IfLocale contains="LEGAL">
                <div>
                  <InfoBox lightBlue>This case has been {caseRequest.state === CASE_CLOSED ? "finished " : "assigned to an expert "} and no updates can be requested to the client at the moment.</InfoBox>
                </div>
              </IfLocale>
            </>
            
            : <CheckboxGroup
              framedBoxes highlightSelected
              label="Select the actions you would like them to complete:"
            >
              {(isNotLegal && !allTermsAccepted && providerInfo.serviceTerms) &&
                <SimpleCheckbox
                  css="margin-top: .5rem;"
                  path="askForServiceTerms"
                  label={
                    <span css="padding-right:10rem">
                      Sign the required <Link
                      className="link"
                      to={`/admin/request/${caseRequest.requestCode}/agreements`}
                    >agreements</Link>
                      <MarkAsReadButton
                        as={Link}
                        small
                        to={`/admin/request/${caseRequest.requestCode}/agreements`}
                      >Review agreements</MarkAsReadButton>
                    </span>
                  }
                />
              }
              <SimpleCheckbox
                css="margin-top: .5rem;"
                path="askedPatientDetails"
                label={<FormattedMessage defaultMessage="Update patient & contact details" description="ContactPatientStep askPatientDetails checkbox label"/>}
              />
              {/* <SimpleCheckbox
                path="askedMedicalRelease"
                label="Locate records and sign release forms"
                css="min-width: 14rem;"
              /> */}
              <SimpleCheckbox path="askedRecords" label="Medical records and releases"/>
              
              {isNotLegal && <SimpleCheckbox
                  disabled={!needsPayment} path="askForPayments" label={
                    <>Provide payment {requestedPayment &&
                    <PaymentRequestedTag><Icon icon="clock"/> Waiting on payment</PaymentRequestedTag>}</>
                  }
                />
              }
              
            </CheckboxGroup>
        }
      </Card>

      {
        (allTermsAccepted && providerInfo.serviceTerms) &&
        <TermsInfo caseRequest={caseRequest} setCaseRequest={setCaseRequest}/>
      }

      <Errors/>
      <PageFooter>
        <Button
          type="submit"
          icon="email"
          highlight
          disabled={wasSentToExpert && !needsPayment && ((isNotLegal && allTermsAccepted) || !isNotLegal)}
        >
          Send request
        </Button>
      </PageFooter>
    </WithForm>
  </PageContainer>
}

const TermsAgreementStatus = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    margin-bottom: 0;
  }
  ${Button} {
    margin-left: 0.5rem;
  }
`

const TermsInfo = ({caseRequest}) => {

  const allSignedByPatient = !caseRequest.acceptanceInfo?.agreements?.find(({byRepresentative}) => byRepresentative)

  return <Card>
    <TermsAgreementStatus>
      {
        allSignedByPatient
          ? <p>This contact has already signed the required <Link
            to={`/admin/request/${caseRequest.requestCode}/agreements`}
            className="link"
          >agreements</Link>.</p>
          : <p>The required <Link
            to={`/admin/request/${caseRequest.requestCode}/agreements`}
            className="link"
          >agreements</Link> were completed.</p>
      }
      <div css="flex-shrink: 0;">
        {
          allSignedByPatient
            ? <SignatureDate><Icon icon="check"/>All agreements signed</SignatureDate>
            : <SignatureDate><Icon icon="check"/>All agreements done</SignatureDate>
        }
      </div>
    </TermsAgreementStatus>
  </Card>
}
