import { Button, Icon, SplitColumnsContainer } from '@startlibs/components';
import {TextInput} from '@startlibs/form'
import {callIfFunction, getColor, parseDate} from '@startlibs/utils';
import React, { useEffect } from 'react';
import _ from 'lodash/fp'
import styled, { css } from 'styled-components';
import {
  BIOPSY_FACILITIES,
  HOSPITALS,
  PHYSICIAN_OR_PROVIDER,
  SCAN_FACILITIES,
  OTHER_FACILITIES
} from '../enums/MedicalRecordLocationType'
import { CASE_REVIEWED, WAITING_ACCEPTANCE } from '../enums/CaseState';
import {ConfirmDialog, useConfirmDialog} from '../hooks/useConfirmDialog'
import { FormBox } from '../components/FormBox';
import {
  ReleaseDetails,
  ReleaseLocationCard,
  ReleaseLocationContent
} from '../request/medicalRelease/ReleaseLocationCard'
import {getJwt} from '../hooks/useJwt'
import {isStateBefore} from '../request/utils'
import { jwtFormFetcher } from '../utils/authFetch';


export const ReleaseList = ({caseRequest,isPatient,editItemButton,onEmpty,suspendedButton,children,releases,setReleases}) => {


  useEffect(() => {
    if (!releases.length) {
      callIfFunction(onEmpty)
    }
  },[])


  const setRelease = (id) => (update) => setReleases(_.map(release => release.id === id ? callIfFunction(update,release) : release))

  const physicianOrProviders = releases.filter(_.matchesProperty('medicalRecordLocationItem.locationType', PHYSICIAN_OR_PROVIDER))
  const hospitals = releases.filter(_.matchesProperty('medicalRecordLocationItem.locationType', HOSPITALS))
  const scanFacilities = releases.filter(_.matchesProperty('medicalRecordLocationItem.locationType', SCAN_FACILITIES))
  const biopsyFacilities = releases.filter(_.matchesProperty('medicalRecordLocationItem.locationType', BIOPSY_FACILITIES))
  const otherFacilities = releases.filter(_.matchesProperty('medicalRecordLocationItem.locationType', OTHER_FACILITIES))

  return <>
    {physicianOrProviders.length > 0 && <>
      <h4>Physicians and providers seen for this condition</h4>
      {physicianOrProviders.map(release =>
        <ReleaseLocationItem
          isPatient={isPatient}
          editItemButton={editItemButton}
          suspendedButton={callIfFunction(suspendedButton,releases)}
          setRelease={setRelease(release.id)}
          release={release}
          caseRequest={caseRequest}
        />)}
      </>
    }
    {hospitals.length > 0 && <>
      <h4>Hospitals visited for this condition</h4>
      {hospitals.map(release =>
        <ReleaseLocationItem
          isPatient={isPatient}
          editItemButton={editItemButton}
          suspendedButton={callIfFunction(suspendedButton,releases)}
          release={release}
          setRelease={setRelease(release.id)}
          caseRequest={caseRequest}
        />)}
    </>}
    {scanFacilities.length > 0 && <>
      <h4>Facilities visited for scans, i.e MRI and CT scan</h4>
      {scanFacilities.map(release =>
        <ReleaseLocationItem
          isPatient={isPatient}
          editItemButton={editItemButton}
          suspendedButton={callIfFunction(suspendedButton,releases)}
          release={release}
          caseRequest={caseRequest}
          setRelease={setRelease(release.id)}
        />)}
    </>}
    {biopsyFacilities.length > 0 && <>
      <h4>Facilities visited for biopsy</h4>
      {biopsyFacilities.map(release =>
        <ReleaseLocationItem
          isPatient={isPatient}
          editItemButton={editItemButton}
          suspendedButton={callIfFunction(suspendedButton,releases)}
          release={release}
          caseRequest={caseRequest}
          setRelease={setRelease(release.id)}
        />)}
    </>}
    {otherFacilities.length > 0 && <>
      <h4>Other Facilities</h4>
      {otherFacilities.map(release =>
        <ReleaseLocationItem
          isPatient={isPatient}
          editItemButton={editItemButton}
          suspendedButton={callIfFunction(suspendedButton,releases)}
          release={release}
          caseRequest={caseRequest}
          setRelease={setRelease(release.id)}
        />)}
    </>}
    {callIfFunction(children,releases)}
  </>
}

const A_DAY = 24*60*60*1000

const ReleaseLocationItem = ({release,setRelease,caseRequest,editItemButton,isPatient,suspendedButton}) => {

  const date = new Date()
  date.setHours(0)

  const expiredDate = parseDate(release.medicalRecordLocationItem.dateExpiration,date,'MM-dd-yyyy').getTime() < date.getTime()
  const suspendedByContact = release.suspend
  const lockedByStatus = !isStateBefore(caseRequest.state,WAITING_ACCEPTANCE) && caseRequest.state !== CASE_REVIEWED

  const locked = suspendedByContact || expiredDate || lockedByStatus

  const edit = useConfirmDialog(<ConfirmDialog
      title="Editing medical location contact"
      action={jwtFormFetcher(getJwt())("/api/medicalRecordLocationFormContact?requestId="+caseRequest.requestId,{method:"PUT"})}
      confirm={<Button highlight>Save changes</Button>}
      values={{...release.medicalRecordLocationItem,locationFormId:release.id}}
      onSuccess={(values) => setRelease(_.set('medicalRecordLocationItem',values))}
      notify="Provider contact details updated successully."
    >
      <p>You are editing the following medical record location. Please review the details and make the desired changes in the inputs below:</p>
      <ReleaseLocationCard location={release.medicalRecordLocationItem}/>
      <FormBox>
        <TextInput
          label="New contact name"
          path="contactName"
          helpText="(Optional)"
        />
        <SplitColumnsContainer>
          <TextInput
            label="New phone/fax"
            path="phoneNumber"
            helpText="(Optional)"
          />
          <TextInput
            label="New email"
            path="email"
            helpText="(Optional)"
          />
        </SplitColumnsContainer>
      </FormBox>
    </ConfirmDialog>
  )

  return <ReleaseLocationReadOnly suspended={expiredDate || suspendedByContact} lockedByStatus={lockedByStatus}>
    <ReleaseLocationContent location={release.medicalRecordLocationItem} editContacts={!locked && edit}/>
    <ReleaseDetails isPatient={isPatient} isExpired={expiredDate || suspendedByContact} release={release} suspendedButton={suspendedButton}/>
    <MainActionContainer>
    {locked ?
      <SuspendedLabel outline small>
        {
          expiredDate
            ? "Expired authorization"
            : suspendedByContact
            ? "Suspended by case contact"
            : (isPatient ? "Editing locked" : "Requesting records locked")
        }</SuspendedLabel>
      :
      callIfFunction(editItemButton,release,setRelease)
    }
    </MainActionContainer>
  </ReleaseLocationReadOnly>
}

const ReleaseLocationReadOnly = styled.div `
  padding: 1rem 1.25rem;
  background: rgba(41, 122, 163, 0.07);
  box-shadow: 0 0 0 1px rgba(41, 122, 163, 0.3);
  border-radius: 5px;
  margin-bottom: 2rem;
  position: relative;
  & + & {
    margin-top: -1rem;
  }
  :last-child {
    margin-bottom: 0;
  }
  ${props => props.suspended && css`
    background: ${getColor('gray240')};
    box-shadow: 0 0 0 1px ${getColor('gray210')};
  `}
  .DocumentLink {
    text-decoration: none;
    color: ${getColor('main')};
    span {
      text-decoration: underline;
    }
    ${Icon} {
      vertical-align: text-top;
      font-size: 17px;
      text-decoration: none;
      margin-right: 4px;
    }
  }
`
const MainActionContainer = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
`

const SuspendedLabel = styled(Button) `
  box-shadow: none;
  pointer-events: none;
  opacity: 0.75;
`
