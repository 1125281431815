import React from 'react'
import {Routes, Route} from 'react-router'
import {AuditLog} from './controlPanel/AuditLog'
import {ControlPanelSidebar} from './controlPanel/ControlPanelSidebar'
import {PageContent, PageWrapper} from './steps/RequestSidebar'
import {NotificationSettings} from './controlPanel/NotificationSettings'
import {PageLoadingSuspense} from '../components/PageLoading'
import {StaffManagement} from './controlPanel/StaffManagement'
import {DivisionManagement} from './controlPanel/DivisionManagement'
import {SuspensePage} from '../components/SuspensePage'

export const AdminControlPanel = () => {

  return <PageWrapper responsiveWorkaround controlPanel>
    <ControlPanelSidebar/>
    <PageContent controlPanel>
      <PageLoadingSuspense>
        <Routes>
          <Route path="audit" element={<AuditLog />}/>
          <Route path="staff" element={<SuspensePage Component={StaffManagement}/>}/>
          <Route path="divisions" element={<SuspensePage Component={DivisionManagement} />}/>
          <Route path="notifications" element={<NotificationSettings />}/>
        </Routes>
      </PageLoadingSuspense>
    </PageContent>
  </PageWrapper>
}
