import React from 'react'
import {ConfirmDialog, useConfirmDialog} from '../../hooks/useConfirmDialog'
import {Button} from '@startlibs/components'
import {jwtPostFetcher} from '../../utils/authFetch'
import {getJwt} from '../../hooks/useJwt'
import {CaseRequestCard} from '../CaseRequestCard'

export const useUnarchiveDialog = (caseRequest,onSuccess) => useConfirmDialog(
  <ConfirmDialog
    title="Confirm unarchiving case"
    confirm={<Button highlight>Yes, unarchive case</Button>}
    action={() => jwtPostFetcher(getJwt())("/api/admin/unarchiveCase?requestId="+(caseRequest.requestId || caseRequest.id))}
    notify="Case unarchived successfully."
    onSuccess={onSuccess}
  >
    <p>By unarchiving the following case it will become a "Case draft" in "Active cases":</p>
    <CaseRequestCard viewOnly caseRequest={caseRequest} />
    <p>You will no longer be able to access this case under "Archived cases", but it will be available in "Active cases" and "All cases".</p>
    <p>Are you sure you want to unarchive this case?</p>
  </ConfirmDialog>
)