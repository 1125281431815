import {useRef} from 'react'
import {useConstant, useLazyConstant, useToggle} from '@startlibs/core'
import _ from 'lodash/fp'
import {createNewRecord, isMultiFile, RecordFormat} from './utils'
import {callIfFunction} from '@startlibs/utils'
import {Failed, Queued, Uploaded} from "./enums/RecordStatus";
import {useUpdateSignal} from "../hooks/useSignalUpdate";
import {jwtPostFetcher} from "../utils/authFetch";
import {Quarantined, Uploading, Waiting} from "../enums/FileState";
import {Radiology} from "../enums/RecordClass";
import {NonCompliantDicom} from "../enums/RecordFormat";
import {updateActivityLogFile} from "../components/hooks/useActivityLog";
import { UploaderAction } from './UploaderAction'

const insertRecord = newRecord => records => {
  const cleanedRecord = _.flow(
    _.unset('files'),
    _.unset('date'),
    _.set('onlyDelete', true)
  )(newRecord)
  if (newRecord.recordFormat !== RecordFormat.DicomStudy || !newRecord.studyUID) {
    return records.concat(cleanedRecord)
  }
  const index = _.findIndex(['studyUID', newRecord.studyUID], records)
  if (index < 0) {
    return records.concat(cleanedRecord)
  }

  // update original key to sync with recordFiles
  return _.update([index], _.flow(
    _.set('onlyDelete', true),
    _.unset('uploadDate'),
    _.set('status',Queued),
    _.update('modalities', _.union(newRecord.modalities)),
    _.update('seriesUIDs', _.union(newRecord.seriesUIDs))
  ), records)
}

export const mergeQueueRecord = _.curry((r1,r2) => {
  if (!r1 && !r2) { return {} }
  if (!r2) { return r1 }
  if (!r1) { return r2 }
  const concat = (a=[],b=[]) => [...a,...b]
  return {
    failed: concat(r1.failed,r2.failed),
    uploaded: concat(r1.uploaded,r2.uploaded),
    uploading: concat(r1.uploading,r2.uploading),
    quarantined: concat(r1.quarantined,r2.quarantined),
    files: concat(r1.files,r2.files)
  }
})

const updateFormattedDate = record => {
  return _.set('uploadDate', new Date().getTime(), record)
}

const insertManyRecords = newRecords => records =>
  newRecords.reduce((updatedRecords, newRecord) => insertRecord(newRecord)(updatedRecords), records)

const enqueueBySize = (record, recordsFilesList) => {
  const size =
    _.sumBy(_.get(['file', 'size']), record.files) +
    _.sumBy(_.get(['file', 'size']), record.uploading) +
    _.sumBy(_.get(['file', 'size']), record.uploaded)

  const index = _.findIndex(
    recordFiles => recordFiles.size > size && !recordFiles.uploading,
    recordsFilesList
  )
  const recordFiles = { ...record, size }
  if (index >= 0) {
    return [...recordsFilesList.slice(0, index), recordFiles, ...recordsFilesList.slice(index)]
  }

  const lastNotUploadingIndex = _.findLastIndex(
    recordFilesItem => !recordFilesItem.uploading,
    recordsFilesList
  )
  if (lastNotUploadingIndex >= 0) {
    return [
      ...recordsFilesList.slice(0, lastNotUploadingIndex),
      recordFiles,
      ...recordsFilesList.slice(lastNotUploadingIndex),
    ]
  }
  return recordsFilesList.concat(recordFiles)
}

const reenqueue = (record, recordsFiles, index) => {
  const previous = recordsFiles[index]
  const updated = _.update('files', _.concat(record.files), previous)
  return enqueueBySize(updated, [...recordsFiles.slice(0, index), ...recordsFiles.slice(index + 1)])
}

const enqueueRecordFiles = record => recordsFiles => {
  if (record.recordFormat !== RecordFormat.DicomStudy || !record.studyUID) {
    return enqueueBySize(record, recordsFiles)
  }
  const index = recordsFiles.findIndex(_.matchesProperty('studyUID', record.studyUID))
  if (index < 0) {
    return enqueueBySize(record, recordsFiles)
  }
  return reenqueue(record, recordsFiles, index)
}

const enqueueManyRecordFiles = records => files =>
  records.reduce((updatedFiles, record) => enqueueRecordFiles(record)(updatedFiles), files)


const useRecordQueue = () => {
  const filesRef = useRef([])
  const [registerUpdateSignal,updateSignal] = useUpdateSignal()

  const setRecord = useConstant(
    _.curry((recordKey, updater) => {
      const index = _.findIndex(['key', recordKey], filesRef.current)
      if (index < 0) {
        return null
      }
      if (!updater) {
        filesRef.current = [
          ...filesRef.current.slice(0, index),
          ...filesRef.current.slice(index + 1),
        ]
        updateSignal()
        return null
      }

      filesRef.current = _.update(
        [index],
        value => callIfFunction(updater, value),
        filesRef.current
      )
      updateSignal()
      return filesRef.current[index]
    })
  )

  const getRecord = useConstant(recordKey => _.find(['key', recordKey], filesRef.current))

  const enqueueRecords = useConstant(records => {
    filesRef.current = enqueueManyRecordFiles(records)(filesRef.current)
    updateSignal()
  })

  const getNextInQueue = useConstant(smallest =>
    smallest
      ? _.find(_.get('files.length'), filesRef.current)
      : _.findLast(_.get('files.length'), filesRef.current)
  )
  const getQueue = useConstant(() => filesRef.current)

  return [getRecord, setRecord, getNextInQueue, enqueueRecords, getQueue, registerUpdateSignal]
}

const getMultiFileUID = async (record,appJwt) => {
  if (!isMultiFile(record) || record.params.multiFileUID) {
    return null
  }
  const response = await jwtPostFetcher(appJwt)('/uploader/init-multifile')
  return response.multiFileUID
}

export const useRecordStateManager = (initialRecords,appJwt, updateGroups, config, doAction) => {
  const records = useToggle(initialRecords)
  const keyMapper = useRef({})

  const [getQueuedRecord, setQueuedRecord, getNextInQueue, enqueueRecords, getQueue, registerQueueUpdateSignal] = useRecordQueue()

  const recentlyQuarantined = useRef({})

  const updateRecordByKey = useConstant(
    _.curry((key, update) =>
      records.openWith(
        _.flatMap(i => {
          if (i.key !== key) {
            return i
          }
          return callIfFunction(update, i) || []
        })
      )
    )
  )

  const completeRecord = useConstant((recordKey, recordUID, quarantined) => {
    const queuedRecord = getQueuedRecord(recordKey)
    if (!queuedRecord) {
      return recordUID
    }
    setQueuedRecord(recordKey, null)
    updateRecordByKey(recordKey, record =>
      _.flow(
        _.set('recordUID', recordUID),
        updateFormattedDate,
        _.update('queueHistory',mergeQueueRecord(queuedRecord)),
        _.set('uploadDate', new Date().getTime()),
        _.set('status', Uploaded),
        _.set('progress', 0),
        _.update('quarantined', q => q || quarantined)
      )(record)
    )
    return recordUID
  })

  const splitNonCompliantRecordFromDicomRecord = useConstant((queuedRecord, fileUploaded, recordUID, responseFromUploader) => {
    const instance = queuedRecord.uploading?.find(({file}) => file === fileUploaded.file)
    const updatedQueuedRecord = setQueuedRecord(
      queuedRecord.key,
      _.update(['uploading'], _.differenceBy('file',_, [fileUploaded]))
    )
    const totalFiles =
      (updatedQueuedRecord.files.length || 0) +
      (updatedQueuedRecord.uploading.length || 0) +
      (updatedQueuedRecord.failed?.length || 0)
    if (!totalFiles && queuedRecord.isNew) {
      config.setToBeUploaded(i => i - 1)
      setQueuedRecord(queuedRecord.key, null)
      updateRecordByKey(queuedRecord.key,null)
    }
    
    // Sometimes, for NonCompliant Dicom Files, the uploader returns more detailed error message in the response
    // This is used to display the error message in the UI and to store this error message in the metadata
    const hasErrorMessage = responseFromUploader.error && responseFromUploader?.error?.length > 0
    const metadata = hasErrorMessage 
    ? {'errorMessage': responseFromUploader.error} 
    : {}
    
    const newRecord = createNewRecord({
      description:instance.filename,
      fileExtension: instance.extension,
      files:[instance],
      recordClass: Radiology,
      recordFormat: NonCompliantDicom,
    },
    {
      key: recordUID,
      recordUID,
      status: Uploaded,
      metadata: metadata
    })
    records.openWith(_.concat(newRecord))
    if(hasErrorMessage){
      const metadata2 = {
        'errorMessage': responseFromUploader.error+' '
      }
      const setMetadata = (value) => doAction(UploaderAction.SetMetadata, newRecord, value)
      setMetadata(metadata2)
    }
    updateGroups()
  })

  const uploadSuccess = useConstant(async (recordKey, instanceUploaded, response) => {
    let recordUID = response.medicalRecordID
    keyMapper.current = _.set([recordKey],recordUID,keyMapper.current)
    const queuedRecord = getQueuedRecord(recordKey)
    if (!queuedRecord) {
      return recordUID
    }

    updateActivityLogFile(instanceUploaded.file,Uploaded,queuedRecord)

    if (queuedRecord.recordFormat === RecordFormat.DicomStudy && !response.dicomAttributes) {
      splitNonCompliantRecordFromDicomRecord(queuedRecord, instanceUploaded ,recordUID, response)
      return recordUID
    }
    const updatedQueuedRecord = setQueuedRecord(
      recordKey,
      _.flow(
        _.update(['uploading'], _.differenceBy('file',_, [instanceUploaded])),
        _.update(['uploaded'], (uploaded = []) => uploaded.concat(instanceUploaded)),
        recordUID ? _.identity : _.set(['recordUID'], recordUID)
      )
    )


    if (
      updatedQueuedRecord.files.length ||
      updatedQueuedRecord.uploading.length ||
      updatedQueuedRecord.failed?.length
    ) {
      if (response.quarantined) {
        recentlyQuarantined.current = _.set(
          recordKey,
          _.update('quarantined', (q = []) => q.concat(instanceUploaded), updatedQueuedRecord),
          recentlyQuarantined.current
        )
        updateRecordByKey(
          recordKey,
          _.flow(
            _.set('quarantined', response.quarantined),
            _.set('showDialog', true),
            _.set('dialogVariant', 'warning')
          )
        )
      }
      if (recordUID && !updatedQueuedRecord.recordUID) {
        updateRecordByKey(recordKey, _.set('recordUID', recordUID))
      }
      config.setToBeUploaded(i => i - 1)
      return recordUID
    }
    if (isMultiFile(updatedQueuedRecord) && updatedQueuedRecord.params.multiFileUID) {
      if (recentlyQuarantined.current[recordKey] || response.quarantined) {
        config.setToBeUploaded(i => i - 1)
        return completeRecord(recordKey, recordUID, true)
      }
      const data = await jwtPostFetcher(appJwt)('/uploader/end-multifile', queuedRecord.params)
      recordUID = data.medicalRecordID
    }
    if (queuedRecord.metadata) {
      await jwtPostFetcher(appJwt)(`${config.apiEndpoints.storageHost()}/record/${recordUID}/metadata`, queuedRecord.metadata, {method: 'PUT'})
    }
    config.setToBeUploaded(i => i - 1)
    return completeRecord(recordKey, recordUID, response.quarantined)
  })

  const incrementProgress = useConstant(
    _.curry((recordKey, delta, fileOrFiles) => {
      const queuedRecord = getQueuedRecord(recordKey)
      if (!queuedRecord) {
        return
      }
      const files = [].concat(fileOrFiles)
      const updatedRecord = setQueuedRecord(
        recordKey,
        _.flow(
          _.update(['uploadedBytes'], (uploadedBytes = 0) => uploadedBytes + delta),
          _.update('uploading',_.map(item => files.includes(item.file) ? _.update('uploadedBytes',(b = 0) => b+delta,item) : item))
        )
      )
      const progress = (updatedRecord.uploadedBytes * 100) / updatedRecord.size
      updateRecordByKey(recordKey, _.set('progress', Math.round(progress)))
    })
  )

  const uploadFailure = useConstant(async (recordKey, instance, failure, progressDelta) => {
    const queuedRecord = getQueuedRecord(recordKey)
    if (!queuedRecord) {
      return
    }
    updateActivityLogFile(instance.file,Queued,queuedRecord)
    setQueuedRecord(
      recordKey,
      _.flow(
        _.update(['uploading'], _.differenceBy('file',_, [instance])),
        _.update(['failed'], (uploaded = []) => uploaded.concat({ ...instance, failure }))
      )
    )
    incrementProgress(recordKey, progressDelta, instance)
    updateRecordByKey(
      recordKey,
      _.flow(
        updateFormattedDate,
        _.set('status', Failed),
        _.set('showDialog', true),
        _.set('actions', 'retry'),
        _.set('dialogVariant', 'warning'),
        _.set('failed', failure)
      )
    )
    updateActivityLogFile(instance.file,failure,queuedRecord)
  })

  const retryFilteringFile = useConstant(async (record,filter) => {
    const queuedRecord = getQueuedRecord(record.key)
    if (!queuedRecord) {
      return
    }
    const [filesToRetry,filesToKeepFailed] = _.partition(filter,queuedRecord.failed)
    setQueuedRecord(
      record.key,
      _.flow(
        _.update(['files'], _.concat(filesToRetry || [])),
        filesToKeepFailed.length ? _.set('failed',filesToKeepFailed) : _.unset(['failed'])
      )
    )
    filesToRetry.forEach(instance => updateActivityLogFile(instance.file,Queued,queuedRecord))
    incrementProgress(record.key, 0, filesToRetry)
    updateRecordByKey(
      record.key,
      _.flow(
        _.set('date', null),
        filesToKeepFailed.length ? _.identity : _.unset('failed'),
        _.set('showDialog', false),
        _.set('status',filesToKeepFailed.length ? Failed : Queued),
        _.set('actions', 'delete'),
        _.set('dialogVariant', null)
      )
    )
  })

  const queueToRetry = useConstant((record,thisFile) => {
    retryFilteringFile(record,({file}) => !thisFile || file === thisFile)
  })

  const retryByFailure = useConstant((failureType) => {
    getQueue().filter(({failed}) => _.find(({failure}) => !failureType || failure === failureType, failed))
      .forEach(record => retryFilteringFile(record,({failure}) => !failureType || failure === failureType))
  })

  const consumeFile = useConstant(async smallest => {
    const queuedRecord = getNextInQueue(smallest)
    if (!queuedRecord) {
      return []
    }
    const recordKey = queuedRecord.key
    const multiFileUID = await getMultiFileUID(queuedRecord,appJwt)

    const consumeFileInRecord = () => {
      const queuedRecordAfterAwait = getQueuedRecord(recordKey)
      const fileToUpload = _.get(['files', 0], queuedRecordAfterAwait)
      if (!fileToUpload) {
        return consumeFile()
      }
      const updatedRecord = setQueuedRecord(
        recordKey,
        _.flow(
          multiFileUID ? _.set(['params', 'multiFileUID'], multiFileUID) : _.identity,
          _.update(['files'], files => files.slice(1)),
          _.update(['uploading'], (uploading = []) => uploading.concat(fileToUpload))
        )
      )
      return [fileToUpload, recordKey, updatedRecord.params, updatedRecord]
    }

    const consumableInstance = consumeFileInRecord(recordKey, multiFileUID)
    if (consumableInstance) {
      const [instance,,,record] = consumableInstance
      updateActivityLogFile(instance.file,Uploading,record)
    }
    return consumableInstance
  })

  const manager = useLazyConstant(() => ({
    setIsUploading: config.setIsUploading,
    insertMany: newRecords => {
      enqueueRecords(newRecords)
      records.openWith(insertManyRecords(newRecords))
    },
    consumeFile,
    updateRecordByKey,
    updateRecord: (obj, update) => updateRecordByKey(obj.key, update),
    cancelRecord: record => {
      setQueuedRecord(record.key, _.flow(
        _.set('uploading', []),
        _.set('failed', []),
        _.set('files', [])
      ))
      const hasUploaded = getQueuedRecord(record.key)?.uploaded?.length
      updateRecordByKey(record.key, record =>
        (!record.isNew || record.queueHistory || hasUploaded) && _.update('status',(s) => s === Quarantined ? Quarantined : Uploaded, record)
      )
    },
    removeRecord: record => {
      setQueuedRecord(record.key, null)
      records.openWith(_.differenceBy('key', _, [record]))
    },
    removeFile: (record,file) => {
      setQueuedRecord(record.key, _.flow(
        _.update(['uploading'], _.differenceBy('file',_, [{file}])),
        _.update(['uploaded'], _.differenceBy('file', _,[{file}])),
        _.update(['failed'], _.differenceBy('file', _,[{file}]))
      ))
    },
    uploadSuccess,
    uploadFailure,
    incrementProgress,
    queueToRetry,
    retryByFailure,
    getPersistedKey: (key) => key && key.length && key.includes("temp-") ? keyMapper.current[key] : key,
    getRecentlyQuarantinedRecord: record => recentlyQuarantined.current[record.key],
    queueSignal: [registerQueueUpdateSignal,getQueue],
    getRecords: records.get,
    setRecords: records.openWith,
    addRecord: (record) => records.openWith(_.concat(_,record))
  }))

  return [records.isOpen, manager]
}
