import { Button, Icon } from '@startlibs/components';
import { createGlobalStyle } from 'styled-components';
import {formatDate, getFetcher} from '@startlibs/utils'
import React, { useEffect, useState } from 'react'
import {useParams} from 'react-router'
import { Card, PageContainer } from '../components/PageLayout';
import { Header } from '../components/Header';
import {Navbar} from '../components/Navbar'
import {PATIENT} from '../enums/ContactRelationType'
import {ProviderReleaseError} from './errors/ProviderReleaseError'
import { PurviewFooter } from '../components/PurviewFooter';
import {ReleaseTermsContent} from '../request/medicalRelease/ReleaseTerms'
import {SignagureImageContainer, SignatureDate} from '../request/Payment'
import {jwtGetFetcher} from '../utils/authFetch'
import {willUseSuspense} from '../hooks/useSuspense'
import { useIntl } from 'react-intl';
import { getRelationLabel } from '../enums/ContactRelationKind';
import { lazyProviderInfo } from '../components/WithProvider';
import * as ReactDOMServer from 'react-dom/server';

const PrintStyle = createGlobalStyle`
  @page {
    margin: 25mm 25mm 25mm 25mm;
    size: auto;
  }
  @media print {
    ${PageContainer} {
      padding: 0;
    }
    .nobreak {
      page-break-inside: avoid;
    }
    body {
      margin: 0px;
    }
    ${Card} {
      border: none;
      padding: 0;
    }
    ${Button} {
      display: none;
    }
  }
`

const useSuspense = willUseSuspense((code) =>
    getFetcher(`/api/locationForm/${code}`),
  true
)


export const MedicalReleaseDocument = () => {
  const {code} = useParams()
  return <ProviderReleaseError><LoadedMedicalReleaseDocument code={code} /></ProviderReleaseError>
}
export const LoadedMedicalReleaseDocument = ({code}) => {

  const intl = useIntl()

  const providerInfo = lazyProviderInfo.read()
  const releaseInfo = useSuspense(code)
  const contacRelationType = getRelationLabel(intl,releaseInfo.contactDetailsRelationType,'representative')
  const patientName = `${releaseInfo?.firstName} ${releaseInfo?.middleName} ${releaseInfo?.lastName}`
  const entityName = providerInfo.name


  const contactName = releaseInfo.contactDetailsRelationType === PATIENT
    ? (releaseInfo.firstName + ' ' + releaseInfo?.middleName + ' ' + releaseInfo.lastName)
    : releaseInfo.contactDetailsName

  const [htmlTerm, setHtmlTerm] = useState('')
  useEffect(() => {
    var vHtmlTerm = ReactDOMServer.renderToStaticMarkup(<ReleaseTermsContent
      contacRelationType={contacRelationType}
      patientName={patientName}
      entityName={entityName}
      contactName={contactName}
      dateExpiration={releaseInfo.medicalRecordLocationItem.dateExpiration}
      dateTo={releaseInfo.medicalRecordLocationItem.dateTo}
      dateFrom={releaseInfo.medicalRecordLocationItem.dateFrom}
      locations={[releaseInfo.medicalRecordLocationItem]}
    />)
    setHtmlTerm(vHtmlTerm)
  },[])

  return <>
    <PrintStyle />
    <Navbar/>
    <PageContainer>
      <Header
        title="Authorization for Use or Disclosure of Protected Health Information"
      >
        <Button onClick={() => window.print()} icon="print" className="nowrap" css="margin-left: 1rem;">Print</Button>
      </Header>
      <Card>
        {/* <p>We request that you <strong>provide any medical records in your possession from the period of {releaseInfo.medicalRecordLocationItem.dateFrom} to {releaseInfo.medicalRecordLocationItem.dateTo} regarding the Patient <span className='fs-exclude'>{patientName}</span></strong> as authorized by the signed agreement below.</p>
        <p>The following medical records release authorization was signed by <span className='fs-exclude'>{patientName}</span> or their legal guardian, representative or parent (as indicated) on {releaseInfo.medicalRecordsReleaseLicense.date}, and is valid through {releaseInfo.medicalRecordLocationItem.dateExpiration}.</p> */}
        <p>We request that you <strong>provide to Purview pursuant to this request and authorization any medical records in your possession from the period of {releaseInfo.medicalRecordLocationItem.dateFrom.replace('-','/').replace('-','/')} to {releaseInfo.medicalRecordLocationItem.dateTo.replace('-','/').replace('-','/')} regarding the Patient <span className='fs-exclude'>{patientName}</span></strong> as authorized by the signed agreement below, for the purposes of his or her continuing care and treatment.</p>
        <p>The following medical records release authorization was signed by <span className='fs-exclude'>{patientName}</span> or his/her legal guardian, representative or parent (as indicated) on {releaseInfo.medicalRecordsReleaseLicense.date}, and is valid through {releaseInfo.medicalRecordLocationItem.dateExpiration.replace('-','/').replace('-','/')}.</p>
        <hr></hr>
        <br></br>
        <ReleaseTermsContent
          contacRelationType={contacRelationType}
          patientName={patientName}
          entityName={entityName}
          contactName={contactName}
          dateExpiration={releaseInfo.medicalRecordLocationItem.dateExpiration}
          dateTo={releaseInfo.medicalRecordLocationItem.dateTo}
          dateFrom={releaseInfo.medicalRecordLocationItem.dateFrom}
          locations={[releaseInfo.medicalRecordLocationItem]}
          signedTerm={releaseInfo?.medicalRecordsReleaseLicense?.signedTerm}
        />
        {
          releaseInfo.medicalRecordsReleaseLicense.signature &&
          <SignagureImageContainer css="position: relative; margin-top: 1.5rem;">
            <img src={releaseInfo.medicalRecordsReleaseLicense.signature}/>
            <SignatureDate><Icon icon="check"/>Signed on <span>{releaseInfo.medicalRecordsReleaseLicense.date}</span></SignatureDate>
          </SignagureImageContainer>
        }
        
        {releaseInfo?.medicalRecordsReleaseLicense?.signedTerm && releaseInfo?.medicalRecordsReleaseLicense?.signedTerm?.length > 0 &&
          <div>
            {releaseInfo?.medicalRecordsReleaseLicense?.signedByName ? <p><b>Signed by: </b><b className='fs-exclude'>{releaseInfo?.medicalRecordsReleaseLicense?.signedByName}</b></p> : null}
            {releaseInfo?.medicalRecordsReleaseLicense?.signedByRelation ? <p><b>Relationship to Patient: {releaseInfo?.medicalRecordsReleaseLicense?.signedByRelation}</b></p> : null}
          </div>
        }
    </Card>
  </PageContainer>
  <PurviewFooter/>
</>
}
