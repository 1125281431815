import {Button, Icon, Link, Tooltip} from '@startlibs/components';
import { callIfFunction, getColor, preventDefault } from '@startlibs/utils';
import {usePopupToggle} from '@startlibs/core'
import React from 'react'
import styled, { css } from 'styled-components';
import {
  CASE_CLOSED,
  CASE_REVIEWED,
  UNDER_REVIEW,
  WAITING_ACCEPTANCE,
  WAITING_MORE_INFORMATION,
} from '../enums/CaseState';
import {RequestCard} from '../admin/CaseRequestCard'
import {autocompleteFetch, formatDateNoUTC} from '../utils/utils'
import { IfLocale } from '../hocs/IfLocale';

const ActionsWrapper = styled.div`
  position: absolute;
  top: .75rem;
  right: .75rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .priority-icon {
    cursor: pointer;
    ${props => props.viewOnly && css`
      cursor: default;
    `}
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    color: ${getColor('warning')};
    background-color: ${getColor('lightYellow')};
    border-radius: 6px;
    font-size: 20px;
    margin-right: .5rem;
    ${Icon} {
      margin-top: -2px;
    }
  }
`
const DoneLabel = styled.div`
  margin-left: auto;
  color: ${getColor('success')};
  background: ${getColor('lightGreen')};
  border-radius: 5px;
  padding: 2px 0.75rem 3px 0.5rem;
  display: inline-flex;
  align-items: center;
  position: absolute;
  right: 0.25rem;
  font-size:
`

export const ExpertCaseCard = ({caseRequest, isResults, showFooter, contextMenu, onClick, linkTo}) => {
  const contextMenuToggle = usePopupToggle()

  const getStatusLabel = (state, isCoReview, hasCoReviewFinish, is) => {
    if(isCoReview){
      // return <div className="status"><Icon icon="view"/> <span>Read only</span> {hasCoReviewFinish && <DoneLabel><Icon icon="check"/>Done</DoneLabel>}</div>
      return <div className="status">
          <Icon icon="view"/> <span>Read only</span> 
          {hasCoReviewFinish && (state == WAITING_ACCEPTANCE || state == UNDER_REVIEW || state == WAITING_MORE_INFORMATION) &&
            <DoneLabel>
              <Tooltip content="This case is still active as another Expert’s review is in progress.">
                <span css="display: flex; align-items: center;"><Icon icon="check"/>Done</span>
              </Tooltip>
            </DoneLabel>
          }
          {hasCoReviewFinish &&  (state !== WAITING_ACCEPTANCE && state !== UNDER_REVIEW && state !== WAITING_MORE_INFORMATION) && <DoneLabel><Icon icon="check"/> Done </DoneLabel>}
      </div>
    }
    switch (state) {
      case WAITING_ACCEPTANCE: return <div className="status"><Icon icon="failure"/> <span>Pending acceptance</span></div>
      case UNDER_REVIEW:
      case WAITING_MORE_INFORMATION: return <div className="status"><Icon icon="edit-box"/> <span>Under review</span></div>
      case CASE_REVIEWED: return <div className="status"><Icon icon="check"/> <span>Review submitted</span></div>
      case CASE_CLOSED: return <div className="status"><div className="finished-icon"><Icon icon="check"/></div> <span>Case finished</span></div>
    }
  }

  return <RequestCard
    as={linkTo ? Link : undefined}
    viewOnly={!contextMenu}
    isResults={isResults}
    onClick={onClick}
    to={linkTo}
    isPriority={caseRequest.markedAsPriorityEpochMilli}
    isExpert
  >
    {contextMenu && <ActionsWrapper>
      {
        caseRequest.markedAsPriorityEpochMilli &&
        <Tooltip content="This case was marked as priority.">
          <div className="priority-icon" onClick={e=> {e.preventDefault();e.stopPropagation()}}>
            <Icon icon="star"/>
          </div>
        </Tooltip>
      }
      <Button small onlyDropdown onClick={preventDefault(contextMenuToggle.toggle)}>
        {callIfFunction(contextMenu,contextMenuToggle.isOpen)}
      </Button>
    </ActionsWrapper>
    }
    {!contextMenu && caseRequest.markedAsPriorityEpochMilli &&
      <ActionsWrapper viewOnly>
        <Tooltip content="This case was marked as priority.">
          <div className="priority-icon" onClick={e=> {e.preventDefault();e.stopPropagation()}}>
            <Icon icon="star"/>
          </div>
        </Tooltip>
      </ActionsWrapper>
    }
    <div className="main-content">
      <div className="patient-name fs-exclude"><Icon icon="user-line"/> {
      (caseRequest.firstName || caseRequest.patientInfo.firstName)
        + ' ' + ((caseRequest.middleName ? caseRequest.middleName + ' ' : '') || (caseRequest.patientInfo?.middleName ? caseRequest.patientInfo?.middleName + ' ' : '')) 
        + (caseRequest.lastName || caseRequest.patientInfo?.lastName)
      }</div>
      <IfLocale contains="LEGAL">
        {caseRequest?.legalInfo?.caseNumber?.length > 0 
          ? <div className="code"><b>Case Number:</b> {caseRequest.legalInfo.caseNumber}</div>
          :  null
        }
      </IfLocale>
      <div className="last-update">Last updated on <span className="nowrap">{formatDateNoUTC(new Date(caseRequest.whenModifiedEpochMilli), "MM/dd/yyyy at hh:mm")}</span>
      </div>
    </div>
    {(showFooter || caseRequest.isCoReview) &&
    <div className={"card-footer" + (caseRequest.isCoReview ? " coreview" : "")}>
      {getStatusLabel(caseRequest.state, caseRequest.isCoReview, caseRequest.coReviewDateEpochMilli !== null)}
    </div>}
  </RequestCard>
}
