import React, {useRef, useState} from 'react'
import {Errors, FormValue, WithForm} from '@startlibs/form'
import {callIfFunction, formatDate, parseDate, smoothScroll} from '@startlibs/utils'
import {useEnsureRef, useToggle} from '@startlibs/core'
import _ from 'lodash/fp'
import {Button} from '@startlibs/components'
import {formatDateNoUTC, getDate} from '../utils/utils'
import {ReleaseSignoff} from './medicalRelease/ReleaseSignoff'
import {MEDICAL_RELEASE_PATH, ReleaseLocationForms} from './medicalRelease/ReleaseLocationForms'
import {buildValidation, ignore, required} from '../utils/validation'
import {requiredTerms} from './components/AcceptTermsCheckbox'
import {locationIsNotEmpty} from './forms/MedicalRecordLocationForm'
import { getContact, getRelationLabel } from '../enums/ContactRelationKind'
import { useIntl } from 'react-intl'

export const EMPTY_MEDICAL_RECORDS_LOCATION_FORM = []

const EMPTY_FORM = {[MEDICAL_RELEASE_PATH]: EMPTY_MEDICAL_RECORDS_LOCATION_FORM, expirationDate: getDate(90)}

const sanitizeLocation = (dateFormat) => _.flow(
  _.filter(({id, ...value}) => Object.values(value).filter(_.identity).length > 0),
  _.map(_.update('date', dateFormat)),
)

const parse = _.update(MEDICAL_RELEASE_PATH, sanitizeLocation((str) => _.isString(str) ? parseDate(str, null, "yyyy-MM-dd") : str === null ? undefined : str))
const A_DAY = 24*60*60*1000

export const MedicalReleaseForm = ({readOnly, formRef, action, onSuccess, onFailure, children, caseRequest, withReleaseSignoff, values = EMPTY_FORM, step, mode, toggle}) => {

  const intl = useIntl()
  const ensuredFormRef = useEnsureRef(formRef)
  const signaturePad = useToggle({})
  const locationRef = useRef()
  const contact = getContact(intl, caseRequest)
  const contactRelationType = getRelationLabel(intl,caseRequest.contactDetails.contactRelationType,'representative')


  const [htmlTerm, setHtmlTerm] = useState('')
  const transform = ({dateTo, dateFrom, dateExpiration, ...values}) => {
    var payload = _.flow(
      _.update(MEDICAL_RELEASE_PATH, _.filter(locationIsNotEmpty)),
      _.update(MEDICAL_RELEASE_PATH, sanitizeLocation((d) => d ? formatDate(d, 'yyyy-MM-dd') : d)),
      _.update(MEDICAL_RELEASE_PATH, _.map(_.flow(
        _.set('dateTo', formatDate(dateTo, 'MM-dd-yyyy')),
        _.set('dateFrom', formatDate(dateFrom, 'MM-dd-yyyy')),
        _.set('dateExpiration', formatDate(dateExpiration, "MM-dd-yyyy")),
        (item) => ({item, id: item.serverId})
      ))),
      withReleaseSignoff
        ? _.set('medicalRecordsReleaseLicense', {
          signature: signaturePad.get().toDataURL("image/"),
          date: formatDateNoUTC(new Date(), 'MM/dd/yyyy at hh:mm'),
          signedTerm: htmlTerm,
          signedByName: contact.name,
          signedByRelation: contactRelationType
        })
        : _.identity
    )(values)
    return payload
  }

  const preValidation = buildValidation({
    "": (withReleaseSignoff && (() => signaturePad.get().isEmpty() && "You must agree to the terms by providing a signature above to continue.")),
    // acceptedTerms: withReleaseSignoff && requiredTerms(caseRequest),
    'dateTo':withReleaseSignoff && required,
    'dateFrom':withReleaseSignoff && required,
    'dateExpiration':[withReleaseSignoff && required,(v) => (v+A_DAY) < Date.now() && "Expiration date can't be in the past. Please enter a valid expiration date"]
  },({records}) => {
    const withoutName = records.filter(locationIsNotEmpty).filter(({name})=> !name)
    return _.fromPairs(withoutName.map(record => [`records[${records.indexOf(record)}].name`,required()]))
  })

  const focusLocations = () => {
    smoothScroll({target: locationRef.current, offset: -80})
  }


  return <WithForm
    alwaysSave
    ref={ensuredFormRef}
    transform={transform}
    action={action}
    preValidation={preValidation}
    onSuccess={onSuccess}
    onFailure={onFailure}
    values={parse(values)}
  >{form => <>
    <ReleaseLocationForms caseRequest={caseRequest} locationRef={locationRef} readOnly={readOnly} form={form} step={step} mode={mode} toggle={toggle}/>
    <FormValue path={MEDICAL_RELEASE_PATH}>{records =>
      withReleaseSignoff && !!records.find(locationIsNotEmpty) &&
      <ReleaseSignoff focusLocations={focusLocations} signaturePad={signaturePad} caseRequest={caseRequest} mode={mode} htmlTerm={htmlTerm} setHtmlTerm={setHtmlTerm}/>
    }</FormValue>
    <Errors/>
    <FormValue path={MEDICAL_RELEASE_PATH}>{records =>
      callIfFunction(children, form, <Button onClick={() => signaturePad.get().clear()}>Clear signature</Button>, !records.find(locationIsNotEmpty))
    }</FormValue>
  </>}</WithForm>
}
