import { Button, Icon } from '@startlibs/components';
import { getColor } from '@startlibs/utils';
import React from 'react'
import _ from 'lodash/fp'
import styled, { css } from 'styled-components';
import {CASE_DRAFT, PENDING_REQUEST, WAITING_MEDICAL_RECORDS} from '../../enums/CaseState';
import { Card, PageContainer, PageFooter } from '../../components/PageLayout';
import {Header} from '../../components/Header'
import {InfoBox} from '../../components/InfoBox';
import {PurviewFooter} from '../../components/PurviewFooter'
import {isDigitalPaymentPending} from '../../request/utils'
import {useConfirmSubmitUpdate} from '../utils/useConfirmSubmitUpdate'

const RequestCodeBox = styled.div`
  background: ${getColor('gray240')};
  border: 1px solid ${getColor('gray210')};
  border-radius: 6px;
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  margin-bottom: 0.75rem;
  font-size: 16px;
  min-height: 4rem;
  width: 100%;
  font-size: 16px;
  max-width: 33rem;
  justify-content: space-between;
  flex-wrap: wrap;
  > div {
    margin: 0.5rem 1rem 0.5rem 0;
  }
  ${Button} {
    margin: 0.5rem 0;
    justify-self: flex-end;
    flex-shrink: 0;
  }
`

const RequestSubmittedPage = styled(PageContainer)`
  @media print {
    ${Button} {
      display: none;
    }
    ${RequestCodeBox} {
      max-width: auto;
      display: inline-block;
      width: auto;
    }
  }
`

const UpdatesRequestedCard = styled(Card)`
  .buttons-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.25rem;
    ${Button} {
      margin: 0.25rem;
      flex-basis: 32%;
      justify-content: left;
      min-width: 190px;
    }
  }
`

const UpdateButton = styled(Button.Link)`
  ${props => props.checked && props.outline && css`
    color: ${getColor('gray150')};
    background: rgba(0,0,0,0.025);
    ${Icon} {
      color: ${getColor('gray150')};
    }
    :before {
      border: 1px solid rgba(0,0,0,0.15);
    }
  `}
`

export const PatientUpdatesRequested = ({caseRequest,setCaseRequest}) => {

  const submit = useConfirmSubmitUpdate(
    caseRequest,
    setCaseRequest,
    () => {
      setCaseRequest(_.flow(
        _.set('state', CASE_DRAFT),
        _.set('recentlySubmitted', true),
        _.set('caseFlags.requestFlags',{}),
        _.set('caseFlags.patientDoneFlags',{})
      ))
    }
  )

  const {
    askedPatientDetails,
    askedMedicalRelease,
    askedRecords,
    askForPayments,
    askForServiceTerms
  } = caseRequest.caseFlags.requestFlags

  const donePayments = !caseRequest.payments.find(isDigitalPaymentPending)
  const doneTerms = caseRequest.acceptanceInfo.acceptedTerms
  const {
    doneRecords,
    donePatientDetails,
    doneMedicalReleases
  } = caseRequest.caseFlags.patientDoneFlags || {}

  const allCompleted =
    (!askedMedicalRelease || doneMedicalReleases) &&
    (!askedPatientDetails || donePatientDetails) &&
    (!askedRecords || doneRecords) &&
    (!askForServiceTerms || doneTerms) &&
    (!askForPayments || donePayments)

  const askedOnlyPayment = (!askedRecords && !askedMedicalRelease && !askedPatientDetails && !askForServiceTerms) && askForPayments

  const isPending = caseRequest.state === PENDING_REQUEST
  const isWaitingRecords = caseRequest.state === WAITING_MEDICAL_RECORDS

  return <>
    <RequestSubmittedPage>
      <Header
        title="Status"
      />
      <UpdatesRequestedCard>
          <h3>
            Please complete the following actions
          </h3>
          <p>{
            askedOnlyPayment
              ? "Please provide the requested payment. The institution will be automatically notified once it is done."
              : "Once you are done, click 'Submit' to notify the institution."
          }</p>
          {
            caseRequest.customMessages?.caseAcceptance &&
            <InfoBox css="margin-bottom:1.5rem;">{caseRequest.customMessages?.caseAcceptance}</InfoBox>
          }
          <div className="buttons-wrapper">
            {
              askForServiceTerms &&
              <UpdateButton to="/patient/agreements"
                            outline={doneTerms}
                            checked={doneTerms}
                            icon={doneTerms ? "check" : "sign-doc"}>
                Agree to terms
              </UpdateButton>
            }
            {
              askedPatientDetails && (isWaitingRecords || isPending) &&
              <UpdateButton to="/patient/details"
                outline={donePatientDetails}
                checked={donePatientDetails}
                icon={donePatientDetails ? "check" : "user-line"}>
                Edit contact details
              </UpdateButton>
            }
            {
              askedMedicalRelease &&
              <UpdateButton to="/patient/release"
                outline={doneMedicalReleases}
                checked={doneMedicalReleases}
                icon={doneMedicalReleases ? "check" : "edit-box"}>
                Locate medical records
              </UpdateButton>
            }
            {
              askedRecords && (isWaitingRecords || isPending) &&
              <UpdateButton to="/patient/records"
                outline={doneRecords}
                checked={doneRecords}
                icon={doneRecords ? "check" : "dicom-no-text"}>
                Medical records
              </UpdateButton>
            }
            {
              askForPayments &&
              <UpdateButton highlight={askedOnlyPayment}
                to="/patient/payments"
                outline={donePayments}
                checked={donePayments}
                icon={donePayments ? "check" : "payment"}>
                Provide payment
              </UpdateButton>
            }
          </div>
      </UpdatesRequestedCard>
      <PageFooter>
        <Button.Link to="/patient/overview">Go to overview</Button.Link>
        {
          !askedOnlyPayment &&
          <Button disabled={!allCompleted} onClick={submit} highlight>Submit</Button>
        }
      </PageFooter>
    </RequestSubmittedPage>
    <PurviewFooter/>
  </>
}
