import {Button, SplitColumnsContainer} from '@startlibs/components'
import {WithForm, FormValue, TextInput, DatePicker, CheckboxGroup, SimpleCheckbox, Errors} from '@startlibs/form'
import { callIfFunction, formatDate, media } from '@startlibs/utils';
import {capitalize} from 'lodash'
import {useToggle} from '@startlibs/core'
import React, {useState} from 'react'
import {FormattedMessage} from 'react-intl'
import _ from 'lodash/fp'
import styled from 'styled-components';
import { AddButton } from '../../../components/AddButton';
import { Card, SectionHeading } from '../../../components/PageLayout';
import {CaseRequestCard} from '../../CaseRequestCard'
import {ConfirmDialog, useConfirmDialog} from '../../../hooks/useConfirmDialog'
import { FormBox, RemoveFormBox } from '../../../components/FormBox';
import {PATIENT, CAREGIVER, OTHER, REF_PHYSICIAN} from '../../../enums/ContactRelationType'
import {buildValidation, required} from '../../../utils/validation'
import {getContact, getRelationLabel, PRIMARY} from '../../../enums/ContactRelationKind'
import {getJwt} from '../../../hooks/useJwt'
import {jwtFormFetcher, jwtGetFetcher, jwtPostFetcher} from '../../../utils/authFetch'
import {willUseSuspense} from '../../../hooks/useSuspense'
import {OTHER_RELATION, LEGALGUARDIAN, EXECUTOR, PARENT} from '../../../enums/RemoteConsultationParticipantType'
import {TimeInput} from '../../../components/TimeInput'
import {addZeroPrefix} from '../../../utils/utils'
import {useIntl} from 'react-intl'

const ConsultationBox = styled(FormBox)`
  font-size: 13px;
  padding-bottom: 2rem;
  .heading-wrapper {
    width: 100%;
    border-bottom: 1px solid rgba(0,0,0,0.15);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
    .action-buttons {
      margin-left: 1rem;
      margin-bottom: .75rem;
      flex-grow: 1;
      text-align: right;
      ${Button} ~ ${Button} {
        margin-left: .5rem;
      }
    }
  }
  .participants {
    flex-basis: 35%;
    min-width: 150px;
    margin-right: 1rem;
    strong {
      font-size: 14px;
    }
    ul {
      margin-top: .25rem;
      list-style-type: disc;
      padding-left: 1rem;
      margin-left: .5rem;
    }
  }
  .notes {
    flex-basis: 55%;
    min-width: 200px;
    flex-grow: 1;
    strong {
      font-size: 14px;
    }
    ${media.max(820)`
      margin-top: .5rem;
    `}
  }
`

const useConsultationLog = willUseSuspense((requestId) =>
    jwtGetFetcher(getJwt())(`/api/consultationLog/${requestId}`)
  // Promise.resolve([])
)

const getParticipantKey = ({name, type, email}) => name + type + email

const getDateTime = (date, time) => {
  const dateTime = new Date(date)
  const [hour, minutes = 0] = time.split(":")
  dateTime.setUTCHours(hour, minutes)
  return dateTime.getTime()
}
const formatValues = (values) => {
  const date = new Date(values.dateTimeEpochMilli)
  const time = values.dateTimeEpochMilli ? date.getUTCHours() + ':' + date.getMinutes() : ''
  return {...values, date: values.dateTimeEpochMilli, time}
}

const preValidation = buildValidation({
  participants: (v) => !v.length && required(),
  date: (v) => !v && required(),
  time: v => {
    if (!v) {
      return required()
    }
    const [hour, minutes = 0] = v.split(":")
    if (parseInt(hour, 10) >= 0 && parseInt(hour, 10) <= 24 && parseInt(minutes, 10) >= 0 && parseInt(minutes, 10) <= 59) {
      return
    }
    return "Invalid time"
  },
})

export const RemoteConsultationLog = ({caseRequest, experts, canDelete, isExpert}) => {

  const referringPhysicianAsSecondary = caseRequest.contactDetails.contactRelationType !== REF_PHYSICIAN && caseRequest.referringPhysician.email
  const intl = useIntl()
  const primary = getContact(intl, caseRequest, PRIMARY)
  const physician = getContact(intl, caseRequest, REF_PHYSICIAN)
  const isOtherPrimaryRelation = caseRequest.contactDetails.contactRelationType === OTHER || caseRequest.contactDetails.contactRelationType === OTHER_RELATION
  const participants = React.useMemo(() => [
    {
      name: primary.name,
      email: primary.email,
      typeLabel: isOtherPrimaryRelation ? TYPE_LABELS[OTHER_RELATION] : primary.relationType ,
      type: isOtherPrimaryRelation ? OTHER_RELATION : caseRequest.contactDetails.contactRelationType
    },
    referringPhysicianAsSecondary && {
      name: physician.name,
      email: physician.email,
      typeLabel: physician.relationType,
      type: REF_PHYSICIAN
    },
    ...experts.map(({expert}) => ({
      name: ((expert.firstName || "") + " " + (expert.lastName ||"")),
      type: "EXPERT",
      typeLabel: "Expert",
      email: expert.email
    }))
  ].filter(_.identity),[])

  const [consultations, setConsultations] = useState(useConsultationLog(caseRequest.requestId))

  const addItem = () => setConsultations(_.concat(_, {
    id: Date.now() + "",
    participants: isExpert ? participants.slice(-1) : []
  }))

  return <div css="margin-top: 2rem;">
    <SectionHeading>
      <h3>Consultation log</h3>
    </SectionHeading>
    <Card>
      {
        consultations.map(consultation =>
          <ConsultationLogItem
            key={consultation.id}
            consultation={consultation}
            setConsultation={(nextConsultation) => setConsultations(_.map(v => v === consultation ? callIfFunction(nextConsultation, v) : v))}
            removeConsultation={() => setConsultations(_.without([consultation]))}
            experts={experts}
            caseRequest={caseRequest}
            assignableParticipants={participants}
            canDelete={canDelete(consultation)}
            isExpert={isExpert}
          />)
      }
      <AddButton onClick={addItem}>Register a finished consultation</AddButton>
    </Card>
  </div>

}

const ConsultationLogItem = ({consultation, setConsultation, removeConsultation, assignableParticipants, caseRequest, experts,isExpert, canDelete}) => {

  const transient = _.isString(consultation.id)
  const isEditing = useToggle(transient)
  const intl = useIntl()

  const cancel = () => transient ? removeConsultation() : isEditing.close()

  if (isEditing.isOpen) {
    return <WithForm
      alwaysSave
      values={formatValues(consultation)}
      preValidation={preValidation}
      transform={(values) => _.set('dateTimeEpochMilli', getDateTime(values.date, values.time), transient ? _.unset('id', values) : values)}
      action={transient
        ? jwtFormFetcher(getJwt())(`/api/${isExpert ? "expert" : "admin"}/consultationLog/${caseRequest.requestId}`)
        : jwtFormFetcher(getJwt())(`/api/consultationLog`, {method: "PUT"})
      }
      onSuccess={(values, {id}) => {
        isEditing.close()
        setConsultation(_.flow(
          transient ? _.set('id', id) : _.identity,
          transient ? _.set('owner', true) : _.identity
        )(values))
      }}
    >{form => <ConsultationBox>
      <RemoveFormBox onClick={() => form.confirm('discard-remote-log').then(cancel)}></RemoveFormBox>

      <SplitColumnsContainer>
        <DatePicker
          path="date"
          label="Date"
          format="MM/dd/yyyy"
          placeholder="Ex: MM/DD/YYYY"
          mandatory
        />
        <TimeInput
          label="Time"
          path="time"
          mandatory
        />
      </SplitColumnsContainer>

      <TextInput
        label="Notes"
        path="notes"
        textarea
        placeholder={intl.formatMessage({defaultMessage:"Ex: Patient had concerns regarding medical conditions.",description:"RemoteConsultationLog notes placeholder"})}
      />
      {
        transient
          ? <FormValue path="participants">{checkedParticipants => <>
            <CheckboxGroup horizontal label="Participants" mandatory>
              {assignableParticipants.map(participant =>
                <SimpleCheckbox
                  raw
                  key={participant.email + "" + participant.type}
                  value={checkedParticipants.indexOf(participant) >= 0}
                  setValue={() => form.setValues(_.set(
                    'participants',
                    checkedParticipants.indexOf(participant) >= 0
                      ? _.without([participant], checkedParticipants)
                      : [participant, ...checkedParticipants]
                  ))}
                  // label={`${participant.name} (${capitalize(participant.typeLabel)})`}
                  // label={`${participant.name} (${TYPE_LABELS[participant.type] || capitalize(participant.typeLabel)})`}
                  label={`${participant.name} (${getRelationLabel(intl,participant.type,TYPE_LABELS[participant.type]) || capitalize(participant.typeLabel)})`}
                />
              )}
              <SimpleCheckbox
                raw
                label="Other"
                value={checkedParticipants.find(({type}) => type === "OTHER")}
                setValue={() => form.setValues(_.set(
                  'participants',
                  checkedParticipants.find(({type}) => type === "OTHER")
                    ? _.without([checkedParticipants.find(({type}) => type === "OTHER")], checkedParticipants)
                    : [...checkedParticipants, {type: "OTHER", name: ""}]
                ))}
              />
            </CheckboxGroup>
            <OtherParticipant form={form} otherParticipant={checkedParticipants.find(({type}) => type === "OTHER")}/>
          </>}</FormValue>
          : <div className="participants">
            <strong>Participants</strong>
            <ul>{consultation.participants.map(participant =>
              <ParticipantListItem key={getParticipantKey(participant)} participant={participant} />
            )}</ul>
          </div>
      }
      <Errors/>
      <div css="text-align: right; margin-top: .5rem;">
        <Button onClick={() => form.confirm('discard-remote-log').then(cancel)}>Cancel</Button>
        <Button type='submit' highlight isLoading={form.isLoading}>Save to log</Button>
      </div>
    </ConsultationBox>}</WithForm>
  }

  return <ViewLogItem
    caseRequest={caseRequest}
    isEditing={isEditing}
    consultation={consultation}
    canDelete={canDelete}
    removeConsultation={removeConsultation}
  />
}

const zeroToTwelve = (v) => parseInt(v) === 0 ? 12 : v

const ViewLogItem = ({consultation, isEditing, caseRequest, removeConsultation, canDelete}) => {

  const confirmDelete = useConfirmDialog(<ConfirmDialog
    title="Remove consultation from log"
    confirm={<Button alert>Yes, remove consultation</Button>}
    action={() => jwtPostFetcher(getJwt())(`/api/consultationLog`, {id: consultation.id}, {method: 'DELETE'})}
    onSuccess={removeConsultation}
  >
    <p>This consultation will be removed from the log of the following case.</p>
    <CaseRequestCard caseRequest={caseRequest}/>
    <p>Are you sure you want to proceed? This action can not be undone.</p>

  </ConfirmDialog>)

  const [hours,minutes] = formatDate(new Date(consultation.dateTimeEpochMilli),"hh:mm").split(":")
  const ampm = parseInt(hours)>11 ? "PM" : "AM"

  return <ConsultationBox>
    <div className="heading-wrapper">
      <h4>Consultation from {formatDate(new Date(consultation.dateTimeEpochMilli), "MM/dd/yyyy")} at {zeroToTwelve(addZeroPrefix(parseInt(hours)%12))}:{addZeroPrefix(minutes)} {ampm}</h4>
      <div className="action-buttons">
        <Button small onClick={isEditing.open}>Edit</Button>
        {canDelete && <Button small onClick={confirmDelete}>Delete</Button>}
      </div>
    </div>
    <SplitColumnsContainer viewportMinWidth={820}>
      <div className="participants">
        <strong>Participants</strong>
        <ul>{consultation.participants.map(participant =>
          <ParticipantListItem key={getParticipantKey(participant)} participant={participant} />
        )}</ul>
      </div>
      {consultation.notes &&
        <div className="notes">
          <strong>Notes</strong>
          <p>{consultation.notes}</p>
        </div>
      }
    </SplitColumnsContainer>
  </ConsultationBox>
}

const TYPE_LABELS = {
  [OTHER_RELATION]: "Case contact",
  [REF_PHYSICIAN]: "Referring physician",
  [PATIENT]: 'Patient',
  [CAREGIVER]: 'Caregiver',
  [OTHER]: 'Case contact',
  [LEGALGUARDIAN]: 'Legal Guardian',
  [EXECUTOR]: 'Executor',
  [PARENT]: 'Parent of Minor'
}

const ParticipantListItem = ({participant:{type,name}}) => {
  const intl = useIntl()
  
  return type === "OTHER"
    ? <li>Other(s): {name}</li>
    // : <li>{name} ({TYPE_LABELS[type] || capitalize(type)})</li>
    : <li className='fs-exclude'>{name} ({getRelationLabel(intl, type, TYPE_LABELS[type]) || capitalize(type)})</li>
}
  


const OtherParticipant = ({otherParticipant, form}) => otherParticipant
  ? <TextInput
    raw
    value={otherParticipant.name}
    label="Other participant(s)"
    placeholder="Ex: John Doe (Caregiver), James Smith (Father)"
    setValue={(value) => form.setValues(_.update('participants', _.map(participant => participant === otherParticipant ? {
      ...otherParticipant,
      name: value
    } : participant)))}
  />
  : null
