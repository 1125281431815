import { Button, Icon, Loading, Tooltip } from '@startlibs/components';
import {getColor, callIfFunction, isMobile, postFetcher, getFetcher, addQueryString} from '@startlibs/utils';
import {usePopupToggle, useToggle} from '@startlibs/core';
import React, { useState } from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components';

import {DATE_FORMAT} from './config/DateFormat'
import {StudyCell} from './StudyCell'
import {ToolsCell} from './ToolsCell'
import {WorklistTableColumn} from './Table';
import {WorklistTableRow} from './Table';
import {dicomDateToDate, formatNoUTCDate} from '../../utils'
import {strToDate} from '../../utils/utils'
import { jwtGetFetcher, jwtPostFetcher } from '../../utils/authFetch';
import { isDev } from '../../utils/isDev';

const ImportButton = styled.div`
  display: inline-flex;
  align-items: center;
  margin-top: ${props => props.selected ? '1px' : '0.25rem'};
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  color: ${props => props.selected ? getColor('success') : getColor('secondary')};
  ${Icon} {
    margin-right: 0.25rem;
    font-size: 26px;
  }
  a {
    text-decoration: underline;
    color: ${getColor('gray90')};
    font-weight: 400;
    margin-top: -3px;
    display: block;
  }
`

const LoadingAction = styled.div`
  color: rgba(0,0,0,0.3);
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  margin-top: 7px;
  ${Loading} {
    margin-right: 0.5rem;
  }  
`

export const StudyRow = ({ setParams, study, refreshList, setStudy, userRights, linkedStudies, setLinkedStudies, isSelected, records, linkRecord, providerJwt, jwt, setNotification, setLoadingAction, isLoadingAction, viewerLoadUrl, worklistViewerJwt, storageHost}) => {
  
  // const worklistUrl = window.WORKLIST_URL || "https://purviewimage.purview.net/worklist/api";
  const worklistUrl = isDev ? window.WORKLIST_URL || "" : window.WORKLIST_URL || "https://purviewimage.purview.net/worklist/api";
  const uploadDate = strToDate(study.createdTime)
  const formattedUploadDate = isNaN(uploadDate.getTime()) ? "" : formatNoUTCDate(uploadDate, DATE_FORMAT())
  const selected = linkedStudies.findIndex(uid => uid == study.studyIuid) >= 0;

  const [isLoadingInfo,setLoadingInfo] = useState(false);

  function handleClick(e, studyId, pk){
    if(!isLoadingAction){
      // if contains the id --> removes it
      if(linkedStudies.findIndex(id => id == studyId) >= 0){
        setLoadingInfo(true);
        setLoadingAction(true);
        let filtered = linkedStudies.filter(item => item != studyId)
        if(_.first(records.filter((record) => record.studyUID == studyId))){
          setLinkedStudies(filtered,"Remove",_.first(records.filter((record) => record.studyUID == studyId)))
            .then((response)=> {
              setLoadingInfo(false)
              setLoadingAction(false)
            })
            .catch((error)=> {
              setNotification({type:"alert", timeout: 4000,msg:(close) => <span>The study could not be removed from case. Please try again.</span>})
              setLoadingInfo(false)
              setLoadingAction(false)
            })
        }
        
      }else{
        // do not contains --> add it into the array
        if (pk > 0){
          setLoadingAction(true);
          setLoadingInfo(true);
          return jwtGetFetcher(providerJwt())(addQueryString(worklistUrl+'/studies/' + pk))
            .then((response) => {

              // Population variables of info class with response from study worklist
              var patientID = response.patient?.patId || null
              var patientName = response.patient?.patName || null
              var patientDoB = response.patient?.patBirthdate || null
              var patientSex = response.patient.patSex || null
              var referringPhysician = response.refPhysician || null
              var accessionNumber = null
              var studyUID = response.studyIuid
              var studyDate = response.studyDateTime
              var studyDescription = response.studyDesc
              var modalities = [response.modsInStudy]
              var seriesUIDList = []
              
              response.seriesStudy.map((serie) => {
                seriesUIDList.push(serie.seriesIuid)
              })

              let data = {
                resourceUID: studyUID,
                originalFileName: studyUID,
                fileType:"",
                info:{
                    patientID: patientID,
                    patientName: patientName ? patientName.replace(/\^/g, ' ') : patientName,
                    patientDoB: patientDoB,
                    patientSex: patientSex,
                    referringPhysician: referringPhysician ? referringPhysician.replace(/\^/g, ' ') : referringPhysician,
                    studyUID: studyUID,
                    studyDate: studyDate,
                    studyDescription: studyDescription,
                    seriesUIDs: seriesUIDList,
                    modalities: modalities,
                    type:"DicomStudy",
                }
              }

              jwtPostFetcher(jwt)(storageHost()+"/medicalRecord/dicomStudy", data)
                .then((postResponse) => {
                  
                  let added = linkedStudies.concat(studyId)
                  // add this fields
                  let newStudyToAdd = {
                    caseUID: "",
                    fileExtension: null,
                    fileType: "",
                    id: postResponse.id,
                    info: {
                      type: postResponse.info.type,
                      patientID: postResponse.info.patientID,
                      patientName: postResponse.info.patientName,
                      patientDoB: postResponse.info.patientDoB, 
                      patientSex: postResponse.info.patientSex,
                      referringPhysician: postResponse.info.referringPhysician,
                      accessionNumber: postResponse.info.accessionNumber,
                      studyUID: postResponse.info.studyUID,
                      studyDate: postResponse.info.studyDate, //or null
                      studyDescription: postResponse.info.studyDescription,
                      seriesUIDs: postResponse.info.seriesUIDs,
                      modalities: postResponse.info.modalities,
                      numInstances: postResponse.info.numInstances
                    },
                    accessionNumber: postResponse.info.accessionNumber,
                    modalities: postResponse.info.modalities,
                    patientDoB: postResponse.info.patientDoB, 
                    patientID: postResponse.info.patientID,
                    patientName: postResponse.info.patientName,
                    patientSex: postResponse.info.patientSex,
                    referringPhysician: postResponse.info.referringPhysician,
                    seriesUIDs: postResponse.info.seriesUIDs,
                    studyDate: postResponse.info.studyDate, //or null
                    studyDescription: postResponse.info.studyDescription,
                    studyUID: postResponse.info.studyUID,
                    type: postResponse.info.type,
                    instances: [],
                    metadata: {},
                    notes: {note: ""},
                    originalFileName: postResponse.originalFileName,
                    patientUID: postResponse.patientUID,
                    quarantined: postResponse.quarantined,
                    recordFormat: "DicomStudy",
                    recordClass: postResponse.recordClass,
                    recordContext: postResponse.recordContext,
                    recordUID: postResponse.recordUID,
                    resourceUID: postResponse.resourceUID,
                    state: postResponse.state,
                    stateContext: postResponse.stateContext,
                    uploadSessionID: postResponse.uploadSessionID,
                    vna: postResponse.vna,
                    whenCreatedEpochMilli: postResponse.whenCreatedEpochMilli,
                    whenModifiedEpochMilli: postResponse.whenModifiedEpochMilli
                  }
                  setLinkedStudies(added,"Add",newStudyToAdd);
                  linkRecord(newStudyToAdd);
                  setLoadingInfo(false);
                  setLoadingAction(false);
                })
                .catch((error) =>{
                  setLoadingInfo(false);
                  setLoadingAction(false);
                  setNotification({type:"alert", timeout: 4000,msg:(close) => <span>The study could not be added to the case. Please try again.</span>})
                })
            })
            .catch((error) =>{
              setLoadingInfo(false);
              setLoadingAction(false);
              setNotification({type:"alert", timeout: 4000,msg:(close) => <span>The study could not be added to the case. Please try again.</span>})
            })
        }
      }
    }
  }

  return <WorklistTableRow isMobile={isMobile()} isSelected={selected}>
     <WorklistTableColumn className="patient fs-exclude">
      <PatientCell patient={study.patient}/>
    </WorklistTableColumn>
    <WorklistTableColumn className="study fs-exclude"><StudyCell
      refreshList={refreshList}
      setParams={setParams}
      modsInStudy={study.modsInStudy}
      numInstances={study.numInstances}
      studyDesc={study.studyDesc}
      tagsStudy={study.tagsStudy}
    /></WorklistTableColumn> 
    <WorklistTableColumn className="physician fs-exclude">{study.refPhysician ? study.refPhysician.replace(/\^/g, ' ') : ""}</WorklistTableColumn>
    <WorklistTableColumn className="institution fs-exclude">{study.seriesStudy?.[0].institution ? study.seriesStudy?.[0].institution.replace(/\^/g, ' '):""}</WorklistTableColumn>
    <WorklistTableColumn className="uploadDate">{formattedUploadDate}</WorklistTableColumn>
    <WorklistTableColumn className="tools" isMobile={isMobile()}>
      <ToolsCell 
        refreshList={refreshList} 
        userRights={userRights} 
        study={study} 
        jwt={jwt} 
        providerJwt={providerJwt} 
        worklistViewerJwt={worklistViewerJwt}
        setNotification={setNotification}
      />
    </WorklistTableColumn>
    <WorklistTableColumn className="action">
      {isLoadingInfo 
      ?
        <LoadingAction>
          <Loading size={18} borderWidth={3}/> Processing...
        </LoadingAction>
      :
        selected 
          ? 
            isLoadingAction 
              ? 
                <Tooltip content={"Processing..."}>
                  <ImportButton selected={selected} disabled={isLoadingAction}>
                    <Icon icon="imported" />
                    <div>
                      <div>Added to case</div>
                      <a onClick={() => handleClick(!(linkedStudies.findIndex(uid => uid == study.studyIuid) >= 0), study.studyIuid, study.pk)}>Remove</a>
                    </div>
                  </ImportButton>
                </Tooltip>
              :
                <ImportButton selected={selected} disabled={isLoadingAction}>
                  <Icon icon="imported" />
                  <div>
                    <div>Added to case</div>
                    <a onClick={() => handleClick(!(linkedStudies.findIndex(uid => uid == study.studyIuid) >= 0), study.studyIuid, study.pk)}>Remove</a>
                  </div>
                </ImportButton>
          :
            isLoadingAction 
              ? <Tooltip content={"Processing..."}>
                  <ImportButton disabled={isLoadingAction}>
                    <Icon icon="import" />
                    <div onClick={() => handleClick(!(linkedStudies.findIndex(uid => uid == study.studyIuid) >= 0), study.studyIuid, study.pk)}>Add to case</div>
                  </ImportButton>
                </Tooltip>
              :
                <ImportButton disabled={isLoadingAction}>
                  <Icon icon="import" />
                  <div onClick={() => handleClick(!(linkedStudies.findIndex(uid => uid == study.studyIuid) >= 0), study.studyIuid, study.pk)}>Add to case</div>
                </ImportButton>
      }
    </WorklistTableColumn>
  </WorklistTableRow>
}

const PatientCell = ({patient}) => {
  const dob = dicomDateToDate(patient?.patBirthdate)
  const formattedDob = isNaN(dob.getTime()) ? "" : formatNoUTCDate(dob, DATE_FORMAT(), undefined, true)

  return <>
    <div><b>{patient?.patName ? patient.patName.replace(/\^/g, ' ') : ""}</b></div>
    <div><span css="margin-right:0.75rem"><b>ID:</b> {patient?.patId}</span> <span
      className="nowrap"
    ><b>DOB:</b> {formattedDob}</span></div>
  </>
}
