import React from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components/macro'
import {RecordRowContainer} from "./RecordRowContainer";
import {
  AttachmentBox,
  AttachmentDescription,
  AttachmentDetails,
  AttachmentIcon,
  AttachmentInfoContainer
} from "../AttachmentBoxStyles";
import {NonCompliantDicom} from "../../enums/RecordFormat";
import {formatDate} from "@startlibs/utils";
import {dicomDateToDate} from "../../utils";
import {useToggle} from "@startlibs/core";
import {ConfirmDialog} from "@startlibs/form";
import {RecordDetailsDialog} from "./RecordDetailsDialog";
import {Button, Dialog} from "@startlibs/components";
import {useDoAction} from "../../service/hooks/useDoAction";
import {UploaderAction} from "../../service/UploaderAction";
import {useRecordsSelector} from "../../service/hooks/useRecords";
import {Failed, Queued, Uploaded} from "../../service/enums/RecordStatus";
import {Uploading} from "../../enums/FileState";
import {getRowsForRecords} from "../../service/utils/recordUtils";

styled

const toRecordFiles = records => {
  const recordsLikeInstances =
    records.map(record => _.flow(
      _.set('filename', record.info?.fileName || record.description),
      _.set('extension', record.fileExtension || record.info?.fileExtension),
    )(record))
  return ({
    failed: recordsLikeInstances.filter(_.matchesProperty('status', Failed)),
    uploaded: recordsLikeInstances.filter(_.matchesProperty('status', Uploaded)),
    uploading: recordsLikeInstances.filter(_.matchesProperty('status', Uploading)),
    files: recordsLikeInstances.filter(_.matchesProperty('status', Queued))
  })
}

export const NonCompliantDicomRecordRow = ({group, dragProvided}) => {
  const records = useRecordsSelector(_.filter(['recordFormat',NonCompliantDicom]))
  const [record] = getRowsForRecords(records)
  const doAction = useDoAction()
  const recordDetailsDialog = useToggle()
  const cancelDialog = useToggle()
  const cancelLoading = useToggle()
  const deleteLoading = useToggle()
  const deleteDialog = useToggle()
  const deleteInstanceDialog = useToggle()
  const deleteRecord = async () => {
    await deleteLoading.wait(doAction(UploaderAction.Delete,record))
    cancelDialog.close()
  }
  const cancelUpload = async () => {
    await cancelLoading.wait(doAction(UploaderAction.CancelRecord,record))
    cancelDialog.close()
  }

  if (!records.length) { return null }

  return <RecordRowContainer
    group={group}
    record={record}
    dragProvided={dragProvided}
    cancelUpload={cancelDialog.open}
    deleteRecord={deleteDialog.open}
    withNotes={false}
    withMeta={false}
  >{isMinified =>
    <>
      <AttachmentDescription>
        <b>{records.length} Non compliant DICOM files</b> <a className="light-link" onClick={recordDetailsDialog.open}>Details</a>
      </AttachmentDescription>
      {
        recordDetailsDialog.isOpen &&
        <RecordDetailsDialog
          title="Non compliant DICOM study details"
          subtitle="Some DICOM files were incompatible with our webviewer. They are grouped together here and will be available to download them."
          closeDialog={recordDetailsDialog.close}
          cancelUpload={cancelDialog.open}
          deleteFile={deleteInstanceDialog.openWith}
          record={record}
          recordFiles={toRecordFiles(records)}
          withCancelSingleFile
        />
      }
      {
        cancelDialog.isOpen &&
        <Dialog
          closeDialog={cancelDialog.close}
          title="Cancel non compliant DICOM upload"
          footer={<>
            <Button alert isLoading={deleteLoading.isOpen} onClick={deleteRecord}>Delete all non compliant files</Button>
            <Button alert isLoading={cancelLoading.isOpen} onClick={cancelUpload}>Stop remaining uploads only</Button>
          </>}
        >
          <p>Would you like to cancel only the remaining file uploads in queue or also delete all non compliant DICOM files?</p>
        </Dialog>
      }
      {
        deleteDialog.isOpen &&
        <Dialog
          closeDialog={deleteDialog.close}
          title="Delete all non compliant files"
          footer={<>
            <Button onClick={deleteDialog.close}>Cancel</Button>
            <Button alert isLoading={deleteLoading.isOpen} onClick={deleteRecord}>Delete non compliant files</Button>
          </>}
        >
          <p>Would you like to delete all non compliant DICOM files already uploaded?</p>
          <p>Once confirmed this cannot be undone.</p>
        </Dialog>
      }
      {
        deleteInstanceDialog.isOpen &&
        <ConfirmDialog
          closeDialog={deleteInstanceDialog.close}
          action={() => doAction(UploaderAction.Delete,deleteInstanceDialog.get())}
          title="Delete non compliant file"
          cancelButton={<Button onClick={deleteInstanceDialog.close}>Cancel</Button>}
          confirm={<Button alert>Delete non compliant file</Button>}
        >
          <p>You are about to delete the following file:</p>
          <AttachmentBox nonCompliantDeleteFileBox css="margin-bottom:1rem;">
            <AttachmentIcon icon="dicom" css="font-size:44px;" className="desktop-only"/>
            <AttachmentInfoContainer>
              <div className="file-details">
                <div><strong>{deleteInstanceDialog.isOpen.filename}</strong></div>
              </div>
            </AttachmentInfoContainer>
          </AttachmentBox>
          <p><b>Are you sure you want to delete it?</b> Once confirmed this cannot be undone.</p>
        </ConfirmDialog>
      }
    </>
  }</RecordRowContainer>
}