import {ContextMenu, Li} from '@startlibs/components'
import React from 'react'
import {
  CASE_ARCHIVED,
  CASE_DRAFT,
  CASE_REJECTED,
  PENDING_ASSIGNMENT,
  PENDING_REQUEST,
  REQUEST_REJECTED,
  WAITING_ACCEPTANCE,
  WAITING_APPROVAL,
  WAITING_MEDICAL_RECORDS
} from '../../enums/CaseState'
import { IfLocale, useIsLocale } from '../../hocs/IfLocale';
import {useArchiveDialog} from '../hooks/useArchiveDialog'
import {useUnarchiveDialog} from '../hooks/useUnarchiveDialog'

export const AdminCaseCardMenu = ({caseRequest, refreshList, isOpen, editCategories, editCaseNumber, toggleAsPriority}) => {

  const openArchive = useArchiveDialog(caseRequest, refreshList)
  
  const isLegal = useIsLocale({contains:'LEGAL'})

  const openUnarchive = useUnarchiveDialog(caseRequest, refreshList)

  const getArchiveLabel = (state) => {
    switch (state) {
      case PENDING_REQUEST:
        return <Li label="Decline and archive" icon="unavailable" onClick={openArchive}/>
      case CASE_DRAFT:
      case WAITING_MEDICAL_RECORDS:
      case WAITING_APPROVAL:
      case PENDING_ASSIGNMENT:
      case WAITING_ACCEPTANCE:
      case CASE_REJECTED:
        return <Li label="Abort and archive" icon="unavailable" onClick={openArchive}/>
      case CASE_ARCHIVED:
      case REQUEST_REJECTED:
        return <Li label="Unarchive" onClick={openUnarchive}/>
    }
  }

  return isOpen && <ContextMenu>
    <Li label="View" linkTo={`/admin/request/${caseRequest.requestCode}`}/>
    {editCategories && <Li label="Edit divisions" onClick={editCategories(caseRequest)}/>}
    {editCaseNumber && isLegal && <Li label="Edit case number" onClick={editCaseNumber(caseRequest)}/>}
    <Li icon={caseRequest.markedAsPriorityEpochMilli ? "star" : "star-line"} label={caseRequest.markedAsPriorityEpochMilli ? "Remove as priority" : "Mark as priority"} onClick={toggleAsPriority(caseRequest)}/>
    {getArchiveLabel(caseRequest.state)}
  </ContextMenu>
}
