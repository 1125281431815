import {Combobox, ComboboxInput, FormValue, RadioboxGroup, SimpleRadiobox, TextInput} from '@startlibs/form'
import {FormattedMessage,useIntl} from 'react-intl'
import { Icon, SplitColumnsContainer } from '@startlibs/components';
import {getColor, smoothScroll} from '@startlibs/utils';
import React, {useEffect, useRef} from 'react'
import styled, { css } from 'styled-components';
import {AFTERNOON, EVENING, MORNING} from '../../enums/ContactTime'
import {CAREGIVER, CONTACT_RELATION_LABELS, CONTACT_RELATION_LEGAL_LABELS, CONTACT_RELATION_LEGAL_OPTIONS, CONTACT_RELATION_MEDICAL_LABELS, CONTACT_RELATION_MEDICAL_OPTIONS, CONTACT_RELATION_OPTIONS, EXECUTOR, LEGALGUARDIAN, OTHER, PARENT, PATIENT, REF_PHYSICIAN} from '../../enums/ContactRelationType'
import {Card, SectionHeading} from '../../components/PageLayout';
import {ConfirmEmailInput} from '../../components/ConfirmEmailInput'
import {EMAIL, PHONE, TEXT} from '../../enums/ContactCommunicationType'
import {IfLocale} from '../../hocs/IfLocale'

export const AddressHeading = styled.div`
  font-size: 13px;
  color: ${getColor('gray150')};
  padding-bottom: .25rem;
  border-bottom: 1px solid ${getColor('gray210')};
  margin: 1.5rem 0;
`

const ComboboxContainer = styled.div`
  ${props => props.readOnly && `
    ${ComboboxInput} {
      background: rgba(0,0,0,0.075) !important;
      opacity: 0.75 !important;
      pointer-events: none !important;
      ${Icon} {
        opacity: 0.4;
      }
    }
  `}
`

const VIEWPORT_MIN_WIDTH = 500

export const ContactDetails = ({readOnly, scrollTo}) => {

  const headerRef = useRef()
  const intl = useIntl()

  useEffect(() => {
    if (scrollTo) {
      smoothScroll({target:headerRef.current,offset:-90})
    }
  },[])

  return <>
    <SectionHeading ref={headerRef}>
      <h3><FormattedMessage
        description='ContactDetails title'
        defaultMessage='Contact party'
      /></h3>
      <p>
        <FormattedMessage
          description='ContactDetails subtitle'
          defaultMessage="This is the party responsible for this case. They may be contacted about patient information, medical records, payment, and the case report as applicable."
        />

      </p>
    </SectionHeading>
    <Card css="padding-bottom:1.5rem;">
      <IfLocale not contains="LEGAL">
        <ComboboxContainer readOnly={readOnly} className='fs-exclude'><Combobox 
          disabled={readOnly}
          className="combobox fs-exclude"
          label={intl.formatMessage({ defaultMessage: "Select relation to the patient", description: "ContactDetails contactRelationType label" })}
          path='contactDetails.contactRelationType'
          options={CONTACT_RELATION_MEDICAL_OPTIONS}
          getLabel={v => CONTACT_RELATION_MEDICAL_LABELS[v]}
          mandatory
        /></ComboboxContainer>
      </IfLocale>
      
      <IfLocale contains="LEGAL">
        <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
          <ComboboxContainer readOnly={readOnly} className='fs-exclude'><Combobox
            disabled={readOnly}
            className="combobox fs-exclude"
            label={intl.formatMessage({ defaultMessage: "Select relation to the patient", description: "ContactDetails contactRelationType label" })}
            path='contactDetails.contactRelationType'
            options={CONTACT_RELATION_LEGAL_OPTIONS}
            getLabel={v => CONTACT_RELATION_LEGAL_LABELS[v]}
            mandatory
          /></ComboboxContainer>
        </SplitColumnsContainer>
      </IfLocale>

      <FormValue path="contactDetails.contactRelationType">{relationtype =>
        relationtype && relationtype !== PATIENT && <>
        <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
          <TextInput
            disabled={readOnly}
            type="text"
            path="contactDetails.fullName.first"
            label="Your first name"
            placeholder="Ex: John"
            maxLength={255}
            mandatory
          />
          <TextInput
            disabled={readOnly}
            type="text"
            path="contactDetails.fullName.last"
            label="Your last name"
            placeholder="Ex: Doe"
            maxLength={255}
            mandatory
          />
          {relationtype === REF_PHYSICIAN && <TextInput
            disabled={readOnly}
            type="text"
            path="contactDetails.npi"
            label="NPI"
            placeholder="Ex: 1234567890"
            maxLength={255}
            // mandatory
          />}
        </SplitColumnsContainer>
          {relationtype === OTHER &&
            <TextInput
              disabled={readOnly}
              type="text"
              path="contactDetails.otherRelationType"
              label={intl.formatMessage({ defaultMessage: "Specify your relation with the patient", description: "ContactDetails otherRelationType label" })}
              // label="Specify your relation with the patient"
              // placeholder="Ex: Father"
              maxLength={255}
              mandatory
              />
          }
      </>}</FormValue>

      {/* IF IS REF_PHYSICIAN */}
      <FormValue path="contactDetails.contactRelationType">{relationtype =>
        relationtype && relationtype === REF_PHYSICIAN && <>
        <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
          <TextInput
            disabled={readOnly}
            type="text"
            path="contactDetails.address.address"
            label="Street address"
            placeholder="Ex: 001 Street Name"
            maxLength={255}
            // mandatory
          />
          <TextInput
            disabled={readOnly}
            type="text"
            path="contactDetails.address.city"
            label="City"
            placeholder="Ex: New York"
            maxLength={255}
            // mandatory
          />
        </SplitColumnsContainer>
        <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
          <TextInput
            disabled={readOnly}
            type="text"
            path="contactDetails.address.state"
            label="State"
            placeholder="Ex: New York"
            maxLength={255}
            // mandatory
          />
          <TextInput
            disabled={readOnly}
            type="text"
            path="contactDetails.address.country"
            label="Country"
            placeholder="Ex: USA"
            maxLength={255}
            // mandatory
          />
          <TextInput
            disabled={readOnly}
            type="text"
            path="contactDetails.address.zipCode"
            label="Postal code"
            placeholder="Ex: 12345"
            maxLength={255}
            // mandatory
          />
        </SplitColumnsContainer>
      </>}</FormValue>
      {/* END IF IS REF_PHYSICIAN */}

      <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
        <TextInput
          disabled={readOnly}
          type="text"
          path="contactDetails.email"
          label="Email address"
          placeholder="Ex: your@email.com"
          maxLength={255}
          mandatory
        />

        <ConfirmEmailInput
          disabled={readOnly}
          type="text"
          confirmingPath="contactDetails.email"
          path="contactDetails.confirmEmail"
          label="Confirm email"
          placeholder="Ex: your@email.com"
          maxLength={255}
          mandatory
        />
      </SplitColumnsContainer>
      <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
        <TextInput
          disabled={readOnly}
          path="contactDetails.phoneNumber"
          label="Phone number"
          setValue={(newValue, _, setValue) => setValue(newValue.replace(/[^0-9()\-+\s]/, ''))}
          helpText="(include country code)"
          placeholder="Ex: +1 (123) 456 7890"
          maxLength={255}
          mandatory
        />
        <RadioboxGroup disabled={readOnly} mandatory label="Preferred mode of communication" horizontal path="contactDetails.prefCommunicationMode" framedBoxes wide>
          <SimpleRadiobox fieldValue={PHONE} label="Phone" className='fs-exclude'/>
          <SimpleRadiobox fieldValue={EMAIL} label="Email" className='fs-exclude'/>
          <SimpleRadiobox fieldValue={TEXT} label="Text" className='fs-exclude'/>
        </RadioboxGroup>
      </SplitColumnsContainer>
      <RadioboxGroup disabled={readOnly} label="Preferred contact time" horizontal path="contactDetails.prefContactTime" framedBoxes wide>
        <SimpleRadiobox fieldValue={MORNING} label="Morning" className='fs-exclude'/>
        <SimpleRadiobox fieldValue={AFTERNOON} label="Afternoon" className='fs-exclude'/>
        <SimpleRadiobox fieldValue={EVENING} label="Evening" className='fs-exclude'/>
      </RadioboxGroup>
    </Card>
  </>
}
