import {AutoComplete, DatePicker, Errors, Field, FormContext, WithForm} from '@startlibs/form'
import {Button, SplitColumnsContainer} from '@startlibs/components'
import {_s, formatDate, getColor} from '@startlibs/utils';
import {useToggle} from '@startlibs/core'
import React, {Suspense, useEffect, useRef} from 'react'
import _ from 'lodash/fp'
import styled from 'styled-components'
import {DATE_FORMAT} from '../../../config/DateFormat'
import {TimeInput} from '../../../components/TimeInput'
import {buildValidation, required} from '../../../utils/validation'
import {autocompleteFetch, getDate, getDateValue} from '../../../utils/utils'
import {lazyEvents} from './utils'

const AdvancedPopupComponent = styled.div `
  position: absolute;
  background-color: #FFF;
  border: 1px solid ${getColor('gray210')};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 1.25rem;
  z-index: 999;
  left: 0;
  width: 100%;
  margin-top: -1px;
  .popup-buttons-container {
    text-align: right;
  }
  .close-icon {
    color: ${getColor('gray150')};
    font-size: 20px;
    position: absolute;
    top: 6px;
    right: 5px;
    cursor: pointer;
    width: 2rem;
    line-height: 2rem;
    text-align: center;
    border-radius: 50%;
    :hover {
      background: ${getColor('gray240')};
    }
  }
  .inputs-wrapper {
    input {
      min-width: 4rem;
    }
    .field-fix {
      min-width: 5rem;
    }
  }
`

export const DateShortCuts = styled.div`
  margin-top: -0.25rem;
  margin-bottom: 1rem;
  a {
    color: ${getColor('gray150')};
    margin-right: 1rem;
    text-decoration: underline;
  }
`

const getHourValue = (key,params) => params[key] ? params[key].replace("-",":") : ""
const timeValidation =  (v) => {
  if (!v || v === ":") return;
  const [hour, minutes = 0] = v.split(":")
  if (parseInt(hour, 10) >= 0 && parseInt(hour, 10) <= 24 && parseInt(minutes || 0, 10) >= 0 && parseInt(minutes || 0, 10) <= 59) {
    return
  }
  return "Invalid time"
}
const dateRequiredOn = (key) => (d,k, props) => !(!props[key] || props[key]===":" || !isNaN(d)) && required()

const getValuesFromParams = (params,events) => ({
  search: params.search,
  from: getDateValue('from',params),
  timeFrom: getHourValue('timeFrom',params),
  to: getDateValue('to',params),
  timeTo: getHourValue('timeTo',params),
  events:(params.events||"").split("|").map(key =>events.find(({id})=>id===key)).filter(Boolean)
})
const transform = ({from,to,timeFrom,timeTo,events,search}) => ({
  search,
  from: _.isNumber(from) || _.isDate(from) ? formatDate(from, "MM-dd-yyyy") : "",
  to: _.isNumber(to) || _.isDate(to) ? formatDate(to, "MM-dd-yyyy") : "",
  timeFrom: timeFrom.replace(":",'-').replace(/^-$/,""),
  timeTo: timeTo.replace(":",'-').replace(/^-$/,""),
  events:events.map(_.get('id')).filter(Boolean).join("|")
})

const preValidation = buildValidation({
  timeFrom:timeValidation,
  timeTo:timeValidation,
  from:dateRequiredOn('timeFrom'),
  to:dateRequiredOn('timeTo'),
  events: (e) => e.find(_.isString) && "Invalid event category"
})

export const auditUtils = {preValidation,transform,getValuesFromParams}

export const AdvancedSearchPopup = ({params, focusedInput, events, setParams,closePopup}) => {
  const popupRef = React.useRef()

  useEffect(() => {
    const tryClose = (e) => !(popupRef.current.contains(e.target) || e.target === popupRef.current || e.defaultPrevented) && closePopup()
    document.addEventListener("click",tryClose)
    return () => document.removeEventListener("click",tryClose)
  },[])

  const action = (values) => {
    setParams(_s.assign(values))
    closePopup()
  }

  return <WithForm
    action={action}
    values={getValuesFromParams(params,events)}
    transform={transform}
    preValidation={preValidation}
  >{form =>
    <AdvancedPopupComponent
      ref={popupRef}
      // onKeyDown={({key}) => key === "Enter" && form.submitForm()}
      onKeyDown={({key,defaultPrevented}) => key === "Enter" && !defaultPrevented && form.submitForm()}
      onClick={(e) => e.stopPropagation()}
    >
      <AuditFilterInputs focusedInput={focusedInput}/>
      <Errors css="margin-bottom: 1rem;"/>
      <div className="popup-buttons-container">
        <Button onClick={closePopup} tabIndex={2}>Cancel</Button>
        <Button highlight type="submit">Search</Button>
      </div>
    </AdvancedPopupComponent>
  }</WithForm>

}


const validateTag = (v) => ({errors: "Please select a valid category from the list."})
const getLabel = (v) => v.name

export const AuditFilterInputs = ({focusedInput}) => {
  const updateFields = useToggle()
  const focusRef = useRef()
  const form = React.useContext(FormContext)

  useEffect(() => {
    if (focusRef.current) {
      focusRef.current.focus()
    }
  },[])

  return <>
    <Field>
      <Suspense fallback={null}>
        <EventsInput autoFocus={focusedInput === "events"}/>
      </Suspense>
    </Field>
    <Field label="Entries registered between">
      <SplitColumnsContainer key={updateFields.isOpen+'init'} viewportMinWidth={465}>
        <DatePicker ref={focusedInput === "from" ? focusRef : undefined} label="" placeholder={DATE_FORMAT().toUpperCase()} format={DATE_FORMAT()} path="from" />
        <TimeInput path="timeFrom" noLabel />
      </SplitColumnsContainer>
      <SplitColumnsContainer key={updateFields.isOpen+'end'}>
        <DatePicker ref={focusedInput === "to" ? focusRef : undefined} label="" placeholder={DATE_FORMAT().toUpperCase()} format={DATE_FORMAT()}  path="to" />
        <TimeInput path="timeTo" noLabel />
      </SplitColumnsContainer>
      <DateShortCuts>
        <a onClick={() => {form.setValue('from',getDate(-30)); form.setValue("to",getDate(0)); updateFields.openWith(Date.now())}}>Last 30 days</a>
        <a onClick={() => {form.setValue('from',getDate(-7)); form.setValue("to",getDate(0)); updateFields.openWith(Date.now())}}>Last 7 days</a>
        <a onClick={() => {form.setValue('from',getDate(0)); form.setValue("to",getDate(0)); updateFields.openWith(Date.now())}}>Today</a>
      </DateShortCuts>
    </Field>
  </>
}

const EventsInput = ({autoFocus}) => {
  return <div 
    onClick={e => e.preventDefault()}
    onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
  >
    <AutoComplete
      minQueryLength={1}
      autoFocus={autoFocus}
      label="Filter by event type"
      confirmIfValueKeys={['Tab']}
      path='events'
      clickOpensWhenEmpty
      value={[]}
      autoSelect
      fetchSuggestions={autocompleteFetch(lazyEvents)}
      validation={validateTag}
      getLabel={({name})=>name}
      withDropdown
      placeholder="Click or start typing to select"
    />
  </div>
}
