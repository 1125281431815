import {Button, Icon, Loading, SplitColumnsContainer} from '@startlibs/components';
import {getColor, getFetcher, parseDate} from '@startlibs/utils';
import {useToggle} from '@startlibs/core'
import {useParams} from 'react-router';
import React, {Suspense, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {AssignmentStatusBox,} from '../admin/steps/expertReview/ExpertReviewList';
import {Card, PageContainer, PageFooter, SectionHeading} from '../components/PageLayout';
import {ConfirmDialog, useConfirmDialog} from '../hooks/useConfirmDialog'
import {FieldDetail, ReadOnlyField} from '../request/RequestOverview';
import {GENDER_LABELS} from '../enums/Gender'
import {Header} from '../components/Header';
import {Navbar} from '../components/Navbar'
import {ProviderReleaseError} from './errors/ProviderReleaseError'
import {PurviewFooter} from '../components/PurviewFooter';
import {RecordsManager} from '../request/MedicalRecords'
import {ReleaseDetails, ReleaseFormDetails} from '../request/medicalRelease/ReleaseLocationCard';
import {WAITING_ACCEPTANCE} from '../enums/CaseState'
import {calculateAge, createDateReformatter} from '../utils/utils';
import {getJwt, setJwt} from '../hooks/useJwt'
import {isStateBefore} from '../request/utils'
import {jwtGetFetcher, jwtPostFetcher} from '../utils/authFetch'
import {lazyProviderInfo} from '../components/WithProvider'
import {willUseSuspense, WithLazyResource} from '../hooks/useSuspense'
import { getUploaderJwt, setUploaderJwt } from '../hooks/useUploaderJwt';
import { getStorageHost, setStorageHost } from '../hooks/useStorageHost';
import { RecordsManagerNew } from '../request/RecordsManagerNew';
import { PROVIDER } from '../enums/UserRoles';
import { FormattedMessage } from 'react-intl';

const RequestDetailsCard = styled(Card)`
  position: relative;
  ${ReadOnlyField} {
    margin-bottom: 0;
  }
  ${SplitColumnsContainer} {
    padding-right: 13rem;
  }
  ${ReleaseFormDetails} {
    padding-top: 1.25rem;
    margin-top: 1.25rem;
  }
  .DocumentLink {
    text-decoration: none;
    color: ${getColor('main')};
    span {
      text-decoration: underline;
    }
    ${Icon} {
      vertical-align: text-top;
      font-size: 17px;
      text-decoration: none;
      margin-right: 4px;
    }
  }
  .view-auth {
    position: absolute;
    bottom: 3rem;
    right: 2.5rem;
    z-index: 2;
  }
`

const PatientCard = styled.div`
  display:block;
  background: white;
  border-radius: 5px;
  border: 1px solid ${getColor('gray210')};
  padding: 1rem;
  width: 100%;
  text-decoration: none;
  color: inherit;
  margin-bottom: 1rem;
  strong {
    font-size: 14px;
  }
`

const ExpiredLinkWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  text-align: center;
  max-width: 400px;
  ${Card} {
    margin-bottom: 1rem;
    padding-bottom: 4rem;
  }
  ${Icon} {
    font-size: 5rem;
    color: ${getColor('gray180')};
  }
  h1 {
    font-size: 18px;
    margin: 1rem auto;
    color: ${getColor('main')};
  }
  p {
    margin: 0 .25rem;
  }
`
const SuspendedLabel = styled(Button) `
  box-shadow: none;
  pointer-events: none;
  opacity: 0.75;
  position: absolute;
  top: 1.5rem;
  right: 2.5rem;
  z-index: 2;
  color: ${getColor('warning')};
  &:before {
    border-color: ${getColor('warning')};
  }
`

const useSuspense = willUseSuspense((code) =>
    
    Promise.all([
      getFetcher(`/api/locationForm/${code}`),
      getFetcher(`/api/uploaderTokenFromTimedLink/${code}`)
        .catch(([body, resp]) => {
          resp.status === 401 
          ? Promise.resolve({}) 
          : Promise.reject([body, resp])})
    ]),
  true
)

const useMedicalRecordsSuspense = willUseSuspense(() =>
  jwtGetFetcher(getJwt())(`/api/timedMedicalRecords`)
)

const useMedicalRecordsSuspenseNew = willUseSuspense(() =>
    jwtGetFetcher(getJwt())(`/api/storage/timedMedicalRecords/${getUploaderJwt()}`) 
)

export const ProviderUploadRecords = () => {
  const {code} = useParams()
  return <ProviderReleaseError><AuthentitcatedProviderUploadRecords code={code} /></ProviderReleaseError>
}

const AuthentitcatedProviderUploadRecords = ({code}) => {
  
  const [releaseInfo, {jwt, newUploader, uploaderJWT, storageHost}] = useSuspense(code)
  var caseUID = 0;
  if (newUploader){
    let responseJson = JSON.parse(uploaderJWT);
    setUploaderJwt(responseJson.jwt)
    setStorageHost(storageHost)
    caseUID = responseJson.caseUID
  }

  const providerInfo = lazyProviderInfo.read()

  useEffect(() => {

    window.FS.setUserVars({
      displayName: releaseInfo.medicalRecordLocationItem.name,
      locationFormId: releaseInfo.id,
      locationFormTimedCode: releaseInfo.timedCode,
      patientName: releaseInfo.firstName + ' ' + releaseInfo.lastName,
      type: "PROVIDER",
      customer: providerInfo.name
    })
    
  },[])
  
  setJwt(jwt)
  const dobDate = parseDate(releaseInfo.dob,new Date(),'MM-dd-yyyy')
  const yearsOld = calculateAge(dobDate)

  const dateFormatter = createDateReformatter('MM-dd-yyyy', "MM/dd/yyyy")
  const gender = GENDER_LABELS[releaseInfo.gender]
  const uploaderRef = useRef()
  const submitted = useToggle()

  const isAuthorized = releaseInfo.medicalRecordsReleaseLicense.signature

  const date = new Date()
  date.setHours(0)

  const expiredDate =  (releaseInfo.suspend || parseDate(releaseInfo.medicalRecordLocationItem.dateExpiration, date, 'MM-dd-yyyy').getTime() < date.getTime()) && !jwt
  const lockedByStatus = !isStateBefore(releaseInfo.caseState,WAITING_ACCEPTANCE) && !jwt

  const confirmFinish = useConfirmDialog(<ConfirmDialog
    title="All complete"
    confirm={<Button highlight>Proceed</Button>}
    action={() => jwtPostFetcher(getJwt())("/api/notifyMedicalRecordUploadedByInstitution")}
    onSuccess={submitted.open}
    notify="The institution was notified of the provided records."
  >
    <p>
      This will notify <strong><Suspense><WithLazyResource value={lazyProviderInfo}>{providerInfo => providerInfo.name}</WithLazyResource></Suspense></strong> that the requested records for the following patient have now been uploaded:
    </p>
    <PatientCard className='fs-exclude'>
      <div><strong>{releaseInfo.firstName} {releaseInfo.middleName} {releaseInfo.lastName} ({gender})</strong></div>
      <div>Date of birth: {dateFormatter(releaseInfo.dob)}</div>
    </PatientCard>
    <p>You grant <strong><Suspense><WithLazyResource value={lazyProviderInfo}>{providerInfo => providerInfo.name}</WithLazyResource></Suspense></strong> access to those records.
    </p>
    <p>Are you sure you want to proceed?</p>
  </ConfirmDialog>)

  return <>
    <Navbar/>
    <PageContainer>
      <Header
        title="Request for medical records"
      />
      {!submitted.isOpen ? <>
          <SectionHeading>
            <h3>{
              (expiredDate || lockedByStatus) ? "Medical records requested"
              : isAuthorized ? "Please provide medical records as described and authorized below"
              : "Please provide medical records as described below"}
            </h3>
            <p>You have been requested to provide the following medical records in your possession:</p>
          </SectionHeading>
          <RequestDetailsCard>
            {
              expiredDate &&
               <SuspendedLabel expiredLabel outline small>
                 Expired authorization
               </SuspendedLabel>
            }
            <SplitColumnsContainer>
              <ReadOnlyField>
                <label><FormattedMessage description='ProviderUploadRecords label records for' defaultMessage='Records for patient:' /></label>
                <FieldDetail className='fs-exclude'>{releaseInfo.firstName} {releaseInfo.middleName} {releaseInfo.lastName} ({gender})</FieldDetail>
                <div><FieldDetail className='fs-exclude'>Born in {dateFormatter(releaseInfo.dob)} ({yearsOld} years old)</FieldDetail></div>
              </ReadOnlyField>
              {isAuthorized &&
              <ReadOnlyField>
                <label>Between dates:</label>
                <FieldDetail>Start date: {dateFormatter(releaseInfo.medicalRecordLocationItem.dateFrom)}</FieldDetail>
                <div><FieldDetail>End date: {dateFormatter(releaseInfo.medicalRecordLocationItem.dateTo)}</FieldDetail>
                </div>
              </ReadOnlyField>
              }
            </SplitColumnsContainer>
            {
              isAuthorized && <>
                <Button.a
                  className="view-auth"
                  small
                  target="_blank"
                  href={"/provider/medicalRelease/" + releaseInfo.timedCode}
                >View</Button.a>
              </>
            }
            <ReleaseDetails release={releaseInfo} isExpired={expiredDate}/>
          </RequestDetailsCard>
        </>
        : <Card>
          <h3>The institution has been notified regarding the medical records you provided.</h3>
          <PatientCard className='fs-exclude'>
            <div><strong>Patient: {releaseInfo.firstName} {releaseInfo.middleName} {releaseInfo.lastName} ({gender})</strong></div>
            <div>Date of birth: {dateFormatter(releaseInfo.dob)}</div>
          </PatientCard>
          <p>If you'd like to add more medical records still, please click below.</p>
          <Button onClick={submitted.close}>Add more medical records</Button>
        </Card>}
      {
        jwt && <>
          <SectionHeading>
            <h3>
              {submitted.isOpen ? "Medical records files submitted" : "Medical record uploader"}
            </h3>
            {!submitted.isOpen &&
            <p>
              If no additional instructions have been provided, please
              upload all medical records matching the criteria described above,
              including but not limited to exam results, pathology reports,
              medical reports, etc.
            </p>}
          </SectionHeading>
          <Card>
            <Suspense fallback={<Loading size={36} borderWidth={5} css="margin:3rem auto;"/>}>
            {newUploader ? 
              <WithMedicalRecordsNew>{(medicalRecords, setMedicalRecords, caseRequest) =>
                <RecordsManagerNew
                  allowReorder={false}
                  disabled={false}
                  uploaderRef={uploaderRef}
                  caseRequest={caseRequest}
                  medicalRecords={medicalRecords}
                  setMedicalRecords={setMedicalRecords}
                  caseId={caseUID}
                  listMode={false}
                  role={PROVIDER}
                  autoGrouping={false}
                />
              }</WithMedicalRecordsNew>
            :
              <WithMedicalRecords>{(medicalRecordsInfo, setMedicalRecords) =>
                <RecordsManager
                  disabled={submitted.isOpen}
                  uploaderRef={uploaderRef}
                  caseRequest={{medicalRecordsInfo}}
                  setMedicalRecords={setMedicalRecords}
                />
              }</WithMedicalRecords>
            }</Suspense>
          </Card>
        </>
      }
      {
        (expiredDate || lockedByStatus) &&
        <AssignmentStatusBox>
          <div className="waitingBoxContent">
            <Icon icon="clock"/>
            <h4>
              It's no longer possible to submit medical records
            </h4>
            <p>
              {
                expiredDate
                ? "This authorization has expired and it is no longer accepting new medical records"
                : "This request is no longer accepting new medical records"
              }
            </p>
          </div>
        </AssignmentStatusBox>
      }
      {(!submitted.isOpen && jwt) && !(expiredDate || lockedByStatus) &&
      <PageFooter>
        <Button
          icon="email"
          highlight
          onClick={() => uploaderRef.current.confirm().then(confirmFinish)}
        >Finish and notify institution</Button>
      </PageFooter>
      }
    </PageContainer>
    <PurviewFooter/>
  </>
}
const WithMedicalRecords = ({children}) => {
  const [medicalRecords, setMedicalRecords] = useState(useMedicalRecordsSuspense())
  return children(medicalRecords, setMedicalRecords)
}

const WithMedicalRecordsNew = ({children}) => {
  const [medicalRecords, setMedicalRecords] = useState(useMedicalRecordsSuspenseNew())
  const caseRequest = {}
  caseRequest.medicalRecords = medicalRecords
  return children(medicalRecords, setMedicalRecords, caseRequest)
}
