import _ from 'lodash/fp'
import {formatDate, parseDate} from '@startlibs/utils'
import {setNotification} from '../components/Notifications'

export function safeMultipleNewLineToBr(str) {
  // By replace \n into <br/> line breaks will be displayed on RichText input Fields and PDF Reports
  return (str||"").replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/\n/g,'<br/>')
}

export function safeMultipleNewLineToBrOnly(str) {
  // By replace \n into <br/> line breaks will be displayed on RichText input Fields and PDF Reports
  return (str||"").replace(/\n/g,'<br/>')
}

export function isRichText(text){
  const tags = ["<u ","</u>","<b>","</b>","<p>","</p>","<span ", "</span>", "<a ", "</a>", "<div>", "</div>", "\<\b\r\/\>", "<br>", "<br/>", "<ol>", "</ol>", "<ul>", "</ul>", "<li>", "</li>", "<blockquote", "</blockquote>", "&nbsp;"];
  return tags.some(element => text.includes(element));
}

export const EMPTY_CASE_INFO = {
  patientDiagnosis: "",
  clinicalSummary: "",
  questionsConsultant: [{id: Date.now() + "", answer: '', question: ''}],
  version: 3
}

export const getReportResource = (imagePath, origin = "") => imagePath
  ? (origin+"/api/reportResource/"+imagePath)
  : undefined

export const tryOrFail = (test,fail) => {
  try {
    return test()
  } catch(e) {
    fail()
  }
}

export const toLocalState = caseRequest => _.flow(
  _.update('caseInfo', (c) => c ? _.set('version', 3, c) : EMPTY_CASE_INFO),
  _.set(['patientInfo', 'confirmEmail'], _.get(['patientInfo', 'email'], caseRequest))
)(caseRequest)


export const filterExpert = (query) => expert => {
  const tokens = [
    (expert.firstName|| "").toLowerCase().trim(),
    (expert.lastName || "").toLowerCase().trim(),
    (expert.expertEmail || "").toLowerCase(),
    ..._.flatten((expert.specialization||[]).map(v => v.toLowerCase().split(" ")))
  ]
  const queryTokens = query.toLowerCase().trim().split(" ")
  return queryTokens.filter(q => tokens.find(t => t.indexOf(q.trim()) >= 0)).length === queryTokens.length
}


export const priceConstraint = (v) => {
  const price = v.replace(/^[^0-9]*([0-9]*\.?)[^0-9]*([0-9]{0,2})([0-9]?)(.*)/,"$1$2|$3")
  const [current,extra] = price.split("|")
  if (extra) {
    return current.slice(0,-3) + current.slice(-2,-1) + "." + current.slice(-1) + extra
  }
  return current
}
export const formatPrice = (v) => v ? Number(v).toFixed(2) : v

export const limitLength = (length,msg) => (v = "") => {
  if (v.length > length) {
    setNotification({type:"alert", msg})
  }
  return v.slice(0,length)
}

export const searchToParams = (search) => {
  const urlParams = new URLSearchParams(search).entries()
  const result = {}
  for (var pair of urlParams) {
    result[pair[0]] = pair[1]
  }
  return {
    studyStatus: '',
    ...result
  }
}
export const getDateValue = (key,params) => params[key] ? parseDate(params[key],new Date(),"MM-dd-yyyy") : NaN

export const getDate = (n) => {
  const date = new Date()
  date.setDate(date.getDate() + n)
  return date
}

export const getDateYear = (n) => {
  const date = new Date()
  date.setFullYear(date.getFullYear() + n)
  return date
}

export const formatDateNoUTC = (date,format,messages) => formatDate(date,format,messages,true)
export const getTimezoneOffset = () => -(new Date().getTimezoneOffset())

export const createDateReformatter = (from, to) => (date) => formatDate(parseDate(date,new Date(),from),to)

export function calculateAge(birthday) { // birthday is a date
  var ageDifMs = Date.now() - birthday;
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export const addZeroPrefix = (v,minLength = 2) => v && v.toString().length < minLength ? addZeroPrefix("0"+v) : v

export const autocompleteFetch = (resource) => (s) => resource.promise().then(suggestions => suggestions.filter(({name}) => name.toLowerCase().indexOf(s) >= 0))

export const multiWait = (toggle,promise,key) => toggle.wait(promise,_.concat([key]),_.without([key]))

export function safeNewLineToDoubleBr(str) {
  return (str || "").replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/\n\n+/g, '<br/><br/>').replace(/\n+/g, '<br/>');
}

export function RichTextLineBreaksToNewLine(str) {
  return (str || "").replace(/<br>/g,'\n').replace(/<br\/>/g,'\n').replace(/<div>/g,'\n');
}

export function RichTextRemoveTags(str){
  return (str || "").replace(/<li>/g, '\n').replace(/<[^>]+>/g, '').replace(/&nbsp;/g, ' ');
}


// export const RTF_TOOLBAR_OPTIONS = ['bold', 'italic', 'underline','separator', 'link', 'separator', 'insertUnorderedList', 'insertOrderedList', 'separator', 'outdent', 'indent','table'];
export const RTF_TOOLBAR_OPTIONS_STARTLIBS = ['bold', 'italic', 'underline','separator', 'link', 'separator', 'insertUnorderedList', 'insertOrderedList', 'separator', 'outdent', 'indent'];
export const RTF_TOOLBAR_OPTIONS = ['bold', 'italic', 'underline','separator', 'link', 'separator', 'insertUnorderedList', 'insertOrderedList', 'separator', 'outdent', 'indent','separator','image','table'];

// AVAILABLE FORMAT TOOLS
// 'bold': ['b', 'strong'],
// 'italic': 'i',
// 'underline': 'u',
// 'strikethrough': 'strike',
// 'link': 'a',
// 'insertUnorderedList': ['ul', 'li'],
// 'insertOrderedList': ['ol', 'li'],
// 'outdent': '',
// 'indent': 'blockquote',
// 'fontSizeUp': 'font',
// 'fontSizeDown': 'font'