import {
  Button,
  ContextMenu,
  DraggableContainer,
  DraggableItem,
  Icon,
  Li,
  Loading,
  Tooltip
} from '@startlibs/components';
import {
  Combobox,
  Field,
  FileInput,
  FormValue,
  RadioboxGroup,
  SimpleRadiobox,
  TabRadiobox,
  TextInput
} from '@startlibs/form';
import {_s, getColor, wrapLazy} from '@startlibs/utils';
import {
  useLazyConstant,
  usePopupToggle,
  usePrevious,
  useRefState,
  useToggle
} from '@startlibs/core';
import React, {useEffect, useRef, useState} from 'react'
import SignaturePad from 'signature_pad'
import _ from 'lodash/fp'
import styled, { css } from 'styled-components';
import {FileInputBoxStyle} from 'uploader-frontend'
import {AddButton} from '../components/AddButton';
import {ConfirmDialog, useConfirmDialog, useConfirmDialogWithProps} from '../hooks/useConfirmDialog'
import {DIGITAL, NONE, PEN} from '../enums/SignatureType'
import { InfoBox } from '../components/InfoBox';
import {buildValidation, required} from '../utils/validation'
import {defaultListAnimation} from '../UIConfig/defaultListAnimation'
import {getJwt} from '../hooks/useJwt'
import {jwtGetFetcher, jwtPostFetcher} from '../utils/authFetch'
import {useAutoScrollAndFocus} from '../hooks/useAutoScrollAndFocus'
import { useIntl } from 'react-intl';

const SignaturePadContainer = styled("canvas")`
  background: white;
  border: 1px solid ${getColor('gray210')};
  border-radius: 5px;
  display: block;
  margin-bottom: .5rem;
  width: 100%;
  ${props => props.height && `height: ${props.height}px`}
`

export const DigitalSignatureField = styled(Field)`
  position: relative;
  margin-top: 1rem;
  ${Button} {
    position: absolute;
    top: 1rem;
    right: 0;
  }
  .help-text {
    color: ${getColor('gray90')};
  }
`

export const InsertedSignaturesWrapper = styled(DraggableContainer)`
  display: flex;
  flex-wrap: wrap;
  margin: -.5rem;
  margin-bottom: 1rem;
  position: relative;
`

export const InsertedSignatureItem = styled.div`
  position: relative;
  flex-basis: 40%;
  max-width: 48%;
  border-radius: 5px;
  padding: 1rem;
  flex-grow: 1;
  background: white;
  margin: .5rem;
  ${props => !props.inReport && css`
    border: 1px dashed ${getColor('gray180')};
  `}
  ${props => props.dragging &&`
    cursor: grabbing;
  `}
  ${Button}, .field-actions {
    position: absolute;
    top: .5rem;
    right: .5rem;
  }
  .field-actions {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    ${Loading} {
      margin-right: .5rem;
    }
  }
  .signature-image {
    position: absolute;
    height: 5rem;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 1rem;
    left: 2rem;
    right: 2rem;
    ${props => props.img && `
      background-image: url('${props.img}');
    `}
    ${props => (props.signAfter || props.nameOnly) && `
      display: none;
    `}
  }
  .signature-line {
    border-top: 1px solid rgba(0,0,0,0.15);
    margin-bottom: 0.5rem;
    z-index: 1;
    right: 1rem;
    left: 1rem;
    position: absolute;
    top: 5rem;
    ${props => props.nameOnly && css`
      display: none;
    `}
  }
  .display-name {
    font-weight: 600;
    font-size: 14px;
    margin-top: 5rem;
  }
  .move-icon {
    font-size: 18px;
    margin-left: .5rem;
    color: ${getColor('gray150')};
    cursor: pointer;
  }
  ${props => props.inReport && css`
    padding: .5rem 1rem;
    .field-actions {
      right: 1rem;
      top: 1rem;
    }
  `}
  ${props => props.inReport && !props.isPreview && css`
    padding: 3rem 1rem .5rem;
    border: 1px dashed ${getColor('gray210')};
    .signature-line {
      top: 7rem;
    }
    .signature-image {
      top: 3rem;
    }
  `}
  ${props => props.isDraggable && props.inReport && css`
    cursor: grab;
    :hover {
      cursor: grab;
    }
    ${props => props.dragging &&`
      cursor: grabbing;
    `}
  `}
`

const ManageSignatureBox = styled(InfoBox)`
  .responsible-name {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    min-width: 120px;
    > div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
    }
  }
  ${Button} {
    flex-shrink: 0;
    margin-left: 1rem;
  }
`

export const SignatureContainer = styled(FileInputBoxStyle)`
  position: relative;
  ${props => !props.uploading && css`
    background-color: white;
  `}
  ${props => props.image && !props.uploading && `
    background-image: url('${props.image}');
  `}
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-origin: content-box;
  color: ${getColor('gray150')};
  ${Icon} {
    position: absolute;
    top: 0;
    right: .5rem;
    font-size: 3rem;
  }
  :hover {
    ${Icon} {
      color: ${getColor('gray120')};
    }
  }
`

const lazySignatures = wrapLazy((type) => jwtGetFetcher(getJwt())(`/api/${type}/signatures`))

export const ExpertSignatureManagement = ({caseRequest, disabled, location, path = 'signatures',form, apiType = "expert"}) => {
  const loadedAvailableSignatures = lazySignatures.use(apiType).read()
  const [availableSignatures, setAvailableSignatures] = useState(loadedAvailableSignatures)
  const availableSignaturesRef = useRef(availableSignatures)
  availableSignaturesRef.current = availableSignatures

  const addEditDialog = useToggle()
  const createDialog = useToggle()
  const manageDialog = useToggle()

  const addSignature = (signature,previous) => form.setValues(_.update(path,(signatureList) =>
    (signatureList || []).indexOf(previous)>=0
      ? (signatureList || []).map(s => s === previous ? signature : s)
      : (signatureList || []).concat(signature)
  ))
  const removeSignature = (signature) => form.setValues(_.update(path,_.without([signature])))
  const setSignatureOrder = (from,to) => {
    form.setValues(_.update(path,_s.moveElement(from,to)))
  }

  const createAvailableSignature = (v,signature)  => {
    if (!availableSignatures.length) {
      addEditDialog.open()
    }
    setAvailableSignatures(_.unionBy('id',_,[signature]))
  }

  useEffect(() => {
    return () => {
      if (loadedAvailableSignatures !== availableSignaturesRef.current) {
        lazySignatures.refresh()
      }
    }
  },[])

  const scrollRef = useAutoScrollAndFocus(location?.state?.signatures)

  useEffect(() => {
    if (!manageDialog.isOpen && !availableSignatures.length) {
      addEditDialog.close()
    }
  },[manageDialog.isOpen])

  return <>
    <FormValue path={path}>{signatures => <>
      <span ref={scrollRef}/>
      <InsertedSignaturesWrapper
        animation={defaultListAnimation}
        disabled={signatures?.length <= 1 || disabled}
        setItemOrder={setSignatureOrder}
      >
        {(signatures || []).map(signature =>
          <DraggableItem
            DragTag={Signature}
            signature={signature}
            disabled={disabled}
            canEdit={!!availableSignatures.find(({id}) => id === signature.id)}
            editSignature={addEditDialog.willOpenWith(signature)}
            removeSignature={removeSignature}
            isDraggable={signatures?.length > 1}
          />
        )}
      </InsertedSignaturesWrapper>
      </>
    }</FormValue>

    {!disabled && <AddButton onClick={availableSignatures.length ? addEditDialog.open : createDialog.open}>Add signature</AddButton>}
    {
      createDialog.isOpen &&
      <CreateSignatureDialog apiType={apiType} closeDialog={createDialog.close} createAvailableSignature={createAvailableSignature}/>
    }
    <FormValue path={path}>{signatures =>
        addEditDialog.isOpen &&
        <AddEditSignatureDialog values={addEditDialog.isOpen} signaturesInUse={signatures} availableSignatures={availableSignatures} closeDialog={addEditDialog.close} addSignature={addSignature} manageSignatures={manageDialog.open} />
    }</FormValue>
    {
      manageDialog.isOpen &&
        <ManageAvailableSignaturesDialog closeDialog={manageDialog.close} createSignature={createDialog.open} availableSignatures={availableSignatures} setAvailableSignatures={setAvailableSignatures} />
    }
  </>
}

const ManageAvailableSignaturesDialog = ({availableSignatures,closeDialog,setAvailableSignatures,createSignature}) => {
  const deleteSignature = useConfirmDialogWithProps((signature) =>
    <ConfirmDialog
      title="Delete signature template"
      css="max-width: 46rem;"
      confirm={<Button alert>Delete</Button>}
      action={() => jwtPostFetcher(getJwt())(`/api/signature/${signature.id}`,undefined, {method: "DELETE"})}
      onSuccess={() => setAvailableSignatures(_.differenceBy(_.get('id'),_,[signature]))}
    >
      <p>Please confirm deleting the <strong>{signature.name}</strong> signature template.</p>
      <p>Signatures already added in cases or reports won't be removed from their context.</p>
      <p>This action is irreversible. Are you sure you want to delete?</p>
    </ConfirmDialog>
  )

  return <ConfirmDialog
    title="Manage signature templates"
    closeDialog={closeDialog}
    auxiliarActions={<Button highlight onClick={createSignature}>Create new signature</Button>}
    closeLabel="Close"
    >
    {availableSignatures.length > 0 ? <>
      {availableSignatures.map(availableSignature =>
        <ManageSignatureBox flex>
          <div className="responsible-name">
            <Tooltip whenEllipsis={({target}) => target.offsetWidth < target.scrollWidth} content={availableSignature.name}>
              {availableSignature.name}
            </Tooltip>
          </div>
          <Button small onClick={deleteSignature.willOpenWith(availableSignature)}>Delete</Button>
        </ManageSignatureBox>

      )} </>
      : <>
      <ManageSignatureBox>
        No signatures available, click below to create one.
      </ManageSignatureBox>
    </>}
  </ConfirmDialog>
}

const Signature = ({signature,canEdit,onTouchStart,onMouseDown, disabled, children, editSignature, removeSignature, isDraggable}) => {

  const contextMenu = usePopupToggle()

  const confirmRemoveSignature = useConfirmDialog(
    <ConfirmDialog
      title="Confirm removing signature"
      action={() => removeSignature(signature)}
      confirm={<Button alert>Remove</Button>}
    >
      <p>Please confirm removing this signature from the review.</p>
  </ConfirmDialog>
  )

  return <InsertedSignatureItem
    onMouseDown={onMouseDown}
    onTouchStart={onTouchStart}
    img={signature.imagePath && `/api/reportResource/${signature.imagePath}`}
    isDraggable={isDraggable}
    nameOnly={signature.signatureType === "NONE"}
    signAfter={signature.signatureType === "PEN"}
    >
    <div className="signature-content">
      <div className="signature-image" />
      <div className="signature-line" />
      <div className="display-name">{signature.name}</div>
      <div className="description">{signature.description}</div>
    </div>
    {!disabled && <Button small icon="arrow-down" onClick={contextMenu.open}>
      Edit
      {
        contextMenu.isOpen &&
          <ContextMenu>
            <Li onClick={editSignature} disabled={!canEdit} label={canEdit ? "Edit signature" : <div><div><strong>Editing disabled</strong></div><span>(this signature template was deleted)</span></div>} />
            <Li onClick={confirmRemoveSignature} label="Remove signature"/>
          </ContextMenu>
      }
    </Button>}
    {children}
  </InsertedSignatureItem>
}

const updateDefaultSignature = (signature) => ({...signature,signatureType: signature?.responsible?.imagePath ? DIGITAL : (signature?.signatureType === DIGITAL ? PEN : (signature?.signatureType || PEN))})

const AddEditSignatureDialog = ({values, signaturesInUse, availableSignatures, closeDialog, addSignature, manageSignatures}) => {

  const formRef = useRef()

  const prevAvailableSignatures = usePrevious(availableSignatures)

  useEffect(() => {
    if (!prevAvailableSignatures) {
      return
    }
    const newSignatures = _.differenceBy('id',availableSignatures,prevAvailableSignatures)
    if (newSignatures.length) {
      formRef.current.setValue('responsible',newSignatures[0])
    }
    const removedSignatures = _.differenceBy('id',prevAvailableSignatures,availableSignatures)
    if (removedSignatures.length) {
      formRef.current.setValue('responsible',(responsible) => removedSignatures.find(({id})=> id === responsible.id)
        ? availableSignatures[0]
        : responsible
      )
    }
  },[availableSignatures])

  const isEdit = _.isObject(values)

  const formValues = useLazyConstant(() => {
    if (_.isObject(values)) {
      return updateDefaultSignature({responsible:availableSignatures.find(({id}) => id === values.id), ...values})
    } else {
      return updateDefaultSignature({responsible:availableSignatures[0], id: Date.now().toString(36)})
    }
  })

  return <ConfirmDialog
    closeDialog={closeDialog}
    title={isEdit ? "Edit signature" : "Add signature"}
    formRef={formRef}
    confirmChanges={false}
    values={formValues}
    preValidation={buildValidation({
      responsible: (responsible) => responsible.id !== values?.id &&  _.find(({id}) => responsible.id === id, signaturesInUse) && "This signature is already added."
    })}
    onChange={(prev,next) => prev.responsible!==next.responsible && formRef.current.setValues(updateDefaultSignature(next))}
    action={({signatureType,responsible}) => addSignature({...responsible,signatureType},values)}
    auxiliarActions={<Button onClick={manageSignatures}>Manage signatures</Button>}
    confirm={<Button highlight>{isEdit ? 'Save' : 'Add'}</Button>}
    css="max-width: 48rem;"
  >
    <Combobox
      path="responsible"
      label="Responsible name"
      options={availableSignatures}
      getLabel={_.get('name')}
    />

    <FormValue path="responsible.imagePath">{ imagePath =>
    <RadioboxGroup
      path="signatureType"
      label="Signature type"
      horizontal
    >
      <SimpleRadiobox disabled={!imagePath} fieldValue={DIGITAL} label="Digitally signed"/>
      <SimpleRadiobox fieldValue={PEN} label="Sign after printing"/>
      <SimpleRadiobox fieldValue={NONE} label="Name only (without signature)"/>
    </RadioboxGroup>
    }</FormValue>

  </ConfirmDialog>

}


export const checkImage = (file) => {
  if (file.size > 4194304 / 4) {
    return ['Your file needs to be smaller than 1mb.']
  }
  if (['image/gif', 'image/png', 'image/jpeg', 'image/jpg'].indexOf(file.type.toLowerCase()) < 0) {
    return ['Invalid file format. Please select a .JPG or .PNG file.']
  }
}

const getFormDataFile = (file) => {
  const formData = new FormData()
  formData.append('file', file)
  return formData
}


const SignaturePadComponent = ({signaturePad}) => {
  const signatureRef = useRef()

  useEffect(() => {
    if (!signatureRef.current) {
      return
    }
    const canvas = signatureRef.current
    canvas.width = canvas.offsetWidth;
    signaturePad.openWith(new SignaturePad(canvas, {backgroundColor: "rgb(255,255,255)"}));
    const resizeEvent = () => {
      const imageData = canvas.getContext('2d').getImageData(0, 0, canvas.offsetWidth, canvas.offsetHeight);
      canvas.width = canvas.offsetWidth;
      canvas.getContext('2d').putImageData(imageData, 0, 0)
    }
    window.addEventListener("resize", resizeEvent);
    return () => window.removeEventListener("resize", resizeEvent);
  }, [])

  return <SignaturePadContainer height={120} ref={signatureRef}/>
}

const CreateSignatureDialog = ({values, createAvailableSignature, closeDialog, apiType}) => {

  const IMAGE = "Upload a signature"
  const CANVAS = "Draw a signature"
  const signatureMethods = [IMAGE,CANVAS]
  const fileToUpload = useRefState()
  const picture = useToggle()
  const formRef = useRef()
  const uploadingFile = useToggle()
  const draggingOver = useToggle()
  const mode = useToggle(IMAGE)
  const signaturePad = useToggle({})
  const uploading = uploadingFile.isOpen

  const intl = useIntl()
  const changeMode = (v) => {
    mode.openWith(v)
    fileToUpload.set('')
    if(v === IMAGE){
      picture.close()
    }
  }

  const setFile = (e) => {
   
    if(mode.isOpen === IMAGE){
      e.preventDefault()
      const files = (e.nativeEvent.dataTransfer || e.nativeEvent.target).files
      if (files.length > 0) {
        const file = files[0]
        const validation = checkImage(file)
        if (validation) {
          formRef.current.setError("image", validation)
          return
        }
        fileToUpload.set(file)
        const image = URL.createObjectURL(file)
        picture.openWith(image)
      }
    }else{
      const canvasData = e.toDataURL("image/")
      const fileFromCanvasData = dataURLtoFile(canvasData)
      fileToUpload.set(fileFromCanvasData)
    }
  }

  function dataURLtoFile(dataurl, filename) {
 
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});

  }


  const action = (values) => jwtPostFetcher(getJwt())(`/api/${apiType}/signature`,values,{method:"PUT"})
      .then(({id}) =>
        (fileToUpload.get()
        ? jwtPostFetcher(getJwt())(`/api/${apiType}/signaturePicture/${id}`,getFormDataFile(fileToUpload.get()))
      : Promise.resolve({}))
          .then((signature) => ({...values,id,...signature}))
      )

  return <ConfirmDialog
    closeDialog={closeDialog}
    title="Create new signature"
    formRef={formRef}
    values={values}
    preValidation={buildValidation({name:required})}
    action={action}
    onSuccess={createAvailableSignature}
    confirm={<Button highlight>Save</Button>}
  >
    <TextInput
      path="name"
      label="Display name"
      mandatory
      placeholder="Ex: John Doe"
    />

    <TextInput
      path="description"
      label="Description"
      placeholder={intl.formatMessage({defaultMessage:"Ex: General practitioner", description:"Expert Signature ex placeholder"})}
      textarea
      minHeight={36}
      autoResize
    />

    <Field
      css="margin-top: 2rem"
      label="Digital signature"
      descText="Select an option to electronically sign your reports"
    >
      <RadioboxGroup wide>
        {signatureMethods.map((value) => 
          <TabRadiobox
            key={value}
            raw
            label={value}
            fieldValue={value}
            value={mode.isOpen}
            setValue={changeMode}
          />
        )}
      </RadioboxGroup>
        {mode.isOpen === IMAGE 
          ?
            <Field
              css="margin-top: 1rem"
              label="Upload your signature"
              descText="You can upload images on format JPEG or PNG"
            >
              <SignatureContainer
                image={picture.isOpen}
                draggingOver={draggingOver.isOpen}
                uploading={uploadingFile.isOpen}
              >
                {!picture.isOpen && !uploading &&
                  <div>
                    {draggingOver.isOpen ? "Drop your image here to upload it."
                      : "Click or drag and drop an image file here to upload it."}
                  </div>
                }
                {uploading && <div><Loading /> Uploading image</div>}
                <FileInput onDragEnter={draggingOver.open} onDragLeave={draggingOver.close} onChange={setFile} tabIndex={-1}/>
                <Icon icon="image" />
              </SignatureContainer>
            </Field>
          :
            <DigitalSignatureField label="Draw your signature" descText="Sign using the cursor in the space below" onMouseOut={() => setFile(signaturePad.get())}>
              <SignaturePadComponent signaturePad={signaturePad}/>
              <Button small onClick={() => signaturePad.get().clear()}>Clear</Button>
            </DigitalSignatureField>
        }
      </Field>

  </ConfirmDialog>

}
