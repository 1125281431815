import {Button} from '@startlibs/components'
import {useNavigate} from 'react-router'
import {will} from '@startlibs/utils'
import React from 'react'
import _ from 'lodash/fp'
import {
  CASE_ARCHIVED,
  CASE_CLOSED,
  CASE_DRAFT,
  REQUEST_REJECTED
} from '../../enums/CaseState';
import {Header} from '../../components/Header'
import { InfoBox } from '../../components/InfoBox';
import {PageContainer, PageFooter} from '../../components/PageLayout'
import {PurviewFooter} from '../../components/PurviewFooter'
import {RequestOverview} from '../../request/RequestOverview'
import {useUnarchiveDialog} from '../hooks/useUnarchiveDialog'
import { ADMIN } from '../../enums/UserRoles'

export const AdminRequestOverview = ({caseRequest,setCaseRequest}) => {
  const navigate = useNavigate()
  const unarchive = useUnarchiveDialog(caseRequest,_.flow(
    will(setCaseRequest,_.set("state",CASE_DRAFT)),
    will(navigate,`/admin/request/${caseRequest.requestCode}`)
  ))

  return <>
    <PageContainer>
        <Header title="Case overview">
          {
            (caseRequest.state === CASE_ARCHIVED || caseRequest.state === REQUEST_REJECTED) &&
          <Button highlight onClick={unarchive}>Unarchive case</Button>
          }
          {caseRequest.state === CASE_CLOSED &&
            <Button highlight onClick={() => navigate(`/admin/request/${caseRequest.requestCode}/report`)}>View final report</Button>
          }
        </Header>
        {caseRequest.state === CASE_ARCHIVED &&
          <InfoBox lightYellow css="margin-top: -1rem; margin-bottom: 2rem; padding:1.5rem">
            <p css="margin-bottom:0.25rem;"><b>This case was archived by an Administrator</b></p>
            <p css="margin-bottom:1.5rem;">If you need to continue this case, it can be unarchived.</p>
            {caseRequest?.caseInfo?.declineReason ? 
              <>
                <p css="margin-bottom:0.25rem;"><b>Reason given: </b></p>
                <p css="margin-bottom:0;"> {caseRequest?.caseInfo?.declineReason} </p>
              </>
            :  
              <>
                <p css="margin-bottom:0.25rem;"><b>Archive reason: </b></p>
                <p css="margin-bottom:0;"> <i>No reason provided</i> </p>
              </>
            }
          </InfoBox>
        }
        <RequestOverview caseRequest={caseRequest} setCaseRequest={setCaseRequest} withClinicalSummary role={ADMIN}/>
        <PageFooter>
          {
            (caseRequest.state === CASE_ARCHIVED || caseRequest.state === REQUEST_REJECTED) &&
          <Button highlight onClick={unarchive}>Unarchive case</Button>
          }
          {caseRequest.state === CASE_CLOSED &&
            <Button highlight onClick={() => navigate(`/admin/request/${caseRequest.requestCode}/report`)}>View final report</Button>
          }
        </PageFooter>
    </PageContainer>
    <PurviewFooter/>
  </>
}
