import { Button, SplitColumnsContainer } from '@startlibs/components';
import {
  ConfirmDialog,
  Field,
  FormValue,
  TextInput,
  WithForm,
  useConfirmDialog
} from '@startlibs/form';
import React, {useRef} from 'react'
import _ from 'lodash/fp'
import {
  Card,
  PageContainer,
  PageFooter,
  SectionHeading
} from '../components/PageLayout';
import {Header} from '../components/Header';
import {PurviewFooter} from '../components/PurviewFooter';
import {RequestOverview} from '../request/RequestOverview'
import {getJwt} from '../hooks/useJwt'
import { jwtPostFetcher } from '../utils/authFetch';
import {limitLength} from '../utils/utils'
import { PATIENT } from '../enums/UserRoles';

export const ReadOnlyOverview = ({caseRequest,setCaseRequest, allowUpload=false, allowDownload=true}) => {

  const formRef = useRef()
  const inputRef = useRef()

  React.useEffect(() => {
    formRef.current.resetForm()
  },[caseRequest.notes])

  const confirmSubmit = useConfirmDialog(() =>
    <ConfirmDialog
      title="Submit note"
      action={() => jwtPostFetcher(getJwt())("/api/readOnlyAccess/notes", formRef.current.getValues(), {method: "PUT"})}
      onSuccess={() => setCaseRequest(_.set("notes",formRef.current.getValue('notes')))}
      cancelLabel="Cancel"
      confirm={<Button highlight>Submit</Button>}
      notify="Notes submitted successfully."
    >
      <p>Your note will be submitted to the case and the institution will be notified.</p>
      <p>Would you like to proceed?</p>
    </ConfirmDialog>
  )

  return <> <PageContainer>
    <Header title="Case overview">
      <Button onClick={() => inputRef.current.scrollIntoView({behavior:'smooth'})}>Edit notes</Button>
    </Header>
    <RequestOverview caseRequest={caseRequest} setCaseRequest={setCaseRequest} allowUpload={allowUpload} allowDownload={allowDownload} role={PATIENT} />
    <SectionHeading>
      <h3>Notes</h3>
      <p>Add notes or commentary regarding this case. Your note will be sent to the institution when you hit submit.</p>
    </SectionHeading>
    <WithForm
      ref={formRef}
      values={{notes: caseRequest.notes || ""}}
    >{form => <>
      <Card>
        <div ref={inputRef}>
          <Field label="Your name:" mandatory>
            <TextInput
              path='name'
              placeholder="Your name here"
            />
          </Field>
          <SplitColumnsContainer>
            <Field label="E-mail:">
              <TextInput
                path='email'
                placeholder="Your email here"
              />
            </Field>
            <Field label="Phone number:">
              <TextInput
                path='phone'
                placeholder="Your phone here"
              />
            </Field>
          </SplitColumnsContainer>
          <Field label="Notes:" mandatory>
            <TextInput
              path='notes'
              textarea
              placeholder="Your notes here"
              transform={limitLength(9000,"Notes length limit reached.")}
            />
          </Field>
      </div></Card>
      <PageFooter>
        <FormValue path='notes'>{notes => <>
          {notes !== caseRequest.notes && !!notes && <Button onClick={form.resetForm}>Discard changes</Button>}
          <Button disabled={notes === caseRequest.notes} highlight onClick={confirmSubmit}>Submit notes</Button>
        </>}</FormValue>
      </PageFooter>
    </>}</WithForm>
  </PageContainer>
    <PurviewFooter/>
  </>
}
