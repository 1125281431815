import {getUUID} from './common'
import {RecordClass, RecordFormat} from './recordEnums'
import {Failed, Queued, Uploaded} from "../enums/RecordStatus";
import _ from "lodash/fp";
import {NonCompliantDicom} from "../../enums/RecordFormat";
import {Clinical, Pathology, Radiology} from "../../enums/RecordClass";
import {getMultiAttachmentState} from "../../utils/businessUtils";
import {getFileTypeIcon} from "../../utils/FileType";

const getType = recordClass => recordClass

export const isMultiFile = record =>
  [RecordFormat.Mirax, RecordFormat.HamamatsuVMS, RecordFormat.HamamatsuVMU].indexOf(
    record.recordFormat
  ) >= 0

export const isDicom = record => record.recordFormat === RecordFormat.DicomStudy || record.info?.type === RecordFormat.DicomStudy

const getStatus = (state, quarantined) => {
  if (quarantined) return Uploaded
  if (state === 'Submitted') return Uploaded
  return 'Accepted'
}

const getActions = (state, quarantined, hasMRN) => {
  if (hasMRN) {
    if (quarantined) {
      return 'delete'
    }
    if (state === 'Submitted') {
      return 'complete'
    }
    return 'readonly'
  }
  return 'none'
}

export const formatRecord = record => {
  const {
    recordClass,
    format,
    fileExtension,
    info,
    notes,
    metadata,
    whenCreatedEpochMilli,
    recordUID,
    quarantined,
    state,
    stateContext,
    originalFileName,
    doNotHide,
  } = record
  // const { description, studyUID, studyDescription } = info
  // const { studyUID, studyDescription } = info
  const description = info?.description || ''
  const studyUID = info?.studyUID || ''
  const studyDescription = info?.studyDescription || ''
  const status = getStatus(state, quarantined)

  return {
    description:
      studyDescription ||
      description,
    type: getType(recordClass, format),
    recordClass,
    recordFormat: format,
    uploadDate: new Date(whenCreatedEpochMilli),
    sortDate: whenCreatedEpochMilli,
    progress: null,
    status,
    info,
    notes,
    metadata,
    showDialog: quarantined,
    dialogVariant: quarantined ? 'warning' : undefined,
    actions: getActions(state, quarantined),
    quarantined,
    studyUID,
    recordUID,
    fileExtension,
    key: studyUID || (recordUID + ""),
    stateContext: stateContext,
    doNotHide: doNotHide,
  }
}

const DEFAULT_PATHOLOGY_METADATA = {}

const getMetadata = recordClass =>
  recordClass === RecordClass.Pathology ? DEFAULT_PATHOLOGY_METADATA : undefined

export const createNewRecord = ({description, recordClass, recordFormat, files, fileExtension }, extraProperties = {}) => ({
  description,
  type: getType(recordClass, recordFormat),
  recordClass,
  recordFormat,
  files,
  fileExtension,
  metadata: getMetadata(recordClass),
  uploadDate: new Date().getTime(),
  sortDate: new Date(),
  progress: null,
  status: Queued,
  actions: 'delete',
  key: extraProperties.studyUID || ("temp-"+getUUID()),
  isNew: true,
  ...extraProperties,
  params: {
    class: recordClass,
    format: recordFormat,
    ...extraProperties.params,
  },
})

export const getItemSessionUID = (item) => {
  return (item.key || item.recordUID)+""
}
export const getItemPersistedUID = (item) => {
  return (item.recordUID || item.key)+""
}
const STATIC_NON_COMPLIANT = {
  nonCompliant: true,
  key: NonCompliantDicom,
  recordUID: NonCompliantDicom,
  recordClass: Radiology,
  status: Uploaded
}

const _getRowsForRecords = createNonCompliant => (records) => {
  const [recordsWithoutNonCompliant,nonCompliantRecords] = _.partition(({recordFormat}) => recordFormat !== NonCompliantDicom,records)
  return recordsWithoutNonCompliant
    .concat(
      nonCompliantRecords.length
      ? createNonCompliant(nonCompliantRecords)
      : []
    )
}
export const getRowsForRecords = _getRowsForRecords(nonCompliantRecords => {
  const nonCompliantByStatus = _.groupBy('status',nonCompliantRecords)
  return {
    nonCompliant: true,
    key: NonCompliantDicom,
    recordUID: NonCompliantDicom,
    recordClass: Radiology,
    status: nonCompliantByStatus[Queued]?.length ? Queued : nonCompliantByStatus[Failed]?.length ? Failed : Uploaded,
    instances: nonCompliantRecords,
    recordUIDs: nonCompliantRecords.map(_.get('recordUID')),
    partiallyQuarantined: nonCompliantRecords.find(_.get('quarantined')),
    quarantined: nonCompliantRecords.every(_.get('quarantined')),
    progress: _.sum(nonCompliantRecords.map(_.get('progress')).map(progress => _.isNumber(progress) ? progress : 100)) / nonCompliantRecords.length
  }
})
export const getRowsForRecordsStatic = _getRowsForRecords(nonCompliantRecords => STATIC_NON_COMPLIANT)

export const isOfRecordClass = (recordClass) => (attachment) =>
  attachment?.recordClass === recordClass

export const getRecordIcon = (record) => {
  switch (record.recordClass) {
    case RecordClass.Pathology:
      return 'pathology'
    case RecordClass.Radiology:
      return 'dicom'
  }
  if (record.key === NonCompliantDicom) {
    return 'dicom'
  }
  if (record.recordClass === Clinical && ['xml','ccda','cda'].indexOf((record.fileExtension||"").toLowerCase())>=0) {
    return 'ccda'
  }
  return getFileTypeIcon(record.fileExtension)
}

export const canView = (attachment) => {
  return ["pdf","jpg","jpeg","png","gif","bmp","webp","pdf"].indexOf((attachment.fileExtension || "").toLowerCase())>=0  ||
    attachment.recordClass === Radiology || attachment.recordClass === Clinical || attachment.recordClass === Pathology
}

export const canPlay = (attachment) => {
  return ["webm","mp4","mov","ogv","m4a","ogg"].indexOf((attachment.fileExtension || "").toLowerCase())>=0
}