import {getDicomInstanceJson} from './DicomInstance'
import {executables, settings, system} from './UploaderBlacklist'
import {readFile} from '../utils/fileUtils'
import {checkIsCCDA, isCCDA, isPossibleCCDA} from '../utils/ccdaUtils'
// import utif from 'utif'

export const PATHOLOGY_EXTENSIONS = ['tiff','tif','bif','scn','svslide','svs','ndpi','isyntax']
const VALID_EXTENSIONS = PATHOLOGY_EXTENSIONS.concat(['jpg','jpeg','jpe','gif','png','bmp','ico','asf','asx','wax','wmv','wmx','avi','divx','flv','mov','qt','mpeg','mpg','mpe','mp4','m4v','ogv','webm','mkv','txt','asc','cc','csv','tsv','ics','rtx','htm','html','mp3','m4a','m4b','ra','ram','wav','ogg','oga','mid','midi','wma','mka','rtf','pdf','swf','tar','zip','gz','gzip','rar','7z','doc','pot','pps','ppt','wri','xla','xls','xlt','xlw','mdb','mpp','docx','docm','dotx','dotm','xlsx','xlsm','xlsb','xltx','xltm','xlam','pptx','pptm','ppsx','ppsm','potx','potm','ppam','sldx','sldm','onetoc','onetoc2','onetmp','onepkg','odt','odp','ods','odg','odc','odb','odf','wp','wpd','dcm','heic'])
const IGNORED_FILES = ['DICOMDIR','.DS_Store','.AppleDouble','.LSOverride','Icon\r\r','.DocumentRevisions-V100','.fseventsd','.Spotlight-V100','.TemporaryItems','.Trashes','.VolumeIcon.icns','.com.apple.timemachine.donotpresent','.AppleDB','.AppleDesktop','.apdisk','Thumbs.db','Thumbs.db:encryptable','ehthumbs.db', 'ehthumbs_vista.db','Desktop.ini','desktop.ini']
const IGNORED_FILE_PATTERNS = [/^\._.*/]
export const OTHER_KNOW_EXTENSIONS = [
  // 'jpg','jpeg','jpe','gif','png','bmp','webp','heic', // IMAGES
  'webp','heic', // 'jpg','jpeg', // IMAGES
  'webm', 'wmv','avi','mov','mpeg','mpg','ogg','mpe','mp4','m4v','ogv','mkv','m4a', // VIDEOS
  'pdf','txt','xml','cda','ccda','doc','docx','rtf','odt', 'wri', // NOTES
  // 'doc','docx','rtf','odt', 'wri', // NOTES
  'csv','tsv','xla','xls','xlt','xlw','xlsx','xlsm','xlsb','xltx','xltm','xlam', // EXCEL
  'mp3','m4a','m4b','wav','ogg','oga','mid','midi','wma','mka', // AUDIO
  'tar','zip','gz','gzip','rar','7z','s7z','xz','bz','bz2', // ZIPS
  'pot','pps','ppt','pptx','pptm','ppsx','ppsm','potx','potm','ppam', // POWERPOINTS
  'onepkg','odt','odp','ods','odg','odc','odb','odf','wp','wpd','dcm' // OTHER AND OPENDOCUMENT
]

const PARTIAL_DICOM_BUFFER_SIZE = 5e5


export const notIgnoredFiles = (file) => {
  if (IGNORED_FILES.indexOf(file.name)>=0) {
    return false
  } else if (IGNORED_FILE_PATTERNS.find(pattern => pattern.test(file.name))) {
    return false
  }
  return true
}

const testPathology = (file) => {
  // readFile(file,Math.min(file.size,20000000)).then(buffer => console.log(utif.decode(buffer)))
}

export const FileParser = (config = {partialBufferSize: PARTIAL_DICOM_BUFFER_SIZE}) => (file,withDicomBytes) => {
  if (file.size === 0) {
    return Promise.reject({empty: true})
  }
  else {
    // testPathology(file)
    if (isPossibleCCDA(file)) {
      return checkIsCCDA(file).then((isValid) => ({ccda:true,isValid:isValid,file}))
        .catch(() => ({ccda:true,isValid:false,file}))
    } else if (filterIsNonDicom(file)) {
      if (!filterIsValidNonDicom(file)) {
        return Promise.reject({invalidExtension:true})
      }
      /////////////////////////////////////
      // NON DICOM files
      /////////////////////////////////////
      return Promise.reject({nonDicomFile: true})
    }
    else {
      /////////////////////////////////////
      // try as DICOM P10 file
      /////////////////////////////////////
      // return Promise.reject({nonCompliant:true})
      return processDicomFile(file, config, true)
        .catch((e) => {
          
          // if (e && e.type === "error" && e.target && e.target.error && e.target.error.code === 4) {
          //   return Promise.reject({nonReadable:true})
          // }
          if (e?.type === "error" && e?.target?.error?.name === "NotFoundError") {
            return Promise.reject({fileNotFound:true})
          }
          if (filterIsBlacklisted(file)) {
            return Promise.reject({invalidExtension:true})
          }
          if (filterIsPossibleDicom(file)) {
            return Promise.reject({nonCompliant:true})
          }
          return Promise.reject(e)
        });
    }
  }
}

export const filterIsNonDicom = (file) => {
  if (file.size === 0) {
    return true
  }
  else {
    if (file.type !== undefined && file.type !== null && file.type !== "" && file.type.toLowerCase() !== "application/dicom") {
      return true
    }
  }
  return false
}

export const isOtherKnowExtension = (file) => (
  (OTHER_KNOW_EXTENSIONS.indexOf(file?.description?.toLowerCase().split(".").pop())>=0 
  && file?.description?.indexOf(".")>=0) 
  || OTHER_KNOW_EXTENSIONS.indexOf(file?.name?.toLowerCase().split(".").pop())>=0 
  || OTHER_KNOW_EXTENSIONS.indexOf(file?.fileExtension?.toLowerCase())>=0)
export const filterIsValidNonDicom = (file) => VALID_EXTENSIONS.indexOf(file.name.toLowerCase().split(".").pop())>=0
export const filterIsPossibleDicom = (file) => ["dcm"].indexOf(file.name.toLowerCase().split(".").pop())>=0
export const filterIsBlacklisted = (file) => [...executables,...settings,...system].indexOf(file.name.toUpperCase().split(".").pop())>=0


const arrayFromBuffers = (a, b) => {
  const merged = new Uint8Array(a.length + b.length);
  merged.set(a);
  merged.set(b, a.length);
  return merged
}

const processDicomFile = (file, config, completeFile) =>
  // try partial
  readFile(completeFile ? file : file.slice(0, Math.min(config.partialBufferSize, file.size)))
    .then((partialBufferArray) => {
      const partialByteArray = new Uint8Array(partialBufferArray);
      try {
        return {file,dicomInstance: getDicomInstanceJson(partialByteArray,!completeFile)}
      } catch (e) {
        if (file.size > config.partialBufferSize && !completeFile) {
          // try full
          return readFile(file.slice(config.partialBufferSize, file.size))
            .then((remainingBufferArray) => {
              const byteArray = arrayFromBuffers(partialByteArray, new Uint8Array(remainingBufferArray))
              return {file,dicomInstance: getDicomInstanceJson(byteArray),byteArray: (completeFile ? byteArray : undefined)}
            })
        } else {
          return Promise.reject(e)
        }
      }
    })