import {Button, SplitColumnsContainer} from '@startlibs/components'
import {ToggleCheckbox, WithForm} from '@startlibs/form'
import React from 'react'
import { Card, PageContainer } from '../../components/PageLayout';
import {getJwt} from '../../hooks/useJwt'
import {jwtFormFetcher, jwtGetFetcher} from '../../utils/authFetch'
import {setNotification} from '../../components/Notifications'
import {willUseSuspense} from '../../hooks/useSuspense'
import {FormattedMessage} from 'react-intl'

const useNotificationSettings = willUseSuspense(() => jwtGetFetcher(getJwt())("/api/providerInfo").then((info)=> info.notifications))

export const NotificationSettings = () => {

  const [notificationsSettings,setNotificationSettings] = React.useState(useNotificationSettings())


  return <PageContainer css="max-width: 100%;">
    <Card>
      <h3>Notification settings</h3>
      <p>Set defaults for email notifications for the following parties. Starred notifications can be toggled manually when performing the action.</p>
    <WithForm
      values={notificationsSettings}
      onSuccess={(values) =>{setNotificationSettings(values); setNotification("Notifications settings saved.")}}
      action={jwtFormFetcher(getJwt())("/api/admin/providerNotifications", {method: "PUT"})}
    >{form => <>
      <SplitColumnsContainer
        margin="big"
        viewportMinWidth={950}
        css="padding-top: 1.5rem;"
      >
        <div>
          <h4><FormattedMessage defaultMessage="Case contact or referring physician" description="NotificationSettings title of 'case contact or ref physician' section"/></h4>
          <p>Notify by email when an administrator:</p>
          <ToggleCheckbox path="caseRejected" label="Rejects pending request"/>
          <ToggleCheckbox path="caseAccepted" label="Accepts pending request"/>
          <ToggleCheckbox path="caseArchived" label="Archives case"/>
          <ToggleCheckbox path="caseCreated" label="Creates new case"/>
          <ToggleCheckbox path="reportReady" label="Completes case report*"/>
        </div>
        <div>
          <h4><FormattedMessage defaultMessage="Expert physician" description="NotificationSettings title of expert physician section"/></h4>
          <p>Notify by email when an administrator:</p>
          <ToggleCheckbox path="assignExpert" label="Assigns case to expert*"/>
          <ToggleCheckbox path="unassignExpert" label="Unassigns case from expert*"/>
        </div>
      </SplitColumnsContainer>
      <div css="text-align: right;">
        {form.hasChanged && <Button onClick={form.resetForm}>Reset changes</Button>}
        <Button disabled={!form.hasChanged} type="submit" highlight isLoading={form.isLoading}>Save changes</Button>
      </div>
    </>}</WithForm>
    </Card>
  </PageContainer>
}
