import {getColor, isMobile} from '@startlibs/utils';
import { useToggle } from '@startlibs/core';
import React from 'react'
import styled from 'styled-components';
import { AuditLogTableColumn, AuditLogTableRow, DetailContainer } from './Table';
import {formatDateNoUTC} from '../../../utils/utils'
import {TransitionDiv} from '@startlibs/components';
import { IfLocale } from '../../../hocs/IfLocale';

const STATUS_TO_COLOR = {
  'normal': 'success',
  'stat': 'alert',
  'time sensitive': 'warning',
}

const CaseStatus = styled.span`
  color: ${props => getColor(STATUS_TO_COLOR[(props.status || "").toLowerCase()] || "gray120")(props)};
  font-weight: 600;
`

export const AuditRow = ({record}) => {
  // const uploadDate = strToDate(study.study.uploadDate)
  // const formattedUploadDate = isNaN(uploadDate.getTime()) ? "" : formatDate(uploadDate, DATE_FORMAT())
  //caseCode: "REW3R"
  // createdEpochMili: 1593725999778
  // event: "CASE_REVIEW_UPDATED_REQUESTED"
  // firstName: "Guga"
  // id: 1996
  // lastName: "111"
  // who: "admin@purview"
  const showDetails = useToggle()
  
  return <AuditLogTableRow isMobile={isMobile()}>
    <AuditLogTableColumn className="date">
      <div>{formatDateNoUTC(new Date(record.createdEpochMili),"MM/dd/yyyy")} <span className="time">- {formatDateNoUTC(new Date(record.createdEpochMili),"hh:mm")}</span></div>
    </AuditLogTableColumn>
    <AuditLogTableColumn className="event">
      <div className="event-name">
        <strong>{record.event}</strong> <span className="nowrap id">(ID: {record.id})</span> {record?.message?.length > 0 ? <a className="link" onClick={showDetails.toggle}>Details</a> : null}
      </div>
      <TransitionDiv>{showDetails.isOpen && <DetailContainer><strong>Message: </strong>{record?.message}</DetailContainer>}</TransitionDiv>
    </AuditLogTableColumn>
    <IfLocale not contains="LEGAL">
      <AuditLogTableColumn className="case-id">{record.caseCode ? <a className="link" href={`/admin/request/${record.caseCode}`}>{record.caseCode}</a> : <span className="not-case">----</span>}</AuditLogTableColumn>
      <AuditLogTableColumn className="case-patient">{(record.firstName || record.lastName) ? <span>{record.firstName} {record.lastName}</span> : <span className="not-case">----</span>}</AuditLogTableColumn>
    </IfLocale>
    <IfLocale contains="LEGAL">
      <AuditLogTableColumn className="case-id-legal">{record.caseCode ? <a className="link" href={`/admin/request/${record.caseCode}`}>{record.caseCode}</a> : <span className="not-case">----</span>}</AuditLogTableColumn>
      <AuditLogTableColumn className="case-patient-legal">{(record.firstName || record.lastName) ? <span>{record.firstName} {record.lastName}</span> : <span className="not-case">----</span>}</AuditLogTableColumn>
    </IfLocale>
    
    <AuditLogTableColumn className="user">
      <div>{record.who}</div>
    </AuditLogTableColumn>
  </AuditLogTableRow>
}
