let idcount = 0

const getNextId = () => {
  idcount += 1
  return idcount
}

export const getUUID = () => `${Date.now()}${getNextId()}`

export const getFileNameAndExtension = (file) => {
  const fileName = file.name.replace(/(.*)\..+$/, '$1')
  const extension =
    file.name.indexOf('.') >= 0 ? file.name.replace(`${fileName}.`, '').slice(0, 10) : ''
  return [fileName.slice(0, 127), extension]
}
export const augmentFile = (file, path) => {
  const [filename, extension] = getFileNameAndExtension(file)
  return {
    file,
    path,
    filename,
    extension,
    key: path+"/"+filename+"."+extension
  }
}
