import {Icon} from '@startlibs/components';
import {getColor, media} from '@startlibs/utils';
import React from 'react';
import styled, {css} from 'styled-components';

const PageTitle = styled.h1 `
  font-size: 21px;
  margin-bottom: 1rem;
  flex-shrink; 0;
  margin-bottom: 0;
`
const SubHeader = styled.div `
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
  font-size: 13px;
  color: rgba(0,0,0,0.6);
`

export const ReturnButton = styled(Icon) `
  position: absolute;
  font-size: 23px;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  border-radius: 50%;
  background: rgba(0,0,0,0.07);
  left: -4.25rem;
  top: 8px;
  :hover {
    cursor: pointer;
    background: rgba(0,0,0,0.1);
  }
  :active {
    background: rgba(0,0,0,0.13);
  }
  ${media.max(895)`
    left: 0;
  `}
  ${media.max(530)`
    top: 0;
  `}
  ${props => props.returnTerms && css `
    position: static;
    text-decoration: none;
    color: ${getColor('gray90')};
    background: transparent;
    border: 1px solid rgba(0,0,0,0.05);
    font-size: 18px;
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    ${Icon} {
      padding-right: 2px;
    }
  `}
`

export const Header = styled(({caseRequest,title,className,children, isExpert}) => {

  return <div className={className}>
    <div>
      <PageTitle>{title ? title : "Case"}</PageTitle>
    </div>
    <div>
    {children}
    </div>
  </div>
})`
  border-bottom: 1px solid ${getColor('gray210')};
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 5rem;
  position: relative;
  ${media.max(895)`
    &.associate {
      padding-left: 4rem;
    }
  `}
  ${media.max(530)`
    flex-wrap: wrap;
    ${PageTitle} {
      flex-basis: 100%;
    }
  `}
 `
