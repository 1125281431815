import React from 'react'
import {isStateBefore} from '../../request/utils'
import {
  CaseId,
  Sidebar,
  SidebarCard,
  SidebarContainer,
  SidebarDesc, SidebarSeparator,
  SidebarTitle, StepLink
} from '../../admin/steps/RequestSidebar'
import {ScrollToTop} from '../../components/ScrollToTop'
import {CASE_CLOSED, PENDING_REQUEST} from '../../enums/CaseState'
import {Icon} from '@startlibs/components'
import {Match} from "../../components/Match";

export const ReadonlyRequestSidebar = ({caseRequest = {}}) => {



  return <Match path="/:userType/:step">{({match}) => {
    const step = match?.params && match?.params.step

    return <SidebarContainer className="hideOnPrint">
      <ScrollToTop when={step}/>
      <Sidebar>
        <SidebarCard>
          <div className="sidebar-heading">
            <SidebarDesc>CASE DETAILS</SidebarDesc>
            <SidebarTitle>{caseRequest.patientInfo.firstName} {caseRequest.patientInfo?.middleName} {caseRequest.patientInfo.lastName}</SidebarTitle>
            {caseRequest.requestCode && <CaseId><b>Case Id:</b> {caseRequest.requestCode}</CaseId>}
          </div>

          <StepLink
            to="overview"
            selected
          ><Icon icon="user-line"/> Case Overview</StepLink>

        </SidebarCard>
      </Sidebar>
    </SidebarContainer>
  }
  }</Match>
}
